import moment from 'moment';
import * as i0 from "@angular/core";
export class TimeAgoPipe {
    constructor(cdRef, ngZone) {
        this.cdRef = cdRef;
        this.ngZone = ngZone;
    }
    format(m) {
        return m.from(moment(), this.lastOmitSuffix);
    }
    transform(value, omitSuffix, formatFn) {
        if (this.hasChanged(value, omitSuffix)) {
            this.lastTime = this.getTime(value);
            this.lastValue = value;
            this.lastOmitSuffix = omitSuffix;
            this.lastLocale = this.getLocale(value);
            this.formatFn = formatFn || this.format.bind(this);
            this.removeTimer();
            this.createTimer();
            this.lastText = this.formatFn(moment(value));
        }
        else {
            this.createTimer();
        }
        return this.lastText;
    }
    ngOnDestroy() {
        this.removeTimer();
    }
    createTimer() {
        if (this.currentTimer) {
            return;
        }
        const momentInstance = moment(this.lastValue);
        const timeToUpdate = this.getSecondsUntilUpdate(momentInstance) * 1000;
        this.currentTimer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    if (this.formatFn)
                        this.lastText = this.formatFn(moment(this.lastValue));
                    this.currentTimer = null;
                    this.ngZone.run(() => this.cdRef.markForCheck());
                }, timeToUpdate);
            }
            else {
                return null;
            }
        });
    }
    removeTimer() {
        if (this.currentTimer) {
            window.clearTimeout(this.currentTimer);
            this.currentTimer = null;
        }
    }
    getSecondsUntilUpdate(momentInstance) {
        const howOld = Math.abs(moment().diff(momentInstance, 'minute'));
        if (howOld < 1) {
            return 1;
        }
        else if (howOld < 60) {
            return 30;
        }
        else if (howOld < 180) {
            return 300;
        }
        else {
            return 3600;
        }
    }
    hasChanged(value, omitSuffix) {
        return (this.getTime(value) !== this.lastTime ||
            this.getLocale(value) !== this.lastLocale ||
            omitSuffix !== this.lastOmitSuffix);
    }
    getTime(value) {
        if (moment.isDate(value)) {
            return value.getTime();
        }
        else if (moment.isMoment(value)) {
            return value.valueOf();
        }
        else {
            return moment(value).valueOf();
        }
    }
    getLocale(value) {
        return moment.isMoment(value) ? value.locale() : moment.locale();
    }
    static { this.ɵfac = function TimeAgoPipe_Factory(t) { return new (t || TimeAgoPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16), i0.ɵɵdirectiveInject(i0.NgZone, 16)); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amTimeAgo", type: TimeAgoPipe, pure: false }); }
}

import * as i0 from "@angular/core";
import * as i1 from "../../modules/dotlottie-player/dotlottie-player.component";
export class NotFoundPageComponent {
    static { this.ɵfac = function NotFoundPageComponent_Factory(t) { return new (t || NotFoundPageComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotFoundPageComponent, selectors: [["app-not-found-page"]], decls: 3, vars: 0, consts: [[1, "container-fluid", "g-0", "min-vh-100", "background"], [1, "d-flex", "flex-row", "justify-content-center"], ["autoplay", "", "loop", "", "mode", "normal", "src", "/assets/animations/404_lotties.lottie", 2, "width", "400px"]], template: function NotFoundPageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
            i0.ɵɵelement(2, "dotlottie-player", 2);
            i0.ɵɵelementEnd()();
        } }, dependencies: [i1.DotlottiePlayerComponent], styles: [".background[_ngcontent-%COMP%] {\n  background: #8817F8;\n}"] }); }
}

import moment from 'moment';
import * as i0 from "@angular/core";
export class DateFormatPipe {
    transform(value, ...args) {
        if (!value) {
            return '';
        }
        return moment(value).format(args[0]);
    }
    static { this.ɵfac = function DateFormatPipe_Factory(t) { return new (t || DateFormatPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amDateFormat", type: DateFormatPipe, pure: true }); }
}

import { OverlayModule, FullscreenOverlayContainer, OverlayContainer, } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ContextMenuService } from './contextMenu.service';
import { CONTEXT_MENU_OPTIONS } from './contextMenu.tokens';
import * as i0 from "@angular/core";
export class ContextMenuModule {
    static forRoot(options) {
        return {
            ngModule: ContextMenuModule,
            providers: [
                ContextMenuService,
                {
                    provide: CONTEXT_MENU_OPTIONS,
                    useValue: options,
                },
                { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
            ],
        };
    }
    static { this.ɵfac = function ContextMenuModule_Factory(t) { return new (t || ContextMenuModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ContextMenuModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            OverlayModule] }); }
}

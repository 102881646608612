import { authFeatureKey } from "../../modules/stores/auth";
import * as i0 from "@angular/core";
import * as i1 from "../../services/push-notification.service";
import * as i2 from "@angular/service-worker";
import * as i3 from "../../types/AppConfig";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/action-logging.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/common";
import * as i10 from "@angular/forms";
import * as i11 from "../../modules/directives/default-image.directive";
import * as i12 from "../../components/todo-widget/todo-widget.component";
import * as i13 from "../../components/action-history-dialog/action-history-dialog.component";
import * as i14 from "../../modules/ngx-moment/time-ago.pipe";
function HomePageComponent_div_35_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "p")(2, "a", 22);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(4);
    i0.ɵɵelementStart(5, "a", 23);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(7);
    i0.ɵɵpipe(8, "amTimeAgo");
    i0.ɵɵpipe(9, "date");
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const action_r4 = ctx.$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(action_r4.tblUser.name);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate3(" ", action_r4.action, " ", action_r4.description, " ", action_r4.type, " ");
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("href", action_r4.routerLink, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(action_r4.targetName);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(8, 7, i0.ɵɵpipeBind2(9, 9, action_r4.time, "yyyy-MM-dd hh:mm:ss")), "");
} }
function HomePageComponent_div_35_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, HomePageComponent_div_35_div_1_Template, 10, 12, "div", 21);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r0.historyAction);
} }
function HomePageComponent_ng_template_38_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 24)(1, "div", 25)(2, "h1", 26);
    i0.ɵɵtext(3, "Action History");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "button", 27);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "div", 28)(6, "form")(7, "div", 29);
    i0.ɵɵelement(8, "app-action-history-dialog");
    i0.ɵɵelementEnd()()()();
} if (rf & 2) {
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("matDialogClose", false);
} }
export class HomePageComponent {
    constructor(pushNotificationService, swPush, config, router, store, actionLoggingService, dialog, overlay) {
        this.pushNotificationService = pushNotificationService;
        this.swPush = swPush;
        this.config = config;
        this.router = router;
        this.store = store;
        this.actionLoggingService = actionLoggingService;
        this.dialog = dialog;
        this.overlay = overlay;
        this.logLength = 100;
        this.logPageSize = 10;
        this.logPaging = [];
        this.historyAction = [];
        this.appBaseUrl = config.appBaseUrl;
        this.authState = this.store.select(authFeatureKey);
        this.log_result = this.actionLoggingService.getFullLog({ 'limit': 5, 'page': 1 });
    }
    ngOnInit() {
        this.log_result.subscribe({
            error: (err) => {
                console.log(err);
            },
            next: (result) => {
                this.historyAction = this.actionLoggingService.process_data(result[0].data);
            }
        });
    }
    open(content) {
        this.dialog.open(content, {
            disableClose: true,
            hasBackdrop: true
        });
    }
    static { this.ɵfac = function HomePageComponent_Factory(t) { return new (t || HomePageComponent)(i0.ɵɵdirectiveInject(i1.PushNotificationService), i0.ɵɵdirectiveInject(i2.SwPush), i0.ɵɵdirectiveInject(i3.AppConfig, 8), i0.ɵɵdirectiveInject(i4.Router), i0.ɵɵdirectiveInject(i5.Store), i0.ɵɵdirectiveInject(i6.ActionLoggingService), i0.ɵɵdirectiveInject(i7.MatDialog), i0.ɵɵdirectiveInject(i8.Overlay)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: HomePageComponent, selectors: [["app-home-page"]], decls: 40, vars: 12, consts: [[1, "p-1", "p-md-4"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-column", "flex-md-row", "align-items-md-center"], [1, "d-inline-flex", "flex-row", "align-items-center"], [1, "me-3", 2, "font-weight", "500", "font-size", "24px"], [1, "row", "g-0", "g-md-2", "m-1", "m-md-3"], [1, "col-12", "col-md-9"], [1, "col-12", "col-md-3"], [1, "row"], [1, "col-12"], [1, "card", "py-3"], [1, "card-body"], [1, "img-fluid", "rounded-circle", "d-block", "mx-auto", "avatar", 3, "src"], [1, "row", "mt-4"], [1, "col-12", "text-center"], [1, "row", "mt-3"], [1, "card"], [2, "font-weight", "700", "font-size", "1.25rem", "text-align", "center"], [4, "ngIf"], ["target", "_blank", 1, "btn", "btn-primary", "text-white", "btn-block", "btnShowFull", 3, "click"], ["content", ""], [4, "ngFor", "ngForOf"], ["href", "javascript:void(0)", 1, "fw-bold", "text-decoration-none"], [1, "fw-bold", "text-decoration-none", 3, "href"], [1, "modal-class"], [1, "modal-header"], ["id", "modal-basic-title", 1, "modal-title"], ["type", "button", "aria-label", "Close", 1, "btn-close", 3, "matDialogClose"], [1, "modal-body"], [1, "mb-3"]], template: function HomePageComponent_Template(rf, ctx) { if (rf & 1) {
            const _r6 = i0.ɵɵgetCurrentView();
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3)(4, "span", 4);
            i0.ɵɵtext(5, "Dashboard view");
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(6, "div", 5)(7, "div", 6);
            i0.ɵɵelement(8, "app-todo-widget");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "div", 7)(10, "div", 8)(11, "div", 9)(12, "div", 10)(13, "div", 11)(14, "div", 8)(15, "div", 9);
            i0.ɵɵelement(16, "img", 12);
            i0.ɵɵpipe(17, "async");
            i0.ɵɵpipe(18, "async");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(19, "div", 13)(20, "div", 14)(21, "h3");
            i0.ɵɵtext(22);
            i0.ɵɵpipe(23, "async");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(24, "h6");
            i0.ɵɵtext(25);
            i0.ɵɵpipe(26, "async");
            i0.ɵɵelementEnd()()()()()()();
            i0.ɵɵelementStart(27, "div", 15)(28, "div", 9)(29, "div", 16)(30, "div", 11)(31, "div", 8)(32, "div", 9)(33, "p", 17);
            i0.ɵɵtext(34, "Last Activity");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(35, HomePageComponent_div_35_Template, 2, 1, "div", 18);
            i0.ɵɵelementStart(36, "a", 19);
            i0.ɵɵlistener("click", function HomePageComponent_Template_a_click_36_listener() { i0.ɵɵrestoreView(_r6); const _r1 = i0.ɵɵreference(39); return i0.ɵɵresetView(ctx.open(_r1)); });
            i0.ɵɵtext(37, "View More");
            i0.ɵɵelementEnd()()()()()()()()()();
            i0.ɵɵtemplate(38, HomePageComponent_ng_template_38_Template, 9, 1, "ng-template", null, 20, i0.ɵɵtemplateRefExtractor);
        } if (rf & 2) {
            let tmp_0_0;
            let tmp_1_0;
            let tmp_2_0;
            i0.ɵɵadvance(16);
            i0.ɵɵpropertyInterpolate("src", ((tmp_0_0 = i0.ɵɵpipeBind1(17, 4, ctx.authState)) == null ? null : tmp_0_0.me == null ? null : tmp_0_0.me.avatar) ? (tmp_0_0 = i0.ɵɵpipeBind1(18, 6, ctx.authState)) == null ? null : tmp_0_0.me == null ? null : tmp_0_0.me.avatar : ctx.appBaseUrl + "../placeholder/300x300", i0.ɵɵsanitizeUrl);
            i0.ɵɵadvance(6);
            i0.ɵɵtextInterpolate((tmp_1_0 = i0.ɵɵpipeBind1(23, 8, ctx.authState)) == null ? null : tmp_1_0.me == null ? null : tmp_1_0.me.name);
            i0.ɵɵadvance(3);
            i0.ɵɵtextInterpolate((tmp_2_0 = i0.ɵɵpipeBind1(26, 10, ctx.authState)) == null ? null : tmp_2_0.me == null ? null : tmp_2_0.me.email);
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngIf", ctx.historyAction);
        } }, dependencies: [i9.NgForOf, i9.NgIf, i10.ɵNgNoValidate, i10.NgControlStatusGroup, i10.NgForm, i7.MatDialogClose, i11.DefaultImageDirective, i12.TodoWidgetComponent, i13.ActionHistoryDialogComponent, i9.AsyncPipe, i9.DatePipe, i14.TimeAgoPipe], styles: [".card[_ngcontent-%COMP%] {\n  border: none !important;\n}\n\n.avatar[_ngcontent-%COMP%] {\n  border: 5px solid #8817F8;\n}\n\n.task-list-item[_ngcontent-%COMP%]:not(:first-child) {\n  border-top: 0.5px solid #E0E0E0;\n}\n\n.btnShowFull[_ngcontent-%COMP%] {\n  float: right;\n}\n\n  .modal-dialog {\n  width: 900px !important;\n}"] }); }
}

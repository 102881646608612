import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { fromSnackbar } from "../../modules/stores/reducer";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as i0 from "@angular/core";
import * as i1 from "src/app/types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "../../services/projects.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/material/form-field";
import * as i12 from "ngx-dropzone";
import * as i13 from "../../modules/directives/default-image.directive";
import * as i14 from "@angular/material/input";
function EditProjectDialogComponent_mat_error_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Vui l\u00F2ng nh\u1EADp \u0111\u1EA7y \u0111\u1EE7 t\u00EAn d\u1EF1 \u00E1n");
    i0.ɵɵelementEnd();
} }
function EditProjectDialogComponent_ngx_dropzone_image_preview_23_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngx-dropzone-image-preview", 19);
    i0.ɵɵlistener("removed", function EditProjectDialogComponent_ngx_dropzone_image_preview_23_Template_ngx_dropzone_image_preview_removed_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r4); const f_r2 = restoredCtx.$implicit; const ctx_r3 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r3.onRemove(f_r2)); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const f_r2 = ctx.$implicit;
    i0.ɵɵproperty("file", f_r2)("removable", true);
} }
export class EditProjectDialogComponent {
    constructor(config, $gaService, dialogRef, data, dialog, overlay, projectsService, store) {
        this.config = config;
        this.$gaService = $gaService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.overlay = overlay;
        this.projectsService = projectsService;
        this.store = store;
        this.loading = false;
        this.form = new FormGroup({
            _id: new FormControl(''),
            color: new FormControl("#" + Math.floor(Math.random() * 16777215).toString(16)),
            description: new FormControl('', [Validators.maxLength(100)]),
            file: new FormControl(''),
            name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        });
        this.hideRequiredControl = new FormControl(false);
        this.floatLabelControl = new FormControl('auto');
        this.submited = false;
        this.files = [];
        this.form.patchValue({ ...data.project });
    }
    async onSubmit() {
        this.submited = true;
        this.loading = true;
        if (this.form.valid) {
            try {
                const data = this.toFormData(this.form.value);
                const project$ = await lastValueFrom(this.projectsService.update(data));
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Cập nhật dự án thành công." }));
                this.dialogRef.close(project$);
            }
            catch (e) {
                console.log(e);
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Không thể cập nhật dự án, vui lòng liên hệ người quản trị." }));
            }
            finally {
                this.loading = false;
            }
        }
    }
    getFloatLabelValue() {
        return this.floatLabelControl.value || 'auto';
    }
    onSelect($event) {
        this.files.push(...$event.addedFiles);
        if (this.files.length > 1) {
            this.replaceFile();
        }
        this.form.patchValue({
            file: this.files[0]
        });
    }
    replaceFile() {
        this.files.splice(0, 1); // index =0 , remove_count = 1
    }
    onRemove(f) {
        this.files.splice(this.files.indexOf(f), 1);
        this.form.patchValue({
            file: null
        });
    }
    toFormData(formValue) {
        const formData = new FormData();
        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }
        return formData;
    }
    async deleteProject() {
        this.submited = true;
        this.loading = true;
        if (this.form.valid) {
            try {
                this.dialogRef_confirm = this.dialog.open(ConfirmDialogComponent, { data: { message: "Bạn có chắc muốn xóa dự án này", title: "Xóa dự án" }, width: "400px" });
                this.dialogRef_confirm.afterClosed().subscribe(async (dialogResult) => {
                    if (dialogResult) {
                        if (this.config.ga)
                            this.$gaService.event("edit-project", 'project', 'edit-project');
                        const result = await lastValueFrom(this.projectsService.delete(this.data.project._id));
                        this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Xóa dự án thành công." }));
                        this.dialogRef.close(result);
                    }
                });
            }
            catch (e) {
                console.log(e);
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Không thể xóa dự án, vui lòng liên hệ người quản trị." }));
            }
            finally {
                this.loading = false;
            }
        }
    }
    static { this.ɵfac = function EditProjectDialogComponent_Factory(t) { return new (t || EditProjectDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i3.MatDialog), i0.ɵɵdirectiveInject(i4.Overlay), i0.ɵɵdirectiveInject(i5.ProjectsService), i0.ɵɵdirectiveInject(i6.Store)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: EditProjectDialogComponent, selectors: [["app-edit-project-dialog"]], decls: 33, vars: 15, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 3, "cdkTrapFocusAutoCapture"], [1, "col-12"], [1, "d-flex", "flex-column", "align-items-center", "my-2"], ["src", "assets/img/logo.png", 2, "height", "50px"], ["novalidate", "", 3, "formGroup", "ngSubmit"], [1, "row", "g-0", "px-4", "py-2", 2, "background", "#F2F2F2"], [1, "mt-2", "mb-5", 2, "font-size", "1.5rem", "font-weight", "700"], ["appearance", "outline", 1, "mb-3", "w-100", 3, "floatLabel", "hideRequiredMarker"], ["matInput", "", "id", "name", "name", "name", "formControlName", "name", "maxlength", "50", "placeholder", "T\u1ED1i \u0111a 50 k\u00FD t\u1EF1"], [4, "ngIf"], [1, "mb-3"], ["accept", "image/jpeg,image/jpg,image/png,image/gif,image/webp", 3, "multiple", "maxFileSize", "change"], [1, "fa-thin", "fa-arrow-up-from-bracket", "fa-3x", "mb-2"], ["ngProjectAs", "ngx-dropzone-preview", "class", "ngx-dropzone-preview", 5, ["ngx-dropzone-preview"], 3, "file", "removable", "removed", 4, "ngFor", "ngForOf"], [1, "d-flex", "flex-row", "p-4"], ["type", "button", "color", "warn", "mat-raised-button", "", 3, "disabled", "click"], [1, "flex-spacer"], ["mat-button", "", 3, "disabled", "mat-dialog-close"], ["cdkFocusInitial", "", "mat-raised-button", "", "color", "primary", 1, "ms-3", 2, "color", "white", "font-size", "15px", "font-weight", "700", 3, "disabled"], ["ngProjectAs", "ngx-dropzone-preview", 5, ["ngx-dropzone-preview"], 1, "ngx-dropzone-preview", 3, "file", "removable", "removed"]], template: function EditProjectDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
            i0.ɵɵelement(3, "img", 3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "form", 4);
            i0.ɵɵlistener("ngSubmit", function EditProjectDialogComponent_Template_form_ngSubmit_4_listener() { return ctx.onSubmit(); });
            i0.ɵɵelementStart(5, "div", 5)(6, "div", 1)(7, "p", 6);
            i0.ɵɵtext(8, "Th\u00F4ng tin d\u1EF1 \u00E1n?");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "p");
            i0.ɵɵtext(10, "T\u00EAn d\u1EF1 \u00E1n");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "div")(12, "mat-form-field", 7);
            i0.ɵɵelement(13, "input", 8);
            i0.ɵɵtemplate(14, EditProjectDialogComponent_mat_error_14_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(15, "div", 10)(16, "p");
            i0.ɵɵtext(17, "\u1EA2nh b\u00ECa d\u1EF1 \u00E1n");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(18, "ngx-dropzone", 11);
            i0.ɵɵlistener("change", function EditProjectDialogComponent_Template_ngx_dropzone_change_18_listener($event) { return ctx.onSelect($event); });
            i0.ɵɵelementStart(19, "ngx-dropzone-label");
            i0.ɵɵelement(20, "i", 12);
            i0.ɵɵelementStart(21, "p");
            i0.ɵɵtext(22, "T\u1EA3i \u1EA3nh cover t\u1EEB thi\u1EBFt b\u1ECB c\u1EE7a b\u1EA1n");
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(23, EditProjectDialogComponent_ngx_dropzone_image_preview_23_Template, 1, 2, "ngx-dropzone-image-preview", 13);
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(24, "div", 14)(25, "button", 15);
            i0.ɵɵlistener("click", function EditProjectDialogComponent_Template_button_click_25_listener() { return ctx.deleteProject(); });
            i0.ɵɵtext(26, "X\u00F3a d\u1EF1 \u00E1n");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(27, "div", 16);
            i0.ɵɵelementStart(28, "button", 17);
            i0.ɵɵtext(29, "Cancel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(30, "button", 18);
            i0.ɵɵelement(31, "i");
            i0.ɵɵtext(32, " X\u00E1c nh\u1EADn ");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(8);
            i0.ɵɵproperty("floatLabel", ctx.getFloatLabelValue())("hideRequiredMarker", true);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.form.controls["name"].invalid);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("multiple", false)("maxFileSize", 1000000);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngForOf", ctx.files);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("disabled", ctx.loading)("mat-dialog-close", true);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMapInterpolate1("fa fa-spinner fa-spin me-3 text-white ", ctx.loading ? "d-inline-flex" : "d-none", "");
        } }, dependencies: [i7.NgForOf, i7.NgIf, i8.ɵNgNoValidate, i8.DefaultValueAccessor, i8.NgControlStatus, i8.NgControlStatusGroup, i8.MaxLengthValidator, i9.MatButton, i3.MatDialogClose, i10.CdkTrapFocus, i8.FormGroupDirective, i8.FormControlName, i11.MatFormField, i11.MatError, i12.NgxDropzoneComponent, i12.NgxDropzoneLabelDirective, i12.NgxDropzoneImagePreviewComponent, i13.DefaultImageDirective, i14.MatInput] }); }
}

import * as i0 from "@angular/core";
export class DateTimeService {
    getMonday(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff)).getTime();
    }
    getLastWeekSameDay() {
        const d = new Date();
        const diff = d.getDate() - 7;
        return new Date(d.setDate(diff)).getTime();
    }
    getLastMonthSameDay() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).getTime();
    }
    getFirstDayOfYear() {
        const DayOfYear = new Date(new Date().getFullYear(), 0, 1).getTime();
        return DayOfYear;
    }
    static { this.ɵfac = function DateTimeService_Factory(t) { return new (t || DateTimeService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DateTimeService, factory: DateTimeService.ɵfac, providedIn: 'root' }); }
}

import * as i0 from "@angular/core";
export class AppConfig {
    constructor() {
        this.version = '0.0.0';
        this.production = false;
        this.auto_update = false;
        this.socket_endpoint = "https://socket.tuesday.baby";
        this.appUrl = '';
        this.appBaseUrl = '';
        this.url = '';
        this.baseUrl = '';
    }
    static { this.ɵfac = function AppConfig_Factory(t) { return new (t || AppConfig)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AppConfig, factory: AppConfig.ɵfac }); }
}
export class GoogleConfig {
    constructor() {
        this.appId = '';
        this.clientId = '';
        this.apiKey = '';
    }
}
export class GCMConfig {
}
export class VapidConfig {
}

import { authFeatureKey } from "../modules/stores/auth";
import { Socket } from "../utils/ngx-socket-io";
import * as i0 from "@angular/core";
import * as i1 from "../types/AppConfig";
import * as i2 from "@ngrx/store";
export class SocketioService extends Socket {
    constructor(_config, store) {
        super({ options: { transports: ["websocket", "polling"] }, url: _config.socket_endpoint });
        this._config = _config;
        this.store = store;
        this.authState = this.store.select(authFeatureKey);
        this.authState.subscribe({
            next: (auth) => {
                if (auth && auth.token && auth.device) {
                    this.ioSocket.auth = { cid: auth.device, token: auth.token };
                }
            }
        });
    }
    static { this.ɵfac = function SocketioService_Factory(t) { return new (t || SocketioService)(i0.ɵɵinject(i1.AppConfig), i0.ɵɵinject(i2.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SocketioService, factory: SocketioService.ɵfac }); }
}

import * as i0 from "@angular/core";
export class VarDirective {
    constructor(templateRef, vcRef) {
        this.templateRef = templateRef;
        this.vcRef = vcRef;
        this.context = { $implicit: null, ngVar: null, };
        this.hasView = false;
    }
    set ngVar(context) {
        this.context.$implicit = this.context.ngVar = context;
        if (!this.hasView) {
            this.vcRef.createEmbeddedView(this.templateRef, this.context);
            this.hasView = true;
        }
    }
    static { this.ɵfac = function VarDirective_Factory(t) { return new (t || VarDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: VarDirective, selectors: [["", "ngVar", ""]], inputs: { ngVar: "ngVar" } }); }
}

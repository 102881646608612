import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class WorkspaceService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    find(data) {
        const url = `${this.config.appUrl}/workspace/find`;
        return this.http.post(url, data);
    }
    filterWorkspace(query) {
        const url = `${this.config.appUrl}/workspace/filter`;
        return this.http.post(url, query);
    }
    invite(data) {
        const url = `${this.config.appUrl}/workspace/invite`;
        return this.http.post(url, data);
    }
    getInviteCode() {
        const url = `${this.config.appUrl}/workspace/invite-code`;
        return this.http.get(url);
    }
    acceptInvite(query) {
        const url = `${this.config.appUrl}/workspace/join`;
        return this.http.post(url, query);
    }
    removeMember(query) {
        const url = `${this.config.appUrl}/workspace/remove-member`;
        return this.http.post(url, query);
    }
    listMyWorkspace() {
        const url = `${this.config.appUrl}/workspace/list-workspace`;
        return this.http.get(url);
    }
    updateWorkspace(data) {
        const url = `${this.config.appUrl}/workspace/update`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function WorkspaceService_Factory(t) { return new (t || WorkspaceService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: WorkspaceService, factory: WorkspaceService.ɵfac, providedIn: 'root' }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt";
import * as i3 from "../types/AppConfig";
import * as i4 from "@ngrx/store";
export class ChannelService {
    constructor(http, jwtHelper, config, store) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.config = config;
        this.store = store;
    }
    getFBPageList(data) {
        const url = `${this.config.appUrl}/channel/fb-pages`;
        return this.http.post(url, data);
    }
    addFBPage(data) {
        const url = `${this.config.appUrl}/channel/add-fb-channel`;
        return this.http.post(url, data);
    }
    listChannel() {
        const url = `${this.config.appUrl}/channel/list`;
        return this.http.get(url);
    }
    removeChannel(id) {
        const url = `${this.config.appUrl}/channel/${id}`;
        return this.http.delete(url);
    }
    static { this.ɵfac = function ChannelService_Factory(t) { return new (t || ChannelService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.AppConfig, 8), i0.ɵɵinject(i4.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ChannelService, factory: ChannelService.ɵfac, providedIn: 'root' }); }
}

import { CommonModule } from '@angular/common';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { reducer, SocketEffects, socketFeatureKey } from "../socket";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class SocketModule {
    static { this.ɵfac = function SocketModule_Factory(t) { return new (t || SocketModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SocketModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            StoreModule.forFeature(socketFeatureKey, reducer),
            EffectsModule.forFeature([SocketEffects])] }); }
}

import { authFeatureKey } from "../modules/stores/auth";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt";
import * as i3 from "../types/AppConfig";
import * as i4 from "@ngrx/store";
export class GuestAuthService {
    constructor(http, jwtHelper, config, store) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.config = config;
        this.store = store;
        this.guestAuthState = this.store.select(authFeatureKey);
    }
    signIn(email, secret_code) {
        const url = `${this.config.appUrl}/auth/guest-signin`;
        return this.http.post(url, { email, secret_code });
    }
    getToken() {
        return localStorage.getItem('x-access-token');
    }
    forgot(data) {
        const url = `${this.config.appUrl}/auth/guest-forgot`;
        return this.http.post(url, data);
    }
    reset(data) {
        const url = `${this.config.appUrl}/auth/guest-reset`;
        return this.http.post(url, data);
    }
    isAuthenticated() {
        const token = this.getToken();
        if (token)
            return !this.jwtHelper.isTokenExpired(token);
        return false;
    }
    static { this.ɵfac = function GuestAuthService_Factory(t) { return new (t || GuestAuthService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.AppConfig, 8), i0.ɵɵinject(i4.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GuestAuthService, factory: GuestAuthService.ɵfac, providedIn: 'root' }); }
}

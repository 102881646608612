import { createReducer, on } from '@ngrx/store';
import { socket_notify } from './socket.actions';
export const socketFeatureKey = 'socket';
export const initialState = {
    data: undefined,
    event: undefined
};
export const _reducer = createReducer(initialState, on(socket_notify, (state, { event, data }) => ({ ...state, data, event })));
export function reducer(state, action) {
    return _reducer(state, action);
}

import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { fromSnackbar } from "../../modules/stores/reducer";
import { AttachmentType } from "../../types/Project";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/forms";
import * as i5 from "../../services/file.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "ngx-dropzone";
import * as i11 from "../../modules/directives/default-image.directive";
function UploadDialogComponent_ngx_dropzone_preview_14_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngx-dropzone-preview", 13);
    i0.ɵɵlistener("removed", function UploadDialogComponent_ngx_dropzone_preview_14_Template_ngx_dropzone_preview_removed_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r3); const f_r1 = restoredCtx.$implicit; const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.onRemove(f_r1)); });
    i0.ɵɵelementStart(1, "ngx-dropzone-label");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const f_r1 = ctx.$implicit;
    i0.ɵɵproperty("removable", true);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate2("", f_r1.name, " (", f_r1.type, ")");
} }
export class UploadDialogComponent {
    constructor(config, $gaService, dialogRef, data, fb, fileService, store) {
        this.config = config;
        this.$gaService = $gaService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.fb = fb;
        this.fileService = fileService;
        this.store = store;
        this.loading = false;
        this.files = [];
        this.form = new FormGroup({});
        console.log(data);
        this.project = data.project;
        this.task = data.task;
    }
    onSelect($event) {
        this.files.push(...$event.addedFiles);
    }
    onRemove(f) {
        this.files.splice(this.files.indexOf(f), 1);
    }
    onNoClick() {
        this.dialogRef.close(false);
    }
    async onSubmit() {
        try {
            if (this.files.length !== 0) {
                this.loading = true;
                const data = this.toFormData(this.form.value);
                for (let i = 0; i < this.files.length; i++) {
                    data.append("file", this.files[i]);
                }
                const uploadResults = await lastValueFrom(this.fileService.uploadPrivateFile(this.project, this.task.id, data));
                if (uploadResults) {
                    const atts = [];
                    for (const _uploadResult of uploadResults) {
                        const _att = { provider: AttachmentType.upload, url: _uploadResult.url };
                        atts.push(_att);
                    }
                    if (this.config.ga)
                        this.$gaService.event("upload-file", 'project', 'upload-file');
                    this.loading = false;
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "success-dialog" }, message: "Upload file success" }));
                    this.dialogRef.close(atts);
                }
            }
        }
        catch (err) {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
        }
    }
    toFormData(formValue) {
        const formData = new FormData();
        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }
        return formData;
    }
    static { this.ɵfac = function UploadDialogComponent_Factory(t) { return new (t || UploadDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i4.FormBuilder), i0.ɵɵdirectiveInject(i5.FileService), i0.ɵɵdirectiveInject(i6.Store)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UploadDialogComponent, selectors: [["app-upload-dialog"]], decls: 21, vars: 11, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 3, "cdkTrapFocusAutoCapture"], [1, "col-12", "d-flex", "flex-column"], [1, "d-flex", "flex-column", "align-items-center", "my-2"], ["src", "assets/img/logo.png", 2, "height", "50px"], ["novalidate", "", 3, "formGroup", "ngSubmit"], [1, "row", "g-0", "px-4", "py-2", "h-100", 2, "background", "#F2F2F2"], [1, "mt-2", "mb-5", 2, "font-size", "1.5rem", "font-weight", "700"], [2, "flex", "1 1 auto", 3, "multiple", "maxFileSize", "change"], [1, "fa-thin", "fa-arrow-up-from-bracket", "fa-3x", "mb-2"], [3, "removable", "removed", 4, "ngFor", "ngForOf"], [1, "d-flex", "flex-row-reverse", "p-4"], ["mat-button", "", "type", "submit", 1, "ms-3", 2, "background", "#8817F8", "border-radius", "5px", "padding", "5px 50px", "color", "white", "font-size", "15px", "font-weight", "700", 3, "disabled"], ["mat-button", "", "cdkFocusInitial", "", "type", "button", 3, "disabled", "click"], [3, "removable", "removed"]], template: function UploadDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
            i0.ɵɵelement(3, "img", 3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "form", 4);
            i0.ɵɵlistener("ngSubmit", function UploadDialogComponent_Template_form_ngSubmit_4_listener() { return ctx.onSubmit(); });
            i0.ɵɵelementStart(5, "div", 5)(6, "div", 1)(7, "p", 6);
            i0.ɵɵtext(8);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "ngx-dropzone", 7);
            i0.ɵɵlistener("change", function UploadDialogComponent_Template_ngx_dropzone_change_9_listener($event) { return ctx.onSelect($event); });
            i0.ɵɵelementStart(10, "ngx-dropzone-label");
            i0.ɵɵelement(11, "i", 8);
            i0.ɵɵelementStart(12, "p");
            i0.ɵɵtext(13, "T\u1EA3i file t\u1EEB thi\u1EBFt b\u1ECB c\u1EE7a b\u1EA1n");
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(14, UploadDialogComponent_ngx_dropzone_preview_14_Template, 3, 3, "ngx-dropzone-preview", 9);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(15, "div", 10)(16, "button", 11);
            i0.ɵɵelement(17, "i");
            i0.ɵɵtext(18, " Upload ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(19, "button", 12);
            i0.ɵɵlistener("click", function UploadDialogComponent_Template_button_click_19_listener() { return ctx.onNoClick(); });
            i0.ɵɵtext(20, "Cancel");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(4);
            i0.ɵɵtextInterpolate1("T\u1EA3i file l\u00EAn ", ctx.task.label, "");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("multiple", true)("maxFileSize", 1000000);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngForOf", ctx.files);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading || ctx.files.length === 0);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMapInterpolate1("fa fa-spinner fa-spin me-3 text-white ", ctx.loading ? "d-inline-flex" : "d-none", "");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading);
        } }, dependencies: [i7.NgForOf, i4.ɵNgNoValidate, i4.NgControlStatusGroup, i8.MatButton, i9.CdkTrapFocus, i4.FormGroupDirective, i10.NgxDropzoneComponent, i10.NgxDropzoneLabelDirective, i10.NgxDropzonePreviewComponent, i11.DefaultImageDirective] }); }
}

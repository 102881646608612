import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
export class DefaultImageDirective {
    get checkPath() {
        return this.src ? this.src : this.fallback ? this.fallback : this.defaultImg;
    }
    onError(event) {
        this.src = this.fallback ? this.fallback : this.defaultImg;
    }
    constructor(config) {
        this.config = config;
        this.defaultImg = "https://api.tuesday.baby/assets/placeholder/300x300";
        this.defaultImg = `${this.config.appBaseUrl}/assets/placeholder/300x300`;
    }
    static { this.ɵfac = function DefaultImageDirective_Factory(t) { return new (t || DefaultImageDirective)(i0.ɵɵdirectiveInject(i1.AppConfig)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: DefaultImageDirective, selectors: [["img"]], hostVars: 1, hostBindings: function DefaultImageDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("error", function DefaultImageDirective_error_HostBindingHandler($event) { return ctx.onError($event); });
        } if (rf & 2) {
            i0.ɵɵhostProperty("src", ctx.checkPath, i0.ɵɵsanitizeUrl);
        } }, inputs: { src: "src", fallback: "fallback" } }); }
}

import { CommonModule } from '@angular/common';
import { NotifierConfig } from './models/notifier-config.model';
import { NotifierConfigToken, NotifierOptionsToken } from './notifier.tokens';
import { NotifierService } from './services/notifier.service';
import { NotifierAnimationService } from './services/notifier-animation.service';
import { NotifierQueueService } from './services/notifier-queue.service';
import * as i0 from "@angular/core";
/**
 * Factory for a notifier configuration with custom options
 *
 * Sidenote:
 * Required as Angular AoT compilation cannot handle dynamic functions; see <https://github.com/angular/angular/issues/11262>.
 *
 * @param   options - Custom notifier options
 * @returns - Notifier configuration as result
 */
export function notifierCustomConfigFactory(options) {
    return new NotifierConfig(options);
}
/**
 * Factory for a notifier configuration with default options
 *
 * Sidenote:
 * Required as Angular AoT compilation cannot handle dynamic functions; see <https://github.com/angular/angular/issues/11262>.
 *
 * @returns - Notifier configuration as result
 */
export function notifierDefaultConfigFactory() {
    return new NotifierConfig({});
}
/**
 * Notifier module
 */
export class NotifierModule {
    /**
     * Setup the notifier module with custom providers, in this case with a custom configuration based on the givne options
     *
     * @param   [options={}] - Custom notifier options
     * @returns - Notifier module with custom providers
     */
    static withConfig(options = {}) {
        return {
            ngModule: NotifierModule,
            providers: [
                // Provide the options itself upfront (as we need to inject them as dependencies -- see below)
                {
                    provide: NotifierOptionsToken,
                    useValue: options,
                },
                // Provide a custom notifier configuration, based on the given notifier options
                {
                    deps: [NotifierOptionsToken],
                    provide: NotifierConfigToken,
                    useFactory: notifierCustomConfigFactory,
                },
            ],
        };
    }
    static { this.ɵfac = function NotifierModule_Factory(t) { return new (t || NotifierModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: NotifierModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            NotifierAnimationService,
            NotifierService,
            NotifierQueueService,
            // Provide the default notifier configuration if just the module is imported
            {
                provide: NotifierConfigToken,
                useFactory: notifierDefaultConfigFactory,
            },
        ], imports: [CommonModule] }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./guest-auth.service";
export class GuestAuthGuardService {
    constructor(router, guestAuthService) {
        this.router = router;
        this.guestAuthService = guestAuthService;
    }
    canActivate(route, state) {
        if (!this.guestAuthService.isAuthenticated()) {
            const email = route.queryParamMap.get('email');
            const secret_code = route.queryParamMap.get('scode');
            if (email && secret_code) {
                this.router.navigate(['/guest/login'], { queryParams: { email, scode: secret_code } });
            }
            else {
                this.router.navigate(['/guest/login']);
            }
            return false;
        }
        return true;
    }
    static { this.ɵfac = function GuestAuthGuardService_Factory(t) { return new (t || GuestAuthGuardService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.GuestAuthService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GuestAuthGuardService, factory: GuestAuthGuardService.ɵfac, providedIn: 'root' }); }
}

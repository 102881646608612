/**
 * Fade animation preset
 */
export const fade = {
    hide: () => {
        return {
            from: {
                opacity: '1',
            },
            to: {
                opacity: '0',
            },
        };
    },
    show: () => {
        return {
            from: {
                opacity: '0',
            },
            to: {
                opacity: '1',
            },
        };
    },
};

import { concat, first, interval } from "rxjs";
import { fromSnackbar } from "../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ngrx/store";
import * as i3 from "../types/AppConfig";
export class CheckForUpdateService {
    constructor(appRef, updates, store, config) {
        this.appRef = appRef;
        this.updates = updates;
        this.store = store;
        this.config = config;
        if (config.production && config.auto_update) {
            // Allow the app to stabilize first, before starting
            // polling for updates with `interval()`.
            const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable));
            const everyThirtyMinus$ = interval(30 * 60 * 1000);
            const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyThirtyMinus$);
            everySixHoursOnceAppIsStable$.subscribe(async (val) => {
                try {
                    const updateFound = await updates.checkForUpdate();
                    if (updateFound) {
                        store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Found new version" }));
                    }
                    console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
                }
                catch (err) {
                    console.error('Failed to check for updates:', err);
                }
            });
        }
    }
    static { this.ɵfac = function CheckForUpdateService_Factory(t) { return new (t || CheckForUpdateService)(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.AppConfig)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CheckForUpdateService, factory: CheckForUpdateService.ɵfac, providedIn: 'root' }); }
}

import { fromSnackbar } from "../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ngrx/store";
import * as i3 from "../types/AppConfig";
export class LogUpdateService {
    constructor(updates, store, config) {
        this.store = store;
        this.config = config;
        if (config.production && config.auto_update) {
            updates.versionUpdates.subscribe(evt => {
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: `Downloading new app version: ${evt.version.hash}` }));
                        console.log(`Downloading new app version: ${evt.version.hash}`);
                        break;
                    case 'VERSION_READY':
                        store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: `Current app version: ${evt.currentVersion.hash} - New app version ready for use: ${evt.latestVersion.hash}` }));
                        console.log(`Current app version: ${evt.currentVersion.hash}`);
                        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "error-dialog" }, message: `Failed to install app version '${evt.version.hash}': ${evt.error}` }));
                        console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                        break;
                }
            });
        }
    }
    static { this.ɵfac = function LogUpdateService_Factory(t) { return new (t || LogUpdateService)(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.AppConfig)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: LogUpdateService, factory: LogUpdateService.ɵfac, providedIn: 'root' }); }
}

import { clone } from 'lodash';
import { lastValueFrom } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@angular/router";
import * as i3 from "src/app/services/date-time.service";
import * as i4 from "src/app/services/member-report-ws.service";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/material/form-field";
import * as i10 from "ngx-echarts";
import * as i11 from "../../modules/directives/default-image.directive";
import * as i12 from "@angular/material/input";
import * as i13 from "@angular/material/tooltip";
function WsMemberReportComponent_div_15_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11)(1, "div", 12)(2, "div", 13)(3, "div", 14)(4, "div", 15);
    i0.ɵɵelement(5, "img", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 17)(7, "p", 18);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "small", 19);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(11, "div", 20)(12, "div", 21);
    i0.ɵɵelement(13, "div", 22);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "div", 21)(15, "div", 20)(16, "div", 23)(17, "span", 24);
    i0.ɵɵtext(18);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(19, "span", 25);
    i0.ɵɵtext(20, "On Process");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(21, "div", 26)(22, "span", 24);
    i0.ɵɵtext(23);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(24, "span", 25);
    i0.ɵɵtext(25, "Completed");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(26, "div", 27)(27, "span", 24);
    i0.ɵɵtext(28);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(29, "span", 28);
    i0.ɵɵtext(30, "Missed");
    i0.ɵɵelementEnd()()()()()()()();
} if (rf & 2) {
    const mem_r1 = ctx.$implicit;
    i0.ɵɵadvance(5);
    i0.ɵɵpropertyInterpolate("src", mem_r1.user.avatar, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(mem_r1.user.name);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(mem_r1.user.email);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("options", mem_r1.option);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(mem_r1.todo_tasks);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(mem_r1.finished_tasks);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(mem_r1.missed_tasks);
} }
export class WsMemberReportComponent {
    constructor(config, router, dateTimeService, memberReport) {
        this.config = config;
        this.router = router;
        this.dateTimeService = dateTimeService;
        this.memberReport = memberReport;
        this.option = {};
        this.filterMember = [];
    }
    ngOnInit() {
        (async () => {
            await this.memberPerformanceQuery();
        })();
    }
    async memberPerformanceQuery() {
        const start_date = this.dateTimeService.getLastMonthSameDay();
        this.memberData = await lastValueFrom(this.memberReport.getMemberReportWS(start_date));
        this.tempMemberData = clone(this.memberData);
        if (this.memberData) {
            for (const mem of this.memberData.message) {
                const _option = {
                    legend: {
                        left: "center",
                        orient: 'horizontal',
                        top: 'top',
                    },
                    series: [
                        {
                            animationDelay(idx) {
                                return Math.random() * 200;
                            },
                            animationEasing: 'elasticOut',
                            animationType: 'scale',
                            avoidLabelOverlap: true,
                            center: ['50%', '60%'],
                            data: [
                                { itemStyle: { 'color': '#ec6b27' }, name: 'High', value: mem.high },
                                { itemStyle: { 'color': '#40def5' }, name: 'Medium', value: mem.medium },
                                { itemStyle: { 'color': '#def163' }, name: 'Low', value: mem.low }
                            ],
                            emphasis: {
                                label: {
                                    fontSize: 30,
                                    fontWeight: 'bold',
                                    show: true
                                },
                                scale: true,
                                scaleSize: 20
                            },
                            itemStyle: {
                                borderRadius: 5,
                                shadowBlur: 20,
                                shadowColor: 'rgba(0, 0, 0, 0.1)'
                            },
                            label: {
                                position: 'center',
                                show: false,
                            },
                            labelLine: {
                                show: false
                            },
                            name: 'Number of Tasks',
                            radius: ['40%', '70%'],
                            roseType: 'radius',
                            type: 'pie'
                        }
                    ],
                    toolbox: {
                        bottom: '5%',
                        feature: {
                            dataView: { readOnly: false, show: true },
                            mark: { show: true },
                            saveAsImage: { show: true }
                        },
                        show: true
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                mem.option = _option;
            }
        }
    }
    async search() {
        if (this.memberName === "" || this.memberName === undefined) {
            await this.memberPerformanceQuery();
        }
        else if (this.memberName) {
            this.filterMember = [];
            for (const mem of this.tempMemberData.message) {
                if (mem.user.name.includes(this.memberName)) {
                    this.filterMember.push(mem);
                }
            }
            if (this.memberData) {
                this.memberData.message = this.filterMember;
            }
        }
    }
    static { this.ɵfac = function WsMemberReportComponent_Factory(t) { return new (t || WsMemberReportComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.DateTimeService), i0.ɵɵdirectiveInject(i4.MemberReportWSService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WsMemberReportComponent, selectors: [["app-ws-member-report"]], decls: 16, vars: 2, consts: [[1, "p-1", "p-md-4"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-column", "flex-md-row", "align-items-md-center"], [1, "me-3", 2, "font-weight", "500", "font-size", "24px"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "flex-md-row"], ["appearance", "outline", 1, "mt-2", "mt-md-0"], ["matInput", "", "type", "text", 3, "ngModel", "ngModelChange", "keyup"], ["matSuffix", "", "mat-icon-button", "", 3, "click"], [1, "row", "m-1", "m-md-3"], ["class", "col-12 mt-2 col-md-3 p-0", 4, "ngFor", "ngForOf"], [1, "col-12", "mt-2", "col-md-3", "p-0"], [1, "m-0", "m-md-2", "card"], [1, "card-body"], [1, "row", "mt-2"], [1, "col-12", "col-md-3", "d-flex", "flex-row", "flex-md-column", "justify-content-center"], [2, "height", "55px", "width", "55px", "border-radius", "50%", 3, "src"], [1, "col-12", "col-md-9", "d-flex", "flex-column", "justify-content-center"], [1, "fw-bold", "mb-1", "fs-5", "text-center", "text-md-start"], [1, "text-muted", "text-center", "text-md-start"], [1, "row"], [1, "col-12"], ["echarts", "", "id", "section_chart", 1, "mt-4", "echartMembersRP", 3, "options"], ["matTooltip", "On Process", 1, "col-4", "border-dark", "border-top", "d-flex", "flex-column"], [1, "w-100", "m-1", "fw-bold", "fs-5", "text-center"], [1, "m-1", "text-nowrap", "text-center"], ["matTooltip", "Completed", 1, "col-4", "border-dark", "border-top", "d-flex", "flex-column"], ["matTooltip", "Missed", 1, "col-4", "border-dark", "border-top", "d-flex", "flex-column"], [1, "w-100", "m-1", "text-nowrap", "text-center"]], template: function WsMemberReportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "span", 3);
            i0.ɵɵtext(4, "Workspace Member Report");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(5, "div", 4);
            i0.ɵɵelementStart(6, "div", 5)(7, "mat-form-field", 6)(8, "mat-label");
            i0.ɵɵtext(9, "Search");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "input", 7);
            i0.ɵɵlistener("ngModelChange", function WsMemberReportComponent_Template_input_ngModelChange_10_listener($event) { return ctx.memberName = $event; })("keyup", function WsMemberReportComponent_Template_input_keyup_10_listener() { return ctx.search(); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "button", 8);
            i0.ɵɵlistener("click", function WsMemberReportComponent_Template_button_click_11_listener() { return ctx.search(); });
            i0.ɵɵelementStart(12, "mat-icon");
            i0.ɵɵtext(13, "search");
            i0.ɵɵelementEnd()()()()()();
            i0.ɵɵelementStart(14, "div", 9);
            i0.ɵɵtemplate(15, WsMemberReportComponent_div_15_Template, 31, 7, "div", 10);
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngModel", ctx.memberName);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngForOf", ctx.memberData == null ? null : ctx.memberData.message);
        } }, dependencies: [i5.NgForOf, i6.DefaultValueAccessor, i6.NgControlStatus, i6.NgModel, i7.MatIconButton, i8.MatIcon, i9.MatFormField, i9.MatLabel, i9.MatSuffix, i10.NgxEchartsDirective, i11.DefaultImageDirective, i12.MatInput, i13.MatTooltip], styles: ["[_nghost-%COMP%]  .echartMembersRP {\n  height: 220px;\n}"] }); }
}

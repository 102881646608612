import { authFeatureKey } from "../../modules/stores/auth";
import { fromAuth, fromSnackbar } from "../../modules/stores/reducer";
export class GooglePicker {
    constructor(config, store) {
        this.store = store;
        this.SCOPES = [
            "https://www.googleapis.com/auth/drive.file",
            "https://www.googleapis.com/auth/drive.metadata.readonly",
        ];
        this.DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
        this.pickerInited = false;
        this.gisInited = false;
        this.authState = store.select(authFeatureKey);
        this.authState.subscribe((state) => {
            if (state.OAuthToken) {
                this.accessToken = state.OAuthToken;
            }
        });
        this.APP_ID = config.APP_ID;
        this.CLIENT_ID = config.CLIENT_ID;
        this.API_KEY = config.API_KEY;
        // this.callback = config.callback;
    }
    /**
     * Callback after Google Identity Services are loaded.
     */
    async gapiLoaded() {
        gapi.load('client:picker', this.intializePicker.bind(this));
    }
    /**
     * Callback after Google Identity Services are loaded.
     */
    async gisLoaded() {
        this.tokenClient = google.accounts.oauth2.initTokenClient({
            callback: '',
            clientid: this.CLIENT_ID,
            scope: this.SCOPES.join(' '), // defined later
        });
        this.gisInited = true;
        console.log("Google Identity Services are loaded");
    }
    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    async intializePicker() {
        gapi.client.load(this.DISCOVERY_DOCS[0]);
        this.pickerInited = true;
    }
    /**
     *  Sign in the user upon button click.
     */
    async picker(callback) {
        console.log(this);
        this.callback = callback;
        const promise_api = this.loadScript("https://apis.google.com/js/api.js");
        const promise_gsi = this.loadScript("https://accounts.google.com/gsi/client");
        Promise.all([promise_api, promise_gsi]).then(async (values) => {
            console.log(values);
            console.log(gapi);
            console.log(google);
            await this.gapiLoaded();
            await this.gisLoaded();
            this.tokenClient.callback = async (response) => {
                if (response.error !== undefined) {
                    throw (response);
                }
                if (google.accounts.oauth2.hasGrantedAllScopes(response, ...this.SCOPES)) {
                    this.accessToken = response.access_token;
                    this.store.dispatch(fromAuth.oAuth2SigninSuccess({ OAuthToken: this.accessToken }));
                    await this.createPicker();
                }
                else {
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Hệ thống không được cung cấp đầy đủ quyền truy cập tài khoàn google." }));
                }
            };
            if (!this.accessToken) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                this.tokenClient.requestAccessToken({ prompt: 'consent' });
            }
            else {
                // Skip display of account chooser and consent dialog for an existing session.
                this.tokenClient.requestAccessToken({ prompt: '' });
            }
        });
    }
    async invoke() {
        if (this.accessToken) {
            this.accessToken = undefined;
            google.accounts.oauth2.revoke(this.accessToken);
        }
    }
    async createPicker() {
        const view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes('image/png,image/jpeg,image/jpg');
        const picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setDeveloperKey(this.API_KEY)
            .setAppId(this.APP_ID)
            .setOAuthToken(this.accessToken)
            .addView(view)
            .addView(new google.picker.DocsUploadView())
            .setCallback(this.pickerCallback.bind(this))
            .build();
        picker.setVisible(true);
    }
    /**
     * Displays the file details of the user's selection.
     * @param {object} data - Containers the user selection from the picker
     */
    async pickerCallback(data) {
        if (data.action === google.picker.Action.PICKED) {
            const documents = data[google.picker.Response.DOCUMENTS];
            this.callback(data.action, documents);
        }
        else {
            this.callback(data.action, []);
        }
    }
    loadScript(url) {
        return new Promise((resolve, reject) => {
            const script = document.body.querySelector(`script[src="${url}"]`);
            if (!script) {
                const s = document.createElement("script");
                s.src = url;
                s.async = true;
                s.defer = true;
                s.onload = (event) => {
                    console.log(event);
                    resolve({ loaded: true, script: url, status: 'Loaded' });
                };
                s.onerror = (error) => {
                    console.log(error);
                    reject({ loaded: false, script: url, status: 'Loaded' });
                };
                document.body.appendChild(s);
            }
            else {
                resolve({ loaded: true, script: url, status: 'Loaded' });
            }
        });
    }
}

import packageJson from "../../../package.json";
export const Config = {
    appBaseUrl: 'https://api.tuesday.baby',
    appUrl: 'https://api.tuesday.baby/api',
    auto_update: true,
    baseUrl: '/vi',
    cookie: {
        domain: "app.tuesday.baby",
        expires: 365,
        path: "/",
        sameSite: "Strict",
        secure: false
    },
    ga: false,
    gcm: {
        vapid: {
            publicKey: "BMK0en0gQfVakmcOhvTJydT83BqJWWmx42C5becv9i9L37AipG3O8CRNc4QSrJ7-tIcal3AD6AeDo3W2wUk7K90",
        }
    },
    google: {
        apiKey: 'AIzaSyBUPn6D33X_xCRuo3wlyY3Gq1uXlZX7fnE',
        appId: 'tuesday-357808',
        clientId: '174205079631-hpabob76g8d9ntjorjgbibgu1n5l18he.apps.googleusercontent.com'
    },
    production: true,
    socket_endpoint: "https://socket.tuesday.baby/frontend",
    url: 'https://app.tuesday.baby/vi',
    version: packageJson.version
};

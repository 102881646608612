import { AddFacebookPageDialogComponent } from "../../components/add-facebook-page-dialog/add-facebook-page-dialog.component";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "../../modules/directives/default-image.directive";
export class AddChannelPageComponent {
    constructor(config, store, router, activatedRoute, dialog, overlay) {
        this.config = config;
        this.store = store;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.dialog = dialog;
        this.overlay = overlay;
        this.action = this.activatedRoute.snapshot.paramMap.get('action');
        this.id = this.activatedRoute.snapshot.queryParamMap.get('id');
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        if (this.action.toLowerCase() === 'facebook') {
            if (!this.dialogRef) {
                this.dialogRef = this.dialog.open(AddFacebookPageDialogComponent, {
                    data: { id: this.id },
                    disableClose: true,
                    hasBackdrop: true,
                    maxHeight: '600px',
                    minWidth: '30%',
                    scrollStrategy
                });
                this.dialogRef.afterClosed().subscribe({
                    next: (data) => {
                        this.router.navigate(["/channel/manage"]);
                    }
                });
            }
        }
    }
    facebookAuth($event) {
        const callback = encodeURI(`${this.config.url}/channel/create`);
        window.location.href = `${this.config.appBaseUrl}/api/auth/fb_choosepage?callback=${callback}`;
    }
    googleAuth($event) {
        const callback = encodeURI(`${this.config.url}/channel/create`);
        window.location.href = `${this.config.appBaseUrl}/api/auth/gg_choosepage?callback=${callback}`;
    }
    static { this.ɵfac = function AddChannelPageComponent_Factory(t) { return new (t || AddChannelPageComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.Router), i0.ɵɵdirectiveInject(i3.ActivatedRoute), i0.ɵɵdirectiveInject(i4.MatDialog), i0.ɵɵdirectiveInject(i5.Overlay)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AddChannelPageComponent, selectors: [["app-add-channel-page"]], decls: 22, vars: 0, consts: [[1, "p-1", "p-md-4", "min-vh-100"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12"], [2, "font-weight", "700", "font-size", "24px"], [1, "row", "g-0", "g-md-2", "m-1", "m-md-3"], [1, "col-12", "col-md-3"], ["mat-ripple", "", 1, "d-flex", "flex-column", "align-items-center", 2, "background", "#F2F2F2", "width", "100%", "padding", "15px", "border-radius", "10px", "border", "none", 3, "click"], ["src", "assets/icons/Facebook.svg", 1, "img-fluid", 2, "width", "50%"], [1, "fw-700", "mt-2", 2, "font-size", "1.2rem"], [1, "small"], ["mat-ripple", "", 1, "d-flex", "flex-column", "align-items-center", "mt-2", "mt-md-0", 2, "background", "#F2F2F2", "width", "100%", "padding", "15px", "border-radius", "10px", "border", "none", 3, "click"], ["src", "assets/icons/Instagram.svg", 1, "img-fluid", 2, "width", "50%"]], template: function AddChannelPageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "p", 3);
            i0.ɵɵtext(4, "Create a new channel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "small");
            i0.ɵɵtext(6, "Looking for step-by-step instructions? Visit our Help Center to read our Getting Started guides and learn about supported channel types.");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(7, "div", 4)(8, "div", 5)(9, "button", 6);
            i0.ɵɵlistener("click", function AddChannelPageComponent_Template_button_click_9_listener($event) { return ctx.facebookAuth($event); });
            i0.ɵɵelement(10, "img", 7);
            i0.ɵɵelementStart(11, "p", 8);
            i0.ɵɵtext(12, "Facebook");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "p", 9);
            i0.ɵɵtext(14, "Fanpage");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(15, "div", 5)(16, "button", 10);
            i0.ɵɵlistener("click", function AddChannelPageComponent_Template_button_click_16_listener($event) { return ctx.googleAuth($event); });
            i0.ɵɵelement(17, "img", 11);
            i0.ɵɵelementStart(18, "p", 8);
            i0.ɵɵtext(19, "Instagram");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(20, "p", 9);
            i0.ɵɵtext(21, "Business Account");
            i0.ɵɵelementEnd()()()()();
        } }, dependencies: [i6.DefaultImageDirective] }); }
}

import { InjectionToken } from '@angular/core';
import { WrappedSocket } from './socket-io.service';
import * as i0 from "@angular/core";
/** Socket factory */
export function SocketFactory(config) {
    return new WrappedSocket(config);
}
export const SOCKET_CONFIG_TOKEN = new InjectionToken('__SOCKET_IO_CONFIG__');
export class SocketIoModule {
    static forRoot(config) {
        return {
            ngModule: SocketIoModule,
            providers: [
                { provide: SOCKET_CONFIG_TOKEN, useValue: config },
                {
                    deps: [SOCKET_CONFIG_TOKEN],
                    provide: WrappedSocket,
                    useFactory: SocketFactory,
                },
            ],
        };
    }
    static { this.ɵfac = function SocketIoModule_Factory(t) { return new (t || SocketIoModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SocketIoModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({}); }
}

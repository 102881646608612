import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Config } from "../../configs/config";
import { AuthModule } from './auth';
import { ConfigModule } from "./config";
import { metaReducers } from "./meta.reducer";
import { SidenavModule } from "./sidenav";
import { SnackbarModule } from "./snackbar";
import { SocketModule } from "./socket";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppStoreModule {
    static { this.ɵfac = function AppStoreModule_Factory(t) { return new (t || AppStoreModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppStoreModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            StoreModule.forRoot({}, {
                metaReducers,
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictActionSerializability: true,
                    strictActionTypeUniqueness: true,
                    strictStateImmutability: true,
                    strictStateSerializability: true,
                },
            }),
            AuthModule,
            SnackbarModule,
            MatSnackBarModule,
            SocketModule,
            SnackbarModule,
            SidenavModule,
            ConfigModule,
            !Config.production ? StoreDevtoolsModule.instrument() : []] }); }
}

import moment from 'moment';
import * as i0 from "@angular/core";
export class DifferencePipe {
    transform(value, otherValue, unit, precision) {
        const date = moment(value);
        const date2 = otherValue !== null ? moment(otherValue) : moment();
        return date.diff(date2, unit, precision);
    }
    static { this.ɵfac = function DifferencePipe_Factory(t) { return new (t || DifferencePipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amDifference", type: DifferencePipe, pure: true }); }
}

import { $, createSVG } from './utils';
export default class Connector {
    constructor(gantt, fromEl, toEl) {
        this.gantt = gantt;
        this.from_element = fromEl;
        this.to_element = toEl;
        this.gravity = 40;
        this.animation = true;
        this.dash = true;
        if (this.from_element.classList.contains('connector')) {
            this.from_task_id = this.from_element.dataset.task;
        }
        if (this.to_element.classList.contains('connector')) {
            this.to_task_id = this.to_element.dataset.task;
        }
        this.id = this.from_task_id + '-' + this.to_task_id;
        this.calculate_path();
        this.draw();
    }
    calculate_path() {
        const start_left = this.from_element.classList.contains('left');
        const end_left = this.to_element.classList.contains('left');
        if (!start_left && end_left) {
            this.type = 0;
        }
        else if (start_left && end_left) {
            this.type = 1;
        }
        else if (!start_left && !end_left) {
            this.type = 2;
        }
        else if (start_left && !end_left) {
            this.type = 3;
        }
        const start_x = this.from_element.cx.baseVal.value +
            this.from_element.r.baseVal.value / 2;
        const start_y = this.from_element.cy.baseVal.value +
            this.from_element.r.baseVal.value / 2;
        const end_x = this.to_element.cx.baseVal.value +
            this.to_element.r.baseVal.value / 2;
        const end_y = this.to_element.cy.baseVal.value +
            this.from_element.r.baseVal.value / 2;
        const from_is_below_to = this.from_element.cy.baseVal.value >
            this.to_element.cy.baseVal.value;
        const curve = this.gantt.options.arrow_curve;
        const clockwise = from_is_below_to ? 1 : 0;
        if (!clockwise) {
            if (start_left && end_left) {
                // console.log("top to bottom: start left - end left");
                this.path = `
                M ${start_x} ${start_y}
                H ${end_x - this.gravity < start_x
                    ? end_x - this.gravity
                    : start_x - this.gravity}
                a ${curve} ${curve} 0 0 0 -${curve} ${curve}
                V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                a ${curve} ${curve} 0 0 0 ${curve} ${curve}
                H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                m -${curve} -${curve}
                l ${curve} ${curve}
                l -${curve} ${curve}
            `;
            }
            if (!start_left && !end_left) {
                // console.log("top to bottom: start right - end right");
                this.path = `
                M ${start_x} ${start_y}
                H ${start_x + this.gravity < end_x
                    ? end_x + this.gravity
                    : start_x + this.gravity}
                a ${curve} ${curve} 0 0 1 ${curve} ${curve}
                V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                a ${curve} ${curve} 0 0 1 -${curve} ${curve}
                H ${end_x + curve - this.to_element.r.baseVal.value / 2}
                m ${curve} -${curve}
                l -${curve} ${curve}
                l ${curve} ${curve}
            `;
            }
            if (start_left && !end_left) {
                // console.log("top to bottom: start left - end right");
                this.path = `
                    M ${start_x} ${start_y}
                    H ${start_x - this.gravity}
                    a ${curve} ${curve} 0 0 0 -${curve} ${curve}
                    V ${(start_y + end_y) / 2 - curve}
                    a ${curve} ${curve} 0 0 0 ${curve} ${curve}
                    H ${end_x + this.gravity}
                    a ${curve} ${curve} 0 0 1 ${curve} ${curve}
                    V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 1 -${curve} ${curve}
                    H ${end_x + this.to_element.r.baseVal.value / 2}
                    m ${curve} -${curve}
                    l -${curve} ${curve}
                    l ${curve} ${curve}
                `;
            }
            if (!start_left && end_left) {
                if (this.to_element.cx.baseVal.value <
                    this.from_element.cx.baseVal.value) {
                    // console.log("top to bottom: start right - end left - to left side");
                    this.path = `
                    M ${start_x} ${start_y}
                    H ${start_left
                        ? start_x - this.gravity
                        : start_x + this.gravity}
                    a ${curve} ${curve} 0 0 1 ${curve} ${curve}
                    V ${(end_y + start_y) / 2 - curve}
                    a ${curve} ${curve} 0 0 1 -${curve} ${curve}
                    H ${end_x - this.gravity}
                    a ${curve} ${curve} 0 0 0 -${curve} ${curve}
                    V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 ${end_left ? 0 : -1} ${end_left ? curve : -curve} ${curve}
                    H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                    m -${curve} -${curve}
                    l ${curve} ${curve}
                    l -${curve} ${curve}
                `;
                }
                else {
                    // console.log("top to bottom: start right - end left - to right side");
                    this.path = `
                    M ${start_x} ${start_y}
                    H ${end_x - this.gravity}
                    a ${curve} ${curve} 0 0 1 ${curve} ${curve}
                    V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 0 ${curve} ${curve}
                    H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                    m -${curve} -${curve}
                    l ${curve} ${curve}
                    l -${curve} ${curve}
                `;
                }
            }
        }
        else {
            if (start_left && end_left) {
                // console.log("bottom to top: start left - end left");
                this.path = `
                    M ${start_x} ${start_y}
                    H ${start_x - this.gravity < end_x
                    ? start_x - this.gravity
                    : end_x - this.gravity}
                    a ${curve} ${curve} 0 0 1 -${curve} -${curve}
                    V ${end_y + curve - this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 1 ${curve} -${curve}
                    H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                    m -${curve} -${curve}
                    l ${curve} ${curve}
                    l -${curve} ${curve}
                `;
            }
            if (!start_left && !end_left) {
                // console.log("bottom to top: start right - end right");
                this.path = `
                    M ${start_x} ${start_y}
                    H ${end_x < start_x + this.gravity
                    ? start_x + this.gravity
                    : end_x + this.gravity}
                    a ${curve} ${curve} 0 0 0 ${curve} -${curve}
                    V ${end_y + curve - this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 0 -${curve} -${curve}
                    H ${end_x + curve - this.to_element.r.baseVal.value / 2}
                    m ${curve} -${curve}
                    l -${curve} ${curve}
                    l ${curve} ${curve}
                `;
            }
            if (start_left && !end_left) {
                // console.log("bottom to top: start left - end right");
                this.path = `
                    M ${start_x} ${start_y}
                    H ${start_x - this.gravity}
                    a ${curve} ${curve} 0 0 1 -${curve} -${curve}
                    V ${(start_y + end_y) / 2 + curve}
                    a ${curve} ${curve} 0 0 ${start_x - this.gravity <
                    end_x + this.gravity + this.to_element.r.baseVal.value
                    ? 1
                    : 0} ${start_x - this.gravity <
                    end_x + this.gravity + this.to_element.r.baseVal.value
                    ? curve
                    : -curve} -${curve}
                    H ${end_x + this.gravity}
                    a ${curve} ${curve} 0 0 ${start_x - this.gravity <
                    end_x + this.gravity + this.to_element.r.baseVal.value
                    ? 0
                    : 1} ${start_x - this.gravity <
                    end_x + this.gravity + this.to_element.r.baseVal.value
                    ? curve
                    : -curve} -${curve}
                    V ${end_y + this.to_element.r.baseVal.value / 2}
                    a ${curve} ${curve} 0 0 0 -${curve} -${curve}
                    H ${end_x + this.to_element.r.baseVal.value / 2}
                    m ${curve} -${curve}
                    l -${curve} ${curve}
                    l ${curve} ${curve}
                `;
            }
            if (!start_left && end_left) {
                if (this.to_element.cx.baseVal.value <
                    this.from_element.cx.baseVal.value) {
                    // console.log("bottom to top: start left - end left - to left side");
                    this.path = `
                        M ${start_x} ${start_y}
                        H ${start_left
                        ? start_x - this.gravity
                        : start_x + this.gravity}
                        a ${curve} ${curve} 0 0 0 ${curve} -${curve}
                        V ${(end_y + start_y) / 2 - curve}
                        a ${curve} ${curve} 0 0 0 -${curve} -${curve}
                        H ${end_x - this.gravity}
                        a ${curve} ${curve} 0 0 1 -${curve} -${curve}
                        V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                        a ${curve} ${curve} 0 0 1 ${curve} -${curve}
                        H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                        m -${curve} -${curve}
                        l ${curve} ${curve}
                        l -${curve} ${curve}
                    `;
                }
                else {
                    // console.log("bottom to top: start left - end left - to right side");
                    this.path = `
                        M ${start_x} ${start_y}
                        H ${end_x - this.gravity}
                        a ${curve}  ${curve}  0 0 0 ${curve}  -${curve}
                        V ${end_y - curve - this.to_element.r.baseVal.value / 2}
                        a ${curve}  ${curve}  0 0 1 ${curve}  -${curve}
                        H ${end_x - curve - this.to_element.r.baseVal.value / 2}
                        m -${curve}  -${curve}
                        l ${curve}  ${curve}
                        l -${curve}  ${curve}
                    `;
                }
            }
        }
        // console.log(this.path)
    }
    draw() {
        this.group = createSVG('g', { class: 'group-connector' });
        this.fillPath = createSVG('path', {
            append_to: this.group,
            'class': 'connector-stroke',
            d: this.path,
            'data-from': this.from_task_id,
            'data-to': this.to_task_id,
            'stroke': 'transparent',
            'stroke-width': '6'
        });
        this.element = createSVG('path', {
            append_to: this.group,
            'class': 'connector-line',
            d: this.path,
            'data-from': this.from_task_id,
            'data-to': this.to_task_id,
            'stroke-dasharray': this.dash ? '3,3' : '0'
        });
        if (this.animation) {
            $.attr(this.element, { class: 'connector-line animation' });
            $.attr(this.fillPath, { class: 'connector-stroke animation' });
        }
    }
    set_option(options) {
        if (options.id)
            this.id = options.id;
        if (options.from_element)
            this.from_element = options.from_element;
        if (options.to_element) {
            this.to_element = options.to_element;
            const to_task_id = this.to_element.dataset.task;
            $.attr(this.element, {
                'data-to': to_task_id,
            });
            $.attr(this.fillPath, {
                'data-to': to_task_id,
            });
        }
        if (options.color) {
            this.color = options.color;
            $.attr(this.element, {
                stroke: this.color,
            });
        }
        if (options.gravity)
            this.gravity = options.gravity;
        this.calculate_path();
        this.element.setAttribute('d', this.path);
        this.fillPath.setAttribute('d', this.path);
    }
    update() {
        this.calculate_path();
        this.element.setAttribute('d', this.path);
        this.fillPath.setAttribute('d', this.path);
    }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../components/toolbar/toolbar.component";
export class ChooseWorkspaceLayoutComponent {
    static { this.ɵfac = function ChooseWorkspaceLayoutComponent_Factory(t) { return new (t || ChooseWorkspaceLayoutComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChooseWorkspaceLayoutComponent, selectors: [["app-choose-workspace-layout"]], decls: 6, vars: 0, consts: [[1, "row", "g-0"], [1, "col-12"]], template: function ChooseWorkspaceLayoutComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
            i0.ɵɵelement(2, "app-toolbar");
            i0.ɵɵelementStart(3, "div", 0)(4, "div", 1);
            i0.ɵɵelement(5, "router-outlet");
            i0.ɵɵelementEnd()()()();
        } }, dependencies: [i1.RouterOutlet, i2.ToolbarComponent] }); }
}

import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(_route, _state) {
        if (!this.authService.isAuthenticated()) {
            this.router.navigate(['/auth/signin']);
            return false;
        }
        if (!this.authService.isChoosedWorkspace()) {
            this.router.navigate(['/workspace']);
            return false;
        }
        return true;
    }
    static { this.ɵfac = function AuthGuardService_Factory(t) { return new (t || AuthGuardService)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthGuardService, factory: AuthGuardService.ɵfac, providedIn: 'root' }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class AuthLayoutComponent {
    static { this.ɵfac = function AuthLayoutComponent_Factory(t) { return new (t || AuthLayoutComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AuthLayoutComponent, selectors: [["app-auth-layout"]], decls: 4, vars: 0, consts: [[1, "container-fluid"], [1, "row", "min-vh-100"], [1, "col-12", "col-md-10", "m-auto"]], template: function AuthLayoutComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
            i0.ɵɵelement(3, "router-outlet");
            i0.ɵɵelementEnd()()();
        } }, dependencies: [i1.RouterOutlet] }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../modules/directives/default-image.directive";
import * as i3 from "@angular/material/tooltip";
function PinMarkComponent_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "i", 2);
    i0.ɵɵelementContainerEnd();
} }
function PinMarkComponent_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "img", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵpropertyInterpolate("src", ctx_r1.avatar, i0.ɵɵsanitizeUrl);
} }
export class PinMarkComponent {
    constructor() {
        this.isOwner = false;
    }
    static { this.ɵfac = function PinMarkComponent_Factory(t) { return new (t || PinMarkComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: PinMarkComponent, selectors: [["app-pin-mark"]], decls: 3, vars: 10, consts: [[1, "pin", 3, "id", "matTooltip"], [4, "ngIf"], [1, "fa-light", "fa-crown", "fa-1x"], [3, "src"]], template: function PinMarkComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtemplate(1, PinMarkComponent_ng_container_1_Template, 2, 0, "ng-container", 1);
            i0.ɵɵtemplate(2, PinMarkComponent_ng_container_2_Template, 2, 1, "ng-container", 1);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵstyleProp("left", (ctx.position == null ? null : ctx.position.x) + "px")("top", (ctx.position == null ? null : ctx.position.y) + "px")("background", ctx.background);
            i0.ɵɵpropertyInterpolate("id", ctx.info == null ? null : ctx.info.id);
            i0.ɵɵpropertyInterpolate("matTooltip", ctx.info == null ? null : ctx.info.name);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.isOwner);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", !ctx.isOwner);
        } }, dependencies: [i1.NgIf, i2.DefaultImageDirective, i3.MatTooltip], styles: [".pin[_ngcontent-%COMP%] {\n  position: absolute;\n  z-index: 10;\n  width: 30px;\n  height: 30px;\n  border-radius: 50% 50% 50% 0;\n  transform: rotate(-45deg);\n  background: #f5f5f5;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  -o-box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\n  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\n  transition: top 2s, left 2s, transform 2s;\n}\n\n.pin[_ngcontent-%COMP%]   img[_ngcontent-%COMP%] {\n  height: 20px;\n  width: 20px;\n  border-radius: 10px;\n}\n\n.pin[_ngcontent-%COMP%]   i[_ngcontent-%COMP%] {\n  color: #fff;\n}\n\n.pin[_ngcontent-%COMP%]   .wx-header[_ngcontent-%COMP%] {\n  margin: 10px 0;\n}"] }); }
}

import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@angular/router";
import * as i3 from "../../services/workspace-report.service";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/form-field";
import * as i7 from "@angular/material/select";
import * as i8 from "@angular/material/core";
import * as i9 from "ngx-echarts";
function WorkspaceReportComponent_mat_option_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 17);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r5 = ctx.$implicit;
    i0.ɵɵproperty("value", item_r5.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(item_r5.display);
} }
function WorkspaceReportComponent_div_44_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵlistener("chartInit", function WorkspaceReportComponent_div_44_Template_div_chartInit_0_listener($event) { i0.ɵɵrestoreView(_r7); const ctx_r6 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r6.sectionChartInit($event)); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("options", ctx_r1.section_chart_option);
} }
function WorkspaceReportComponent_div_46_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 19);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("options", ctx_r2.priority_ChartOption);
} }
function WorkspaceReportComponent_div_48_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 19);
} if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("options", ctx_r3.assignee_ChartOption);
} }
function WorkspaceReportComponent_div_51_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 19);
} if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("options", ctx_r4.updownChartOption);
} }
export class WorkspaceReportComponent {
    constructor(config, router, workspaceReportService) {
        this.config = config;
        this.router = router;
        this.workspaceReportService = workspaceReportService;
        this.priority = [
            { id: 1, label: "high" },
            { id: 2, label: "medium" },
            { id: 3, label: "low" },
        ];
        this.selectedViewOption = 2;
        this.viewOptions = [
            {
                display: 'view in week', value: 1
            },
            {
                display: 'view in month', value: 2
            },
            {
                display: 'view in year', value: 3
            }
        ];
        this.section_Chart_data = [];
        this.priority_ChartData = [];
        this.assignee_Chart_data = [];
        this.updown_Chart_timeseries_data = [];
        this.updown_Chart_finishedData = [];
        this.updown_Chart_missedData = [];
        this.onHoldTasks = 0;
        this.completedTasks = 0;
        this.missTasks = 0;
        this.totalTasks = 0;
        this.totalProject = 0;
        this.isChecked = true;
        this.workspace_report = this.workspaceReportService.getWorkspaceReport(this.getLastMonthSameDay());
        this.workspaceChartReport = this.workspaceReportService.getWorkspaceChartReport(this.getLastMonthSameDay());
    }
    ngOnInit() {
        this.workspace_report = this.workspaceReportService.getWorkspaceReport(this.getLastMonthSameDay());
        this.workspace_report.subscribe({
            next: (result) => {
                this.onHoldTasks = result.todo_tasks_count;
                this.completedTasks = result.finished_tasks_count;
                this.missTasks = result.missed_tasks_count;
                this.totalTasks = result.tasks_count;
                this.totalProject = result.project_count;
                const members = result.project_members;
                console.log(members);
                for (let i = 0; i < members.length; i++) {
                    this.assignee_Chart_data.push({
                        name: members[i].user.name,
                        value: members[i].total_tasks
                    });
                }
                this.assignee_ChartOption = {
                    series: [
                        {
                            data: this.assignee_Chart_data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    shadowOffsetX: 0
                                }
                            },
                            name: 'Access From',
                            radius: '50%',
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: 'center',
                        text: 'Tasks by Assignees'
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
            }
        });
        const workspaceChartReport = this.workspaceReportService.getWorkspaceChartReport(this.getLastMonthSameDay());
        workspaceChartReport.subscribe({
            next: (result) => {
                console.log(result);
                for (let i = 0; i < result.section.length; i++) {
                    this.section_Chart_data.push({
                        name: result.section[i].name,
                        value: result.section[i].total
                    });
                }
                this.section_chart_option = {
                    /* legend: {
                      orient: 'vertical',
                      left: 'left'
                    },*/
                    series: [
                        {
                            data: this.section_Chart_data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    shadowOffsetX: 0
                                }
                            },
                            name: 'Access From',
                            radius: '50%',
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: 'center',
                        text: 'Tasks by Sections'
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                for (let j = 0; j < result.priority.length; j++) {
                    for (let k = 0; k < this.priority.length; k++) {
                        if (result.priority[j]._id === this.priority[k].id) {
                            this.priority_ChartData.push({
                                name: this.priority[k].label,
                                value: result.priority[j].total
                            });
                        }
                    }
                }
                this.priority_ChartOption = {
                    legend: {
                        left: 'center',
                        top: '10%'
                    },
                    series: [
                        {
                            avoidLabelOverlap: false,
                            data: this.priority_ChartData,
                            emphasis: {
                                label: {
                                    fontSize: '40',
                                    fontWeight: 'bold',
                                    show: true
                                }
                            },
                            label: {
                                position: 'center',
                                show: true
                            },
                            labelLine: {
                                show: false
                            },
                            name: 'Access From',
                            radius: ['40%', '70%'],
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: "center",
                        text: "Tasks By Priority"
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                for (const task of result.updown_timeseries) {
                    this.updown_Chart_finishedData.push(task.finished_tasks);
                    this.updown_Chart_missedData.push(task.missed_tasks);
                    this.updown_Chart_timeseries_data.push(task.date_group_string);
                }
                this.updownChartOption = {
                    legend: {
                        // Try 'horizontal'
                        orient: 'vertical',
                        right: 5,
                        top: 'center'
                    },
                    series: [
                        {
                            data: this.updown_Chart_finishedData,
                            name: 'Finished Tasks',
                            type: 'line',
                        },
                        {
                            data: this.updown_Chart_missedData,
                            name: 'Missed Tasks',
                            type: 'line'
                        }
                    ],
                    title: {
                        left: 'center',
                        text: 'Finished/Missed Tasks'
                    },
                    xAxis: {
                        data: this.updown_Chart_timeseries_data,
                        type: 'category',
                    },
                    yAxis: {
                        type: 'value',
                    },
                };
            }
        });
    }
    sectionChartInit($event) {
        console.log($event);
    }
    onChangeView() {
        this.router.navigate(["/workspaces/member-report"]);
    }
    getMonday(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff)).getTime();
    }
    getLastWeekSameDay() {
        const d = new Date();
        const diff = d.getDate() - 7;
        return new Date(d.setDate(diff)).getTime();
    }
    getLastMonthSameDay() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).getTime();
    }
    getFirstDayOfYear() {
        const DayOfYear = new Date(new Date().getFullYear(), 0, 1).getTime();
        return DayOfYear;
    }
    viewOptionFunc() {
        let start_date;
        if (this.selectedViewOption === 1) {
            const date = new Date();
            start_date = this.getLastWeekSameDay();
        }
        else if (this.selectedViewOption === 2) {
            start_date = this.getLastMonthSameDay();
        }
        else if (this.selectedViewOption === 3) {
            start_date = new Date(new Date().getFullYear(), 0, 1).getTime();
        }
        console.log(start_date);
        const workspace_report = this.workspaceReportService.getWorkspaceReport(start_date);
        workspace_report.subscribe({
            next: (result) => {
                this.onHoldTasks = result.todo_tasks_count;
                this.completedTasks = result.finished_tasks_count;
                this.missTasks = result.missed_tasks_count;
                this.totalTasks = result.tasks_count;
                this.totalProject = result.project_count;
                const members = result.project_members;
                console.log(members);
                for (let i = 0; i < members.length; i++) {
                    this.assignee_Chart_data.push({
                        name: members[i].user.name,
                        value: members[i].total_tasks
                    });
                }
                this.assignee_ChartOption = {
                    series: [
                        {
                            data: this.assignee_Chart_data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    shadowOffsetX: 0
                                }
                            },
                            name: 'Access From',
                            radius: '50%',
                            type: 'pie'
                        }
                    ],
                    title: {
                        // subtext: 'Fake Data',
                        left: 'center',
                        text: 'Tasks by Assignees'
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
            }
        });
        const workspaceChartReport = this.workspaceReportService.getWorkspaceChartReport(start_date);
        workspaceChartReport.subscribe({
            next: (result) => {
                console.log(result);
                this.section_Chart_data = [];
                this.priority_ChartData = [];
                this.assignee_Chart_data = [];
                this.updown_Chart_timeseries_data = [];
                this.updown_Chart_finishedData = [];
                this.updown_Chart_missedData = [];
                for (let i = 0; i < result.section.length; i++) {
                    this.section_Chart_data.push({
                        name: result.section[i].name,
                        value: result.section[i].total
                    });
                }
                this.section_chart_option = {
                    series: [
                        {
                            data: this.section_Chart_data,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    shadowOffsetX: 0
                                }
                            },
                            name: 'Access From',
                            radius: '50%',
                            type: 'pie'
                        }
                    ],
                    title: {
                        // subtext: 'Fake Data',
                        left: 'center',
                        text: 'Tasks by Sections'
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                for (let j = 0; j < result.priority.length; j++) {
                    for (let k = 0; k < this.priority.length; k++) {
                        if (result.priority[j]._id === this.priority[k].id) {
                            this.priority_ChartData.push({
                                name: this.priority[k].label,
                                value: result.priority[j].total
                            });
                        }
                    }
                }
                this.priority_ChartOption = {
                    legend: {
                        left: 'center',
                        top: '10%'
                    },
                    series: [
                        {
                            avoidLabelOverlap: false,
                            data: this.priority_ChartData,
                            emphasis: {
                                label: {
                                    fontSize: '40',
                                    fontWeight: 'bold',
                                    show: true
                                }
                            },
                            label: {
                                position: 'center',
                                show: true
                            },
                            labelLine: {
                                show: false
                            },
                            name: 'Access From',
                            radius: ['40%', '70%'],
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: "center",
                        text: "Tasks By Priority"
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                for (const task of result.updown_timeseries) {
                    this.updown_Chart_finishedData.push(task.finished_tasks);
                    this.updown_Chart_missedData.push(task.missed_tasks);
                    this.updown_Chart_timeseries_data.push(task.date_group_string);
                }
                this.updownChartOption = {
                    legend: {
                        // Try 'horizontal'
                        orient: 'vertical',
                        right: 5,
                        top: 'center'
                    },
                    series: [
                        {
                            data: this.updown_Chart_finishedData,
                            name: 'Finished Tasks',
                            type: 'line',
                        },
                        {
                            data: this.updown_Chart_missedData,
                            name: 'Missed Tasks',
                            type: 'line'
                        }
                    ],
                    title: {
                        left: 'center',
                        text: 'Finished/Missed Tasks'
                    },
                    xAxis: {
                        data: this.updown_Chart_timeseries_data,
                        type: 'category',
                    },
                    yAxis: {
                        type: 'value',
                    },
                };
            }
        });
    }
    static { this.ɵfac = function WorkspaceReportComponent_Factory(t) { return new (t || WorkspaceReportComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.WorkspaceReportService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: WorkspaceReportComponent, selectors: [["app-workspace-report"]], decls: 52, vars: 11, consts: [[1, "p-1", "p-md-4"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-column", "flex-md-row", "align-items-md-center"], [1, "me-3", 2, "font-weight", "500", "font-size", "24px"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "flex-md-row"], ["appearance", "outline", 1, "mt-2", "mt-md-0"], [3, "ngModel", "ngModelChange", "change"], [3, "value", 4, "ngFor", "ngForOf"], [1, "m-3", "d-flex", "flex-column", "flex-md-row", "justify-content-between"], [2, "flex", "1 1 auto", "text-align", "center"], [1, "border-left", 2, "flex", "1 1 auto", "text-align", "center"], [1, "row", "m-3"], [1, "col-12", "col-md-4"], ["echarts", "", 3, "options", "chartInit", 4, "ngIf"], ["echarts", "", 3, "options", 4, "ngIf"], [1, "col-12", "col-md-12"], [3, "value"], ["echarts", "", 3, "options", "chartInit"], ["echarts", "", 3, "options"]], template: function WorkspaceReportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "span", 3);
            i0.ɵɵtext(4, "Workspace Report");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(5, "div", 4);
            i0.ɵɵelementStart(6, "div", 5)(7, "mat-form-field", 6)(8, "mat-label");
            i0.ɵɵtext(9, "Report type");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "mat-select", 7);
            i0.ɵɵlistener("ngModelChange", function WorkspaceReportComponent_Template_mat_select_ngModelChange_10_listener($event) { return ctx.selectedViewOption = $event; })("change", function WorkspaceReportComponent_Template_mat_select_change_10_listener() { return ctx.viewOptionFunc(); });
            i0.ɵɵtemplate(11, WorkspaceReportComponent_mat_option_11_Template, 2, 2, "mat-option", 8);
            i0.ɵɵelementEnd()()()()();
            i0.ɵɵelementStart(12, "div", 9)(13, "div", 10)(14, "h2");
            i0.ɵɵtext(15);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(16, "label");
            i0.ɵɵtext(17, "On hold Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(18, "div", 11)(19, "h2");
            i0.ɵɵtext(20);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(21, "label");
            i0.ɵɵtext(22, "Completed Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(23, "div", 11)(24, "h2");
            i0.ɵɵtext(25);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(26, "label");
            i0.ɵɵtext(27, "Missed Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(28, "div", 11)(29, "h2");
            i0.ɵɵtext(30);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(31, "label");
            i0.ɵɵtext(32, "Total Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(33, "div", 11)(34, "h2");
            i0.ɵɵtext(35);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(36, "label");
            i0.ɵɵtext(37, "Total Projects");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(38, "div", 12);
            i0.ɵɵelement(39, "div", 13)(40, "div", 13)(41, "div", 13);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(42, "div", 12)(43, "div", 13);
            i0.ɵɵtemplate(44, WorkspaceReportComponent_div_44_Template, 1, 1, "div", 14);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(45, "div", 13);
            i0.ɵɵtemplate(46, WorkspaceReportComponent_div_46_Template, 1, 1, "div", 15);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(47, "div", 13);
            i0.ɵɵtemplate(48, WorkspaceReportComponent_div_48_Template, 1, 1, "div", 15);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(49, "div", 12)(50, "div", 16);
            i0.ɵɵtemplate(51, WorkspaceReportComponent_div_51_Template, 1, 1, "div", 15);
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngModel", ctx.selectedViewOption);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.viewOptions);
            i0.ɵɵadvance(4);
            i0.ɵɵtextInterpolate(ctx.onHoldTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.completedTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.missTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.totalTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.totalProject);
            i0.ɵɵadvance(9);
            i0.ɵɵproperty("ngIf", ctx.section_chart_option);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.priority_ChartOption);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.assignee_ChartOption);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.updownChartOption);
        } }, dependencies: [i4.NgForOf, i4.NgIf, i5.NgControlStatus, i5.NgModel, i6.MatFormField, i6.MatLabel, i7.MatSelect, i8.MatOption, i9.NgxEchartsDirective] }); }
}

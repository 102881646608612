import "@dotlottie/player-component/dist/dotlottie-player.js";
import * as i0 from "@angular/core";
export class DotlottiePlayerComponent {
    static { this.ɵfac = function DotlottiePlayerComponent_Factory(t) { return new (t || DotlottiePlayerComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: DotlottiePlayerComponent, selectors: [["dotlottie-player"]], decls: 2, vars: 0, template: function DotlottiePlayerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "p");
            i0.ɵɵtext(1, "dotlottie-player works!");
            i0.ɵɵelementEnd();
        } } }); }
}

import * as i0 from "@angular/core";
export class PathResolveService {
    constructor() {
        this.paths = {
            'accept-invite': 'accept-invite',
            'form': 'form',
            'forms': 'forms',
            'home': 'home',
            'project-gantt': 'project-gantt',
            'projects': 'projects',
            'singin': 'singin',
            'singup': 'singup',
            'workspaces': 'member'
        };
    }
    resolve(route, state) {
        const typoPath = state.url.replace('/', '');
        const threshold = this.getThreshold(typoPath);
        const dictionary = Object.values(this.paths)
            .filter(path => Math.abs(path.length - typoPath.length) < threshold);
        if (!dictionary.length) {
            return null;
        }
        this.sortByDistances(typoPath, dictionary);
        if (dictionary[0]) {
            const key = Object.keys(this.paths).find((key) => {
                return this.paths[key] === dictionary[0];
            });
            return `${key}||${dictionary[0]}`;
        }
        else {
            /**
             * Neu khong co goi y nao thi chuyen ve home
             */
            return `home||/`;
        }
    }
    getThreshold(path) {
        if (path.length < 5) {
            return 3;
        }
        return 5;
    }
    sortByDistances(typoPath, dictionary) {
        const pathsDistance = {};
        dictionary.sort((a, b) => {
            if (!(a in pathsDistance)) {
                pathsDistance[a] = this.levenshtein(a, typoPath);
            }
            if (!(b in pathsDistance)) {
                pathsDistance[b] = this.levenshtein(b, typoPath);
            }
            return pathsDistance[a] - pathsDistance[b];
        });
    }
    /**
     * Calculate the levenshtein distance of two strings.
     * See https://en.wikipedia.org/wiki/Levenshtein_distance.
     * Based off https://gist.github.com/andrei-m/982927 (for using the faster dynamic programming
     * version).
     *
     * @param a String a.
     * @param b String b.
     * @returns A number that represents the distance between the two strings. The greater the number
     *   the more distant the strings are from each others.
     */
    levenshtein(a, b) {
        if (a.length === 0) {
            return b.length;
        }
        if (b.length === 0) {
            return a.length;
        }
        const matrix = [];
        // increment along the first column of each row
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }
        // increment each column in the first row
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }
        // Fill in the rest of the matrix
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                }
                else {
                    matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1, // insertion
                    matrix[i - 1][j] + 1);
                }
            }
        }
        return matrix[b.length][a.length];
    }
    static { this.ɵfac = function PathResolveService_Factory(t) { return new (t || PathResolveService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PathResolveService, factory: PathResolveService.ɵfac, providedIn: 'root' }); }
}

import * as i0 from "@angular/core";
export class SearchFilterPipe {
    transform(value, args) {
        if (!value)
            return null;
        if (!args)
            return value;
        const key = Object.keys(args)[0];
        return value.filter((data) => {
            return data[key] === args[key];
        });
    }
    static { this.ɵfac = function SearchFilterPipe_Factory(t) { return new (t || SearchFilterPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "searchFilter", type: SearchFilterPipe, pure: true }); }
}

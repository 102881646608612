import { CommonModule } from '@angular/common';
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import * as i0 from "@angular/core";
export class GanttModule {
    static { this.ɵfac = function GanttModule_Factory(t) { return new (t || GanttModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: GanttModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            MatMenuModule,
            MatTooltipModule] }); }
}

import moment from 'moment';
import * as i0 from "@angular/core";
export class AddPipe {
    transform(value, amount, unit) {
        if (typeof amount === 'undefined' ||
            (typeof amount === 'number' && typeof unit === 'undefined')) {
            throw new Error('AddPipe: missing required arguments');
        }
        return moment(value).add(amount, unit);
    }
    static { this.ɵfac = function AddPipe_Factory(t) { return new (t || AddPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amAdd", type: AddPipe, pure: true }); }
}

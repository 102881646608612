import * as __NgCli_bootstrap_1 from "@angular/platform-browser";
/* eslint-disable @typescript-eslint/no-empty-function */
import { enableProdMode } from '@angular/core';
import { Config } from '../src/app/configs/config';
import { AppModule } from './app/app.module';
if (Config.production) {
    enableProdMode();
}
__NgCli_bootstrap_1.platformBrowser()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt";
import * as i3 from "../types/AppConfig";
export class AuthService {
    constructor(http, jwtHelper, config) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.config = config;
    }
    getToken() {
        return localStorage.getItem('x-access-token');
    }
    getWorkspace() {
        return localStorage.getItem('workspace');
    }
    isAuthenticated() {
        const token = this.getToken();
        if (token)
            return !this.jwtHelper.isTokenExpired(token);
        return false;
    }
    isChoosedWorkspace() {
        const token = this.getToken();
        if (token)
            return this.jwtHelper.decodeToken(token).workspace && this.getWorkspace();
        return false;
    }
    signIn(email, password) {
        const url = `${this.config.appUrl}/auth/signin`;
        return this.http.post(url, { email, password });
    }
    signOut() {
        const url = `${this.config.appUrl}/users/signout`;
        return this.http.get(url);
    }
    guestSignOut() {
        const url = `${this.config.appUrl}/guest/signout`;
        return this.http.get(url);
    }
    signUp(email, password, name, user_type) {
        const url = `${this.config.appUrl}/auth/signup`;
        return this.http.post(url, { email, name, password, user_type });
    }
    getUser() {
        const url = `${this.config.appUrl}/users/me`;
        return this.http.get(url);
    }
    setWorkspace(data) {
        const url = `${this.config.appUrl}/users/set-workspace`;
        return this.http.post(url, data);
    }
    changeAvatar(data) {
        const url = `${this.config.appUrl}/users/change-avatar`;
        return this.http.post(url, data);
    }
    updateProfile(data) {
        const url = `${this.config.appUrl}/users/update-profile`;
        return this.http.post(url, data);
    }
    changePassword(data) {
        const url = `${this.config.appUrl}/users/change-password`;
        return this.http.post(url, data);
    }
    forgot(data) {
        const url = `${this.config.appUrl}/auth/forgot`;
        return this.http.post(url, data);
    }
    reset(data) {
        const url = `${this.config.appUrl}/auth/reset`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function AuthService_Factory(t) { return new (t || AuthService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.AppConfig)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac, providedIn: 'root' }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "../types/AppConfig";
export class HandleUnrecoverableStateService {
    constructor(updates, config) {
        this.config = config;
        if (config.production && config.auto_update) {
            updates.unrecoverable.subscribe(event => {
                console.log('An error occurred that we cannot recover from:\n' + event.reason + '\n\nPlease reload the page.');
            });
        }
    }
    static { this.ɵfac = function HandleUnrecoverableStateService_Factory(t) { return new (t || HandleUnrecoverableStateService)(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.AppConfig)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: HandleUnrecoverableStateService, factory: HandleUnrecoverableStateService.ɵfac, providedIn: 'root' }); }
}

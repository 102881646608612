import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { EventEmitter, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { CONTEXT_MENU_OPTIONS } from './contextMenu.tokens';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
const _c0 = ["menu"];
const _c1 = ["li"];
function ContextMenuContentComponent_li_3_a_2_ng_template_1_Template(rf, ctx) { }
const _c2 = function (a0) { return { $implicit: a0 }; };
function ContextMenuContentComponent_li_3_a_2_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 7);
    i0.ɵɵlistener("click", function ContextMenuContentComponent_li_3_a_2_Template_a_click_0_listener($event) { i0.ɵɵrestoreView(_r10); const menuItem_r2 = i0.ɵɵnextContext().$implicit; const ctx_r8 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r8.onMenuItemSelect(menuItem_r2, $event)); })("mouseenter", function ContextMenuContentComponent_li_3_a_2_Template_a_mouseenter_0_listener($event) { i0.ɵɵrestoreView(_r10); const menuItem_r2 = i0.ɵɵnextContext().$implicit; const ctx_r11 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r11.onOpenSubMenu(menuItem_r2, $event)); });
    i0.ɵɵtemplate(1, ContextMenuContentComponent_li_3_a_2_ng_template_1_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const menuItem_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("dropdown-item", ctx_r5.useBootstrap4)("active", menuItem_r2.isActive && ctx_r5.isMenuItemEnabled(menuItem_r2))("disabled", ctx_r5.useBootstrap4 && !ctx_r5.isMenuItemEnabled(menuItem_r2))("hasSubMenu", !!menuItem_r2.subMenu);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", menuItem_r2.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(10, _c2, ctx_r5.item));
} }
function ContextMenuContentComponent_li_3_span_3_ng_template_1_Template(rf, ctx) { }
function ContextMenuContentComponent_li_3_span_3_Template(rf, ctx) { if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 9);
    i0.ɵɵlistener("click", function ContextMenuContentComponent_li_3_span_3_Template_span_click_0_listener($event) { i0.ɵɵrestoreView(_r16); const ctx_r15 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r15.stopEvent($event)); })("contextmenu", function ContextMenuContentComponent_li_3_span_3_Template_span_contextmenu_0_listener($event) { i0.ɵɵrestoreView(_r16); const ctx_r17 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r17.stopEvent($event)); });
    i0.ɵɵtemplate(1, ContextMenuContentComponent_li_3_span_3_ng_template_1_Template, 0, 0, "ng-template", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const menuItem_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("dropdown-item", ctx_r6.useBootstrap4)("disabled", ctx_r6.useBootstrap4 && !ctx_r6.isMenuItemEnabled(menuItem_r2));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngTemplateOutlet", menuItem_r2.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(6, _c2, ctx_r6.item));
} }
function ContextMenuContentComponent_li_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li", null, 4);
    i0.ɵɵtemplate(2, ContextMenuContentComponent_li_3_a_2_Template, 2, 12, "a", 5);
    i0.ɵɵtemplate(3, ContextMenuContentComponent_li_3_span_3_Template, 2, 8, "span", 6);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const menuItem_r2 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("disabled", !ctx_r1.isMenuItemEnabled(menuItem_r2))("divider", menuItem_r2.divider)("dropdown-divider", ctx_r1.useBootstrap4 && menuItem_r2.divider)("active", menuItem_r2.isActive && ctx_r1.isMenuItemEnabled(menuItem_r2));
    i0.ɵɵattribute("role", menuItem_r2.divider ? "separator" : undefined);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !menuItem_r2.divider && !menuItem_r2.passive);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !menuItem_r2.divider && menuItem_r2.passive);
} }
const ARROW_LEFT_KEYCODE = 37;
export class ContextMenuContentComponent {
    constructor(changeDetector, elementRef, options) {
        this.changeDetector = changeDetector;
        this.elementRef = elementRef;
        this.options = options;
        this.menuItems = [];
        this.isLeaf = false;
        this.execute = new EventEmitter();
        this.openSubMenu = new EventEmitter();
        this.closeLeafMenu = new EventEmitter();
        this.closeAllMenus = new EventEmitter();
        this.autoFocus = false;
        this.useBootstrap4 = false;
        this.subscription = new Subscription();
        if (options) {
            this.autoFocus = options.autoFocus;
            this.useBootstrap4 = options.useBootstrap4;
        }
    }
    ngOnInit() {
        this.menuItems?.forEach(menuItem => {
            menuItem.currentItem = this.item;
            this.subscription.add(menuItem.execute.subscribe(event => this.execute.emit({ ...event, menuItem })));
        });
        const queryList = new QueryList();
        if (this.menuItems)
            queryList.reset(this.menuItems);
        this._keyManager = new ActiveDescendantKeyManager(queryList).withWrap();
    }
    ngAfterViewInit() {
        if (this.autoFocus) {
            setTimeout(() => this.focus());
        }
        this.overlay?.updatePosition();
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    focus() {
        if (this.autoFocus) {
            this.menuElement?.nativeElement.focus();
        }
    }
    stopEvent($event) {
        $event.stopPropagation();
    }
    isMenuItemEnabled(menuItem) {
        return this.evaluateIfFunction(menuItem && menuItem.enabled);
    }
    isMenuItemVisible(menuItem) {
        return this.evaluateIfFunction(menuItem && menuItem.visible);
    }
    evaluateIfFunction(value) {
        if (value instanceof Function) {
            return value(this.item);
        }
        return value;
    }
    isDisabled(link) {
        return link.enabled && !link.enabled(this.item);
    }
    onKeyEvent(event) {
        if (!this.isLeaf) {
            return;
        }
        this._keyManager?.onKeydown(event);
    }
    keyboardOpenSubMenu(event) {
        if (!this.isLeaf) {
            return;
        }
        this.cancelEvent(event);
        if (this._keyManager?.activeItemIndex) {
            const menuItem = this.menuItems?.[this._keyManager.activeItemIndex];
            if (menuItem) {
                this.onOpenSubMenu(menuItem);
            }
        }
    }
    keyboardMenuItemSelect(event) {
        if (!this.isLeaf) {
            return;
        }
        this.cancelEvent(event);
        if (this._keyManager?.activeItemIndex) {
            const menuItem = this.menuItems?.[this._keyManager.activeItemIndex];
            if (menuItem) {
                this.onMenuItemSelect(menuItem, event);
            }
        }
    }
    onCloseLeafMenu(event) {
        if (!this.isLeaf) {
            return;
        }
        this.cancelEvent(event);
        this.closeLeafMenu.emit({
            exceptRootMenu: event.keyCode === ARROW_LEFT_KEYCODE,
            event
        });
    }
    closeMenu(event) {
        if (event.type === 'click' && event.button === 2) {
            return;
        }
        this.closeAllMenus.emit({ event });
    }
    onOpenSubMenu(menuItem, event) {
        if (this._keyManager?.activeItemIndex) {
            const anchorElementRef = this.menuItemElements?.toArray()[this._keyManager.activeItemIndex];
            const anchorElement = anchorElementRef && anchorElementRef.nativeElement;
            this.openSubMenu.emit({
                anchorElement,
                contextMenu: menuItem.subMenu,
                event,
                item: this.item,
                parentContextMenu: this
            });
        }
    }
    onMenuItemSelect(menuItem, event) {
        event.preventDefault();
        event.stopPropagation();
        this.onOpenSubMenu(menuItem, event);
        if (!menuItem.subMenu) {
            menuItem.triggerExecute(this.item, event);
        }
    }
    cancelEvent(event) {
        if (!event) {
            return;
        }
        const target = event.target;
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(target.tagName) > -1 ||
            target.isContentEditable) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
    }
    static { this.ɵfac = function ContextMenuContentComponent_Factory(t) { return new (t || ContextMenuContentComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(CONTEXT_MENU_OPTIONS, 8)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ContextMenuContentComponent, selectors: [["context-menu-content"]], viewQuery: function ContextMenuContentComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, 7);
            i0.ɵɵviewQuery(_c1, 5);
        } if (rf & 2) {
            let _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.menuElement = _t.first);
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.menuItemElements = _t);
        } }, hostBindings: function ContextMenuContentComponent_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("keydown.ArrowDown", function ContextMenuContentComponent_keydown_ArrowDown_HostBindingHandler($event) { return ctx.onKeyEvent($event); }, false, i0.ɵɵresolveWindow)("keydown.ArrowUp", function ContextMenuContentComponent_keydown_ArrowUp_HostBindingHandler($event) { return ctx.onKeyEvent($event); }, false, i0.ɵɵresolveWindow)("keydown.ArrowRight", function ContextMenuContentComponent_keydown_ArrowRight_HostBindingHandler($event) { return ctx.keyboardOpenSubMenu($event); }, false, i0.ɵɵresolveWindow)("keydown.Enter", function ContextMenuContentComponent_keydown_Enter_HostBindingHandler($event) { return ctx.keyboardMenuItemSelect($event); }, false, i0.ɵɵresolveWindow)("keydown.Space", function ContextMenuContentComponent_keydown_Space_HostBindingHandler($event) { return ctx.keyboardMenuItemSelect($event); }, false, i0.ɵɵresolveWindow)("keydown.Escape", function ContextMenuContentComponent_keydown_Escape_HostBindingHandler($event) { return ctx.onCloseLeafMenu($event); }, false, i0.ɵɵresolveWindow)("keydown.ArrowLeft", function ContextMenuContentComponent_keydown_ArrowLeft_HostBindingHandler($event) { return ctx.onCloseLeafMenu($event); }, false, i0.ɵɵresolveWindow)("click", function ContextMenuContentComponent_click_HostBindingHandler($event) { return ctx.closeMenu($event); }, false, i0.ɵɵresolveDocument)("contextmenu", function ContextMenuContentComponent_contextmenu_HostBindingHandler($event) { return ctx.closeMenu($event); }, false, i0.ɵɵresolveDocument);
        } }, inputs: { menuItems: "menuItems", item: "item", event: "event", parentContextMenu: "parentContextMenu", menuClass: "menuClass", overlay: "overlay", isLeaf: "isLeaf" }, outputs: { execute: "execute", openSubMenu: "openSubMenu", closeLeafMenu: "closeLeafMenu", closeAllMenus: "closeAllMenus" }, decls: 4, vars: 2, consts: [["tabindex", "0", 1, "dropdown", "open", "show", "ngx-contextmenu", 3, "ngClass"], ["tabindex", "0", 1, "dropdown-menu", "show", 2, "position", "static", "float", "none"], ["menu", ""], [3, "disabled", "divider", "dropdown-divider", "active", 4, "ngFor", "ngForOf"], ["li", ""], ["href", "", 3, "dropdown-item", "active", "disabled", "hasSubMenu", "click", "mouseenter", 4, "ngIf"], ["class", "passive", 3, "dropdown-item", "disabled", "click", "contextmenu", 4, "ngIf"], ["href", "", 3, "click", "mouseenter"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "passive", 3, "click", "contextmenu"]], template: function ContextMenuContentComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "ul", 1, 2);
            i0.ɵɵtemplate(3, ContextMenuContentComponent_li_3_Template, 4, 11, "li", 3);
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵproperty("ngClass", ctx.menuClass);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngForOf", ctx.menuItems);
        } }, dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet], styles: ["@charset \"UTF-8\";\n.passive[_ngcontent-%COMP%] {\n  display: block;\n  padding: 3px 20px;\n  clear: both;\n  font-weight: normal;\n  line-height: 1.5;\n  white-space: nowrap;\n}\n\n.hasSubMenu[_ngcontent-%COMP%]:before {\n  content: \"\u25B6\";\n  float: right;\n}"] }); }
}

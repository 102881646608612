export default class dialog {
    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        this.dialogs = [];
        this.perspectiveCounter = 0;
        this.zIndex = 10000;
        this.utils = {
            debugTrace() {
                let trace = '';
                try {
                    throw new Error();
                }
                catch (e) {
                    trace = e.stack;
                }
                trace = trace.replace(/^Error/, 'Trace');
                return trace;
            },
            getCenterPosition(element) {
                return {
                    x: element.offsetLeft + element.offsetWidth / 2,
                    y: element.offsetTop + element.offsetHeight / 2
                };
            },
            isArray(v) {
                return Array.isArray(v);
            },
            isString(v) {
                return typeof v === 'string' || v instanceof String;
            },
            newId() {
                return 'xd-id-' + Math.random().toString(36).substring(2);
            },
            newZIndex() {
                that.zIndex += 1;
                return that.zIndex;
            },
            setCenterPosition(element, pos) {
                element.style.left = (pos.x - element.offsetWidth / 2) + 'px';
                element.style.top = (pos.y - element.offsetHeight / 2) + 'px';
            }
        };
        // all transitions should end in 1 second, then some cleanup work or fix will be done
        this.transitionTimeout = 1000;
        this.spinOverlayElement = this.createSpin();
        this.spinCount = 0;
        this.dragAsClick = {
            distance: 5,
            timeout: 300
        };
    }
    defaultOptions() {
        return {
            aftercreate: null,
            afterhide: null,
            aftershow: null,
            beforecreate: null,
            beforehide: null,
            beforeshow: null,
            body: '<p>Dialog body</p>',
            buttons: ['ok', 'cancel'],
            effect: 'fade_in_and_scale',
            extraClass: '',
            fixChromeBlur: true,
            listenESCKey: true,
            listenEnterKey: true,
            modal: true,
            oncancel: null,
            ondelete: null,
            ondestroy: null,
            ondrag: null,
            onok: null,
            style: '',
            timeout: 0,
            title: 'Dialog Title',
        };
    }
    callbackParam(dialogElement, dialog, overlayElement, event) {
        return {
            dialog,
            element: dialogElement,
            event,
            id: dialogElement.id,
            overlay: overlayElement
        };
    }
    defaultAlertOptions(text) {
        text = text || 'alert text';
        return {
            body: '<p style="text-align:center;">' + text + '</p>',
            buttons: ['ok'],
            effect: 'sticky_up',
            title: null,
        };
    }
    defaultConfirmOptions(text, onyes) {
        text = text || 'Are you sure?';
        return {
            body: '<p style="text-align:center;">' + text + '</p>',
            buttons: {
                cancel: 'No',
                ok: 'Yes'
            },
            effect: '3d_sign',
            onok: onyes,
            title: 'Confirm',
        };
    }
    defaultInfoOptions(text) {
        text = text || 'some information';
        return {
            body: '<div style="text-align:center;">' + text + '</div>',
            buttons: null,
            effect: 'sticky_up',
            extraClass: 'xd-info',
            modal: false,
            timeout: 5,
            title: null
        };
    }
    defaultWarnOptions(text) {
        text = text || 'Some warning';
        return {
            body: '<div style="text-align:center;">' + text + '</div>',
            buttons: null,
            effect: 'sticky_up',
            extraClass: 'xd-warn',
            modal: false,
            timeout: 10,
            title: null
        };
    }
    defaultErrorOptions(text) {
        text = text || 'An error occured!';
        return {
            body: '<div style="text-align:center;">' + text + '</div>',
            buttons: ['ok'],
            effect: 'slide_in_bottom',
            extraClass: 'xd-error',
            title: 'Error'
        };
    }
    defaultFatalOptions(text) {
        text = text || 'A fatal error occured!';
        return {
            beforehide() {
                return false;
            },
            body: '<div style="text-align:center;">' + text + '</div>',
            buttons: null,
            effect: null,
            extraClass: 'xd-fatal',
            ondrag() {
                return false;
            },
            title: 'Fatal Error',
        };
    }
    getEffect(effectName) {
        if (!effectName) {
            return { clazz: '', perspective: false };
        }
        switch (effectName) {
            case 'fade_in_and_scale':
            default:
                return { clazz: 'xd-effect-1', perspective: false };
            case 'slide_in_right':
                return { clazz: 'xd-effect-2', perspective: false };
            case 'slide_in_bottom':
                return { clazz: 'xd-effect-3', perspective: false };
            case 'newspaper':
                return { clazz: 'xd-effect-4', perspective: false };
            case 'fall':
                return { clazz: 'xd-effect-5', perspective: false };
            case 'side_fall':
                return { clazz: 'xd-effect-6', perspective: false };
            case 'sticky_up':
                return { clazz: 'xd-effect-7', perspective: false };
            case '3d_flip_horizontal':
                return { clazz: 'xd-effect-8', perspective: false };
            case '3d_flip_vertical':
                return { clazz: 'xd-effect-9', perspective: false };
            case '3d_sign':
                return { clazz: 'xd-effect-10', perspective: false };
            case 'super_scaled':
                return { clazz: 'xd-effect-11', perspective: false };
            case 'just_me':
                return { clazz: 'xd-effect-12', perspective: false };
            case '3d_slit':
                return { clazz: 'xd-effect-13', perspective: false };
            case '3d_rotate_bottom':
                return { clazz: 'xd-effect-14', perspective: false };
            case '3d_rotate_in_left':
                return { clazz: 'xd-effect-15', perspective: false };
            case 'blur':
                return { clazz: 'xd-effect-16', perspective: false };
            case 'let_me_in':
                return { clazz: 'xd-effect-17', perspective: true };
            case 'make_way':
                return { clazz: 'xd-effect-18', perspective: true };
            case 'slip_from_top':
                return { clazz: 'xd-effect-19', perspective: true };
        }
    }
    createOverlay(params) {
        params = Object.assign({
            zIndex: this.utils.newZIndex()
        }, params);
        const overlayElement = document.createElement('div');
        overlayElement.classList.add('xd-overlay');
        overlayElement.style['z-index'] = params.zIndex;
        document.body.insertAdjacentElement('beforeend', overlayElement);
        return overlayElement;
    }
    createDialog(options, overlayElement) {
        // create element
        const dialogElement = document.createElement('div');
        const effect = this.getEffect(options.effect);
        dialogElement.id = this.utils.newId();
        dialogElement.effect = effect;
        dialogElement.setAttribute('class', 'xd-dialog xd-center ' + effect.clazz + ' ' + options.extraClass);
        dialogElement.setAttribute('style', 'z-index:' + this.utils.newZIndex() + ';' + options.style);
        // create innerHTML
        let innerHTML = '<div class="xd-content">';
        if (options.title) {
            innerHTML += '<div class="xd-title">' + options.title + '</div>';
        }
        if (options.body) {
            if (this.utils.isString(options.body)) {
                innerHTML += '<div class="xd-body"><div class="xd-body-inner">' + options.body + '</div></div>';
            }
            else {
                let srcElement = null;
                if (options.body.src) {
                    srcElement = document.querySelector(options.body.src);
                }
                else if (options.body.element) {
                    srcElement = options.body.element;
                }
                if (srcElement) {
                    dialogElement.srcOriginalParent = srcElement.parentElement;
                    dialogElement.srcElement = srcElement;
                    innerHTML += '<div class="xd-body"><div class="xd-body-inner"></div></div>';
                }
                else {
                    console.warn('Element of selector not found: ' + options.body.src);
                }
            }
        }
        if (options.buttons) {
            innerHTML += this.createButtons(options);
        }
        innerHTML += '</div>';
        dialogElement.innerHTML = innerHTML;
        if (dialogElement.srcElement) {
            dialogElement.querySelector('.xd-body-inner').appendChild(dialogElement.srcElement);
        }
        if (options.beforecreate) {
            if (options.beforecreate(this.callbackParam(dialogElement, null, overlayElement, null)) === false) {
                return null;
            }
        }
        document.body.insertAdjacentElement('afterbegin', dialogElement);
        options.aftercreate && options.aftercreate(this.callbackParam(dialogElement, null, overlayElement, null));
        return dialogElement;
    }
    predefinedButtonInfo(name) {
        switch (name) {
            case 'ok':
                return {
                    clazz: 'xd-button xd-ok',
                    text: 'OK'
                };
            case 'cancel':
                return {
                    clazz: 'xd-button xd-cancel',
                    text: 'Cancel'
                };
            case 'delete':
                return {
                    clazz: 'xd-button xd-delete',
                    text: 'Delete'
                };
            default:
                return null;
        }
    }
    createButtons(options) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        let html = '';
        const buttonInfos = {};
        if (this.utils.isArray(options.buttons)) {
            options.buttons.forEach((name, i) => {
                const buttonInfo = that.predefinedButtonInfo(name);
                if (buttonInfo) {
                    // predefined
                    buttonInfos[name] = buttonInfo;
                }
                else {
                    // non-predefined
                    buttonInfos['button' + i] = {
                        html: name // name is html
                    };
                }
            });
        }
        else {
            Object.keys(options.buttons).forEach((name) => {
                const buttonInfo = that.predefinedButtonInfo(name);
                const value = options.buttons[name];
                if (buttonInfo) {
                    // predefined
                    if (that.utils.isString(value)) {
                        // value is a string, set text attribute
                        buttonInfo.text = value;
                        buttonInfos[name] = buttonInfo;
                    }
                    else {
                        // value is an object, merge attributes
                        buttonInfos[name] = Object.assign(buttonInfo, value);
                    }
                }
                else {
                    // non-predefined
                    buttonInfos[name] = {
                        html: value
                    };
                }
            });
        }
        html += '<div class="xd-buttons">';
        Object.keys(buttonInfos).forEach((name) => {
            if (buttonInfos[name].html) {
                // html defined
                html += buttonInfos[name].html;
            }
            else {
                const style = buttonInfos[name].style || '';
                html += '<button style="' + style + '" class="' + buttonInfos[name].clazz + '">' + buttonInfos[name].text + '</button>';
            }
        });
        html += '</div>';
        return html;
    }
    /**
     * init xdialog
     *
     * @param {object} options
     * @param {number} options.zIndex0
     */
    init(options) {
        this.zIndex = options.zIndex0 || this.zIndex;
    }
    create(options) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        let dialog = {};
        options = Object.assign(that.defaultOptions(), options);
        let overlayElement = null;
        options.modal && (overlayElement = that.createOverlay());
        const dialogElement = that.createDialog(options, overlayElement);
        if (dialogElement === null) {
            return null;
        }
        const okButton = dialogElement.querySelector('.xd-ok');
        const cancelButton = dialogElement.querySelector('.xd-cancel');
        const deleteButton = dialogElement.querySelector('.xd-delete');
        addEventListeners();
        // load all iframes before showing
        let preparedForShow = false;
        handleIFrame();
        dialog = {
            adjust,
            close,
            destroy,
            element: dialogElement,
            fixChromeBlur,
            hide,
            id: dialogElement.id,
            show
        };
        that.dialogs.push(dialog);
        return dialog;
        function handleIFrame() {
            const iframes = dialogElement.querySelectorAll('iframe');
            if (iframes.length === 0) {
                preparedForShow = true;
                return;
            }
            let loadCount = 0;
            [].slice.call(iframes).forEach((iframe) => {
                iframe.addEventListener('load', function listener(ev) {
                    iframe.removeEventListener('load', listener);
                    loadCount += 1;
                    if (loadCount === iframes.length) {
                        preparedForShow = true;
                    }
                });
            });
        }
        function addEventListeners() {
            okButton && okButton.addEventListener('click', doOk);
            cancelButton && cancelButton.addEventListener('click', doCancel);
            deleteButton && deleteButton.addEventListener('click', doDelete);
            that.dragElement(options.ondrag, dialogElement);
            if (overlayElement) {
                that.dragElement(options.ondrag, dialogElement, overlayElement, doCancel);
            }
        }
        function show() {
            checkStatusAndShow();
            function checkStatusAndShow() {
                if (preparedForShow) {
                    if (options.beforeshow) {
                        if (options.beforeshow(that.callbackParam(dialogElement, dialog, overlayElement, null)) === false) {
                            return;
                        }
                    }
                    showMe();
                    options.aftershow && options.aftershow(that.callbackParam(dialogElement, dialog, overlayElement, null));
                }
                else {
                    // wait for preparedForShow
                    setTimeout(checkStatusAndShow, 0);
                }
            }
            function showMe() {
                // remove foucs from original active element to avoid it response to enter key pressing
                // 'blur not supported in IE 11' occurred, so check it
                if (document.activeElement && document.activeElement.blur) {
                    document.activeElement.blur();
                }
                // use setTimeout to enable css transition
                setTimeout(() => {
                    if (dialogElement.effect.perspective) {
                        that.perspectiveCounter++;
                        if (that.perspectiveCounter === 1) {
                            document.documentElement.classList.add('xd-perspective');
                        }
                    }
                    dialogElement.classList.add('xd-show');
                    overlayElement && overlayElement.classList.add('xd-show-overlay');
                    listenEnterAndEscKey();
                    fixEnterKeyEventInTextarea();
                    if (options.timeout > 0) {
                        dialogElement.addEventListener('mouseenter', stopCloseTimer);
                        dialogElement.addEventListener('mouseleave', startCloseTimer);
                        startCloseTimer();
                    }
                }, 200);
                // NOTE: fix chrome blur
                if (options.fixChromeBlur) {
                    if (!dialogElement.effect.clazz) {
                        // dialogs without effect
                        fixChromeBlur();
                    }
                    else {
                        dialogElement.addEventListener('transitionend', function listener(ev) {
                            if (ev.propertyName === 'transform') {
                                dialogElement.removeEventListener('transitionend', listener);
                                // dialogs with effect on transform end
                                fixChromeBlur();
                            }
                        });
                        // event transitionend not always reliable, so also use setTimeout
                        setTimeout(() => {
                            fixChromeBlur();
                        }, that.transitionTimeout);
                    }
                }
            }
        }
        function hide() {
            if (options.beforehide) {
                if (options.beforehide(that.callbackParam(dialogElement, dialog, overlayElement, null)) === false) {
                    return false;
                }
            }
            // save center position for after adjusting
            dialog.centerPosition = that.utils.getCenterPosition(dialogElement);
            unlistenEnterAndEscKey();
            cleanEnterKeyEventInTextarea();
            if (options.timeout > 0) {
                dialogElement.removeEventListener('mouseenter', stopCloseTimer);
                dialogElement.removeEventListener('mouseleave', startCloseTimer);
            }
            restorePerspective();
            if (dialogElement.effect.perspective) {
                setTimeout(() => {
                    if (that.perspectiveCounter === 1) {
                        document.documentElement.classList.remove('xd-perspective');
                    }
                    that.perspectiveCounter--;
                }, that.transitionTimeout);
            }
            dialogElement.classList.remove('xd-show');
            overlayElement && overlayElement.classList.remove('xd-show-overlay');
            options.afterhide && options.afterhide(that.callbackParam(dialogElement, dialog, overlayElement, null));
        }
        function listenEnterAndEscKey() {
            if (options.listenEnterKey) {
                dialogElement.enterKeyListener = function listener(ev) {
                    if (ev.key !== 'Enter') {
                        return;
                    }
                    const topMostDialogElement = document.querySelector('.xd-dialog.xd-show');
                    if (topMostDialogElement === dialogElement) {
                        doOk(ev);
                    }
                };
                document.addEventListener('keyup', dialogElement.enterKeyListener);
            }
            if (options.listenESCKey) {
                dialogElement.escKeyListener = function listener(ev) {
                    if (ev.key !== 'Escape' && ev.key !== 'Esc') {
                        return;
                    }
                    const topMostDialogElement = document.querySelector('.xd-dialog.xd-show');
                    if (topMostDialogElement === dialogElement) {
                        doCancel(ev);
                    }
                };
                document.addEventListener('keyup', dialogElement.escKeyListener);
            }
        }
        function fixEnterKeyEventInTextarea() {
            [].slice.call(dialogElement.querySelectorAll('textarea')).forEach((textarea) => {
                textarea.addEventListener('keypress', fixEnterKeyEvent);
            });
        }
        function cleanEnterKeyEventInTextarea() {
            [].slice.call(dialogElement.querySelectorAll('textarea')).forEach((textarea) => {
                textarea.removeEventListener('keypress', fixEnterKeyEvent);
            });
        }
        // SEE: https://stackoverflow.com/a/14020398/1440174
        function fixEnterKeyEvent(ev) {
            if (ev.which === 13) {
                ev.stopPropagation();
            }
        }
        function unlistenEnterAndEscKey() {
            if (options.listenEnterKey) {
                document.removeEventListener('keyup', dialogElement.enterKeyListener);
                dialogElement.enterKeyListener = null;
            }
            if (options.listenESCKey) {
                document.removeEventListener('keyup', dialogElement.escKeyListener);
                dialogElement.escKeyListener = null;
            }
        }
        function startCloseTimer() {
            stopCloseTimer();
            dialog.closeTimerId = setTimeout(() => {
                dialog.closeTimerId = null;
                close();
            }, options.timeout * 1000);
        }
        function stopCloseTimer() {
            if (dialog.closeTimerId) {
                clearTimeout(dialog.closeTimerId);
                dialog.closeTimerId = null;
            }
        }
        function fixChromeBlur() {
            if (dialogElement.style.transform === 'none') {
                return;
            }
            // 1. keep current position
            // SEE: https://stackoverflow.com/a/11396681/1440174
            const rect = dialogElement.getBoundingClientRect();
            dialogElement.style.top = rect.top + 'px';
            dialogElement.style.left = rect.left + 'px';
            // 2. set 'transform' and 'perspective' to none, which may make dialog blurry in chrome browser
            dialogElement.style.transform = 'none';
            dialogElement.style.perspective = 'none';
        }
        // restore perspective to enable 3D transform
        function restorePerspective() {
            // remove inline perspective
            // NOTE: do not remove 'top', 'left' and 'transform' to keep dialog position after user's drag
            dialogElement.style.removeProperty('perspective');
        }
        function doOk(e) {
            if (options.onok && options.onok(that.callbackParam(dialogElement, dialog, overlayElement, e)) === false) {
                return;
            }
            close();
        }
        function doCancel(e) {
            if (options.oncancel && options.oncancel(that.callbackParam(dialogElement, dialog, overlayElement, e)) === false) {
                return;
            }
            close();
        }
        function doDelete(e) {
            if (options.ondelete && options.ondelete(that.callbackParam(dialogElement, dialog, overlayElement, e)) === false) {
                return;
            }
            close();
        }
        function destroy() {
            if (options.ondestroy && options.ondestroy(that.callbackParam(dialogElement, dialog, overlayElement, null)) === false) {
                return;
            }
            if (dialogElement.srcElement) {
                // return src element earlier as soon as animation end
                setTimeout(checkAndReturnSrcElement, 300);
            }
            setTimeout(() => {
                const index = that.dialogs.indexOf(dialog);
                if (index === -1) {
                    // user may call destroy() or click OK/Cancle/Delete button multi times
                    return;
                }
                doDestroy(index);
            }, that.transitionTimeout);
            function doDestroy(index) {
                okButton && okButton.removeEventListener('click', doOk);
                cancelButton && cancelButton.removeEventListener('click', doCancel);
                deleteButton && deleteButton.removeEventListener('click', doDelete);
                that.dialogs.splice(index, 1);
                document.body.removeChild(dialogElement);
                overlayElement && document.body.removeChild(overlayElement);
            }
            function checkAndReturnSrcElement() {
                if (dialogElement.contains(dialogElement.srcElement)) {
                    dialogElement.srcOriginalParent.appendChild(dialogElement.srcElement);
                }
                else {
                    setTimeout(checkAndReturnSrcElement, 1000);
                }
            }
        }
        function close() {
            const hideOk = hide();
            if (hideOk !== false) {
                destroy();
            }
        }
        function adjust() {
            if (dialog.status === 'adjusting') {
                // do nothing on adjusting to avoid set style.transition incorrectly
                return;
            }
            if (dialog.centerPosition) {
                // restore dialog center postion before hidding
                that.utils.setCenterPosition(dialogElement, dialog.centerPosition);
            }
            const rect = dialogElement.getBoundingClientRect();
            const clientWidth = document.documentElement.clientWidth;
            const clientHeight = document.documentElement.clientHeight;
            if (rect.left >= 0 && rect.top >= 0 && rect.right < clientWidth && rect.bottom < clientHeight) {
                return;
            }
            dialog.status = 'adjusting';
            const old = dialogElement.style.transition;
            dialogElement.style.transition = 'all .3s ease-in-out';
            if (rect.width > clientWidth) {
                dialogElement.style['max-width'] = clientWidth + 'px';
            }
            if (rect.height > clientHeight) {
                dialogElement.style['max-height'] = clientHeight + 'px';
            }
            const rect2 = dialogElement.getBoundingClientRect();
            if (!(rect.left >= 0 && rect.right < clientWidth)) {
                dialogElement.style.left = (clientWidth - rect2.width) / 2 + 'px';
            }
            if (!(rect.top >= 0 && rect.bottom < clientHeight)) {
                dialogElement.style.top = (clientHeight - rect2.height) / 2 + 'px';
            }
            dialogElement.addEventListener('transitionend', function listener() {
                dialogElement.removeEventListener('transitionend', listener);
                dialogElement.style.transition = old;
                dialog.status = 'adjusted';
            });
        }
    }
    open(options) {
        const dialog = this.create(options);
        if (dialog) {
            dialog.show();
            return dialog;
        }
        return null;
    }
    alert(text, options) {
        options = Object.assign(this.defaultAlertOptions(text), options);
        return this.open(options);
    }
    confirm(text, onyes, options) {
        options = Object.assign(this.defaultConfirmOptions(text, onyes), options);
        return this.open(options);
    }
    info(text, options) {
        options = Object.assign(this.defaultInfoOptions(text), options);
        return this.open(options);
    }
    warn(text, options) {
        options = Object.assign(this.defaultWarnOptions(text), options);
        return this.open(options);
    }
    error(text, options) {
        options = Object.assign(this.defaultErrorOptions(text), options);
        return this.open(options);
    }
    fatal(text, options) {
        options = Object.assign(this.defaultFatalOptions(text), options);
        return this.open(options);
    }
    createSpin() {
        // create spin element
        const spinElement = document.createElement('div');
        let innerHTML = '';
        spinElement.classList.add('sk-fading-circle');
        for (let i = 1; i <= 12; i++) {
            innerHTML += '<div class="sk-circle sk-circle' + i + '"></div>';
        }
        spinElement.innerHTML = innerHTML;
        // create debug info element
        const debugInfoElement = document.createElement('div');
        debugInfoElement.classList.add('xd-debug-info');
        // create overley element
        const spinOverlayElement = this.createOverlay({
            zIndex: 2147483647
        });
        spinOverlayElement.classList.add('xd-spin-overlay');
        spinOverlayElement.classList.add('xd-center-child');
        spinOverlayElement.appendChild(spinElement);
        spinOverlayElement.appendChild(debugInfoElement);
        return spinOverlayElement;
    }
    /**
     * start a spin
     *
     * NOTE: use localStorage for debugging, set in browser console, for example:
     *
     *      localStorage['x-debug-info'] = 1    // show debug info
     *      localStorage['x-debug-info'] = 0    // hide debug info
     */
    startSpin() {
        const debugInfoElement = this.spinOverlayElement.querySelector('.xd-debug-info');
        if (localStorage['x-debug-info'] === '1') {
            debugInfoElement.innerHTML = '<pre>' + this.utils.debugTrace() + '</pre>';
        }
        else {
            debugInfoElement.innerHTML = '';
        }
        if (this.spinCount === 0) {
            this.spinOverlayElement.classList.add('xd-show-overlay');
        }
        this.spinCount++;
    }
    /**
     * stop a spin
     */
    stopSpin() {
        this.spinCount--;
        if (this.spinCount === 0) {
            this.spinOverlayElement.classList.remove('xd-show-overlay');
        }
    }
    /**
     * drag on srcElement to move destElement
     *
     * @param {Element} destElement - element to be moved
     * @param {Element} srcElement - element to drag on
     * @param {Function} onclick - callback function when user clicked
     *
     * SEE: https://www.w3schools.com/howto/howto_js_draggable.asp
     */
    dragElement(ondrag, destElement, srcElement, onclick) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        // use destElement as srcElement if srcElement not supplied
        srcElement = srcElement || destElement;
        srcElement.addEventListener('mousedown', dragMouseDown);
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        let mouseDownEvent = null;
        let oldTransition = null;
        // SEE: https://api.jquery.com/input-selector/
        function isDraggableElement(element) {
            if (ondrag) {
                const res = ondrag(element, destElement, srcElement);
                if (res === false || res === true) {
                    return res;
                }
            }
            // do not start drag when click on inputs
            if (element instanceof HTMLInputElement) {
                return false;
            }
            // do not start drag when click on buttons, selects and textareas
            if (['BUTTON', 'SELECT', 'TEXTAREA'].indexOf(element.tagName) >= 0) {
                return false;
            }
            return true;
        }
        function dragMouseDown(e) {
            // NOTE: In IE 11 clicking on scrollbars does not fire 'mouseup' event
            // see also: http://help.dimsemenov.com/discussions/problems/65378-in-ie11-the-mouseup-event-not-fired-when-clicking-on-a-scrollbar-causes-sliding-to-stick
            //
            // To avoid dragging when clicking on IE 11 scrollbar, do nothing when clicking on scrollbar detected
            if (e.offsetX > e.target.clientWidth || e.offsetY > e.target.clientHeight) {
                return;
            }
            mouseDownEvent = e;
            if (isDraggableElement(e.target) === false) {
                return;
            }
            // save current destElement transition
            // clear transition to make sure smooth dragging
            oldTransition = destElement.style.transition;
            destElement.style.transition = '';
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            // call a function whenever the cursor moves:
            document.addEventListener('mousemove', elementDrag);
            document.addEventListener('mouseup', closeDragElement);
            // Temporarily disable mouse events for IFRAME for smooth dragging
            // SEE: https://www.gyrocode.com/articles/how-to-detect-mousemove-event-over-iframe-element/
            [].slice.call(srcElement.querySelectorAll('iframe')).forEach((iframe) => {
                iframe.style['pointer-events'] = 'none';
            });
        }
        function elementDrag(e) {
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the destElement's new position:
            destElement.style.top = (destElement.offsetTop - pos2) + 'px';
            destElement.style.left = (destElement.offsetLeft - pos1) + 'px';
        }
        function closeDragElement(e) {
            // restore destElement transition
            destElement.style.transition = oldTransition;
            // trigger click when dragging a litter quickly
            if (Math.abs(e.clientX - mouseDownEvent.clientX) + Math.abs(e.clientY - mouseDownEvent.clientY) < that.dragAsClick.distance && e.timeStamp - mouseDownEvent.timeStamp < that.dragAsClick.timeout) {
                onclick && onclick(e);
            }
            // stop moving when mouse button is released:
            document.removeEventListener('mousemove', elementDrag);
            document.removeEventListener('mouseup', closeDragElement);
            // Re-enable mouse events for IFRAME
            [].slice.call(srcElement.querySelectorAll('iframe')).forEach((iframe) => {
                iframe.style['pointer-events'] = 'auto';
            });
        }
    }
}

import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "src/app/services/guest-auth.service";
import * as i4 from "src/app/services/auth.service";
import * as i5 from "src/app/services/guest.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/material/form-field";
import * as i9 from "@angular/material/select";
import * as i10 from "@angular/material/core";
import * as i11 from "ngx-echarts";
function GuestReportComponent_mat_option_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 25);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵproperty("value", item_r3.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(item_r3.display);
} }
function GuestReportComponent_mat_option_17_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 25);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r4 = ctx.$implicit;
    i0.ɵɵproperty("value", item_r4.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(item_r4.display);
} }
function GuestReportComponent_mat_option_51_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 25);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r5 = ctx.$implicit;
    i0.ɵɵproperty("value", item_r5.id);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(item_r5.label);
} }
export class GuestReportComponent {
    constructor(activatedRoute, store, guestAuthService, authService, guestService) {
        this.activatedRoute = activatedRoute;
        this.store = store;
        this.guestAuthService = guestAuthService;
        this.authService = authService;
        this.guestService = guestService;
        this.selectedViewOption = 2;
        this.viewOptions = [
            {
                display: 'Week', value: 1
            },
            {
                display: 'Month', value: 2
            },
            {
                display: 'Year', value: 3
            }
        ];
        this.selectedPriorityOptions = 1;
        this.priorityOptions = [
            { id: 1, label: "high" },
            { id: 2, label: "medium" },
            { id: 3, label: "low" },
        ];
        this.ProjectViewOptions = [];
        this.onHoldTasks = 0;
        this.completedTasks = 0;
        this.missTasks = 0;
        this.totalTasks = 0;
        this.workload_chart_data_yAxis = [];
        this.workload_chart_data_series = [];
        this.priority_ChartData = [];
        this.scode = this.activatedRoute.snapshot.queryParamMap.get('scode');
        this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
        this.selectedProjectOption = this.scode;
    }
    ngOnInit() {
        const data = { 'scode': this.selectedProjectOption, 'start_date': this.getLastWeekSameDay() };
        const listProjectQuery = this.guestService.getListReport();
        if (this.guestAuthService.isAuthenticated()) {
            listProjectQuery.subscribe({
                error: (err) => {
                    const errorMsg = err.error.message || err.error.error || "Connection error";
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
                },
                next: (result) => {
                    console.log(result.message);
                    for (const prj of result.message) {
                        const opt = { 'display': prj.prj.name, 'value': prj._id };
                        this.ProjectViewOptions.push(opt);
                    }
                    if (!this.scode) {
                        this.selectedProjectOption = this.ProjectViewOptions[0].value;
                    }
                    this.loadReportProject();
                }
            });
        }
    }
    sectionChartInit($event) {
        console.log($event);
    }
    getLastWeekSameDay() {
        const d = new Date();
        const diff = d.getDate() - 7;
        return new Date(d.setDate(diff)).getTime();
    }
    getLastMonthSameDay() {
        const date = new Date();
        return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).getTime();
    }
    viewOptionFunc() {
        if (this.selectedViewOption === 1) {
            const date = new Date();
            this.start_date = this.getLastWeekSameDay();
        }
        else if (this.selectedViewOption === 2) {
            this.start_date = this.getLastMonthSameDay();
        }
        else if (this.selectedViewOption === 3) {
            this.start_date = new Date(new Date().getFullYear(), 0, 1).getTime();
        }
        console.log(this.start_date);
        const data = { 'scode': this.selectedProjectOption, 'start_date': this.start_date };
        this.loadReportProject();
    }
    viewProjectOptionFunc() {
        console.log(this.selectedProjectOption);
        this.loadReportProject();
    }
    viewPriorityFunc() {
        console.log(this.selectedPriorityOptions);
        const data = {
            'priority': this.selectedPriorityOptions,
            'scode': this.selectedProjectOption,
            'start_date': this.start_date
        };
        const queryPriorityReport = this.guestService.getPriorityPerformance(data);
        queryPriorityReport.subscribe({
            next: (result) => {
                console.log('priority performance', result);
                this.priority_ChartData = [
                    {
                        itemStyle: { 'color': 'green' },
                        name: 'Finished',
                        value: result.message[0].priFinishedTasks
                    },
                    {
                        itemStyle: { 'color': 'red' },
                        name: 'Missed',
                        value: result.message[0].priMissedTasks
                    },
                    {
                        itemStyle: { 'color': 'yellow' },
                        name: 'On Hold',
                        value: result.message[0].priOnHoldTasks
                    }
                ];
                this.priority_ChartOption = {
                    legend: {
                        left: 'center',
                        top: '10%'
                    },
                    series: [
                        {
                            avoidLabelOverlap: false,
                            data: this.priority_ChartData,
                            emphasis: {
                                label: {
                                    fontSize: '40',
                                    fontWeight: 'bold',
                                    show: true
                                }
                            },
                            label: {
                                position: 'center',
                                show: true
                            },
                            labelLine: {
                                show: false
                            },
                            name: 'Access From',
                            radius: ['40%', '70%'],
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: "center",
                        text: "Tasks By Priority"
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
            }
        });
    }
    loadReportProject() {
        const data = { 'scode': this.selectedProjectOption, 'start_date': this.start_date };
        this.selectedPriorityOptions = 1;
        const queryReport = this.guestService.queryProjectReport(data);
        queryReport.subscribe({
            next: (result) => {
                console.log(result);
                this.totalTasks = result.message[0].tasks_count;
                this.missTasks = result.message[0].missed_tasks_count;
                this.completedTasks = result.message[0].finished_tasks_count;
                this.onHoldTasks = result.message[0].todo_tasks_count;
                const _hold = [];
                const _finished = [];
                const _missed = [];
                const workload_chart_data_yAxis = [];
                for (const data of result.message[0].workloads) {
                    console.log(data);
                    workload_chart_data_yAxis.push(data.name);
                    _hold.push(data.on_hold_task);
                    _finished.push(data.finished_tasks);
                    _missed.push(data.missed_tasks);
                }
                this.workload_chart_data_series = [
                    {
                        data: _finished,
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: { color: 'green' },
                        label: {
                            show: true
                        },
                        name: 'Finised',
                        stack: 'total',
                        type: 'bar',
                    },
                    {
                        data: _hold,
                        emphasis: {
                            focus: _hold
                        },
                        itemStyle: { color: 'yellow' },
                        label: {
                            show: true
                        },
                        name: 'On Hold',
                        stack: 'total',
                        type: 'bar',
                    },
                    {
                        data: _missed,
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: { color: 'red' },
                        label: {
                            show: true
                        },
                        name: 'Missed',
                        stack: 'total',
                        type: 'bar',
                    }
                ];
                this.workload_chart_option = {
                    grid: {
                        bottom: '3%',
                        containLabel: true,
                        left: '3%',
                        right: '4%',
                        top: '30%'
                    },
                    legend: {
                        left: 'right',
                        top: '15%'
                    },
                    series: this.workload_chart_data_series,
                    title: {
                        left: 'left',
                        subtext: this.ProjectViewOptions.filter((x) => x.value === this.selectedProjectOption)[0].display,
                        text: 'Workload'
                    },
                    tooltip: {
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        },
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        data: workload_chart_data_yAxis,
                        type: 'category'
                    }
                };
                this.priority_ChartData = [
                    {
                        itemStyle: { 'color': 'green' },
                        name: 'Finished',
                        value: result.message[0].priFinishedTasks
                    },
                    {
                        itemStyle: { 'color': 'red' },
                        name: 'Missed',
                        value: result.message[0].priMissedTasks
                    },
                    {
                        itemStyle: { 'color': 'yellow' },
                        name: 'On Hold',
                        value: result.message[0].priOnHoldTasks
                    }
                ];
                this.priority_ChartOption = {
                    legend: {
                        left: 'center',
                        top: '10%'
                    },
                    series: [
                        {
                            avoidLabelOverlap: false,
                            data: this.priority_ChartData,
                            emphasis: {
                                label: {
                                    fontSize: '40',
                                    fontWeight: 'bold',
                                    show: true
                                }
                            },
                            label: {
                                position: 'center',
                                show: true
                            },
                            labelLine: {
                                show: false
                            },
                            name: 'Access From',
                            radius: ['40%', '70%'],
                            top: '10%',
                            type: 'pie'
                        }
                    ],
                    title: {
                        left: "center",
                        text: "Tasks By Priority"
                    },
                    tooltip: {
                        trigger: 'item'
                    }
                };
                const updown_Chart_timeseries_data = [];
                const updown_Chart_finishedData = [];
                const updown_Chart_missedData = [];
                for (const timeData of result.message[0].updown_timeseries) {
                    updown_Chart_finishedData.push(timeData.finished_tasks);
                    updown_Chart_missedData.push(timeData.missed_tasks);
                    updown_Chart_timeseries_data.push(timeData.date_group_string);
                }
                this.updownChartOption = {
                    legend: {
                        // Try 'horizontal'
                        orient: 'vertical',
                        right: 5,
                        top: 'center'
                    },
                    series: [
                        {
                            data: updown_Chart_finishedData,
                            name: 'Finished',
                            type: 'line',
                        },
                        {
                            data: updown_Chart_missedData,
                            name: 'Missed',
                            type: 'line'
                        }
                    ],
                    title: {
                        left: 'center',
                        text: 'Finished/Missed'
                    },
                    xAxis: {
                        data: updown_Chart_timeseries_data,
                        type: 'category',
                    },
                    yAxis: {
                        type: 'value',
                    },
                };
            }
        });
    }
    static { this.ɵfac = function GuestReportComponent_Factory(t) { return new (t || GuestReportComponent)(i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.GuestAuthService), i0.ɵɵdirectiveInject(i4.AuthService), i0.ɵɵdirectiveInject(i5.GuestService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: GuestReportComponent, selectors: [["app-guest-report"]], decls: 58, vars: 13, consts: [[1, "p-1", "p-md-4"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-column", "flex-md-row", "align-items-md-center"], [1, "d-inline-flex", "flex-row", "align-items-center"], [1, "me-3", 2, "font-weight", "500", "font-size", "24px"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "flex-md-row", "justify-content-center", "mt-2", "mt-md-0"], ["appearance", "outline"], [3, "ngModel", "ngModelChange", "change"], [3, "value", 4, "ngFor", "ngForOf"], ["appearance", "outline", 1, "ms-0", "ms-md-2"], [1, "row", "g-0", "g-md-2", "m-1", "m-md-3"], [1, "col-12"], [1, "m-3", "d-flex", "flex-row", "justify-content-between"], [2, "flex", "1 1 auto", "text-align", "center"], [1, "border-start", 2, "flex", "1 1 auto", "text-align", "center"], [1, "col-12", "mt-3"], [1, "row", "m-3"], [1, "col-12", "col-md-8"], ["echarts", "", "id", "section_chart", 1, "demo-chart", 3, "options", "chartInit"], [1, "col-12", "col-md-4"], [1, "d-flex", "justify-content-center"], [1, "w-100"], ["echarts", "", 1, "demo-chart", 3, "options"], [1, "col-12", "col-md-12"], [3, "value"]], template: function GuestReportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3)(4, "span", 4);
            i0.ɵɵtext(5, "Project Report");
            i0.ɵɵelementEnd()();
            i0.ɵɵelement(6, "div", 5);
            i0.ɵɵelementStart(7, "div", 6)(8, "mat-form-field", 7)(9, "mat-label");
            i0.ɵɵtext(10, "Project");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "mat-select", 8);
            i0.ɵɵlistener("ngModelChange", function GuestReportComponent_Template_mat_select_ngModelChange_11_listener($event) { return ctx.selectedProjectOption = $event; })("change", function GuestReportComponent_Template_mat_select_change_11_listener() { return ctx.viewProjectOptionFunc(); });
            i0.ɵɵtemplate(12, GuestReportComponent_mat_option_12_Template, 2, 2, "mat-option", 9);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(13, "mat-form-field", 10)(14, "mat-label");
            i0.ɵɵtext(15, "View in");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(16, "mat-select", 8);
            i0.ɵɵlistener("ngModelChange", function GuestReportComponent_Template_mat_select_ngModelChange_16_listener($event) { return ctx.selectedViewOption = $event; })("change", function GuestReportComponent_Template_mat_select_change_16_listener() { return ctx.viewOptionFunc(); });
            i0.ɵɵtemplate(17, GuestReportComponent_mat_option_17_Template, 2, 2, "mat-option", 9);
            i0.ɵɵelementEnd()()()()();
            i0.ɵɵelementStart(18, "div", 11)(19, "div", 12)(20, "div", 13)(21, "div", 14)(22, "h1");
            i0.ɵɵtext(23);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(24, "label");
            i0.ɵɵtext(25, "On hold");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(26, "div", 15)(27, "h1");
            i0.ɵɵtext(28);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(29, "label");
            i0.ɵɵtext(30, "Completed");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(31, "div", 15)(32, "h1");
            i0.ɵɵtext(33);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(34, "label");
            i0.ɵɵtext(35, "Missed");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(36, "div", 15)(37, "h1");
            i0.ɵɵtext(38);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(39, "label");
            i0.ɵɵtext(40, "Total");
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(41, "div", 16)(42, "div", 17)(43, "div", 18)(44, "div", 19);
            i0.ɵɵlistener("chartInit", function GuestReportComponent_Template_div_chartInit_44_listener($event) { return ctx.sectionChartInit($event); });
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(45, "div", 20)(46, "div", 21)(47, "mat-form-field", 7)(48, "mat-label");
            i0.ɵɵtext(49, "Priority");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(50, "mat-select", 8);
            i0.ɵɵlistener("ngModelChange", function GuestReportComponent_Template_mat_select_ngModelChange_50_listener($event) { return ctx.selectedPriorityOptions = $event; })("change", function GuestReportComponent_Template_mat_select_change_50_listener() { return ctx.viewPriorityFunc(); });
            i0.ɵɵtemplate(51, GuestReportComponent_mat_option_51_Template, 2, 2, "mat-option", 9);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelement(52, "div", 22)(53, "div", 23);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(54, "div", 12)(55, "div", 17)(56, "div", 24);
            i0.ɵɵelement(57, "div", 23);
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("ngModel", ctx.selectedProjectOption);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.ProjectViewOptions);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngModel", ctx.selectedViewOption);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.viewOptions);
            i0.ɵɵadvance(6);
            i0.ɵɵtextInterpolate(ctx.onHoldTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.completedTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.missTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.totalTasks);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("options", ctx.workload_chart_option);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("ngModel", ctx.selectedPriorityOptions);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.priorityOptions);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("options", ctx.priority_ChartOption);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("options", ctx.updownChartOption);
        } }, dependencies: [i6.NgForOf, i7.NgControlStatus, i7.NgModel, i8.MatFormField, i8.MatLabel, i9.MatSelect, i10.MatOption, i11.NgxEchartsDirective] }); }
}

import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { io } from "socket.io-client";
export class WrappedSocket {
    constructor(config) {
        this.config = config;
        this.subscribersCounter = {};
        this.eventObservables$ = {};
        this.emptyConfig = {
            options: {},
            url: '',
        };
        if (config === undefined) {
            config = this.emptyConfig;
        }
        const url = config.url;
        const options = config.options;
        this.ioSocket = io(url, options);
    }
    on(eventName, listener) {
        this.ioSocket.on(eventName, listener);
    }
    once(eventName, listener) {
        this.ioSocket.once(eventName, listener);
    }
    connect() {
        return this.ioSocket.connect();
    }
    disconnect() {
        return this.ioSocket.disconnect();
    }
    emit(_eventName, ...args) {
        return this.ioSocket.emit(_eventName, ...args);
    }
    removeListener(_eventName, listener) {
        return this.ioSocket.removeListener(_eventName, listener);
    }
    hasListener(_eventName) {
        return this.ioSocket.hasListeners(_eventName);
    }
    getListener(_eventName) {
        return this.ioSocket.listeners(_eventName);
    }
    off(_eventName, listener) {
        return this.ioSocket.off(_eventName, listener);
    }
    removeAllListeners(_eventName) {
        return this.ioSocket.removeAllListeners(_eventName);
    }
    fromEvent(eventName) {
        if (!this.subscribersCounter[eventName]) {
            this.subscribersCounter[eventName] = 0;
        }
        this.subscribersCounter[eventName]++;
        if (!this.eventObservables$[eventName]) {
            this.eventObservables$[eventName] = new Observable((observer) => {
                const listener = (data) => {
                    observer.next(data);
                };
                this.ioSocket.on(eventName, listener);
                return () => {
                    this.subscribersCounter[eventName]--;
                    if (this.subscribersCounter[eventName] === 0) {
                        this.ioSocket.removeListener(eventName, listener);
                        delete this.eventObservables$[eventName];
                    }
                };
            }).pipe(share());
        }
        return this.eventObservables$[eventName];
    }
    fromOneTimeEvent(eventName) {
        return new Promise(resolve => this.once(eventName, resolve));
    }
}

import { createReducer, on } from '@ngrx/store';
import { hideSnackbar, showSnackbar } from "./snackbar.actions";
export const snackbarFeatureKey = 'snackbar';
export const initialState = {
    show: false
};
export const _reducer = createReducer(initialState, on(showSnackbar, (state) => ({ ...state, show: true })), on(hideSnackbar, (state) => ({ ...state, show: false })));
export function reducer(state, action) {
    return _reducer(state, action);
}

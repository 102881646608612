/* eslint-disable @typescript-eslint/no-this-alias */
import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
import anime from 'animejs';
import _ from "lodash";
import Bar from './bar';
import Connector from './connector';
import ContextMenu from './context_menu';
import date_utils from './date_utils';
import Dialog from './dialog';
import Popup from './popup';
import randomColor from './randomColor';
import { properCase } from './string-utils';
import { $, createSVG } from './utils';
const VIEW_MODE = {
    DAY: 'Day',
    HALF_DAY: 'Half Day',
    MONTH: 'Month',
    QUARTER_DAY: 'Quarter Day',
    WEEK: 'Week',
    YEAR: 'Year'
};
export default class Gantt {
    constructor(wrapper, data, options) {
        this.dates = [];
        this.connectors = [];
        this.bars = [];
        this.inited = false;
        this.setup_wrapper(wrapper);
        this.setup_options(options);
        this.setup_data(data);
        // initialize with default view mode
        this.change_view_mode();
        this.bind_events();
        this.inited = true;
    }
    setup_wrapper(element) {
        let table_element, grid_element, wrapper_element, tooltip_element, context_menu_element;
        // CSS Selector is passed
        if (typeof element === 'string') {
            element = document.querySelector(element);
        }
        // get the SVGElement
        if (element instanceof HTMLElement) {
            wrapper_element = element;
            table_element = element.querySelector('.gantt-table');
            grid_element = element.querySelector('.gantt-grid');
        }
        else {
            throw new TypeError('Frappé Gantt only supports usage of a string CSS selector,' +
                " HTML DOM element or SVG DOM element for the 'element' parameter");
        }
        this.element = element;
        // table element
        if (!table_element) {
            // create it
            this.$table = document.createElement('div');
        }
        else {
            this.$table = table_element;
        }
        this.$table.classList.add('gantt-table');
        // grid element
        if (!grid_element) {
            // create it
            this.$grid = document.createElement('div');
            this.$grid.classList.add('gantt-grid');
        }
        else {
            this.$grid = grid_element;
            this.$grid.classList.add('gantt-grid');
        }
        if (!tooltip_element) {
            this.$tooltip = document.createElement('div');
        }
        else {
            this.$tooltip = tooltip_element;
        }
        this.$tooltip.setAttribute('id', 'ganttTooltip');
        this.$tooltip.setAttribute('role', 'tooltip');
        this.$tooltip.classList.add('gantt-tooltip');
        this.$tooltipContent = document.createElement('div');
        this.$tooltipContent.setAttribute('id', 'toolTipContent');
        this.$tooltipContent.classList.add('tooltip-content');
        this.$tooltipArrow = document.createElement('div');
        this.$tooltipArrow.setAttribute('id', 'toolTipArrow');
        this.$tooltipArrow.classList.add('tooltip-arrow');
        this.$tooltip.appendChild(this.$tooltipContent);
        this.$tooltip.appendChild(this.$tooltipArrow);
        if (!context_menu_element) {
            this.$contextmenu = document.createElement('div');
        }
        else {
            this.$contextmenu = context_menu_element;
        }
        this.$contextmenu.setAttribute('id', 'ganttContextMenu');
        this.$contextmenu.classList.add('gantt-context-menu');
        // wrapper element
        this.$container = document.createElement('div');
        this.$container.classList.add('gantt-container');
        // popup wrapper
        // this.popup_wrapper = document.createElement('div');
        // this.popup_wrapper.classList.add('popup-wrapper');
        this.$container.appendChild(this.$table);
        this.$container.appendChild(this.$grid);
        // this.$container.appendChild(this.$tooltip);
        // this.$container.appendChild(this.$contextmenu);
        element.appendChild(this.$tooltip);
        element.appendChild(this.$contextmenu);
        wrapper_element.appendChild(this.$container);
        this.$dialog = new Dialog();
    }
    setup_options(options) {
        const default_options = {
            arrow_curve: 5,
            bar_corner_radius: 3,
            bar_height: 36,
            column_width: 30,
            // columns: ['caret', 'wbs', 'label', 'priority', 'date', 'control'],
            columns: ['caret', 'label', 'date', 'control'],
            custom_popup_html: null,
            date_format: 'YYYY-MM-DD',
            header_height: 50,
            language: 'en',
            members: [],
            padding: 18,
            parentClass: '.mat-drawer-content',
            popup_trigger: 'click',
            priorities: [
                { color: '#3A9BA4', id: 1, label: 'low' },
                { color: '#d78532', id: 3, label: 'normal' },
                { color: '#BB265F', id: 5, label: 'high' }
            ],
            step: 24,
            view_mode: 'Day',
            view_modes: [...Object.values(VIEW_MODE)],
        };
        this.options = Object.assign({}, default_options, options);
    }
    setup_data(data) {
        const that = this;
        const tasks = data.tasks;
        const links = data.links;
        // prepare tasks
        this.source = tasks;
        this.tasks = tasks.map((task) => {
            // convert to Date objects
            task._start = date_utils.parse(task.start);
            task._end = date_utils.parse(task.end);
            // make task invalid if duration too large
            if (date_utils.diff(task._end, task._start, 'year') > 10) {
                task.end = null;
            }
            // cache index
            // task._index = i;
            // task._index = this.collapseFilted.findIndex(_task => _task.id === task.id);
            // invalid dates
            if (!task.start && !task.end) {
                const today = date_utils.today();
                task._start = today;
                task._end = date_utils.add(today, 2, 'day');
            }
            if (!task.start && task.end) {
                task._start = date_utils.add(task._end, -2, 'day');
            }
            if (task.start && !task.end) {
                task._end = date_utils.add(task._start, 2, 'day');
            }
            // if hours is not set, assume the last day is full day
            // e.g: 2018-09-09 becomes 2018-09-09 23:59:59
            const task_end_values = date_utils.get_date_values(task._end);
            if (task_end_values.slice(3).every((d) => d === 0)) {
                task._end = date_utils.add(task._end, 24, 'hour');
            }
            // invalid flag
            if (!task.start || !task.end) {
                task.invalid = true;
            }
            // links
            if (links) {
                const deps = [];
                const task_link = links.filter((link) => link.target === task.id);
                deps.push(...task_link);
                task.links = deps;
            }
            // uids
            if (!task.id) {
                task.id = (task);
            }
            // members
            if (!task.members || task.members.length === 0) {
                task.members = [];
            }
            else {
                task.members = this.options.members.filter((member) => _.includes(task.members, member.id));
            }
            // collapse
            if (!task.collapsed) {
                task.collapsed = false;
            }
            else {
                // collapse all child
                const child_tasks = this.getChildren(tasks, task.id, true);
                for (const child of child_tasks) {
                    child.collapsed = task.collapsed;
                }
            }
            if (!task.hidden) {
                task.hidden = false;
            }
            console.log(task);
            return task;
        });
        let index = 0;
        const projects = this.tasks.filter((task) => task.type === 'project');
        projects.forEach((project) => {
            project._index = index;
            const childs = that.getChildren(this.tasks, project.id, true);
            childs.forEach((task) => {
                index++;
                task._index = index;
            });
            index++;
        });
        if (this.options.columns.includes('wbs')) {
            this.WBSGenerator(projects);
        }
        this.links = links;
        this.taskLength = this.tasks.filter((task) => {
            return (task.type === 'project' ||
                (task.type !== 'project' && !task.collapsed));
        }).length;
        this.setup_dependencies();
        this.setup_connectors();
    }
    WBSGenerator(projects, last_wbs = null) {
        let wbs = 1;
        for (const project of projects) {
            const childs = this.getChildren(this.tasks, project.id, false);
            if (last_wbs === null) {
                if (childs.length > 0)
                    this.WBSGenerator(childs, wbs);
                project.WBS = wbs.toString();
            }
            else {
                if (childs.length > 0)
                    this.WBSGenerator(childs, last_wbs + '.' + wbs.toString());
                project.WBS = last_wbs + '.' + wbs.toString();
            }
            wbs++;
        }
    }
    setup_dependencies() {
        this.dependency_map = {};
        for (const link of this.links) {
            this.dependency_map[link.source] = this.dependency_map[link] || [];
            this.dependency_map[link.source].push(link.target);
        }
    }
    setup_connectors() {
        this.connector_map = {};
        for (const t of this.tasks) {
            for (const l of this.links) {
                this.connector_map[l] = this.connector_map[l] || [];
                this.connector_map[l].push(t.id);
            }
        }
    }
    refresh(tasks) {
        this.setup_data(tasks);
        this.change_view_mode();
        this.bind_container_events();
        this.bind_connector_events();
    }
    change_view_mode(mode = this.options.view_mode) {
        this.update_view_scale(mode);
        this.setup_dates();
        this.render();
        // fire viewmode_change event
        this.view_change_event = new CustomEvent('view-change', { detail: { mode } });
        this.element.dispatchEvent(this.view_change_event);
        // this.trigger_event('view_change', [mode]);
    }
    update_view_scale(view_mode) {
        this.options.view_mode = view_mode;
        if (view_mode === VIEW_MODE.DAY) {
            this.options.step = 24;
            this.options.column_width = 38;
        }
        else if (view_mode === VIEW_MODE.HALF_DAY) {
            this.options.step = 24 / 2;
            this.options.column_width = 38;
        }
        else if (view_mode === VIEW_MODE.QUARTER_DAY) {
            this.options.step = 24 / 4;
            this.options.column_width = 38;
        }
        else if (view_mode === VIEW_MODE.WEEK) {
            this.options.step = 24 * 7;
            this.options.column_width = 140;
        }
        else if (view_mode === VIEW_MODE.MONTH) {
            this.options.step = 24 * 30;
            this.options.column_width = 120;
        }
        else if (view_mode === VIEW_MODE.YEAR) {
            this.options.step = 24 * 365;
            this.options.column_width = 120;
        }
    }
    setup_dates() {
        this.setup_gantt_dates();
        this.setup_date_values();
    }
    setup_gantt_dates() {
        this.gantt_start = this.gantt_end = null;
        if (this.tasks.length > 0) {
            for (const task of this.tasks) {
                // set global start and end date
                if (!this.gantt_start || task._start < this.gantt_start) {
                    this.gantt_start = task._start;
                }
                if (!this.gantt_end || task._end > this.gantt_end) {
                    this.gantt_end = task._end;
                }
            }
        }
        else {
            const currentYear = new Date().getFullYear();
            this.gantt_start = new Date(currentYear, 0, 1);
            this.gantt_end = new Date(currentYear, 11, 31);
        }
        this.gantt_start = date_utils.start_of(this.gantt_start, 'day');
        this.gantt_end = date_utils.start_of(this.gantt_end, 'day');
        // add date padding on both sides
        if (this.view_is([VIEW_MODE.QUARTER_DAY, VIEW_MODE.HALF_DAY])) {
            this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
            this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
        }
        else if (this.view_is(VIEW_MODE.MONTH)) {
            this.gantt_start = date_utils.start_of(this.gantt_start, 'year');
            this.gantt_end = date_utils.add(this.gantt_end, 1, 'year');
        }
        else if (this.view_is(VIEW_MODE.YEAR)) {
            this.gantt_start = date_utils.add(this.gantt_start, -2, 'year');
            this.gantt_end = date_utils.add(this.gantt_end, 2, 'year');
        }
        else {
            this.gantt_start = date_utils.add(this.gantt_start, -1, 'month');
            this.gantt_end = date_utils.add(this.gantt_end, 1, 'month');
        }
    }
    setup_date_values() {
        this.dates = [];
        let cur_date = null;
        while (cur_date === null || cur_date < this.gantt_end) {
            if (!cur_date) {
                cur_date = date_utils.clone(this.gantt_start);
            }
            else {
                if (this.view_is(VIEW_MODE.YEAR)) {
                    cur_date = date_utils.add(cur_date, 1, 'year');
                }
                else if (this.view_is(VIEW_MODE.MONTH)) {
                    cur_date = date_utils.add(cur_date, 1, 'month');
                }
                else {
                    cur_date = date_utils.add(cur_date, this.options.step, 'hour');
                }
            }
            this.dates.push(cur_date);
        }
    }
    bind_events() {
        if (!this.inited) {
            this.bind_grid_click();
            this.bind_bar_events();
            this.bind_connector_events();
            this.bind_tooltip_event();
            new ContextMenu('.connector-stroke', [
                {
                    fn: (e) => {
                        const from = e.dataset.from;
                        const to = e.dataset.to;
                        const from_bar = this.get_bar(from);
                        const to_bar = this.get_bar(to);
                        from_bar.connectors = from_bar.connectors.filter((c) => c.id !== from + '-' + to);
                        to_bar.connectors = to_bar.connectors.filter((c) => c.id !== from + '-' + to);
                        const connector = this.get_connector(from + '-' + to);
                        if (connector) {
                            connector.group.remove();
                            this.connectors = this.connectors.filter(c => c.id !== from + '-' + to);
                            this.remove_link_event = new CustomEvent('remove-link', {
                                detail: {
                                    source: from,
                                    target: to,
                                    type: connector.type
                                }
                            });
                            this.element.dispatchEvent(this.remove_link_event);
                            // this.trigger_event('remove_link', [{
                            //   from_task_id: from,
                            //   to_task_id: to,
                            //   type: connector.type
                            // }]);
                        }
                    },
                    name: 'Remove connector'
                }
            ], { parent: this.options.parentClass });
            new ContextMenu('.bar-wrapper', [
                {
                    fn: (e) => {
                        const handler = e;
                        const task_id = handler.dataset.id;
                        const task = this.get_task(task_id);
                        this.edit_task_event = new CustomEvent('edit-task', { detail: { card: task } });
                        this.element.dispatchEvent(this.edit_task_event);
                        // this.trigger_event('edit_task', [{task}]);
                    },
                    name: 'Edit task'
                },
                {
                    fn: (e) => {
                        const dialog = this.$dialog.confirm("you're sure?", () => {
                            const task_id = e.dataset.id;
                            const task = this.get_task(task_id);
                            this.remove_task(task);
                            dialog.destroy();
                            this.remove_task_event = new CustomEvent('remove-task', { detail: { card: task } });
                            this.element.dispatchEvent(this.remove_task_event);
                            // this.trigger_event('remove_task', [{task}]);
                        }, {});
                        dialog.show();
                    },
                    name: 'Remove task'
                }
            ], { parent: this.options.parentClass });
        }
    }
    onAddLink(e) {
        console.log(e);
    }
    render() {
        this.clear();
        this.setup_container();
        this.setup_table_header_layers();
        this.setup_table_content_layers();
        this.setup_grid_header_layers();
        this.setup_grid_content_layers();
        this.make_symbol_content();
        this.make_defs_content();
        this.make_grid_header();
        this.make_grid_content();
        if (!this.inited) {
            this.bind_container_events();
            this.bind_table_content_events();
        }
    }
    setup_container() {
        /**
         * TABLE
         */
        this.$resizerRight = document.createElement('div');
        this.$resizerRight.classList.add('resizer-right');
        this.$tableHeader = document.createElement('div');
        this.$tableHeader.classList.add('table-header');
        this.$tableBody = document.createElement('div');
        this.$tableBody.classList.add('table-body');
        // this.$tableBody.innerText = "abc";
        // let $tableResize = document.createElement('div');
        // $tableResize.classList.add('resize');
        this.$tableContent = document.createElement('div');
        this.$tableContent.classList.add('table-content');
        // this.$tableBody.appendChild($tableResize);
        this.$tableBody.appendChild(this.$tableContent);
        this.$table.appendChild(this.$tableHeader);
        this.$table.appendChild(this.$tableBody);
        this.$table.appendChild(this.$resizerRight);
        /**
         * GRID
         */
        this.$gridHeader = document.createElement('div');
        this.$gridHeader.classList.add('grid-header');
        this.$gridBody = document.createElement('div');
        this.$gridBody.classList.add('grid-body');
        this.$ganttHeaderSvg = createSVG('svg', {
            append_to: this.$gridHeader,
            class: 'gantt-header'
        });
        this.$ganttContentSvg = createSVG('svg', {
            append_to: this.$gridBody,
            class: 'gantt-content',
            fill: 'none',
            version: '1.1',
            'xml:space': 'preserve',
            xmlns: 'http://www.w3.org/2000/svg',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink'
        });
        this.popup_wrapper = document.createElement('div');
        this.popup_wrapper.classList.add('popup-wrapper');
        this.$gridBody.appendChild(this.popup_wrapper);
        this.$grid.appendChild(this.$gridHeader);
        this.$grid.appendChild(this.$gridBody);
    }
    bind_container_events() {
        this.scrollLock = {
            isLeftScroll: false,
            isRightScroll: false
        };
        const that = this;
        /**
         * Resize table
         */
        $.on(this.$resizerRight, 'mousedown', (e) => {
            const element = that.$table;
            const startX = e.clientX;
            // let startY = e.clientY;
            const startWidth = element.offsetWidth;
            // let startHeight = element.offsetHeight;
            document.addEventListener('mousemove', doDrag, false);
            document.addEventListener('mouseup', stopDrag, false);
            function doDrag(e) {
                that.$table.style.width =
                    startWidth + e.clientX - startX + 'px';
                // that.$table.style.height = startHeight + e.clientY - startY + "px";
                that.set_scroll_position();
            }
            function stopDrag() {
                document.removeEventListener('mousemove', doDrag, false);
                document.removeEventListener('mouseup', stopDrag, false);
            }
        });
        /**
         * Scroll grid
         */
        $.on(this.$grid, 'scroll', '.gantt-grid', (e) => {
            if (!that.scrollLock.isLeftScroll) {
                that.scrollLock = {
                    ...that.scrollLock,
                    isRightScroll: true
                };
                if (!that.is_progress_change) {
                    that.$table.scroll({
                        top: e.target.scrollTop
                    });
                }
            }
            that.scrollLock = {
                ...that.scrollLock,
                isLeftScroll: false
            };
        });
        /**
         * Scroll table
         */
        $.on(this.$table, 'scroll', '.gantt-table', (e) => {
            if (!that.scrollLock.isRightScroll) {
                that.scrollLock = {
                    ...that.scrollLock,
                    isLeftScroll: true
                };
                if (!that.is_progress_change) {
                    that.$grid.scroll({
                        top: e.target.scrollTop
                    });
                }
            }
            that.scrollLock = {
                ...that.scrollLock,
                isRightScroll: false
            };
        });
    }
    setup_grid_header_layers() {
        this.gridHeaderLayers = {};
        const layers = ['grid', 'date'];
        // make group layers
        for (const layer of layers) {
            this.gridHeaderLayers[layer] = createSVG('g', {
                append_to: this.$ganttHeaderSvg,
                class: layer
            });
        }
    }
    setup_grid_content_layers() {
        this.gridContentLayers = {};
        const layers = [
            'symbols',
            'grid',
            'arrow',
            'progress',
            'bar',
            'mousePoint'
        ];
        // make group layers
        for (const layer of layers) {
            this.gridContentLayers[layer] = createSVG('g', {
                append_to: this.$ganttContentSvg,
                class: layer
            });
        }
        this.$gridDefs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        this.$ganttContentSvg.appendChild(this.$gridDefs);
    }
    make_symbol_content() {
        const folder_tree_symbol = this.createSymbol('folder-tree', 576, 512);
        createSVG('path', {
            append_to: folder_tree_symbol,
            d: 'M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32v96V384c0 35.3 28.7 64 64 64H256V384H64V160H256V96H64V32zM288 192c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4L409.4 9.4c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V192zm0 288c0 17.7 14.3 32 32 32H544c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H445.3c-8.5 0-16.6-3.4-22.6-9.4l-13.3-13.3c-6-6-14.1-9.4-22.6-9.4H320c-17.7 0-32 14.3-32 32V480z'
        });
        const folder_open_symbol = this.createSymbol('folder-open', 576, 512);
        createSVG('path', {
            append_to: folder_open_symbol,
            d: 'M88.7 223.8L0 375.8V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224H544c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z'
        });
        const folder_symbol = this.createSymbol('folder', 512, 512);
        createSVG('path', {
            append_to: folder_symbol,
            d: 'M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z'
        });
        const square_list_symbol = this.createSymbol('square-list', 448, 512);
        createSVG('path', {
            append_to: square_list_symbol,
            d: 'M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM96 288c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm32-128c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM96 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H352c13.3 0 24-10.7 24-24s-10.7-24-24-24H192z'
        });
    }
    make_defs_content() {
        /** this.$gridDefs **/
    }
    createSymbol(id, w, h) {
        return createSVG('symbol', {
            append_to: this.$gridDefs,
            id,
            viewBox: `0 0 ${w} ${h}`
        });
    }
    make_grid_header() {
        this.make_grid_header_background();
        this.make_grid_header_columns();
        this.make_grid_header_dates();
    }
    make_grid_content() {
        this.make_grid_content_background();
        this.make_grid_content_rows();
        this.make_grid_content_columns();
        this.make_grid_content_highlights();
        this.make_grid_content_bars();
        this.make_grid_content_connectors();
        this.make_mouse_point();
        this.map_connectors_on_bars();
        this.set_width();
        this.set_scroll_position();
    }
    make_grid_content_background() {
        const grid_width = this.dates.length * this.options.column_width;
        const grid_height = (this.options.bar_height + this.options.padding) * this.taskLength;
        createSVG('rect', {
            append_to: this.gridContentLayers.grid,
            class: 'grid-background',
            height: grid_height,
            width: grid_width,
            x: 0,
            y: 0
        });
        $.attr(this.$ganttContentSvg, {
            height: grid_height,
            width: grid_width
        });
    }
    make_grid_content_rows() {
        this.rows_layer = createSVG('g', {
            append_to: this.gridContentLayers.grid
        });
        this.lines_layer = createSVG('g', {
            append_to: this.gridContentLayers.grid
        });
        const row_width = this.dates.length * this.options.column_width;
        const row_height = this.options.bar_height + this.options.padding;
        let row_y = 0;
        for (const task of this.tasks) {
            if (this.task_is_show(task)) {
                createSVG('rect', {
                    append_to: this.rows_layer,
                    class: 'grid-row',
                    height: row_height,
                    width: row_width,
                    x: 0,
                    y: row_y
                });
                createSVG('line', {
                    append_to: this.lines_layer,
                    class: 'row-line',
                    x1: 0,
                    x2: row_width,
                    y1: row_y + row_height,
                    y2: row_y + row_height
                });
                row_y += this.options.bar_height + this.options.padding;
            }
        }
    }
    add_grid_row() {
        const grid_height = (this.options.bar_height + this.options.padding) * this.taskLength;
        const row_width = this.dates.length * this.options.column_width;
        const row_height = this.options.bar_height + this.options.padding;
        const row_y = (this.tasks.length - 1) *
            (this.options.bar_height + this.options.padding);
        $.attr(this.$ganttContentSvg, {
            height: grid_height
        });
        createSVG('rect', {
            append_to: this.rows_layer,
            class: 'grid-row',
            height: row_height,
            width: row_width,
            x: 0,
            y: row_y
        });
        createSVG('line', {
            append_to: this.lines_layer,
            class: 'row-line',
            x1: 0,
            x2: row_width,
            y1: row_y + row_height,
            y2: row_y + row_height
        });
    }
    make_grid_content_columns() {
        this.ticks_layer = createSVG('g', {
            append_to: this.gridContentLayers.grid
        });
        this.columns_layer = createSVG('g', {
            append_to: this.gridContentLayers.grid
        });
        let tick_x = 0;
        const tick_y = 0;
        const tick_height = (this.options.bar_height + this.options.padding) * this.taskLength;
        let column_x = 0;
        const column_y = 0;
        let column_width = this.options.column_width;
        const column_height = (this.options.bar_height + this.options.padding) * this.taskLength;
        for (const date of this.dates) {
            let tick_class = 'tick';
            // thick tick for monday
            if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
                tick_class += ' thick';
            }
            // thick tick for first week
            if (this.view_is(VIEW_MODE.WEEK) && date.getDate() >= 1 && date.getDate() < 8) {
                tick_class += ' thick';
            }
            // thick ticks for quarters
            if (this.view_is(VIEW_MODE.MONTH) && (date.getMonth() + 1) % 3 === 0) {
                tick_class += ' thick';
            }
            createSVG('path', {
                append_to: this.ticks_layer,
                class: tick_class,
                d: `M ${tick_x} ${tick_y} v ${tick_height}`
            });
            if (this.view_is(VIEW_MODE.MONTH)) {
                column_width = (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
            }
            let column_class = 'grid-column';
            if (this.view_is(VIEW_MODE.DAY)) {
                const isWeekend = date.getDay() % 6 === 0;
                if (isWeekend) {
                    column_class += ' weekend';
                }
            }
            createSVG('rect', {
                append_to: this.columns_layer,
                class: column_class,
                height: column_height,
                width: column_width,
                x: column_x,
                y: column_y
            });
            if (this.view_is(VIEW_MODE.MONTH)) {
                tick_x += (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
                column_x += (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
            }
            else {
                tick_x += this.options.column_width;
                column_x += this.options.column_width;
            }
        }
    }
    scale_grid_columns() {
        const column_height = (this.options.bar_height + this.options.padding) *
            this.tasks.length;
        const tickers = this.ticks_layer.querySelectorAll('path');
        for (const ticker of tickers) {
            let path = ticker.getAttribute('d');
            path = path.replace(/^(M\s\d*\s\d*\sv\s)(\d*)$/, '$1' + column_height);
            $.attr(ticker, {
                d: path
            });
        }
        const columns = this.columns_layer.querySelectorAll('rect');
        for (const column of columns) {
            $.attr(column, {
                height: column_height
            });
        }
    }
    make_grid_content_highlights() {
        // highlight today's date
        if (this.view_is(VIEW_MODE.DAY)) {
            const x = (date_utils.diff(date_utils.today(), this.gantt_start, 'hour') /
                this.options.step) *
                this.options.column_width;
            const y = 0;
            const width = this.options.column_width;
            const height = (this.options.bar_height + this.options.padding) *
                this.taskLength;
            createSVG('rect', {
                append_to: this.gridContentLayers.grid,
                class: 'today-highlight',
                height,
                width,
                x,
                y
            });
        }
    }
    make_grid_content_bars() {
        this.bars = this.tasks
            .map((task) => {
            const bar = new Bar(this, task);
            this.gridContentLayers.bar.appendChild(bar.group);
            return bar;
        })
            .filter(Boolean);
    }
    make_grid_content_connectors() {
        this.connectors = this.links
            .map((link) => {
            let from_task = this.get_task(link.source);
            let to_task = this.get_task(link.target);
            if (from_task.collapsed) {
                const from_parent = this.getParentCollapsed(this.tasks, from_task.id, true);
                from_task = from_parent[from_parent.length - 1];
            }
            if (to_task.collapsed) {
                const to_parent = this.getParentCollapsed(this.tasks, to_task.id, true);
                to_task = to_parent[to_parent.length - 1];
            }
            // const from_bar = this.get_bar(link.source);
            // const to_bar = this.get_bar(link.target);
            const from_bar = this.get_bar(from_task.id);
            const to_bar = this.get_bar(to_task.id);
            const from_connector_left = from_bar.$connector_left;
            const from_connector_right = from_bar.$connector_right;
            const to_connector_left = to_bar.$connector_left;
            const to_connector_right = to_bar.$connector_right;
            if (!from_task || !to_task)
                return;
            // const connector_type = link.type == 0 ? 'finish_to_start' : link.type == 1 ? 'start_to_start' : link.type == 2 ? 'finish_to_finish' : 'start_to_finish';
            let from_connector, to_connector;
            if (parseInt(link.type) === 0) {
                from_connector = from_connector_right;
                to_connector = to_connector_left;
            }
            else if (parseInt(link.type) === 1) {
                from_connector = from_connector_left;
                to_connector = to_connector_left;
            }
            else if (parseInt(link.type) === 2) {
                from_connector = from_connector_right;
                to_connector = to_connector_right;
            }
            else if (parseInt(link.type) === 3) {
                from_connector = from_connector_left;
                to_connector = to_connector_right;
            }
            const connector = new Connector(this, from_connector, // from_task
            to_connector // to_task
            );
            $.attr(connector.element, {
                stroke: from_task.color
            });
            this.gridContentLayers.arrow.appendChild(connector.group);
            return connector;
        })
            .filter(Boolean); // filter falsy values
    }
    make_mouse_point() {
        this.$mousePoint = createSVG('circle', {
            append_to: this.gridContentLayers.mousePoint,
            class: 'mouse-point connector left',
            cx: 0,
            cy: 0,
            r: 5
        });
    }
    map_connectors_on_bars() {
        for (const bar of this.bars) {
            bar.connectors = this.connectors.filter((connector) => {
                return (connector.from_task_id === bar.task.id ||
                    connector.to_task_id === bar.task.id);
            });
        }
    }
    set_width() {
        const cur_width = this.$grid.getBoundingClientRect().width;
        const grid_row = this.$grid.querySelector('.grid .grid-row');
        if (grid_row) {
            const actual_width = grid_row.getAttribute('width');
            if (cur_width < actual_width) {
                this.$grid.setAttribute('width', actual_width);
            }
        }
    }
    /**
     * Move to first task position
     */
    set_scroll_position() {
        const parent_element = this.$grid;
        if (!parent_element)
            return;
        const hours_before_first_task = date_utils.diff(this.get_oldest_starting_date(), this.gantt_start, 'hour');
        parent_element.scrollLeft =
            (hours_before_first_task / this.options.step) *
                this.options.column_width -
                this.options.column_width;
    }
    /**
     * HEADER
     */
    make_grid_header_background() {
        const grid_header_width = this.dates.length * this.options.column_width;
        const grid_header_height = this.options.header_height + this.options.padding / 2;
        createSVG('rect', {
            append_to: this.gridHeaderLayers.grid,
            class: 'grid-header-background',
            height: grid_header_height,
            width: grid_header_width,
            x: 0,
            y: 0
        });
        createSVG('line', {
            append_to: this.gridHeaderLayers.grid,
            class: 'header-middle-border',
            x1: 0,
            x2: grid_header_width,
            y1: (this.options.header_height + this.options.padding) / 2,
            y2: (this.options.header_height + this.options.padding) / 2
        });
        createSVG('line', {
            append_to: this.gridHeaderLayers.grid,
            class: 'header-bottom-border',
            x1: 0,
            x2: grid_header_width,
            y1: this.options.header_height + this.options.padding / 2,
            y2: this.options.header_height + this.options.padding / 2
        });
        $.attr(this.$ganttHeaderSvg, {
            height: grid_header_height,
            width: grid_header_width
        });
    }
    make_grid_header_columns() {
        const ticks_layer = createSVG('g', {
            append_to: this.gridHeaderLayers.grid
        });
        const columns_layer = createSVG('g', {
            append_to: this.gridHeaderLayers.grid
        });
        let tick_x = 0;
        const tick_y = (this.options.header_height + this.options.padding) / 2;
        const tick_height = (this.options.header_height + this.options.padding) / 2;
        let column_x = 0;
        const column_y = (this.options.header_height + this.options.padding) / 2;
        const column_width = this.options.column_width;
        const column_height = (this.options.header_height + this.options.padding) * this.tasks.length + this.options.padding / 2;
        for (const date of this.dates) {
            let tick_class = 'tick';
            // thick tick for monday
            if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
                tick_class += ' thick';
            }
            // thick tick for first week
            if (this.view_is(VIEW_MODE.WEEK) &&
                date.getDate() >= 1 &&
                date.getDate() < 8) {
                tick_class += ' thick';
            }
            // thick ticks for quarters
            if (this.view_is(VIEW_MODE.MONTH) &&
                (date.getMonth() + 1) % 3 === 0) {
                tick_class += ' thick';
            }
            createSVG('path', {
                append_to: ticks_layer,
                class: tick_class,
                d: `M ${tick_x} ${tick_y} v ${tick_height}`
            });
            let gridHeaderColumnClass = 'grid-header-column';
            if (this.view_is(VIEW_MODE.DAY)) {
                const isWeekend = date.getDay() % 6 === 0;
                if (isWeekend)
                    gridHeaderColumnClass += ' weekend';
            }
            createSVG('rect', {
                append_to: columns_layer,
                class: gridHeaderColumnClass,
                height: column_height,
                width: column_width,
                x: column_x,
                y: column_y
            });
            if (this.view_is(VIEW_MODE.MONTH)) {
                tick_x += (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
            }
            else {
                tick_x += this.options.column_width;
                column_x += this.options.column_width;
            }
        }
    }
    make_grid_header_dates() {
        for (const date of this.get_dates_to_draw()) {
            createSVG('text', {
                append_to: this.gridHeaderLayers.date,
                class: 'lower-text',
                innerHTML: date.lower_text,
                x: date.lower_x,
                y: date.lower_y
            });
            if (date.upper_text) {
                const $upper_text = createSVG('text', {
                    append_to: this.gridHeaderLayers.date,
                    class: 'upper-text',
                    innerHTML: date.upper_text,
                    x: date.upper_x,
                    y: date.upper_y
                });
                // remove out-of-bound dates
                if ($upper_text.getBBox().x2 >
                    this.gridHeaderLayers.grid.getBBox().width) {
                    $upper_text.remove();
                }
            }
        }
    }
    get_dates_to_draw() {
        let last_date = null;
        let last_position_x = 0;
        return this.dates.map((date, i) => {
            const d = this.get_date_info(date, last_date, last_position_x, i);
            last_date = date;
            last_position_x += (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
            return d;
        });
    }
    get_date_info(date, last_date, last_position_x, i) {
        if (!last_date) {
            last_date = date_utils.add(date, 1, 'year');
        }
        const date_text = {
            Day_lower: date.getDate() !== last_date.getDate()
                ? date_utils.format(date, 'D', this.options.language)
                : '',
            Day_upper: date.getMonth() !== last_date.getMonth()
                ? date_utils.format(date, 'MMMM', this.options.language)
                : '',
            'Half Day_lower': date_utils.format(date, 'HH', this.options.language),
            'Half Day_upper': date.getDate() !== last_date.getDate()
                ? date.getMonth() !== last_date.getMonth()
                    ? date_utils.format(date, 'D MMM', this.options.language)
                    : date_utils.format(date, 'D', this.options.language)
                : '',
            Month_lower: date_utils.format(date, 'MMMM', this.options.language),
            Month_upper: date.getFullYear() !== last_date.getFullYear()
                ? date_utils.format(date, 'YYYY', this.options.language)
                : '',
            'Quarter Day_lower': date_utils.format(date, 'HH', this.options.language),
            'Quarter Day_upper': date.getDate() !== last_date.getDate()
                ? date_utils.format(date, 'D MMM', this.options.language)
                : '',
            Week_lower: date.getMonth() !== last_date.getMonth()
                ? date_utils.format(date, 'D MMM', this.options.language)
                : date_utils.format(date, 'D', this.options.language),
            Week_upper: date.getMonth() !== last_date.getMonth()
                ? date_utils.format(date, 'MMMM', this.options.language)
                : '',
            Year_lower: date_utils.format(date, 'YYYY', this.options.language),
            Year_upper: date.getFullYear() !== last_date.getFullYear()
                ? date_utils.format(date, 'YYYY', this.options.language)
                : ''
        };
        const base_pos = {
            lower_y: this.options.header_height,
            upper_y: this.options.header_height - 25,
            x: i * this.options.column_width
        };
        if (this.view_is(VIEW_MODE.MONTH)) {
            base_pos.x = last_position_x;
        }
        const x_pos = {
            Day_lower: this.options.column_width / 2,
            Day_upper: (this.options.column_width * 30) / 2,
            'Half Day_lower': (this.options.column_width * 2) / 2,
            'Half Day_upper': 0,
            Month_lower: this.options.column_width / 2,
            Month_upper: (this.options.column_width * 12) / 2,
            'Quarter Day_lower': (this.options.column_width * 4) / 2,
            'Quarter Day_upper': 0,
            Week_lower: 0,
            Week_upper: (this.options.column_width * 4) / 2,
            Year_lower: this.options.column_width / 2,
            Year_upper: (this.options.column_width * 30) / 2
        };
        if (this.view_is(VIEW_MODE.MONTH)) {
            return {
                lower_text: date_text[`${this.options.view_mode}_lower`],
                lower_x: base_pos.x + ((date_utils.get_days_in_month(date) * this.options.column_width) / 30) / 2,
                lower_y: base_pos.lower_y,
                upper_text: date_text[`${this.options.view_mode}_upper`],
                upper_x: base_pos.x + x_pos[`${this.options.view_mode}_upper`],
                upper_y: base_pos.upper_y
            };
        }
        else {
            return {
                lower_text: date_text[`${this.options.view_mode}_lower`],
                lower_x: base_pos.x + x_pos[`${this.options.view_mode}_lower`],
                lower_y: base_pos.lower_y,
                upper_text: date_text[`${this.options.view_mode}_upper`],
                upper_x: base_pos.x + x_pos[`${this.options.view_mode}_upper`],
                upper_y: base_pos.upper_y
            };
        }
    }
    /**
     * END HEADER
     */
    /** TABLE **/
    setup_table_header_layers() {
        const columns = this.options.columns;
        for (const column of columns) {
            const _column = document.createElement('div');
            _column.classList.add('header-column', column);
            if (column !== 'caret' && column !== 'control') {
                _column.innerText = properCase(column);
            }
            if (column === 'caret') {
                _column.innerHTML = '<i class="fa-solid fa-folder-tree"></i>';
            }
            if (column === 'control') {
                _column.innerHTML =
                    '<button class="gantt-btn gantt-btn-info add-project" data-control="tooltip" data-tooltip="Add project"><i class="fa-regular fa-folder-plus"></i></button>';
            }
            this.$tableHeader.appendChild(_column);
        }
    }
    setup_table_content_layers() {
        const tree_view = document.createElement('ul');
        tree_view.classList.add('gantt-tree-view');
        const projects = this.tasks.filter((task) => task.type === 'project');
        for (const project of projects) {
            const menu = this.genetate_menu_items(project);
            tree_view.appendChild(menu);
        }
        this.$tableContent.appendChild(tree_view);
    }
    genetate_menu_items(task) {
        const childs = this.getChildren(this.tasks, task.id, false);
        const item = document.createElement('li');
        if (childs && childs.length > 0) {
            item.classList.add('parent', 'tree-view-item');
            const row_title = document.createElement('div');
            row_title.classList.add('row-title');
            row_title.setAttribute('data-task', task.id);
            let _caret_content;
            if (this.options.columns.includes('caret')) {
                _caret_content = document.createElement('div');
                _caret_content.classList.add('caret');
                if (task.collapsed) {
                    _caret_content.innerHTML = '<i class="fa-solid fa-folder tree-view-collapse" data-task=' + task.id + '></i>';
                }
                else {
                    _caret_content.innerHTML = '<i class="fa-solid fa-folder-open tree-view-collapse" data-task="' + task.id + '"></i>';
                }
            }
            let _wbs_content;
            if (this.options.columns.includes('wbs')) {
                _wbs_content = document.createElement('div');
                _wbs_content.classList.add('wbs', 'fw-bold');
                _wbs_content.innerHTML = task.WBS;
            }
            let _label_content;
            if (this.options.columns.includes('label')) {
                _label_content = document.createElement('div');
                _label_content.classList.add('label', 'fw-bold');
                _label_content.innerHTML = task.label;
            }
            let _priority_content;
            if (this.options.columns.includes('priority')) {
                _priority_content = document.createElement('div');
                _priority_content.classList.add('priority', 'fw-bold');
                if (task.type !== 'project') {
                    const _priority = this.get_priority(task.priority);
                    _priority_content.innerHTML = '<span class="badge" style="background:' + _priority.color + '">' + _priority.label + '</span>';
                }
            }
            let _date_content;
            if (this.options.columns.includes('date')) {
                _date_content = document.createElement('div');
                _date_content.classList.add('date', 'text-bold');
                _date_content.innerHTML = date_utils.format(task._start, 'MM/DD') + ' - ' + date_utils.format(task._end, 'MM/DD');
            }
            let _control_content;
            if (this.options.columns.includes('control')) {
                _control_content = document.createElement('div');
                _control_content.classList.add('control');
                _control_content.setAttribute('data-control', 'tooltip');
                _control_content.setAttribute('data-tooltip', 'Add project');
                const _control_button_addtask = document.createElement('button');
                _control_button_addtask.classList.add(...['gantt-btn', 'gantt-btn-primary', 'add-task']);
                _control_button_addtask.setAttribute('data-parent', task.id);
                _control_button_addtask.setAttribute('data-control', 'tooltip');
                _control_button_addtask.setAttribute('data-tooltip', 'Add task');
                _control_button_addtask.innerHTML = '<i class="fa-solid fa-layer-plus"></i>';
                const _control_button_edittask = document.createElement('button');
                _control_button_edittask.classList.add(...['gantt-btn', 'gantt-btn-success', 'edit-task']);
                _control_button_edittask.setAttribute('data-id', task.id);
                _control_button_edittask.setAttribute('data-control', 'tooltip');
                _control_button_edittask.setAttribute('data-tooltip', 'Edit task');
                _control_button_edittask.innerHTML = '<i class="fa-regular fa-pen-to-square"></i>';
                const _control_button_removetask = document.createElement('button');
                _control_button_removetask.classList.add(...['gantt-btn', 'gantt-btn-danger', 'remove-task']);
                _control_button_removetask.setAttribute('data-task', task.id);
                _control_button_removetask.setAttribute('data-control', 'tooltip');
                _control_button_removetask.setAttribute('data-tooltip', 'Remove task');
                _control_button_removetask.innerHTML = '<i class="fa-solid fa-trash-list"></i>';
                _control_content.appendChild(_control_button_addtask);
                _control_content.appendChild(_control_button_edittask);
                _control_content.appendChild(_control_button_removetask);
            }
            if (_caret_content)
                row_title.appendChild(_caret_content);
            if (_wbs_content)
                row_title.appendChild(_wbs_content);
            if (_label_content)
                row_title.appendChild(_label_content);
            if (_priority_content)
                row_title.appendChild(_priority_content);
            if (_date_content)
                row_title.appendChild(_date_content);
            if (_control_content)
                row_title.appendChild(_control_content);
            item.appendChild(row_title);
            const _nest_menu = document.createElement('ul');
            _nest_menu.classList.add('nested', 'active');
            for (const _task of childs) {
                const _item = this.genetate_menu_items(_task);
                _nest_menu.appendChild(_item);
            }
            item.appendChild(_nest_menu);
        }
        else {
            item.classList.add('tree-view-item');
            const __row_title = document.createElement('div');
            __row_title.classList.add('row-title');
            __row_title.setAttribute('data-task', task.id);
            let __caret_content;
            if (this.options.columns.includes('caret')) {
                __caret_content = document.createElement('div');
                __caret_content.classList.add('caret');
                __caret_content.innerHTML = '<i class="fa-regular fa-square-list"></i>';
            }
            let __wbs_content;
            if (this.options.columns.includes('wbs')) {
                __wbs_content = document.createElement('div');
                __wbs_content.classList.add('wbs');
                __wbs_content.innerHTML = task.WBS;
            }
            let __label_content;
            if (this.options.columns.includes('label')) {
                __label_content = document.createElement('div');
                __label_content.classList.add('label');
                __label_content.innerHTML = task.label;
            }
            let __priority_content;
            if (this.options.columns.includes('priority')) {
                __priority_content = document.createElement('div');
                __priority_content.classList.add('priority');
                if (task.type !== 'project') {
                    const __priority = this.get_priority(task.priority);
                    __priority_content.innerHTML = '<span class="badge" style="background:' + __priority.color + '">' + __priority.label + '</span>';
                }
            }
            let __date_content;
            if (this.options.columns.includes('date')) {
                __date_content = document.createElement('div');
                __date_content.classList.add('date');
                __date_content.innerHTML = date_utils.format(task._start, 'MM/DD') + ' - ' + date_utils.format(task._end, 'MM/DD');
            }
            let __control_content;
            if (this.options.columns.includes('control')) {
                __control_content = document.createElement('div');
                __control_content.classList.add('control');
                // const __control_button_addtask = document.createElement('button');
                // __control_button_addtask.classList.add(
                //   ...['gantt-btn', 'gantt-btn-primary', 'add-task']
                // );
                // __control_button_addtask.setAttribute('data-parent', task.id);
                // __control_button_addtask.setAttribute('data-control', 'tooltip');
                // __control_button_addtask.setAttribute('data-tooltip', 'Add task');
                // __control_button_addtask.innerHTML = '<i class="fa-solid fa-layer-plus"></i>';
                const __control_button_edittask = document.createElement('button');
                __control_button_edittask.classList.add(...['gantt-btn', 'gantt-btn-success', 'edit-task']);
                __control_button_edittask.setAttribute('data-id', task.id);
                __control_button_edittask.setAttribute('data-control', 'tooltip');
                __control_button_edittask.setAttribute('data-tooltip', 'Edit task');
                __control_button_edittask.innerHTML = '<i class="fa-regular fa-pen-to-square"></i>';
                const __control_button_removetask = document.createElement('button');
                __control_button_removetask.classList.add(...['gantt-btn', 'gantt-btn-danger', 'remove-task']);
                __control_button_removetask.setAttribute('data-task', task.id);
                __control_button_removetask.setAttribute('data-control', 'tooltip');
                __control_button_removetask.setAttribute('data-tooltip', 'Remove task');
                __control_button_removetask.innerHTML = '<i class="fa-solid fa-trash-list"></i>';
                // __control_content.appendChild(__control_button_addtask);
                __control_content.appendChild(__control_button_edittask);
                __control_content.appendChild(__control_button_removetask);
            }
            if (__caret_content)
                __row_title.appendChild(__caret_content);
            if (__wbs_content)
                __row_title.appendChild(__wbs_content);
            if (__label_content)
                __row_title.appendChild(__label_content);
            if (__priority_content)
                __row_title.appendChild(__priority_content);
            if (__date_content)
                __row_title.appendChild(__date_content);
            if (__control_content)
                __row_title.appendChild(__control_content);
            item.appendChild(__row_title);
        }
        return item;
    }
    add_menu_item(task) {
        /** UPDATE TABLE MENU **/
        const row_title = this.$tableContent.querySelector('.row-title[data-task="' + task.parent + '"]');
        const tree_view_item = row_title.closest('.tree-view-item');
        if (tree_view_item.classList.contains('parent')) {
            const new_item = this.genetate_menu_items(task);
            const nested_menu = tree_view_item.querySelector('.nested');
            nested_menu.appendChild(new_item);
        }
        else {
            const parent_task = this.get_task(task.parent);
            const new_item = this.genetate_menu_items(parent_task);
            const ul = tree_view_item.closest('ul');
            ul.replaceChild(new_item, tree_view_item);
        }
    }
    add_task(task) {
        this.tasks.push(task);
        this.setup_data({ links: this.links, tasks: this.tasks });
        /** ADD MENU ITEM TO TABLE **/
        this.add_menu_item(task);
        /** UPDATE GRID TABLE **/
        this.add_grid_row();
        /** SCALE COLUMN & TICKERS**/
        this.scale_grid_columns();
        /** ADD BAR TO GRID **/
        /**
         * - find parent task
         * - get all child bars
         * - if task have no children bar then add below parent bar
         * - if task have children bar then get position of last child bar, then insert below the last child bar
         * - move all bar bellow new bar to new position
         */
        // let parent_task = this.get_task(task.parent);
        const parent_bar = this.get_bar(task.parent);
        const _same_level_child = this.getChildren(this.tasks, task.parent, false);
        const _all_child = this.getChildren(this.tasks, task.parent, true);
        if (_same_level_child.length === 1) {
            const other_tasks = this.tasks.filter((_task) => _task.id !== task.id);
            const other_bars = other_tasks.map((_task) => this.get_bar(_task.id));
            for (const bar of other_bars) {
                if (bar.$bar.getY() > parent_bar.$bar.getY() &&
                    bar.task._index > parent_bar.task._index) {
                    const y = bar.$bar.getY() +
                        (this.options.bar_height + this.options.padding);
                    bar.update_bar_position({ y });
                }
            }
        }
        else if (_same_level_child.length > 1) {
            const _all_child_not_collapsed = _all_child.filter((_task) => !_task.collapsed && _task.id !== task.id);
            const _all_bar_not_collapsed = _all_child_not_collapsed.map((_task) => this.get_bar(_task.id));
            const other_tasks = this.tasks.filter((_task) => _task.id !== task.id);
            const other_bars = other_tasks.map((_task) => this.get_bar(_task.id));
            const last_bar = _all_bar_not_collapsed.reduce((acc, cur) => acc.$bar.getY() > cur.$bar.getY() ? acc : cur);
            for (const bar of other_bars) {
                if (bar.$bar.getY() > last_bar.$bar.getY() &&
                    bar.task._index > last_bar.task._index) {
                    const y = bar.$bar.getY() +
                        (this.options.bar_height + this.options.padding);
                    bar.update_bar_position({ y });
                }
            }
        }
        /**
         * Disable handler progress
         */
        if (parent_bar.$handle_progress) {
            $.attr(parent_bar.$handle_progress, {
                class: 'handle progress disabled'
            });
        }
        const bar = new Bar(this, task);
        bar.connectors = [];
        this.gridContentLayers.bar.appendChild(bar.group);
        this.bars.push(bar);
    }
    update_task(task) {
        this._update_task(task.id, task);
        this.setup_data({ links: this.links, tasks: this.tasks });
        const _task = this.get_task(task.id);
        /** UPDATE MENU ITEM IN TABLE **/
        this.update_menu_item(_task);
        /** UPDATE BAR **/
        this.update_bar(_task);
    }
    remove_task(task) {
        const task_id = task.id;
        // remove all child task
        let childs = this.getChildren(this.tasks, task_id, true);
        const _childNotCollapsed = childs.filter((_child) => !_child.collapsed);
        // remove from below bar to above bar
        childs = childs.sort((a, b) => b._index - a._index);
        for (const child of childs) {
            this.remove_task(child);
        }
        // remove task
        const bar = this.get_bar(task_id);
        const _below_bars = this.bars.filter(_bar => _bar.$bar.getY() > bar.$bar.getY() && _bar.task._index > bar.task._index);
        for (const _bar of _below_bars) {
            if (!task.collapsed) {
                const y = _bar.$bar.getY() - (_childNotCollapsed.length + 1) * (this.options.bar_height + this.options.padding);
                _bar.update_bar_position({ y });
            }
        }
        // remove task from tasks
        this.tasks = this.tasks.filter((_task) => _task.id !== task_id);
        // remove connectors in bar
        for (const connector of bar.connectors) {
            connector.group.remove();
        }
        // remove connector from connectors
        this.connectors = this.connectors.filter((connector) => {
            return (connector.from_task_id !== bar.task.id && connector.to_task_id !== bar.task.id);
        });
        // remove element from gridContentLayers.bar
        bar.group.remove();
        // remove bar from bars
        this.bars = this.bars.filter(_bar => _bar.task.id !== task_id);
        // remove menu
        const row_menu = this.$container.querySelector('.row-title[data-task="' + task_id + '"]');
        row_menu.remove();
        // resetup data
        this.setup_data({ links: this.links, tasks: this.tasks });
    }
    update_menu_item(task) {
        /** Find menu **/
        const task_row_title = this.$table.querySelector('.row-title[data-task="' + task.id + '"]');
        if (this.options.columns.includes('wbs')) {
            const menu_wbs = task_row_title.querySelector('.wbs');
            menu_wbs.innerHTML = task.WBS;
        }
        if (this.options.columns.includes('label')) {
            const menu_label = task_row_title.querySelector('.label');
            menu_label.innerHTML = task.label;
        }
        if (this.options.columns.includes('priority')) {
            const menu_priority = task_row_title.querySelector('.priority');
            const _priority = this.get_priority(task.priority);
            menu_priority.innerHTML = '<span class="badge" style="background:' + _priority.color + '">' + _priority.label + '</span>';
        }
        if (this.options.columns.includes('date')) {
            const menu_date = task_row_title.querySelector('.date');
            menu_date.innerHTML = date_utils.format(task._start, 'MM/DD') + ' - ' + date_utils.format(task._end, 'MM/DD');
        }
    }
    update_bar(task) {
        const bar = this.get_bar(task.id);
        bar.task = task;
        bar.update_bar();
    }
    bind_table_content_events() {
        const that = this;
        $.on(this.$table, 'click', '.add-project', () => {
            const start = new Date();
            const end = new Date();
            end.setDate(end.getDate() + 3);
            const new_project = {
                collapsed: false,
                color: '#7E8898',
                end: date_utils.format(end, 'YYYY-MM-DD'),
                id: start.getTime().toString(),
                label: 'Untitled',
                start: date_utils.format(start, 'YYYY-MM-DD'),
                type: 'project'
            };
            that.tasks.push(new_project);
            that.refresh({ links: that.links, tasks: that.tasks });
            this.add_project_event = new CustomEvent('add-project', { detail: { card: new_project } });
            this.element.dispatchEvent(this.add_project_event);
            // that.trigger_event('add_project', [new_project]);
        });
        $.on(this.$table, 'click', '.add-task', (e) => {
            let handler = e.target;
            if (!e.target.classList.contains('add-task')) {
                handler = e.target.closest('.add-task');
            }
            const parent = handler.getAttribute('data-parent');
            const start = new Date();
            const end = new Date();
            end.setDate(end.getDate() + 3);
            const new_task = {
                collapsed: false,
                color: randomColor({ luminosity: 'bright' }),
                end: date_utils.format(end, 'YYYY-MM-DD'),
                id: start.getTime().toString(),
                // id: start.getTime(),
                label: 'Untitled',
                members: [],
                parent,
                priority: 1,
                progress: 0,
                start: date_utils.format(start, 'YYYY-MM-DD'),
                status: 1,
                type: 'task'
            };
            that.add_task(new_task);
            // that.refresh({tasks: that.tasks, links: that.links});
            this.add_task_event = new CustomEvent('add-task', { detail: { card: new_task } });
            this.element.dispatchEvent(this.add_task_event);
            // that.trigger_event('add_task', [new_task]);
        });
        $.on(this.$table, 'click', '.edit-task', (e) => {
            let handler = e.target;
            if (!e.target.classList.contains('edit-task')) {
                handler = e.target.closest('.edit-task');
            }
            const task_id = handler.dataset.id;
            const task = this.get_task(task_id);
            console.log(task);
            this.edit_task_event = new CustomEvent('edit-task', { detail: { card: task } });
            this.element.dispatchEvent(this.edit_task_event);
            // that.trigger_event('edit_task', [{task}]);
        });
        $.on(this.$table, 'click', '.remove-task', (e) => {
            let handler = e.target;
            if (!e.target.classList.contains('remove-task')) {
                handler = e.target.closest('.remove-task');
            }
            const task_id = handler.dataset.task;
            const dialog = this.$dialog.confirm("you're sure?", () => {
                const task = this.get_task(task_id);
                this.remove_task(task);
                dialog.destroy();
                this.remove_task_event = new CustomEvent('remove-task', { detail: { card: task } });
                this.element.dispatchEvent(this.remove_task_event);
                // this.trigger_event('remove_task', [{task}]);
            }, {});
            dialog.show();
        });
        $.on(this.$table, 'click', '.tree-view-collapse', (e) => {
            const task_id = e.target.dataset.task;
            const parent_bar = that.get_bar(task_id);
            const parent = e.target.closest('.parent');
            const nest_menu = parent.querySelector('.nested');
            // let nested_box = nest_menu.getBoundingClientRect();
            const collapsed = nest_menu.classList.contains('active');
            if (collapsed) {
                e.target.classList.remove('fa-folder-open');
                e.target.classList.add('fa-folder');
            }
            else {
                e.target.classList.remove('fa-folder');
                e.target.classList.add('fa-folder-open');
            }
            const childs = that.getChildren(that.tasks, task_id, true);
            const notCollapse_bar = childs.filter((_task) => !_task.collapsed);
            const childBars = [];
            childs.forEach((_task) => {
                const child_bar = that.get_bar(_task.id);
                childBars.push(child_bar);
                const connectors = child_bar.connectors;
                for (const connector of connectors) {
                    const from_element = connector.from_element;
                    const from_task_id = connector.from_task_id;
                    const from_bar = that.get_bar(from_task_id);
                    const to_element = connector.to_element;
                    const to_task_id = connector.to_task_id;
                    const to_bar = that.get_bar(to_task_id);
                    const from_left = from_element.classList.contains('left');
                    const to_left = to_element.classList.contains('left');
                    if (collapsed) {
                        if (from_task_id === _task.id) {
                            if (from_left) {
                                connector.set_option({
                                    from_element: parent_bar.$connector_left
                                });
                                parent_bar.connectors.push(connector);
                            }
                            else {
                                connector.set_option({
                                    from_element: parent_bar.$connector_right
                                });
                                parent_bar.connectors.push(connector);
                            }
                        }
                        else if (to_task_id === _task.id) {
                            const index = parent_bar.connectors.indexOf(connector);
                            parent_bar.connectors.splice(index, 1);
                            if (to_left) {
                                connector.set_option({
                                    to_element: parent_bar.$connector_left
                                });
                            }
                            else {
                                connector.set_option({
                                    to_element: parent_bar.$connector_right
                                });
                            }
                        }
                    }
                    else {
                        if (from_task_id === _task.id) {
                            if (from_left) {
                                connector.set_option({
                                    from_element: from_bar.$connector_left
                                });
                            }
                            else {
                                connector.set_option({
                                    from_element: from_bar.$connector_right
                                });
                            }
                        }
                        else if (to_task_id === _task.id) {
                            if (to_left) {
                                connector.set_option({
                                    to_element: to_bar.$connector_left
                                });
                            }
                            else {
                                connector.set_option({
                                    to_element: to_bar.$connector_right
                                });
                            }
                        }
                    }
                }
                _task.hidden = collapsed;
                child_bar.task.hidden = collapsed;
                // if child is direct child
                if (_task.parent === task_id) {
                    _task.collapsed = collapsed;
                    child_bar.task.collapsed = collapsed;
                    if (collapsed) {
                        anime({
                            duration: 500,
                            easing: 'linear',
                            opacity: 0,
                            targets: child_bar.group
                        });
                    }
                    else {
                        anime({
                            duration: 500,
                            easing: 'linear',
                            opacity: 1,
                            targets: child_bar.group
                        });
                    }
                }
                else {
                    // if is child of child
                    if (collapsed) {
                        if (!_task.collapsed) {
                            anime({
                                duration: 500,
                                easing: 'linear',
                                opacity: 0,
                                targets: child_bar.group
                            });
                        }
                    }
                    else {
                        if (!_task.collapsed) {
                            anime({
                                duration: 500,
                                easing: 'linear',
                                opacity: 1,
                                targets: child_bar.group
                            });
                        }
                    }
                }
            });
            const _notCollapse_bar = childs.filter((_task) => !_task.collapsed);
            const other_bars = that.bars.filter((bar) => ![parent_bar, ...childBars].includes(bar));
            for (const bar of other_bars) {
                if (bar.$bar.getY() > parent_bar.$bar.getY() &&
                    bar.task._index > parent_bar.task._index) {
                    let y;
                    if (collapsed) {
                        y =
                            bar.$bar.getY() -
                                notCollapse_bar.length *
                                    (that.options.bar_height +
                                        that.options.padding);
                    }
                    else {
                        y =
                            bar.$bar.getY() +
                                _notCollapse_bar.length *
                                    (that.options.bar_height +
                                        that.options.padding);
                    }
                    bar.update_bar_position({ y });
                }
            }
            nest_menu.classList.toggle('active');
            this.task_collapsed_event = new CustomEvent('task-collapsed', { detail: { card: task_id, collapsed } });
            this.element.dispatchEvent(this.task_collapsed_event);
        });
        $.on(this.$table, 'click', '.row-title', (e) => {
            let element;
            if (e.target.classList.contains('row-title')) {
                element = e.target;
            }
            else {
                element = e.target.closest('.row-title');
            }
            const task_id = element.dataset.task;
            const task = that.get_task(task_id);
            const parent_element = that.$grid;
            if (!parent_element)
                return;
            const hours_before_first_task = date_utils.diff(task._start, that.gantt_start, 'hour');
            const scroll_pos = (hours_before_first_task / that.options.step) *
                that.options.column_width -
                that.options.column_width;
            // parent_element.scrollLeft = scroll_pos;
            anime({
                duration: 500,
                easing: 'spring(1, 80, 10, 0)',
                scrollLeft: scroll_pos,
                targets: parent_element
            });
        });
    }
    /** END TABLE **/
    bind_grid_click() {
        $.on(this.$grid, this.options.popup_trigger, '.grid-row, .grid-header', () => {
            this.unselect_all();
            this.hide_popup();
        });
    }
    bind_bar_events() {
        let is_dragging = false;
        let x_on_start = 0;
        // let y_on_start = 0;
        let is_resizing_left = false;
        let is_resizing_right = false;
        let parent_bar_id = null;
        let bars = []; // instanceof Bar
        this.bar_being_dragged = null;
        function action_in_progress() {
            return is_dragging || is_resizing_left || is_resizing_right;
        }
        $.on(this.$grid, 'mousedown', '.bar-wrapper, .handle', (e, element) => {
            const bar_wrapper = $.closest('.bar-wrapper', element);
            if (bar_wrapper.getAttribute('data-type') !== 'project') {
                if (element.classList.contains('left')) {
                    is_resizing_left = true;
                }
                else if (element.classList.contains('right')) {
                    is_resizing_right = true;
                }
                else if (element.classList.contains('bar-wrapper')) {
                    is_dragging = true;
                }
                bar_wrapper.classList.add('active');
                x_on_start = e.offsetX;
                // y_on_start = e.offsetY;
                parent_bar_id = bar_wrapper.getAttribute('data-id');
                const ids = [
                    parent_bar_id,
                    ...this.get_all_dependent_tasks(parent_bar_id)
                ];
                bars = ids.map((id) => this.get_bar(id));
                this.bar_being_dragged = parent_bar_id;
                bars.forEach((bar) => {
                    const $bar = bar.$bar;
                    $bar.ox = $bar.getX();
                    $bar.oy = $bar.getY();
                    $bar.owidth = $bar.getWidth();
                    $bar.finaldx = 0;
                });
            }
        });
        $.on(this.$grid, 'mousemove', (e) => {
            if (!action_in_progress())
                return;
            const dx = e.offsetX - x_on_start;
            // const dy = e.offsetY - y_on_start;
            bars.forEach((bar) => {
                const $bar = bar.$bar;
                $bar.finaldx = this.get_snap_position(dx);
                this.hide_popup();
                if (is_resizing_left) {
                    if (parent_bar_id === bar.task.id) {
                        bar.update_bar_position({
                            width: $bar.owidth - $bar.finaldx,
                            x: $bar.ox + $bar.finaldx
                        });
                        bar.update_parent_position({
                            x: $bar.ox + $bar.finaldx
                        });
                        bar.update_assignments_position();
                    }
                    else {
                        bar.update_bar_position({
                            x: $bar.ox + $bar.finaldx
                        });
                        bar.update_parent_position({
                            x: $bar.ox + $bar.finaldx
                        });
                        bar.update_assignments_position();
                    }
                }
                else if (is_resizing_right) {
                    if (parent_bar_id === bar.task.id) {
                        bar.update_bar_position({
                            width: $bar.owidth + $bar.finaldx
                        });
                        bar.update_parent_position({ x: $bar.ox });
                        bar.update_assignments_position();
                    }
                }
                else if (is_dragging) {
                    bar.update_bar_position({ x: $bar.ox + $bar.finaldx });
                    bar.update_parent_position({ x: $bar.ox + $bar.finaldx });
                    bar.update_assignments_position();
                }
            });
        });
        document.addEventListener('mouseup', () => {
            if (is_dragging || is_resizing_left || is_resizing_right) {
                bars.forEach((bar) => bar.group.classList.remove('active'));
            }
            is_dragging = false;
            is_resizing_left = false;
            is_resizing_right = false;
        });
        $.on(this.$grid, 'mouseup', () => {
            this.bar_being_dragged = null;
            bars.forEach((bar) => {
                const $bar = bar.$bar;
                if (!$bar.finaldx)
                    return;
                bar.date_changed();
                bar.set_action_completed();
            });
        });
        this.bind_bar_progress();
    }
    bind_connector_events() {
        this.connectMode = false;
        $.on(this.$ganttContentSvg, 'mouseup', (e) => {
            console.log("here");
            if (this.connectMode && this.$mousePoint) {
                if (e.target.classList.contains('connector') && e.target.getAttribute('data-type') !== 'project') {
                    this.$endPoint = e.target;
                    const from_task_id = this.$startPoint.dataset.task;
                    const to_task_id = this.$endPoint.dataset.task;
                    const from_task = this.get_task(from_task_id);
                    const to_task = this.get_task(to_task_id);
                    const from_bar = this.get_bar(from_task_id);
                    const to_bar = this.get_bar(to_task_id);
                    const start_direction = this.$startPoint.classList.contains('left')
                        ? 'left'
                        : 'right';
                    const end_direction = this.$endPoint.classList.contains('left')
                        ? 'left'
                        : 'right';
                    const direction_type = start_direction === 'right' && end_direction === 'left'
                        ? 0
                        : start_direction === 'left' &&
                            end_direction === 'left'
                            ? 1
                            : start_direction === 'right' &&
                                end_direction === 'right'
                                ? 2
                                : 3;
                    const link = {
                        id: new Date().getTime(),
                        source: from_task.id,
                        target: to_task.id,
                        type: direction_type
                    };
                    // Change end point to target bar
                    this.$connector.set_option({ id: from_task_id + '-' + to_task_id, to_element: this.$endPoint });
                    this.links.push(link);
                    this.dependency_map[link.source] = this.dependency_map[link.source] || [];
                    this.dependency_map[link.source].push(link.target);
                    to_task.links.push(link);
                    // add connector to bar
                    from_bar.connectors.push(this.$connector);
                    to_bar.connectors.push(this.$connector);
                    this.connectors.push(this.$connector);
                    this.add_link_event = new CustomEvent('add-link', {
                        detail: {
                            // link: {
                            //   source: from_task_id,
                            //   target: to_task_id,
                            //   type: direction_type
                            // }
                            link
                        }
                    });
                    this.element.dispatchEvent(this.add_link_event);
                }
                else {
                    this.$connector.group.remove();
                }
                this.connectMode = false;
            }
            else {
                if (e.target.classList.contains('connector') && e.target.getAttribute('data-type') !== 'project') {
                    this.$startPoint = e.target;
                    this.start_direction = e.target.classList.contains('left')
                        ? 'left'
                        : 'right';
                    const start_task_id = e.target.dataset.task;
                    const start_task = this.get_task(start_task_id);
                    this.$connector = new Connector(this, this.$startPoint, this.$mousePoint);
                    $.attr(this.$connector.element, {
                        stroke: start_task.color
                    });
                    this.gridContentLayers.arrow.appendChild(this.$connector.group);
                    this.connectMode = true;
                    this.update_connector_line(e);
                }
            }
        });
        $.on(this.$ganttContentSvg, 'mouseover', (e) => {
            if (this.connectMode) {
                if (e.target.classList.contains('connector')) {
                    this.end_direction = e.target.classList.contains('left')
                        ? 'left'
                        : 'right';
                    if (this.start_direction && this.end_direction) {
                        $.attr(this.$mousePoint, {
                            class: `mouse-point connector ${this.end_direction}`
                        });
                        this.$connector.set_option({
                            to_element: this.$mousePoint
                        });
                        this.$connector.update();
                    }
                }
            }
        });
        $.on(this.$ganttContentSvg, 'mouseout', (e) => {
            if (this.connectMode) {
                if (!e.target.classList.contains('connector')) {
                    $.attr(this.$mousePoint, {
                        class: 'mouse-point connector left'
                    });
                }
            }
        });
        $.on(this.$ganttContentSvg, 'touchmove mousemove', (e) => {
            if (this.connectMode) {
                e.preventDefault();
                this.update_connector_line(e);
            }
        });
        $.on(this.$ganttContentSvg, 'click', '.connector-stroke', (e) => {
            this.$ganttContentSvg.querySelectorAll('.group-connector').forEach((connector) => {
                connector.classList.remove('active');
            });
            const handler = e.target.closest('.group-connector');
            $.attr(handler, {
                class: 'group-connector active'
            });
        });
    }
    bind_tooltip_event() {
        const that = this;
        $.on(document, 'mouseover', '[data-control="tooltip"]', (e) => {
            if (e.target.hasAttribute('data-control')) {
                that.$tooltipTarget = e.target;
            }
            else {
                that.$tooltipTarget = e.target.closest('[data-control="tooltip"]');
            }
            Object.assign(that.$tooltip.style, {
                visibility: 'visible'
            });
            if (that.$tooltipTarget.dataset.tooltip) {
                autoUpdate(that.$tooltipTarget, that.$tooltip, updateTooltipPosition);
            }
        });
        $.on(document, 'mouseout', '[data-control="tooltip"]', () => {
            Object.assign(that.$tooltip.style, {
                visibility: 'hidden'
            });
        });
        function updateTooltipPosition() {
            computePosition(that.$tooltipTarget, that.$tooltip, {
                middleware: [
                    arrow({ element: that.$tooltipArrow, padding: 10 }),
                    offset(10),
                    flip(),
                    shift(),
                    // hide()
                ],
                placement: 'top-start'
            }).then(({ x, y, middlewareData }) => {
                // const {referenceHidden} = middlewareData.hide;
                //
                Object.assign(that.$tooltip.style, {
                    left: '0',
                    top: '0',
                    transform: `translate(${Math.round(x)}px,${Math.round(y)}px)`,
                    // visibility: referenceHidden ? 'hidden' : 'visible'
                });
                that.$tooltipContent.innerHTML = that.$tooltipTarget.dataset.tooltip;
                if (middlewareData.arrow) {
                    const { x, y } = middlewareData.arrow;
                    Object.assign(that.$tooltipArrow.style, {
                        left: x !== null ? `${x}px` : '',
                        top: y !== null ? `${y}px` : ''
                    });
                }
            });
        }
    }
    update_connector_line(e) {
        if (this.$mousePoint) {
            let cx = null;
            let cy = null;
            if ('touchmove' === e.type) {
                const touch = e.touches[0] || e.changedTouches[0];
                cx = touch.clientX - this.$mousePoint.r.baseVal.value - 2;
                cy = touch.clientY;
            }
            else {
                cx = e.offsetX - this.$mousePoint.r.baseVal.value - 2;
                cy = e.offsetY;
            }
            $.attr(this.$mousePoint, {
                cx,
                cy
            });
            this.$connector.update();
        }
    }
    bind_bar_progress() {
        let x_on_start = 0;
        let bar = null;
        let $bar_progress = null;
        let $bar = null;
        $.on(this.$grid, 'touchstart mousedown', '.handle.progress', (e, handle) => {
            this.is_progress_change = true;
            // this.$gridBody.classList.add('stop-touch');
            this.$ganttContentSvg.classList.add('stop-touch');
            if ('touchstart' === e.type) {
                const touch = e.touches[0] || e.changedTouches[0];
                x_on_start = touch.clientX;
            }
            else {
                x_on_start = e.offsetX;
            }
            const $bar_wrapper = $.closest('.bar-wrapper', handle);
            const id = $bar_wrapper.getAttribute('data-id');
            bar = this.get_bar(id);
            $bar_progress = bar.$bar_progress;
            $bar = bar.$bar;
            $bar_progress.finaldx = 0;
            $bar_progress.owidth = $bar_progress.getWidth();
            $bar_progress.min_dx = -$bar_progress.getWidth();
            $bar_progress.max_dx = $bar.getWidth() - $bar_progress.getWidth();
        });
        $.on(this.$grid, 'touchmove mousemove', (e) => {
            if (!this.is_progress_change)
                return;
            e.preventDefault();
            let offsetX = null;
            if ('touchmove' === e.type) {
                const touch = e.touches[0] || e.changedTouches[0];
                offsetX = touch.clientX;
            }
            else {
                offsetX = e.offsetX;
            }
            let dx = offsetX - x_on_start;
            if (dx > $bar_progress.max_dx) {
                dx = $bar_progress.max_dx;
            }
            if (dx < $bar_progress.min_dx) {
                dx = $bar_progress.min_dx;
            }
            const $handle = bar.$handle_progress;
            $.attr($bar_progress, 'width', $bar_progress.owidth + dx);
            $.attr($handle, 'points', bar.get_progress_polygon_points());
            $bar_progress.finaldx = dx;
        });
        $.on(this.$grid, 'touchend mouseup', () => {
            if (!this.is_progress_change)
                return;
            this.is_progress_change = false;
            this.$ganttContentSvg.classList.remove('stop-touch');
            if (!($bar_progress && $bar_progress.finaldx))
                return;
            bar.progress_changed();
            bar.set_action_completed();
        });
    }
    get_all_dependent_tasks(task_id) {
        let out = [];
        let to_process = [task_id];
        while (to_process.length) {
            const deps = to_process.reduce((acc, curr) => {
                acc = acc.concat(this.dependency_map[curr]);
                return acc;
            }, []);
            out = out.concat(deps);
            to_process = deps.filter((d) => !to_process.includes(d));
        }
        return out.filter(Boolean);
    }
    get_snap_position(dx) {
        const odx = dx;
        let rem;
        let position;
        if (this.view_is(VIEW_MODE.WEEK)) {
            rem = dx % (this.options.column_width / 7);
            position =
                odx -
                    rem +
                    (rem < this.options.column_width / 14
                        ? 0
                        : this.options.column_width / 7);
        }
        else if (this.view_is(VIEW_MODE.MONTH)) {
            rem = dx % (this.options.column_width / 30);
            position =
                odx -
                    rem +
                    (rem < this.options.column_width / 60
                        ? 0
                        : this.options.column_width / 30);
        }
        else {
            rem = dx % this.options.column_width;
            position =
                odx -
                    rem +
                    (rem < this.options.column_width / 2
                        ? 0
                        : this.options.column_width);
        }
        return position;
    }
    unselect_all() {
        [...this.$grid.querySelectorAll('.bar-wrapper')].forEach((el) => {
            el.classList.remove('active');
        });
    }
    view_is(modes) {
        if (typeof modes === 'string') {
            return this.options.view_mode === modes;
        }
        if (Array.isArray(modes)) {
            return modes.some((mode) => this.options.view_mode === mode);
        }
        return false;
    }
    task_is_show(task) {
        return (task.type === 'project' ||
            (task.type !== 'project' && !task.collapsed));
    }
    get_priority(id) {
        return this.options.priorities.find((priority) => {
            return priority.id === id;
        });
    }
    get_task(id) {
        return this.tasks.find((task) => {
            return task.id === id;
        });
    }
    _update_task(id, props) {
        const index = this.tasks.findIndex((task) => {
            return task.id === id;
        });
        this.tasks[index] = Object.assign({}, this.tasks[index], props);
    }
    get_bar(id) {
        return this.bars.find((bar) => {
            return bar.task.id === id;
        });
    }
    get_connector(id) {
        return this.connectors.find((connector) => {
            return connector.id === id;
        });
    }
    getChildrenId(array, id, recursive = false) {
        return array.reduce((acc, cur) => {
            if (cur.parent === id) {
                if (recursive) {
                    acc.push(cur.id, ...this.getChildrenId(array, cur.id));
                }
                else {
                    acc.push(cur.id);
                }
            }
            return acc;
        }, []);
    }
    getChildren(tasks, id, recursive = false) {
        return tasks.reduce((acc, cur) => {
            if (cur.parent === id) {
                if (recursive) {
                    acc.push(cur, ...this.getChildren(tasks, cur.id, true));
                }
                else {
                    acc.push(cur);
                }
            }
            return acc;
        }, []);
    }
    getParent(tasks, id, recursive = false) {
        const task = tasks.filter((_task) => _task.id === id)[0];
        return tasks.reduce((acc, cur) => {
            if (cur.id === task.parent) {
                if (recursive) {
                    acc.push(cur, ...this.getParent(tasks, cur.id, recursive));
                }
                else {
                    acc.push(cur);
                }
            }
            return acc;
        }, []);
    }
    getParentId(tasks, id, recursive = false) {
        const task = tasks.filter((_task) => _task.id === id)[0];
        return tasks.reduce((acc, cur) => {
            if (cur.id === task.parent) {
                if (recursive) {
                    acc.push(cur.id, ...this.getParentId(tasks, cur.id, recursive));
                }
                else {
                    acc.push(cur.id);
                }
            }
            return acc;
        }, []);
    }
    getParentCollapsedId(tasks, id, recursive = false) {
        const task = tasks.filter((_task) => _task.id === id)[0];
        return tasks.reduce((acc, cur) => {
            if (cur.id === task.parent && cur.collapsed) {
                if (recursive) {
                    acc.push(cur.id, ...this.getParentCollapsedId(tasks, cur.id, recursive));
                }
                else {
                    acc.push(cur.id);
                }
            }
            return acc;
        }, []);
    }
    getParentCollapsed(tasks, id, recursive = false) {
        const task = tasks.filter((_task) => _task.id === id)[0];
        return tasks.reduce((acc, cur) => {
            if (cur.id === task.parent && cur.collapsed) {
                if (recursive) {
                    acc.push(cur, ...this.getParentCollapsed(tasks, cur.id, recursive));
                }
                else {
                    acc.push(cur);
                }
            }
            return acc;
        }, []);
    }
    weightedMean(weightedValues) {
        const totalWeight = weightedValues.reduce((sum, weightedValue) => {
            return sum + weightedValue[1];
        }, 0);
        return weightedValues.reduce((mean, weightedValue) => {
            return mean + (weightedValue[0] * weightedValue[1]) / totalWeight;
        }, 0);
    }
    show_popup(options) {
        if (!this.popup) {
            this.popup = new Popup(this.popup_wrapper, this.options.custom_popup_html);
        }
        this.popup.show(options);
    }
    hide_popup() {
        this.popup && this.popup.hide();
    }
    trigger_event(event, args) {
        if (this.options['on_' + event]) {
            this.options['on_' + event].apply(null, args);
        }
    }
    /**
     * Gets the oldest starting date from the list of tasks
     *
     * @returns Date
     * @memberof Gantt
     */
    get_oldest_starting_date() {
        return this.tasks.length > 0 ? this.tasks
            .map((task) => task._start)
            .reduce((prev_date, cur_date) => cur_date <= prev_date ? cur_date : prev_date) : new Date();
    }
    get_newest_ending_date() {
        return this.tasks
            .map((task) => task._start)
            .reduce((prev_date, cur_date) => cur_date >= prev_date ? cur_date : prev_date);
    }
    /**
     * Clear all elements from the parent svg element
     *
     * @memberof Gantt
     */
    clear() {
        this.$table.innerHTML = '';
        this.$grid.innerHTML = '';
    }
}
Gantt.VIEW_MODE = VIEW_MODE;
function generate_id(task) {
    return task.name + '_' + Math.random().toString(36).slice(2, 12);
}

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../services/channel.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/material/list";
import * as i11 from "../../modules/directives/default-image.directive";
function AddFacebookPageDialogComponent_mat_list_option_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-list-option", 12)(1, "div", 13);
    i0.ɵɵelement(2, "img", 14);
    i0.ɵɵelementStart(3, "div", 15)(4, "div", 3);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "span", 16);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd()()()();
} if (rf & 2) {
    const page_r1 = ctx.$implicit;
    i0.ɵɵproperty("value", page_r1.pid);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(page_r1.name);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(page_r1.category);
} }
export class AddFacebookPageDialogComponent {
    constructor(config, $gaService, store, dialogRef, data, channelService) {
        this.config = config;
        this.$gaService = $gaService;
        this.store = store;
        this.dialogRef = dialogRef;
        this.data = data;
        this.channelService = channelService;
        this.form = new FormGroup({
            pages: new FormControl([], [Validators.required]),
        });
        this.loading = false;
        this.id = data.id;
        this.pages = channelService.getFBPageList({ id: this.id });
    }
    onSubmit() {
        if (this.form.valid) {
            this.loading = true;
            const ref = this.channelService.addFBPage(this.form.value);
            ref.subscribe({
                error: (err) => {
                    this.loading = false;
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "danger-dialog" }, message: err }));
                }, next: (data) => {
                    this.loading = false;
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "success-dialog" }, message: "Add channel success" }));
                    this.dialogRef.close(true);
                }
            });
        }
    }
    static { this.ɵfac = function AddFacebookPageDialogComponent_Factory(t) { return new (t || AddFacebookPageDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.Store), i0.ɵɵdirectiveInject(i4.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i5.ChannelService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AddFacebookPageDialogComponent, selectors: [["app-add-facebook-page-dialog"]], decls: 17, vars: 11, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 3, "cdkTrapFocusAutoCapture"], [1, "col-12"], [1, "d-flex", "flex-row", "justify-content-between", "align-items-center", "my-2", "px-2"], [1, "fw-700"], ["src", "assets/icons/Facebook.svg", 1, "img-fluid", 2, "height", "50px"], ["novalidate", "", 3, "formGroup", "ngSubmit"], [1, "row", "g-0", "px-4", "py-2", 2, "background", "#F2F2F2"], ["formControlName", "pages"], ["color", "primary", 3, "value", 4, "ngFor", "ngForOf"], [1, "d-flex", "flex-row-reverse", "p-4"], ["mat-button", "", "cdkFocusInitial", "", 1, "ms-3", 2, "background", "#8817F8", "border-radius", "5px", "padding", "5px 50px", "color", "white", "font-size", "15px", "font-weight", "700", 3, "disabled"], ["mat-button", "", 3, "disabled", "matDialogClose"], ["color", "primary", 3, "value"], [1, "d-flex", "flex-row"], ["src", "assets/icons/Facebook.svg", 1, "img-fluid", "me-3", 2, "width", "36px", "height", "36px"], [1, "d-flex", "flex-column"], [1, "small"]], template: function AddFacebookPageDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "span", 3);
            i0.ɵɵtext(4, "Which channels would you like to add?");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(5, "img", 4);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "form", 5);
            i0.ɵɵlistener("ngSubmit", function AddFacebookPageDialogComponent_Template_form_ngSubmit_6_listener() { return ctx.onSubmit(); });
            i0.ɵɵelementStart(7, "div", 6)(8, "mat-selection-list", 7);
            i0.ɵɵtemplate(9, AddFacebookPageDialogComponent_mat_list_option_9_Template, 8, 3, "mat-list-option", 8);
            i0.ɵɵpipe(10, "async");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(11, "div", 9)(12, "button", 10);
            i0.ɵɵelement(13, "i");
            i0.ɵɵtext(14, " Start connect ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(15, "button", 11);
            i0.ɵɵtext(16, "Cancel");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(10, 9, ctx.pages));
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMapInterpolate1("fa fa-spinner fa-spin me-3 text-white ", ctx.loading ? "d-inline-flex" : "d-none", "");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading)("matDialogClose", false);
        } }, dependencies: [i6.NgForOf, i7.ɵNgNoValidate, i7.NgControlStatus, i7.NgControlStatusGroup, i8.MatButton, i4.MatDialogClose, i9.CdkTrapFocus, i7.FormGroupDirective, i7.FormControlName, i10.MatSelectionList, i10.MatListOption, i11.DefaultImageDirective, i6.AsyncPipe] }); }
}

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../services/workspace.service";
import * as i3 from "../../types/AppConfig";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/material/form-field";
import * as i11 from "@angular/material/card";
import * as i12 from "@angular/cdk/clipboard";
import * as i13 from "@angular/material/input";
function InviteMemberDialogComponent_mat_error_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Please enter a valid email");
    i0.ɵɵelementEnd();
} }
export class InviteMemberDialogComponent {
    constructor(store, workspaceService, config, route) {
        this.store = store;
        this.workspaceService = workspaceService;
        this.config = config;
        this.route = route;
        this.form = new FormGroup({
            email: new FormControl('', [Validators.email, Validators.required, Validators.max(100)]),
        });
        this.inviteLink = "------------------------";
        this.use_invite_link = false;
    }
    async invite() {
        try {
            const _result = await lastValueFrom(this.workspaceService.invite(this.form.value));
            if (_result) {
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Gửi lời mời thành công" }));
            }
        }
        catch (e) {
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Gửi lời mời không thành công" }));
        }
    }
    async create_link() {
        this.use_invite_link = true;
        const _result = await lastValueFrom(this.workspaceService.getInviteCode());
        const code = _result.code;
        this.inviteLink = `${this.config.url}/auth/accept-invite?code=${code}`;
    }
    onCoppySuccess() {
        this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Sao chép đường dẫn thành công" }));
    }
    static { this.ɵfac = function InviteMemberDialogComponent_Factory(t) { return new (t || InviteMemberDialogComponent)(i0.ɵɵdirectiveInject(i1.Store), i0.ɵɵdirectiveInject(i2.WorkspaceService), i0.ɵɵdirectiveInject(i3.AppConfig, 8), i0.ɵɵdirectiveInject(i4.ActivatedRoute)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: InviteMemberDialogComponent, selectors: [["app-invite-member-dialog"]], decls: 33, vars: 15, consts: [[1, "p-3", 2, "min-width", "450px"], [1, "mb-3"], [1, "mb-1"], [1, "my-3", 3, "formGroup"], ["f", "ngForm"], ["appearance", "outline", 1, "w-100"], ["matInput", "", "autocomplete", "off", "formControlName", "email", "placeholder", "Ex. Pizza@example.com"], ["align", "end"], [4, "ngIf"], ["matInput", "", 3, "ngModel", "ngModelChange"], ["mat-icon-button", "", "matSuffix", "", 3, "cdkCopyToClipboard", "click"], [1, "row", "g-0", "mt-3"], [1, "col-12", "col-md-6", "d-flex", "justify-content-start"], ["mat-button", ""], [1, "text-primary"], [1, "ms-2", "text-primary", 3, "click"], [1, "col-12", "col-md-6", "d-flex", "justify-content-end"], ["mat-button", "", "cdkFocusInitial", "", 1, "me-2", 3, "mat-dialog-close"], ["mat-raised-button", "", "color", "primary", "cdkFocusInitial", "", 1, "px-5", 3, "mat-dialog-close", "disabled", "click"]], template: function InviteMemberDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "mat-card-header", 1)(2, "mat-card-title")(3, "h5", 2);
            i0.ɵɵtext(4, "M\u1EDDi th\u00E0nh vi\u00EAn");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(5, "mat-card-content")(6, "form", 3, 4)(8, "mat-form-field", 5)(9, "mat-label");
            i0.ɵɵtext(10, "Email");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(11, "input", 6);
            i0.ɵɵelementStart(12, "mat-hint", 7);
            i0.ɵɵtext(13);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(14, InviteMemberDialogComponent_mat_error_14_Template, 2, 0, "mat-error", 8);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(15, "div")(16, "mat-form-field", 5)(17, "input", 9);
            i0.ɵɵlistener("ngModelChange", function InviteMemberDialogComponent_Template_input_ngModelChange_17_listener($event) { return ctx.inviteLink = $event; });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(18, "a", 10);
            i0.ɵɵlistener("click", function InviteMemberDialogComponent_Template_a_click_18_listener() { return ctx.onCoppySuccess(); });
            i0.ɵɵelementStart(19, "mat-icon");
            i0.ɵɵtext(20, "content_copy");
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(21, "div", 11)(22, "div", 12)(23, "button", 13)(24, "mat-icon", 14);
            i0.ɵɵtext(25, "add_link");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(26, "span", 15);
            i0.ɵɵlistener("click", function InviteMemberDialogComponent_Template_span_click_26_listener() { return ctx.create_link(); });
            i0.ɵɵtext(27, "Create invite link");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(28, "div", 16)(29, "button", 17);
            i0.ɵɵtext(30, "\u0110\u00F3ng");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(31, "button", 18);
            i0.ɵɵlistener("click", function InviteMemberDialogComponent_Template_button_click_31_listener() { return ctx.invite(); });
            i0.ɵɵtext(32, "M\u1EDDi");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(6);
            i0.ɵɵclassProp("d-block", !ctx.use_invite_link)("d-none", ctx.use_invite_link);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(7);
            i0.ɵɵtextInterpolate1("", (ctx.form.controls["email"].value == null ? null : ctx.form.controls["email"].value.length) || 0, "/100");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["email"].invalid);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMap(ctx.use_invite_link ? "d-block" : "d-none");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngModel", ctx.inviteLink);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("cdkCopyToClipboard", ctx.inviteLink);
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("mat-dialog-close", true);
            i0.ɵɵadvance(2);
            i0.ɵɵpropertyInterpolate("disabled", ctx.form.controls["email"].invalid);
            i0.ɵɵproperty("mat-dialog-close", true);
        } }, dependencies: [i5.NgIf, i6.ɵNgNoValidate, i6.DefaultValueAccessor, i6.NgControlStatus, i6.NgControlStatusGroup, i6.NgModel, i7.MatButton, i7.MatIconAnchor, i8.MatIcon, i9.MatDialogClose, i6.FormGroupDirective, i6.FormControlName, i10.MatFormField, i10.MatLabel, i10.MatHint, i10.MatError, i10.MatSuffix, i11.MatCardContent, i11.MatCardHeader, i11.MatCardTitle, i12.CdkCopyToClipboard, i13.MatInput] }); }
}

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "src/app/services/guest.service";
import * as i2 from "src/app/services/projects.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/material/datepicker";
import * as i9 from "@angular/material/form-field";
import * as i10 from "@angular/material/input";
import * as i11 from "@angular/material/tooltip";
function InviteGuestComponent_div_25_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵlistener("click", function InviteGuestComponent_div_25_Template_div_click_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r6); const guest_r3 = restoredCtx.$implicit; const ctx_r5 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r5.itemClick(guest_r3)); });
    i0.ɵɵelementStart(1, "div", 19)(2, "p", 20);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
    i0.ɵɵelement(4, "div", 21);
    i0.ɵɵelementStart(5, "div", 22)(6, "button", 23);
    i0.ɵɵlistener("click", function InviteGuestComponent_div_25_Template_button_click_6_listener() { const restoredCtx = i0.ɵɵrestoreView(_r6); const guest_r3 = restoredCtx.$implicit; const ctx_r7 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r7.deleteGuest(guest_r3)); });
    i0.ɵɵelementStart(7, "mat-icon");
    i0.ɵɵtext(8, "person_remove");
    i0.ɵɵelementEnd()()()();
} if (rf & 2) {
    const guest_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("matTooltip", guest_r3.email);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(guest_r3.email);
} }
export class InviteGuestComponent {
    constructor(data, guestService, projectsService, dialogRef) {
        this.data = data;
        this.guestService = guestService;
        this.projectsService = projectsService;
        this.dialogRef = dialogRef;
    }
    ngOnInit() {
        this.listGuest = this.guestService.getGuestList({ 'project_id': this.data._id });
    }
    validateEmail(email) {
        if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return (true);
        }
        return (false);
    }
    itemClick(_data) {
        this.guestEmail = _data.email;
        this.expireDate = _data.expire_date;
    }
    invite() {
        if (this.guestEmail && this.expireDate && this.validateEmail(this.guestEmail)) {
            this.projectsService.shareProject({
                email: this.guestEmail,
                expireDate: this.expireDate,
                project_id: this.data._id
            }).subscribe({
                next: (result) => {
                    if (result.message === 'ok') {
                        this.listGuest = this.guestService.getGuestList({ 'project_id': this.data._id });
                    }
                }
            });
        }
    }
    close() {
        this.dialogRef.close('close');
    }
    deleteGuest(_data) {
        console.log(_data);
        const deleteResult = this.guestService.deleteGuest({ '_id': _data._id, 'guestEmail': _data.email, 'project_id': this.data._id });
        deleteResult.subscribe({
            next: (result) => {
                console.log(result);
                if (result.message === 'ok') {
                    this.listGuest = this.guestService.getGuestList({ 'project_id': this.data._id });
                }
            }
        });
    }
    static { this.ɵfac = function InviteGuestComponent_Factory(t) { return new (t || InviteGuestComponent)(i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i1.GuestService), i0.ɵɵdirectiveInject(i2.ProjectsService), i0.ɵɵdirectiveInject(i3.MatDialogRef)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: InviteGuestComponent, selectors: [["app-invite-guest"]], decls: 27, vars: 8, consts: [[1, "text-center", "bg-primary", "text-white", 2, "width", "100%"], ["id", "modal-basic-title", 1, "fs-4", "p-3"], [1, "row", "m-2"], [1, "col-12", "col-md-6"], [1, "example-container"], ["appearance", "outline", 1, "mat-input-bg-white"], ["matInput", "", "type", "email", "name", "email", "ngModel", "", "email", "", "placeholder", "Input Supervisors Email", 3, "ngModel", "ngModelChange"], ["inputEmail", ""], ["appearance", "outline", 1, "example-full-width", "mat-input-bg-white"], ["matInput", "", "placeholder", "Choose An Expire Date", 3, "matDatepicker", "ngModel", "ngModelChange"], ["matSuffix", "", 3, "for"], ["picker", ""], [1, "row"], ["mat-raised-button", "", "color", "primary", "mat-button", "", 2, "width", "100%", 3, "disabled", "click"], ["mat-raised-button", "", "color", "basic", "mat-button", "", 2, "width", "100%", 3, "click"], ["dialog-parent-footer", ""], ["dialog-footer", ""], ["class", "card member-card d-flex flex-row lift ps-2 my-2 w-100", 3, "click", 4, "ngFor", "ngForOf"], [1, "card", "member-card", "d-flex", "flex-row", "lift", "ps-2", "my-2", "w-100", 3, "click"], [1, "member-info"], [1, "member-header", 3, "matTooltip"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "justify-content-center"], ["mat-icon-button", "", "matTooltip", "X\u00F3a Kh\u00E1ch", 3, "click"]], template: function InviteGuestComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "h1", 1);
            i0.ɵɵtext(2, "Invite Supervisor");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "div", 4)(6, "mat-form-field", 5)(7, "input", 6, 7);
            i0.ɵɵlistener("ngModelChange", function InviteGuestComponent_Template_input_ngModelChange_7_listener($event) { return ctx.guestEmail = $event; });
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(9, "mat-form-field", 8)(10, "input", 9);
            i0.ɵɵlistener("ngModelChange", function InviteGuestComponent_Template_input_ngModelChange_10_listener($event) { return ctx.expireDate = $event; });
            i0.ɵɵelementEnd();
            i0.ɵɵelement(11, "mat-datepicker-toggle", 10)(12, "mat-datepicker", null, 11);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(14, "div", 12)(15, "div", 3)(16, "button", 13);
            i0.ɵɵlistener("click", function InviteGuestComponent_Template_button_click_16_listener() { return ctx.invite(); });
            i0.ɵɵtext(17, "Invite ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(18, "div", 3)(19, "button", 14);
            i0.ɵɵlistener("click", function InviteGuestComponent_Template_button_click_19_listener() { return ctx.close(); });
            i0.ɵɵtext(20, "Close ");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(21, "footer", 15);
            i0.ɵɵelement(22, "div", 16);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(23, "div", 3);
            i0.ɵɵelementContainerStart(24);
            i0.ɵɵtemplate(25, InviteGuestComponent_div_25_Template, 9, 2, "div", 17);
            i0.ɵɵpipe(26, "async");
            i0.ɵɵelementContainerEnd();
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            const _r1 = i0.ɵɵreference(13);
            let tmp_5_0;
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("ngModel", ctx.guestEmail);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("matDatepicker", _r1)("ngModel", ctx.expireDate);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("for", _r1);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("disabled", ctx.guestEmail === "" || !ctx.guestEmail || !ctx.expireDate);
            i0.ɵɵadvance(9);
            i0.ɵɵproperty("ngForOf", (tmp_5_0 = i0.ɵɵpipeBind1(26, 6, ctx.listGuest)) == null ? null : tmp_5_0.message);
        } }, dependencies: [i4.NgForOf, i5.DefaultValueAccessor, i5.NgControlStatus, i5.EmailValidator, i5.NgModel, i6.MatButton, i6.MatIconButton, i7.MatIcon, i8.MatDatepicker, i8.MatDatepickerInput, i8.MatDatepickerToggle, i9.MatFormField, i9.MatSuffix, i10.MatInput, i11.MatTooltip, i4.AsyncPipe], styles: [".example-container[_ngcontent-%COMP%] {\n  flex-direction: column;\n  padding: 10px;\n}\n\n.example-container[_ngcontent-%COMP%]    > *[_ngcontent-%COMP%] {\n  width: 100%;\n}\n\n[_nghost-%COMP%]  .card-container {\n  min-height: 100vh;\n  background: #F1F1F1;\n  border-radius: 35px;\n  padding: 20px;\n  margin-bottom: 20px;\n}\n[_nghost-%COMP%]  .lift {\n  transition: box-shadow 0.25s ease, transform 0.25s ease;\n}\n[_nghost-%COMP%]  .lift:hover, [_nghost-%COMP%]  .lift:focus {\n  background-color: #edf2f9;\n  box-shadow: 0 1rem 2.5rem rgba(18, 38, 63, 0.1), 0 0.5rem 1rem -0.75rem rgba(18, 38, 63, 0.1) !important;\n  transform: translate3d(0, -3px, 0);\n}\n[_nghost-%COMP%]  .member-card {\n  border: none !important;\n  border-radius: 5px;\n  background: white;\n  width: -moz-fit-content;\n  width: fit-content;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n}\n[_nghost-%COMP%]  .member-card .member-avatar {\n  width: 44px;\n  height: 44px;\n  border-radius: 50%;\n  margin-right: 10px;\n}\n[_nghost-%COMP%]  .member-card .member-info {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n[_nghost-%COMP%]  .member-card .member-info .member-header {\n  padding: 0;\n  margin: 0;\n  word-break: break-all;\n}\n[_nghost-%COMP%]  .member-card .member-info .member-subtext {\n  padding: 0;\n  margin: 0;\n  font-size: 0.7rem;\n  font-weight: 300;\n  word-break: break-all;\n}"] }); }
}

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "../../services/file.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/material/form-field";
import * as i12 from "@angular/material/card";
import * as i13 from "@angular/cdk/clipboard";
import * as i14 from "@angular/material/input";
function ShareFileDialogComponent_mat_error_17_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Please enter a day");
    i0.ɵɵelementEnd();
} }
function ShareFileDialogComponent_mat_error_22_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Please enter a hour");
    i0.ɵɵelementEnd();
} }
function ShareFileDialogComponent_mat_error_27_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Please enter a minus");
    i0.ɵɵelementEnd();
} }
export class ShareFileDialogComponent {
    constructor(config, $gaService, dialogRef, data, store, route, fileService) {
        this.config = config;
        this.$gaService = $gaService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.store = store;
        this.route = route;
        this.fileService = fileService;
        this.sharedLink = "";
        this.form = new FormGroup({
            day: new FormControl(7, [Validators.required]),
            hour: new FormControl(0, [Validators.required]),
            minus: new FormControl(0, [Validators.required]),
        });
    }
    async create_link() {
        const file = this.data.file;
        const project = file.metadata['X-Amz-Meta-Project'];
        const task = file.metadata['X-Amz-Meta-Task'];
        const filetype = file.metadata['X-Amz-Meta-Filetype'];
        const name = file.name;
        if (this.form.valid) {
            const data = { filetype, name, project, task, ...this.form.value };
            const result = this.fileService.createShareFile(data);
            result.subscribe({
                error: () => {
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Tạo link chia sẻ không thành công" }));
                }, next: (data) => {
                    if (this.config.ga)
                        this.$gaService.event("share-file", 'file', 'share-file');
                    this.sharedLink = data.url;
                }
            });
        }
    }
    static { this.ɵfac = function ShareFileDialogComponent_Factory(t) { return new (t || ShareFileDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i4.Store), i0.ɵɵdirectiveInject(i5.ActivatedRoute), i0.ɵɵdirectiveInject(i6.FileService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ShareFileDialogComponent, selectors: [["app-share-file-dialog"]], decls: 42, vars: 13, consts: [[1, "p-4", 2, "min-width", "350px"], [1, "mb-3"], [1, "mb-1"], [3, "formGroup"], ["f", "ngForm"], [1, "mat-small"], [1, "d-flex", "flex-row"], ["appearance", "outline", 1, "mt-3", "me-2", 2, "width", "20%"], ["type", "number", "matInput", "", "formControlName", "day", "placeholder", "Ng\u00E0y"], [4, "ngIf"], ["type", "number", "matInput", "", "formControlName", "hour", "placeholder", "Gi\u1EDD"], ["type", "number", "matInput", "", "formControlName", "minus", "placeholder", "Ph\u00FAt"], [1, "d-flex", "flex-row", "align-content-center", 2, "padding", "20px 0px"], ["mat-raised-button", "", "color", "primary", 3, "click"], ["appearance", "outline", 1, "w-100"], ["matInput", "", 3, "ngModel", "ngModelChange"], ["mat-icon-button", "", "matSuffix", "", 3, "cdkCopyToClipboard"], [1, "row", "g-0", "mt-3"], [1, "col-12", "col-md-6", "d-flex", "justify-content-start"], [1, "col-12", "col-md-6", "d-flex", "justify-content-end"], ["mat-button", "", "cdkFocusInitial", "", 3, "mat-dialog-close"]], template: function ShareFileDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "mat-card-header", 1)(2, "mat-card-title")(3, "h2", 2);
            i0.ɵɵtext(4, "T\u1EA1o link chia s\u1EBB");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(5, "mat-card-content")(6, "form", 3, 4)(8, "p", 5);
            i0.ɵɵtext(9, "\u0110\u00E2y l\u00E0 m\u1ED9t URL t\u1EA1m th\u1EDDi c\u00F3 th\u00F4ng tin x\u00E1c th\u1EF1c truy c\u1EADp t\u00EDch h\u1EE3p \u0111\u1EC3 chia s\u1EBB c\u00E1c \u0111\u1ED1i t\u01B0\u1EE3ng c\u00F3 hi\u1EC7u l\u1EF1c trong t\u1ED1i \u0111a 7 ng\u00E0y.");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "p", 5);
            i0.ɵɵtext(11, "URL t\u1EA1m th\u1EDDi h\u1EBFt h\u1EA1n sau gi\u1EDBi h\u1EA1n th\u1EDDi gian \u0111\u00E3 c\u1EA5u h\u00ECnh.");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(12, "div", 6)(13, "mat-form-field", 7)(14, "mat-label");
            i0.ɵɵtext(15, "Ng\u00E0y");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(16, "input", 8);
            i0.ɵɵtemplate(17, ShareFileDialogComponent_mat_error_17_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(18, "mat-form-field", 7)(19, "mat-label");
            i0.ɵɵtext(20, "Gi\u1EDD");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(21, "input", 10);
            i0.ɵɵtemplate(22, ShareFileDialogComponent_mat_error_22_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(23, "mat-form-field", 7)(24, "mat-label");
            i0.ɵɵtext(25, "Ph\u00FAt");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(26, "input", 11);
            i0.ɵɵtemplate(27, ShareFileDialogComponent_mat_error_27_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(28, "div", 12)(29, "button", 13);
            i0.ɵɵlistener("click", function ShareFileDialogComponent_Template_button_click_29_listener() { return ctx.create_link(); });
            i0.ɵɵtext(30, "T\u1EA1o Link");
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(31, "div")(32, "mat-form-field", 14)(33, "input", 15);
            i0.ɵɵlistener("ngModelChange", function ShareFileDialogComponent_Template_input_ngModelChange_33_listener($event) { return ctx.sharedLink = $event; });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(34, "a", 16)(35, "mat-icon");
            i0.ɵɵtext(36, "content_copy");
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(37, "div", 17);
            i0.ɵɵelement(38, "div", 18);
            i0.ɵɵelementStart(39, "div", 19)(40, "button", 20);
            i0.ɵɵtext(41, "\u0110\u00F3ng");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(6);
            i0.ɵɵclassMap(!ctx.sharedLink ? "d-block" : "d-none");
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(11);
            i0.ɵɵproperty("ngIf", ctx.form.controls["day"].invalid);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngIf", ctx.form.controls["hour"].invalid);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngIf", ctx.form.controls["minus"].invalid);
            i0.ɵɵadvance(4);
            i0.ɵɵclassMap(ctx.sharedLink ? "d-block" : "d-none");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngModel", ctx.sharedLink);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("cdkCopyToClipboard", ctx.sharedLink);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("mat-dialog-close", true);
        } }, dependencies: [i7.NgIf, i8.ɵNgNoValidate, i8.DefaultValueAccessor, i8.NumberValueAccessor, i8.NgControlStatus, i8.NgControlStatusGroup, i8.NgModel, i9.MatButton, i9.MatIconAnchor, i10.MatIcon, i3.MatDialogClose, i8.FormGroupDirective, i8.FormControlName, i11.MatFormField, i11.MatLabel, i11.MatError, i11.MatSuffix, i12.MatCardContent, i12.MatCardHeader, i12.MatCardTitle, i13.CdkCopyToClipboard, i14.MatInput] }); }
}

import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@angular/router";
import * as i3 from "../../services/member-report.service";
export class MemberReportComponent {
    constructor(config, router, memberReportService) {
        this.config = config;
        this.router = router;
        this.memberReportService = memberReportService;
        this.onHoldTasks = 0;
        this.completedTasks = 0;
        this.missedTasks = 0;
        this.totalTasks = 0;
        this.isChecked = false;
        this.tasks = this.memberReportService.getMemberReport();
    }
    ngOnInit() {
        this.tasks.subscribe({
            next: (result) => {
                this.totalTasks = result.on_hold_task;
                this.completedTasks = result.finished_task;
                this.missedTasks = result.missed_task;
                this.onHoldTasks = result.todo_task;
            }
        });
    }
    static { this.ɵfac = function MemberReportComponent_Factory(t) { return new (t || MemberReportComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i3.MemberReportService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: MemberReportComponent, selectors: [["app-member-report"]], decls: 30, vars: 4, consts: [[1, "p-1", "p-md-4", "min-vh-100"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-row", "align-items-center"], [1, "me-3", 2, "font-weight", "500", "font-size", "24px"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "flex-md-row"], [1, "m-3", "d-flex", "flex-column", "flex-md-row", "justify-content-between"], [2, "flex", "1 1 auto", "text-align", "center"], [1, "border-left", 2, "flex", "1 1 auto", "text-align", "center"], [1, "row", "m-3"]], template: function MemberReportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "span", 3);
            i0.ɵɵtext(4, "My Report");
            i0.ɵɵelementEnd()();
            i0.ɵɵelement(5, "div", 4)(6, "div", 5);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(7, "div", 6)(8, "div", 7)(9, "h1");
            i0.ɵɵtext(10);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "label");
            i0.ɵɵtext(12, "On hold Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(13, "div", 8)(14, "h1");
            i0.ɵɵtext(15);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(16, "label");
            i0.ɵɵtext(17, "Completed Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(18, "div", 8)(19, "h1");
            i0.ɵɵtext(20);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(21, "label");
            i0.ɵɵtext(22, "Missed Tasks");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(23, "div", 8)(24, "h1");
            i0.ɵɵtext(25);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(26, "label");
            i0.ɵɵtext(27, "Total Tasks");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelement(28, "div", 9)(29, "div", 9);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(10);
            i0.ɵɵtextInterpolate(ctx.onHoldTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.completedTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.missedTasks);
            i0.ɵɵadvance(5);
            i0.ɵɵtextInterpolate(ctx.totalTasks);
        } }, styles: ["mat-grid-tile[_ngcontent-%COMP%] {\n  background: rgb(240, 242, 243);\n}\n\n.mycontent-left[_ngcontent-%COMP%] {\n  border-right: 1px dashed #333;\n}"] }); }
}

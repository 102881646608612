import { CommonModule } from '@angular/common';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { reducer, SnackbarEffects, snackbarFeatureKey } from "../snackbar";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class SnackbarModule {
    static { this.ɵfac = function SnackbarModule_Factory(t) { return new (t || SnackbarModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SnackbarModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            StoreModule.forFeature(snackbarFeatureKey, reducer),
            EffectsModule.forFeature([SnackbarEffects])] }); }
}

import moment from 'moment';
import * as i0 from "@angular/core";
export class SubtractPipe {
    transform(value, amount, unit) {
        if (typeof amount === 'undefined' ||
            (typeof amount === 'number' && typeof unit === 'undefined')) {
            throw new Error('SubtractPipe: missing required arguments');
        }
        return moment(value).subtract(amount, unit);
    }
    static { this.ɵfac = function SubtractPipe_Factory(t) { return new (t || SubtractPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amSubtract", type: SubtractPipe, pure: true }); }
}

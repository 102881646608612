import * as i0 from "@angular/core";
export class SmsApinamePipe {
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    transform(value) {
        let stringArray = [];
        let name = value;
        if (value.indexOf('_')) {
            stringArray = value.split('_');
            for (const word in stringArray) {
                stringArray[word] = this.capitalizeFirstLetter(stringArray[word]);
            }
            name = stringArray.join(' ');
        }
        return name;
    }
    static { this.ɵfac = function SmsApinamePipe_Factory(t) { return new (t || SmsApinamePipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "smsApiname", type: SmsApinamePipe, pure: true }); }
}

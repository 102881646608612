import { A11yModule } from "@angular/cdk/a11y";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeEn from '@angular/common/locales/en';
import localeVi from '@angular/common/locales/vi';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { EffectsModule } from "@ngrx/effects";
import { CalendarDateFormatter, CalendarModule, DateAdapter as _DateAdapter, MOMENT } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxEchartsModule } from "ngx-echarts";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { AppComponent } from './app.component';
import { ContextMenuModule } from "./components/ngx-contextmenu/ngx-contextmenu";
import { Config } from "./configs/config";
import { DirectivesModule } from "./modules/directives/directives.module";
import { DotlottiePlayerModule } from "./modules/dotlottie-player/dotlottie-player.module";
import { GanttModule } from "./modules/gantt/gantt.module";
import { KanbanModule } from "./modules/kanban/kanban.module";
import { InfiniteScrollModule } from "./modules/ngx-infinite-scroll/lib/ngx-infinite-scroll.module";
import { MomentModule } from "./modules/ngx-moment";
import { NotifierModule } from "./modules/notifier";
import { AppRoutingModule } from "./modules/router/app-routing.module";
import { CustomReuseStrategyModule } from "./modules/router/custom-reuse-strategy.module";
import { SnackbarEffects } from "./modules/stores/snackbar";
import { AppStoreModule } from "./modules/stores/store.module";
import { CustomDateFormatter } from "./pages/channel-publishing-page/CustomDateFormatter";
import { CheckForUpdateService } from "./services/check-for-update.service";
import { HandleUnrecoverableStateService } from "./services/handle-unrecoverable-state.service";
import { LogUpdateService } from "./services/log-update.service";
import { PromptUpdateService } from "./services/prompt-update.service";
import { PushNotificationService } from "./services/push-notification.service";
import { SocketioService } from "./services/socketio.service";
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AppConfig } from "./types/AppConfig";
import * as i0 from "@angular/core";
import * as i1 from "ngx-google-analytics";
import * as i2 from "@ngrx/effects";
import * as i3 from "ngx-echarts";
import * as i4 from "./modules/notifier/lib/notifier.module";
import * as i5 from "angular-calendar";
import * as i6 from "./components/ngx-contextmenu/ngx-contextmenu";
import * as i7 from "@angular/service-worker";
registerLocaleData(localeVi, localeEn);
export function momentAdapterFactory() {
    return adapterFactory(moment);
}
export const MY_FORMATS = {
    display: {
        dateA11yLabel: 'LL',
        dateInput: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
};
export class AppModule {
    static { this.ɵfac = function AppModule_Factory(t) { return new (t || AppModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppModule, bootstrap: [AppComponent] }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            PushNotificationService,
            { provide: RouteReuseStrategy, useClass: CustomReuseStrategyModule },
            CheckForUpdateService,
            LogUpdateService,
            PromptUpdateService,
            HandleUnrecoverableStateService,
            CookieService,
            SocketioService,
            JwtHelperService,
            { provide: AppConfig, useValue: Config },
            { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
            { multi: true, provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService },
            /**
             * DATE FORMAT
             */
            { deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS], provide: DateAdapter, useClass: MomentDateAdapter },
            { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
            { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
            { provide: MOMENT, useValue: moment },
            /**
             * LOCALE
             */
            { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        ], imports: [NgxGoogleAnalyticsModule.forRoot('G-LYY3MST1PZ'),
            NgxGoogleAnalyticsRouterModule,
            EffectsModule.forRoot([
                SnackbarEffects,
            ]),
            MatSnackBarModule,
            HttpClientModule,
            BrowserModule,
            BrowserAnimationsModule,
            FormsModule,
            DotlottiePlayerModule,
            AppRoutingModule,
            AppStoreModule,
            MatButtonModule,
            MatIconModule,
            MatMenuModule,
            MatToolbarModule,
            MatDialogModule,
            A11yModule,
            ReactiveFormsModule,
            MatDatepickerModule,
            MatSelectModule,
            NgxDropzoneModule,
            MatSidenavModule,
            MatListModule,
            InfiniteScrollModule,
            MomentModule,
            MatPaginatorModule,
            MatCardModule,
            ClipboardModule,
            NgxEchartsModule.forRoot({
                echarts: () => import('echarts'),
            }),
            MatTreeModule,
            MatButtonToggleModule,
            KanbanModule,
            GanttModule,
            MatFormFieldModule,
            MatInputModule,
            MatMomentDateModule,
            MatTooltipModule,
            DirectivesModule,
            NotifierModule.withConfig({ animations: { hide: { easing: 'ease-in-out', preset: 'slide' }, show: { easing: 'ease-in-out' } }, behaviour: { autoHide: 3000, showDismissButton: true, stacking: 4 }, position: { horizontal: { position: 'middle' }, vertical: { position: "bottom" } }, theme: 'material' }),
            MatSlideToggleModule,
            DragDropModule,
            OwlDateTimeModule,
            OwlNativeDateTimeModule,
            CalendarModule.forRoot({ provide: _DateAdapter, useFactory: momentAdapterFactory }, { dateFormatter: { provide: CalendarDateFormatter, useClass: CustomDateFormatter } }),
            ContextMenuModule.forRoot({ useBootstrap4: true }),
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: Config.production,
                // enabled: true,
                // Register the ServiceWorker as soon as the application is stable
                // or after 5 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:5000'
            }),
            MatCheckboxModule] }); }
}

import { fromAuth, fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "ngx-cookie-service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/material/snack-bar";
export class OAuthSuccessComponent {
    constructor(config, $gaService, cookieService, store, snackBar) {
        this.config = config;
        this.$gaService = $gaService;
        this.cookieService = cookieService;
        this.store = store;
        this.snackBar = snackBar;
        this.cookieValue = '';
        this.device = '';
    }
    ngOnInit() {
        if (this.config.ga) {
            this.$gaService.event('auth_oauth', 'auth_manager', 'Signin or Signup via OAuth');
        }
        // read cookie
        this.cookieValue = this.cookieService.get('x-access-token');
        this.device = this.cookieService.get('c_id');
        // delete cookie
        // this.cookieService.delete('x-access-token', '/', 'tuesday.baby', true, 'Strict');
        this.store.dispatch(fromSnackbar.showSnackbar({
            action: 'Ok',
            config: { duration: 5000, panelClass: "success-dialog" },
            message: 'Singin successfully'
        }));
        // store token in store
        this.store.dispatch(fromAuth.signinSuccess({ device: this.device, token: this.cookieValue }));
    }
    static { this.ɵfac = function OAuthSuccessComponent_Factory(t) { return new (t || OAuthSuccessComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.CookieService), i0.ɵɵdirectiveInject(i4.Store), i0.ɵɵdirectiveInject(i5.MatSnackBar)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: OAuthSuccessComponent, selectors: [["app-o-auth-success"]], decls: 2, vars: 0, template: function OAuthSuccessComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "p");
            i0.ɵɵtext(1, "o-auth-success works!");
            i0.ɵɵelementEnd();
        } } }); }
}

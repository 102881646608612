import * as i0 from "@angular/core";
const defaultPrecisionMap = { GB: 1, KB: 0, MB: 1, PB: 2, TB: 2, bytes: 0 };
/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that can be a number or a map for each unit.
 * Usage:
 *   bytes | fileSize:precision
 * @example
 * // returns 1 KB
 * {{ 1500 | fileSize }}
 * @example
 * // returns 2.1 GB
 * {{ 2100000000 | fileSize }}
 * @example
 * // returns 1.46 KB
 * {{ 1500 | fileSize:2 }}
 */
export class FileSizePipe {
    constructor() {
        this.units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    }
    transform(bytes = 0, precision = defaultPrecisionMap) {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes))
            return '?';
        let unitIndex = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }
        const unit = this.units[unitIndex];
        if (typeof precision === 'number') {
            return `${bytes.toFixed(+precision)} ${unit}`;
        }
        return `${bytes.toFixed(precision[unit])} ${unit}`;
    }
    static { this.ɵfac = function FileSizePipe_Factory(t) { return new (t || FileSizePipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "fileSize", type: FileSizePipe, pure: true }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./modules/notifier/lib/components/notifier-container.component";
export class AppComponent {
    constructor() {
        this.title = '[TueSday] - Bôi trơn workflow của bạn';
    }
    static { this.ɵfac = function AppComponent_Factory(t) { return new (t || AppComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["app-root"]], decls: 3, vars: 0, consts: [[1, "container-fluid", "g-0"]], template: function AppComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵelement(1, "router-outlet");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(2, "notifier-container");
        } }, dependencies: [i1.RouterOutlet, i2.NotifierContainerComponent], styles: ["[_nghost-%COMP%] {\n  font-family: var(--bs-font-sans-serif);\n  font-size: 14px;\n  color: #333;\n  box-sizing: border-box;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}"] }); }
}

import * as moment from "moment";
import { lastValueFrom } from "rxjs";
import { EditWorkspaceDialogComponent } from "../../components/edit-workspace-dialog/edit-workspace-dialog.component";
import { authFeatureKey } from "../../modules/stores/auth";
import { fromAuth, fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "../../services/auth.service";
import * as i6 from "../../services/workspace.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/material/menu";
import * as i11 from "../../modules/directives/default-image.directive";
import * as i12 from "../../utils/pipes/AuthImagePipe";
function ChooseWorkspacePageComponent_ng_container_14_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 13);
    i0.ɵɵlistener("click", function ChooseWorkspacePageComponent_ng_container_14_Template_div_click_1_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r6); const workspace_r2 = restoredCtx.$implicit; const ctx_r5 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r5.workSpaceClick($event, workspace_r2)); });
    i0.ɵɵelementStart(2, "div", 14)(3, "div", 15);
    i0.ɵɵelement(4, "img", 16);
    i0.ɵɵpipe(5, "async");
    i0.ɵɵpipe(6, "authenticate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 17)(8, "p", 18);
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "p", 19);
    i0.ɵɵtext(11, "Last open: 5 hour ago");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(12, "button", 20)(13, "mat-icon");
    i0.ɵɵtext(14, "menu");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(15, "mat-menu", null, 21)(17, "button", 22);
    i0.ɵɵlistener("click", function ChooseWorkspacePageComponent_ng_container_14_Template_button_click_17_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r6); const workspace_r2 = restoredCtx.$implicit; const ctx_r7 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r7.onEditClick($event, workspace_r2)); });
    i0.ɵɵtext(18, "Edit");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const workspace_r2 = ctx.$implicit;
    const _r4 = i0.ɵɵreference(16);
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("src", i0.ɵɵpipeBind1(5, 3, i0.ɵɵpipeBind1(6, 5, workspace_r2.cover)), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(workspace_r2.name);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("matMenuTriggerFor", _r4);
} }
function ChooseWorkspacePageComponent_ng_container_24_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 13);
    i0.ɵɵlistener("click", function ChooseWorkspacePageComponent_ng_container_24_Template_div_click_1_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r11); const workspace_r8 = restoredCtx.$implicit; const ctx_r10 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r10.workSpaceClick($event, workspace_r8)); });
    i0.ɵɵelementStart(2, "div", 23)(3, "div", 24);
    i0.ɵɵelement(4, "img", 16);
    i0.ɵɵpipe(5, "async");
    i0.ɵɵpipe(6, "authenticate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "div", 17)(8, "p", 18);
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(10, "p", 19);
    i0.ɵɵtext(11, "Last open: 5 hour ago");
    i0.ɵɵelementEnd()()()();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const workspace_r8 = ctx.$implicit;
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("src", i0.ɵɵpipeBind1(5, 2, i0.ɵɵpipeBind1(6, 4, workspace_r8.cover)), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(workspace_r8.name);
} }
export class ChooseWorkspacePageComponent {
    constructor(router, route, store, dialog, overlay, authService, workspaceService) {
        this.router = router;
        this.route = route;
        this.store = store;
        this.dialog = dialog;
        this.overlay = overlay;
        this.authService = authService;
        this.workspaceService = workspaceService;
        this.force = false;
        this.force = this.route.snapshot.queryParamMap.get('force') === 'true' || false;
        this.authState = this.store.select(authFeatureKey);
        const currentHour = parseInt(moment().format('HH'));
        this.dayTime = currentHour < 12 ? 'Morning' : currentHour <= 17 ? 'Afternoon' : 'Evening';
        this.workspaces = workspaceService.listMyWorkspace();
    }
    ngOnInit() {
        this.authState.subscribe(async (result) => {
            if (result?.me?.workspace && !this.force) {
                await this.router.navigate(["/"]);
            }
        });
    }
    workSpaceClick($event, workspace) {
        const menu = $event.target.closest('.mat-mdc-icon-button');
        if (!menu) {
            this.store.dispatch(fromAuth.setWorkspace({ workspace: workspace._id }));
        }
    }
    async onEditClick($event, workspace) {
        try {
            const scrollStrategy = this.overlay.scrollStrategies.reposition();
            if (!this.dialogRef_edit) {
                this.dialogRef_edit = await this.dialog.open(EditWorkspaceDialogComponent, { data: { workspace }, disableClose: true, hasBackdrop: true, maxWidth: "100%", scrollStrategy, width: "400px" });
                const data = await lastValueFrom(this.dialogRef_edit.afterClosed());
                if (!data)
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Cập nhật workspace không thành công" }));
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "success-dialog" }, message: "Cập nhật workspace thành công" }));
                this.workspaces = this.workspaceService.listMyWorkspace();
                this.dialogRef_edit = undefined;
            }
        }
        catch (err) {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
        }
    }
    static { this.ɵfac = function ChooseWorkspacePageComponent_Factory(t) { return new (t || ChooseWorkspacePageComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.MatDialog), i0.ɵɵdirectiveInject(i4.Overlay), i0.ɵɵdirectiveInject(i5.AuthService), i0.ɵɵdirectiveInject(i6.WorkspaceService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChooseWorkspacePageComponent, selectors: [["app-choose-workspace-page"]], decls: 26, vars: 10, consts: [[1, "py-3", "px-1", "px-md-5"], [1, "row", "g-0"], [1, "=col-12"], [1, "text-center", 2, "font-weight", "700"], [1, "row", "g-0", "mt-4"], [1, "col-12"], [1, "d-flex", 2, "width", "fit-content", "background", "#f2f2f2", "border-radius", "5px", "-webkit-border-radius", "5px", "-moz-border-radius", "5px"], [2, "width", "10px", "background", "#8817F8", "border-radius", "5px", "-webkit-border-radius", "5px", "-moz-border-radius", "5px"], [2, "font-size", "1rem", "font-weight", "600", "padding", "10px 20px"], [1, "col-12", "mt-4"], [1, "row"], [4, "ngFor", "ngForOf"], [1, "row", "g-0", "mt-5"], [1, "col-12", "col-md-3", 3, "click"], [1, "card", "workspace-card", 2, "background", "#f2f2f2", "border", "none", "border-radius", "5px", "padding", "10px 30px"], [1, "d-flex", "flex-row", "align-self-center", "px-1"], [1, "img-fluid", 2, "border-radius", "5px", 3, "src"], [1, "d-flex", "flex-column", "mt-3"], [1, "text-center", 2, "font-size", "1.25rem", "font-weight", "600"], [2, "font-size", ".625rem", "font-weight", "100", "color", "#a8a8a8", "text-align", "end", "padding", "0", "margin", "0", "font-style", "italic"], ["mat-icon-button", "", 2, "position", "absolute", "right", "0", 3, "matMenuTriggerFor"], ["menu", "matMenu"], ["mat-menu-item", "", 3, "click"], [1, "card", "workspace-card", "shared"], [1, "d-flex", "flex-row", "align-self-center"]], template: function ChooseWorkspacePageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "h2", 3);
            i0.ɵɵtext(4);
            i0.ɵɵpipe(5, "async");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(6, "div", 4)(7, "div", 5)(8, "div", 6);
            i0.ɵɵelement(9, "div", 7);
            i0.ɵɵelementStart(10, "span", 8);
            i0.ɵɵtext(11, "Your workspace");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(12, "div", 9)(13, "div", 10);
            i0.ɵɵtemplate(14, ChooseWorkspacePageComponent_ng_container_14_Template, 19, 7, "ng-container", 11);
            i0.ɵɵpipe(15, "async");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(16, "div", 12)(17, "div", 5)(18, "div", 6);
            i0.ɵɵelement(19, "div", 7);
            i0.ɵɵelementStart(20, "span", 8);
            i0.ɵɵtext(21, "Shared with you");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(22, "div", 9)(23, "div", 10);
            i0.ɵɵtemplate(24, ChooseWorkspacePageComponent_ng_container_24_Template, 12, 6, "ng-container", 11);
            i0.ɵɵpipe(25, "async");
            i0.ɵɵelementEnd()()()();
        } if (rf & 2) {
            let tmp_0_0;
            let tmp_1_0;
            let tmp_2_0;
            i0.ɵɵadvance(4);
            i0.ɵɵtextInterpolate2("Good ", ctx.dayTime, ", ", (tmp_0_0 = i0.ɵɵpipeBind1(5, 4, ctx.authState)) == null ? null : tmp_0_0.me == null ? null : tmp_0_0.me.name, "");
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngForOf", (tmp_1_0 = i0.ɵɵpipeBind1(15, 6, ctx.workspaces)) == null ? null : tmp_1_0.my);
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngForOf", (tmp_2_0 = i0.ɵɵpipeBind1(25, 8, ctx.workspaces)) == null ? null : tmp_2_0.shared);
        } }, dependencies: [i7.NgForOf, i8.MatIconButton, i9.MatIcon, i10.MatMenu, i10.MatMenuItem, i10.MatMenuTrigger, i11.DefaultImageDirective, i7.AsyncPipe, i12.AuthImagePipe], styles: ["[_nghost-%COMP%]  .workspace-card:not(:first-child) {\n  height: 100%;\n}\n[_nghost-%COMP%]  .workspace-card {\n  background: #f2f2f2;\n  padding: 10px 30px;\n  border: none;\n  border-radius: 5px;\n  -webkit-border-radius: 5px;\n  -moz-border-radius: 5px;\n  height: 100%;\n}\n[_nghost-%COMP%]  .workspace-card.shared {\n  padding: 10px;\n}\n[_nghost-%COMP%]  .workspace-card:not(:first-child) {\n  margin-left: 10px !important;\n}\n[_nghost-%COMP%]  .workspace-card > img:first-child {\n  border-radius: 5px;\n}\n[_nghost-%COMP%]  .workspace-card .workspace-card-title {\n  font-size: 1.25rem;\n  font-weight: 600;\n}\n[_nghost-%COMP%]  .workspace-card .workspace-card-subtitle {\n  font-size: 0.625rem;\n  font-weight: 100;\n  color: #a8a8a8;\n  text-align: end;\n  padding: 0;\n  margin: 0;\n  font-style: italic;\n}\n[_nghost-%COMP%]  .workspace-card .workspace-card-menu {\n  position: absolute;\n  right: 0;\n}"] }); }
}

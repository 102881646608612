import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class ActionLoggingService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    getLatestLog(data) {
        const url = `${this.config.appUrl}/actionLog/getLatestActionLog`;
        return this.http.post(url, data);
    }
    getFullLog(data) {
        const url = `${this.config.appUrl}/actionLog/getFullActionLog`;
        return this.http.post(url, data);
    }
    process_data(actionList) {
        for (const action of actionList) {
            if (action.type === 'project' && action.tblProject.length > 0) {
                action.tblProject.forEach((x) => {
                    if (x.name) {
                        action.targetName = x.name;
                    }
                });
                action.routerLink = `/project/${action.target[0]._id}`;
            }
            else if (action.type === 'workspace' && action.tblWorkspace.length > 0) {
                action.tblWorkspace.forEach((x) => {
                    if (x.name) {
                        action.targetName = x.name;
                    }
                });
                action.routerLink = `/workspaces/`;
            }
            else if (action.type === 'publishing' && action.target.length > 0) {
                action.target.forEach((x) => {
                    if (x.title) {
                        action.targetName = x.title;
                    }
                    if (x.workspace) {
                        action.routerLink = `/workspaces/`;
                    }
                });
            }
        }
        return actionList;
    }
    static { this.ɵfac = function ActionLoggingService_Factory(t) { return new (t || ActionLoggingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ActionLoggingService, factory: ActionLoggingService.ɵfac, providedIn: 'root' }); }
}

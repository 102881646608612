import { lastValueFrom } from "rxjs";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "src/app/services/workspace.service";
import * as i4 from "../../types/AppConfig";
import * as i5 from "ngx-google-analytics";
export class AcceptInviteComponent {
    constructor(store, router, route, workspaceService, config, $gaService) {
        this.store = store;
        this.router = router;
        this.route = route;
        this.workspaceService = workspaceService;
        this.config = config;
        this.$gaService = $gaService;
    }
    ngOnInit() {
        (async () => {
            try {
                const code = this.route.snapshot.queryParamMap.get('code');
                const result = await lastValueFrom(this.workspaceService.acceptInvite({ code }));
                if (this.config.ga)
                    this.$gaService.event("accept-invite", 'auth', 'accept-invite');
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Tham gia nhóm thành công" }));
                await this.router.navigate(['/']);
            }
            catch (e) {
                console.log(e);
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "" + e }));
                await this.router.navigate(['/']);
            }
        })();
    }
    static { this.ɵfac = function AcceptInviteComponent_Factory(t) { return new (t || AcceptInviteComponent)(i0.ɵɵdirectiveInject(i1.Store), i0.ɵɵdirectiveInject(i2.Router), i0.ɵɵdirectiveInject(i2.ActivatedRoute), i0.ɵɵdirectiveInject(i3.WorkspaceService), i0.ɵɵdirectiveInject(i4.AppConfig, 8), i0.ɵɵdirectiveInject(i5.GoogleAnalyticsService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AcceptInviteComponent, selectors: [["app-accept-invite"]], decls: 2, vars: 0, template: function AcceptInviteComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "p");
            i0.ɵɵtext(1, "accept-invite works!");
            i0.ɵɵelementEnd();
        } } }); }
}

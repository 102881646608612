import { formatDate } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { CalendarEventTitleFormatter } from 'angular-calendar';
import * as i0 from "@angular/core";
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    constructor(locale) {
        super();
        this.locale = locale;
    }
    month(event) {
        return `<b>${formatDate(event.start, 'h:m a', this.locale)}</b> ${event.title}`;
    }
    week(event) {
        return `<b>${formatDate(event.start, 'h:m a', this.locale)}</b> ${event.title}`;
    }
    day(event) {
        return `<b>${formatDate(event.start, 'h:m a', this.locale)}</b> ${event.title}`;
    }
    static { this.ɵfac = function CustomEventTitleFormatter_Factory(t) { return new (t || CustomEventTitleFormatter)(i0.ɵɵinject(LOCALE_ID)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CustomEventTitleFormatter, factory: CustomEventTitleFormatter.ɵfac }); }
}

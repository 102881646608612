import { createAction, props } from "@ngrx/store";
export var AuthActionTypes;
(function (AuthActionTypes) {
    AuthActionTypes["INIT"] = "[Auth] Init Effect";
    AuthActionTypes["SIGNIN"] = "[Auth] Signin";
    AuthActionTypes["SIGNIN_SUCCESS"] = "[Auth] Signin Success";
    AuthActionTypes["SIGNIN_FAILURE"] = "[Auth] Signin Failure";
    AuthActionTypes["SIGNOUT"] = "[Auth] Signout";
    AuthActionTypes["SIGNOUT_SUCCESS"] = "[Auth] Signout Success";
    AuthActionTypes["SIGNOUT_FAILURE"] = "[Auth] Signout Failure";
    AuthActionTypes["SIGNUP"] = "[Auth] Signup";
    AuthActionTypes["SIGNUP_SUCCESS"] = "[Auth] Signup Success";
    AuthActionTypes["SIGNUP_FAILURE"] = "[Auth] Signup Failure";
    AuthActionTypes["GET_ME"] = "[Auth] Get Me";
    AuthActionTypes["GET_ME_SUCCESS"] = "[Auth] Get Me Success";
    AuthActionTypes["GET_ME_FAILURE"] = "[Auth] Get Me Failure";
    AuthActionTypes["SET_WORKSPACE"] = "[Auth] Set Workspace";
    AuthActionTypes["SET_WORKSPACE_SUCCESS"] = "[Auth] Set Workspace Success";
    AuthActionTypes["SET_WORKSPACE_FAILURE"] = "[Auth] Set Workspace Failure";
    AuthActionTypes["OAUTH2_SIGNIN_SUCCESS"] = "[Auth] OAuth Login Success";
    AuthActionTypes["GUEST_SIGNIN"] = "[guestAuth] Signin";
    AuthActionTypes["GUEST_SIGNIN_SUCCESS"] = "[guestAuth] Signin Success";
    AuthActionTypes["GUEST_SIGNIN_FAILURE"] = "[guestAuth] Signin Failure";
    AuthActionTypes["GUEST_SIGNOUT"] = "[guestAuth] Signout";
    AuthActionTypes["GUEST_SIGNOUT_SUCCESS"] = "[guestAuth] Signout Success";
    AuthActionTypes["GUEST_SIGNOUT_FAILURE"] = "[guestAuth] Signout Failure";
})(AuthActionTypes || (AuthActionTypes = {}));
export const init = createAction(AuthActionTypes.INIT);
export const signin = createAction(AuthActionTypes.SIGNIN, props());
export const signinSuccess = createAction(AuthActionTypes.SIGNIN_SUCCESS, props());
export const oAuth2SigninSuccess = createAction(AuthActionTypes.OAUTH2_SIGNIN_SUCCESS, props());
export const signinFailure = createAction(AuthActionTypes.SIGNIN_FAILURE, props());
export const signout = createAction(AuthActionTypes.SIGNOUT);
export const signoutFailure = createAction(AuthActionTypes.SIGNOUT_FAILURE);
export const signoutSuccess = createAction(AuthActionTypes.SIGNOUT_SUCCESS);
export const signup = createAction(AuthActionTypes.SIGNUP, props());
export const signupSuccess = createAction(AuthActionTypes.SIGNUP_SUCCESS, props());
export const signupFailure = createAction(AuthActionTypes.SIGNUP_FAILURE, props());
export const getMe = createAction(AuthActionTypes.GET_ME);
export const getMeSuccess = createAction(AuthActionTypes.GET_ME_SUCCESS, props());
export const getMeFailure = createAction(AuthActionTypes.GET_ME_FAILURE, props());
export const setWorkspace = createAction(AuthActionTypes.SET_WORKSPACE, props());
export const setWorkspaceSuccess = createAction(AuthActionTypes.SET_WORKSPACE_SUCCESS, props());
export const setWorkspaceFailure = createAction(AuthActionTypes.SET_WORKSPACE_FAILURE, props());
export const guestSignin = createAction(AuthActionTypes.GUEST_SIGNIN, props());
export const guestSigninSuccess = createAction(AuthActionTypes.GUEST_SIGNIN_SUCCESS, props());
export const guestSigninFailure = createAction(AuthActionTypes.GUEST_SIGNIN_FAILURE, props());
export const guestSignOut = createAction(AuthActionTypes.GUEST_SIGNOUT);
export const guestSignOutSuccess = createAction(AuthActionTypes.GUEST_SIGNOUT_SUCCESS);
export const guestSignOutFailure = createAction(AuthActionTypes.GUEST_SIGNOUT_FAILURE);

import { formatDate } from '@angular/common';
import { CalendarDateFormatter } from 'angular-calendar';
import * as i0 from "@angular/core";
export class CustomDateFormatter extends CalendarDateFormatter {
    // you can override any of the methods defined in the parent class
    monthViewColumnHeader({ date, locale }) {
        return formatDate(date, 'EEE', locale || 'en-US');
    }
    monthViewTitle({ date, locale }) {
        return formatDate(date, 'MMM y', locale || 'en-US');
    }
    weekViewColumnHeader({ date, locale }) {
        return formatDate(date, 'EEE', locale || 'en-US');
    }
    dayViewHour({ date, locale }) {
        return formatDate(date, 'HH:mm', locale || 'en-US');
    }
    static { this.ɵfac = /*@__PURE__*/ function () { let ɵCustomDateFormatter_BaseFactory; return function CustomDateFormatter_Factory(t) { return (ɵCustomDateFormatter_BaseFactory || (ɵCustomDateFormatter_BaseFactory = i0.ɵɵgetInheritedFactory(CustomDateFormatter)))(t || CustomDateFormatter); }; }(); }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CustomDateFormatter, factory: CustomDateFormatter.ɵfac }); }
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class ProjectsService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    createEmpty(data) {
        const url = `${this.config.appUrl}/project/create-project`;
        return this.http.post(url, data);
    }
    create(data) {
        const url = `${this.config.appUrl}/project/create-project-with-template`;
        return this.http.post(url, data);
    }
    update(data) {
        const url = `${this.config.appUrl}/project/update-project`;
        return this.http.post(url, data);
    }
    delete(id) {
        const url = `${this.config.appUrl}/project/${id}`;
        return this.http.delete(url);
    }
    find(data) {
        const url = `${this.config.appUrl}/project/find`;
        return this.http.post(url, data);
    }
    get(id) {
        const url = `${this.config.appUrl}/project/${id}`;
        return this.http.get(url);
    }
    checkBeforeUpdateCard(data) {
        const url = `${this.config.appUrl}/project/check-before-update-card`;
        return this.http.post(url, data);
    }
    updateCard(data) {
        const url = `${this.config.appUrl}/project/update-card`;
        return this.http.post(url, data);
    }
    deleteCard(data) {
        const url = `${this.config.appUrl}/project/delete-card`;
        return this.http.post(url, data);
    }
    addCard(data) {
        const url = `${this.config.appUrl}/project/add-card`;
        return this.http.post(url, data);
    }
    addRow(data) {
        const url = `${this.config.appUrl}/project/add-row`;
        return this.http.post(url, data);
    }
    addColumn(data) {
        const url = `${this.config.appUrl}/project/add-column`;
        return this.http.post(url, data);
    }
    updateRow(data) {
        const url = `${this.config.appUrl}/project/update-row`;
        return this.http.post(url, data);
    }
    updateColumn(data) {
        const url = `${this.config.appUrl}/project/update-column`;
        return this.http.post(url, data);
    }
    deleteColumn(data) {
        const url = `${this.config.appUrl}/project/delete-column`;
        return this.http.post(url, data);
    }
    dragDropColumn(data) {
        const url = `${this.config.appUrl}/project/dragdrop-column`;
        return this.http.post(url, data);
    }
    deleteRow(data) {
        const url = `${this.config.appUrl}/project/delete-row`;
        return this.http.post(url, data);
    }
    dragDropRow(data) {
        const url = `${this.config.appUrl}/project/dragdrop-row`;
        return this.http.post(url, data);
    }
    dragDropCard(data) {
        const url = `${this.config.appUrl}/project/dragdrop-card`;
        return this.http.post(url, data);
    }
    shareProject(data) {
        const url = `${this.config.appUrl}/guest/share-project`;
        return this.http.post(url, data);
    }
    getWorkGroup(id) {
        const url = `${this.config.appUrl}/project/get-workgroup/${id}`;
        return this.http.get(url);
    }
    updateWorkGroup(data) {
        const url = `${this.config.appUrl}/project/update-workgroup`;
        return this.http.post(url, data);
    }
    updateLink(data) {
        const url = `${this.config.appUrl}/project/update-link`;
        return this.http.post(url, data);
    }
    deleteLink(data) {
        const url = `${this.config.appUrl}/project/delete-link`;
        return this.http.post(url, data);
    }
    addLink(data) {
        const url = `${this.config.appUrl}/project/add-link`;
        return this.http.post(url, data);
    }
    publicTemplate(data) {
        const url = `${this.config.appUrl}/project/public-template`;
        return this.http.post(url, data);
    }
    getToDoList() {
        const url = `${this.config.appUrl}/project/todo/list`;
        return this.http.get(url);
    }
    static { this.ɵfac = function ProjectsService_Factory(t) { return new (t || ProjectsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ProjectsService, factory: ProjectsService.ɵfac, providedIn: 'root' }); }
}

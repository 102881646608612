import * as i0 from "@angular/core";
import * as i1 from "./contextMenu.service";
export class ContextMenuAttachDirective {
    constructor(contextMenuService) {
        this.contextMenuService = contextMenuService;
    }
    onContextMenu(event) {
        if (!this.contextMenu?.disabled) {
            this.contextMenuService.show.next({
                contextMenu: this.contextMenu,
                event,
                item: this.contextMenuSubject,
            });
            event.preventDefault();
            event.stopPropagation();
        }
    }
    static { this.ɵfac = function ContextMenuAttachDirective_Factory(t) { return new (t || ContextMenuAttachDirective)(i0.ɵɵdirectiveInject(i1.ContextMenuService)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ContextMenuAttachDirective, selectors: [["", "contextMenu", ""]], hostBindings: function ContextMenuAttachDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("contextmenu", function ContextMenuAttachDirective_contextmenu_HostBindingHandler($event) { return ctx.onContextMenu($event); });
        } }, inputs: { contextMenuSubject: "contextMenuSubject", contextMenu: "contextMenu" } }); }
}

import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "../../layouts/auth-layout/auth-layout.component";
import { ChooseWorkspaceLayoutComponent } from "../../layouts/choose-workspace-layout/choose-workspace-layout.component";
import { GuestAuthLayoutComponent } from "../../layouts/guest-auth-layout/guest-auth-layout.component";
import { GuestLayoutComponent } from "../../layouts/guest-layout/guest-layout.component";
import { UserLayoutComponent } from "../../layouts/user-layout/user-layout.component";
import { AcceptInviteComponent } from "../../pages/accept-invite/accept-invite.component";
import { AddChannelPageComponent } from "../../pages/add-channel-page/add-channel-page.component";
import { ChannelAnalyticsPageComponent } from "../../pages/channel-analytics-page/channel-analytics-page.component";
import { ChannelManagePageComponent } from "../../pages/channel-manage-page/channel-manage-page.component";
import { ChannelPublishingPageComponent } from "../../pages/channel-publishing-page/channel-publishing-page.component";
import { ChooseWorkspacePageComponent } from "../../pages/choose-workspace-page/choose-workspace-page.component";
import { FileManageComponent } from "../../pages/file-manage/file-manage.component";
import { ForgotPassComponent } from "../../pages/forgot-pass/forgot-pass.component";
import { GuestLoginComponent } from "../../pages/guest-login/guest-login.component";
import { GuestPasswordForgotComponent } from "../../pages/guest-password-forgot/guest-password-forgot.component";
import { GuestPasswordRecoverComponent } from "../../pages/guest-password-recover/guest-password-recover.component";
import { GuestReportComponent } from "../../pages/guest-report/guest-report.component";
import { HomePageComponent } from "../../pages/home-page/home-page.component";
import { MemberManageComponent } from "../../pages/member-manage/member-manage.component";
import { MemberReportComponent } from "../../pages/member-report/member-report.component";
import { NotFoundPageComponent } from "../../pages/not-found-page/not-found-page.component";
import { NotificationManagerComponent } from "../../pages/notification-manager/notification-manager.component";
import { OAuthSuccessComponent } from "../../pages/o-auth-success/o-auth-success.component";
import { ProjectGanttComponent } from "../../pages/project-gantt/project-gantt.component";
import { ProjectKanbanComponent } from "../../pages/project-kanban/project-kanban.component";
import { ProjectManageComponent } from "../../pages/project-manage/project-manage.component";
import { ResetPassComponent } from "../../pages/reset-pass/reset-pass.component";
import { SigninPageComponent } from "../../pages/signin-page/signin-page.component";
import { SignupPageComponent } from "../../pages/signup-page/signup-page.component";
import { WorkspaceReportComponent } from "../../pages/workspace-report/workspace-report.component";
import { WsMemberReportComponent } from "../../pages/ws-member-report/ws-member-report.component";
import { AuthGuardService as AuthGuard } from '../../services/auth-guard.service';
import { GuestAuthGuardService } from "../../services/guest-auth-guard.service";
import { PathResolveService } from "../../services/path-resolve.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const routes = [
    {
        children: [
            {
                component: SigninPageComponent,
                data: {
                    title: 'Đăng nhập'
                },
                path: '',
                pathMatch: 'full'
            },
            {
                component: SigninPageComponent,
                data: {
                    title: 'Đăng nhập'
                },
                path: 'signin'
            },
            {
                component: SignupPageComponent,
                data: {
                    title: 'Đăng ký'
                },
                path: 'signup'
            },
            {
                component: ForgotPassComponent,
                data: {
                    title: 'Quên mật khẩu'
                },
                path: 'forgot-pass'
            },
            {
                component: ResetPassComponent,
                data: {
                    title: 'Đổi mật khẩu'
                },
                path: 'reset'
            },
            {
                component: OAuthSuccessComponent,
                data: {
                    title: 'Đăng nhập thành công'
                },
                path: 'oauth-success'
            },
            {
                component: AcceptInviteComponent,
                data: {
                    title: 'Chấp nhận lời mời'
                },
                path: 'accept-invite'
            },
        ],
        component: AuthLayoutComponent,
        path: 'auth'
    },
    {
        children: [
            {
                component: ChooseWorkspacePageComponent,
                data: {
                    title: 'Workspace'
                },
                path: '',
                pathMatch: 'full'
            },
        ],
        component: ChooseWorkspaceLayoutComponent,
        path: 'workspace'
    },
    {
        children: [
            {
                component: GuestLoginComponent,
                data: {
                    title: 'Login'
                },
                path: '',
                pathMatch: 'full'
            },
            {
                component: GuestLoginComponent,
                data: {
                    title: "Login",
                },
                path: 'login'
            },
            {
                component: GuestPasswordRecoverComponent,
                data: {
                    title: "Password Recover"
                },
                path: 'recover-password'
            },
            {
                component: GuestPasswordForgotComponent,
                data: {
                    title: "Forgot Password"
                },
                path: 'forgot-password'
            }
        ],
        component: GuestAuthLayoutComponent,
        path: 'guest'
    },
    {
        canActivate: [GuestAuthGuardService],
        children: [
            {
                component: GuestReportComponent,
                data: {
                    title: 'Project Report'
                },
                path: '',
                pathMatch: 'full'
            },
            {
                component: GuestReportComponent,
                data: {
                    title: 'Project Report'
                },
                path: 'guest-report-view'
            }
        ],
        component: GuestLayoutComponent,
        path: 'guest-view'
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: HomePageComponent,
                data: {
                    title: 'Trang chủ'
                },
                path: '',
                pathMatch: 'full'
            },
            {
                component: HomePageComponent,
                data: {
                    title: 'Trang chủ'
                },
                path: 'home'
            },
            {
                children: [
                    {
                        component: ProjectManageComponent,
                        data: {
                            title: 'Quản lý dự án'
                        },
                        path: ''
                    },
                    {
                        component: ProjectKanbanComponent,
                        data: {
                            title: 'Dự án'
                        },
                        path: 'kanban/:id'
                    },
                    {
                        component: ProjectGanttComponent,
                        data: {
                            title: 'Dự án'
                        },
                        path: 'gantt/:id'
                    },
                ],
                path: 'projects'
            },
            {
                component: FileManageComponent,
                data: {
                    title: 'Quản lý file'
                },
                path: 'file-manage'
            },
            {
                children: [
                    {
                        component: ChannelManagePageComponent,
                        data: {
                            title: 'Quản lý kênh'
                        },
                        path: 'manage',
                        pathMatch: 'full'
                    },
                    {
                        component: AddChannelPageComponent,
                        data: {
                            title: 'Thêm mới kênh'
                        },
                        path: 'create/:action'
                    },
                    {
                        component: ChannelPublishingPageComponent,
                        data: {
                            title: 'Quản lý bài đăng'
                        },
                        path: 'publishing'
                    },
                    {
                        component: ChannelAnalyticsPageComponent,
                        data: {
                            title: 'Phân tích'
                        },
                        path: 'analytics'
                    },
                ],
                path: 'channel',
            },
            {
                children: [
                    {
                        component: MemberManageComponent,
                        data: {
                            title: 'Member Manage'
                        },
                        path: '',
                        pathMatch: 'full'
                    },
                    {
                        component: MemberManageComponent,
                        data: {
                            title: 'Member Manage'
                        },
                        path: 'member-manage'
                    },
                    {
                        component: MemberReportComponent,
                        data: {
                            title: 'Member Report'
                        },
                        path: 'member-report'
                    },
                    {
                        component: WorkspaceReportComponent,
                        data: {
                            title: 'Workspace Report'
                        },
                        path: 'workspace-report'
                    },
                    {
                        component: WsMemberReportComponent,
                        data: {
                            title: 'Workspace Member Report'
                        },
                        path: 'ws-member-report'
                    },
                    {
                        component: NotificationManagerComponent,
                        data: {
                            title: 'Quản lý thông báo'
                        },
                        path: 'notification-manager'
                    },
                ],
                path: 'workspaces'
            },
        ],
        component: UserLayoutComponent,
        path: ''
    },
    {
        component: NotFoundPageComponent,
        data: {
            title: 'Page not found'
        },
        path: '**',
        resolve: {
            path: PathResolveService
        }
    }
];
export class AppRoutingModule {
    static { this.ɵfac = function AppRoutingModule_Factory(t) { return new (t || AppRoutingModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppRoutingModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [RouterModule.forRoot(routes),
            CommonModule, RouterModule] }); }
}

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { clone } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { facebookIndex } from "../../types/socialMediaReportConfig";
import * as i0 from "@angular/core";
import * as i1 from "src/app/services/social-media.service";
import * as i2 from "../../services/channel.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material/button";
import * as i6 from "../../utils/pipes/sms-apiname.pipe";
function SocialMediaOptionsComponent_button_10_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_button_10_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r8); const ctx_r7 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r7.optionPlatformClick($event.target, "facebook")); });
    i0.ɵɵelementStart(1, "span", 13);
    i0.ɵɵtext(2, "Facebook");
    i0.ɵɵelementEnd()();
} }
function SocialMediaOptionsComponent_button_11_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 14);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_button_11_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r10); const ctx_r9 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r9.optionPlatformClick($event.target, "facebook")); });
    i0.ɵɵelementStart(1, "span", 13);
    i0.ɵɵtext(2, "Facebook");
    i0.ɵɵelementEnd()();
} }
function SocialMediaOptionsComponent_button_13_Template(rf, ctx) { if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_button_13_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r12); const ctx_r11 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r11.optionPlatformClick($event.target, "instagram")); });
    i0.ɵɵelementStart(1, "span", 13);
    i0.ɵɵtext(2, "Instagram");
    i0.ɵɵelementEnd()();
} }
function SocialMediaOptionsComponent_button_14_Template(rf, ctx) { if (rf & 1) {
    const _r14 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 14);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_button_14_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r14); const ctx_r13 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r13.optionPlatformClick($event.target, "instagram")); });
    i0.ɵɵelementStart(1, "span", 13);
    i0.ɵɵtext(2, "Instagram");
    i0.ɵɵelementEnd()();
} }
function SocialMediaOptionsComponent_div_20_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r20 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 14);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_20_div_1_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r20); const chan_r15 = i0.ɵɵnextContext().$implicit; const ctx_r18 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r18.optionChannelClick($event.target, chan_r15._id)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const chan_r15 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(chan_r15.name);
} }
function SocialMediaOptionsComponent_div_20_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r24 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 12);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_20_div_2_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r24); const chan_r15 = i0.ɵɵnextContext().$implicit; const ctx_r22 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r22.optionChannelClick($event.target, chan_r15._id)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const chan_r15 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(chan_r15.name);
} }
function SocialMediaOptionsComponent_div_20_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, SocialMediaOptionsComponent_div_20_div_1_Template, 4, 1, "div", 15);
    i0.ɵɵtemplate(2, SocialMediaOptionsComponent_div_20_div_2_Template, 4, 1, "div", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const chan_r15 = ctx.$implicit;
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.activeChannel === chan_r15._id);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r4.activeChannel !== chan_r15._id);
} }
function SocialMediaOptionsComponent_div_26_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r31 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 14);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_26_div_1_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r31); const met_r26 = i0.ɵɵnextContext().$implicit; const ctx_r29 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r29.optionMetricTypeClick($event.target, met_r26.name, met_r26.detail)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "smsApiname");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const met_r26 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 1, met_r26.name));
} }
function SocialMediaOptionsComponent_div_26_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r35 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 12);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_26_div_2_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r35); const met_r26 = i0.ɵɵnextContext().$implicit; const ctx_r33 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r33.optionMetricTypeClick($event.target, met_r26.name, met_r26.detail)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "smsApiname");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const met_r26 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 1, met_r26.name));
} }
function SocialMediaOptionsComponent_div_26_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, SocialMediaOptionsComponent_div_26_div_1_Template, 5, 3, "div", 15);
    i0.ɵɵtemplate(2, SocialMediaOptionsComponent_div_26_div_2_Template, 5, 3, "div", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const met_r26 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r5.activeMetricType === met_r26.name);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r5.activeMetricType !== met_r26.name);
} }
function SocialMediaOptionsComponent_div_32_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r42 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 14);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_32_div_1_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r42); const metric_r37 = i0.ɵɵnextContext().$implicit; const ctx_r40 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r40.optionMetricSelect($event.target, metric_r37)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "smsApiname");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const metric_r37 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 1, metric_r37));
} }
function SocialMediaOptionsComponent_div_32_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r46 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div")(1, "button", 12);
    i0.ɵɵlistener("click", function SocialMediaOptionsComponent_div_32_div_2_Template_button_click_1_listener($event) { i0.ɵɵrestoreView(_r46); const metric_r37 = i0.ɵɵnextContext().$implicit; const ctx_r44 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r44.optionMetricSelect($event.target, metric_r37)); });
    i0.ɵɵelementStart(2, "span", 13);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "smsApiname");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const metric_r37 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 1, metric_r37));
} }
function SocialMediaOptionsComponent_div_32_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, SocialMediaOptionsComponent_div_32_div_1_Template, 5, 3, "div", 15);
    i0.ɵɵtemplate(2, SocialMediaOptionsComponent_div_32_div_2_Template, 5, 3, "div", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const metric_r37 = ctx.$implicit;
    const ctx_r6 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r6.selectedMetric.includes(metric_r37));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r6.selectedMetric.includes(metric_r37));
} }
export class SocialMediaOptionsComponent {
    constructor(data, smsService, channelService, dialogRef) {
        this.data = data;
        this.smsService = smsService;
        this.channelService = channelService;
        this.dialogRef = dialogRef;
        this.channelList = [];
        this.cnListClone = [];
        this.activePlatform = 'facebook';
        this.activeConfig = '';
        this.activeChannel = '';
        this.activeMetricType = '';
        this.activeDetailMetric = [];
        this.selectedMetric = [];
    }
    ngOnInit() {
        (async () => {
            const cList = await lastValueFrom(this.channelService.listChannel());
            const result = await cList;
            for (const item of result) {
                this.channelList.push(item);
            }
            this.cnListClone = clone(this.channelList);
            this.channelName = this.data.cName;
            this.channel_id = this.data.cid;
            this.activeChannel = this.data.cid;
            this._provider = this.data.provider;
            this.selectedMetric = this.data.metrics;
            if (this._provider === 'facebook') {
                const _channel = this.cnListClone.filter(x => x._id === this.data.cid)[0];
                if (_channel.metrics.length > 0) {
                    this.selectedMetric = _channel.metrics[0].metrics;
                }
            }
            this.fbConfig = {
                channel: this.channel_id,
                index: facebookIndex,
                provider: this._provider
            };
            this.instaConfig = {};
            this.activeConfig = this.fbConfig;
            this.typeConfig = {
                'facebook': {
                    'config': this.fbConfig
                },
                'instagram': {
                    'config': this.instaConfig
                }
            };
        })();
    }
    activeColor(el) {
        let button;
        if (el.classList.contains('mat-mdc-outlined-button') || el.classList.contains('mat-mdc-raised-button')) {
            button = el;
        }
        else {
            button = el.closest('.mat-mdc-outlined-button') || el.closest('.mat-mdc-raised-button');
        }
        if (button.classList.contains('mat-mdc-outlined-button')) {
            button.classList.remove('mat-mdc-outlined-button');
            button.classList.toggle('mat-mdc-raised-button');
        }
        else if (button.classList.contains('mat-mdc-raised-button')) {
            button.classList.remove('mat-mdc-raised-button');
            button.classList.toggle('mat-mdc-outlined-button');
        }
    }
    optionPlatformClick(el, provider) {
        this.activeDetailMetric = [];
        this.selectedMetric = [];
        this.activePlatform = provider;
        this.activeConfig = this.typeConfig[provider].config;
        this.channelList = [];
        for (const item of this.cnListClone) {
            if (item.provider === provider) {
                this.channelList.push(item);
            }
        }
    }
    optionChannelClick(el, cid) {
        this.selectedMetric = [];
        this.activeChannel = cid;
        this.activeDetailMetric = [];
        const _channel = this.cnListClone.filter(x => x._id === cid)[0];
        if (_channel.metrics.length > 0) {
            this.selectedMetric = _channel.metrics[0].metrics;
        }
    }
    optionMetricTypeClick(el, metricType, detailList) {
        this.activeDetailMetric = [];
        this.activeMetricType = metricType;
        this.activeDetailMetric = detailList;
    }
    optionMetricSelect(el, metric) {
        if (this.selectedMetric.includes(metric)) {
            this.selectedMetric.splice(this.selectedMetric.indexOf(metric), 1);
        }
        else {
            this.selectedMetric.push(metric);
        }
        this.activeColor(el);
    }
    onNoClick() {
        this.dialogRef.close('close');
    }
    async onSubmit() {
        const data = {
            'channel': this.activeChannel,
            'metrics': this.selectedMetric,
            'provider': this.activePlatform
        };
        this.dialogRef.close(data);
    }
    static { this.ɵfac = function SocialMediaOptionsComponent_Factory(t) { return new (t || SocialMediaOptionsComponent)(i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i1.SocialMediaService), i0.ɵɵdirectiveInject(i2.ChannelService), i0.ɵɵdirectiveInject(i3.MatDialogRef)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SocialMediaOptionsComponent, selectors: [["app-social-media-options"]], decls: 38, vars: 7, consts: [[1, "text-center", "bg-primary", "text-white"], [1, "fs-4", "p-2"], [1, "row", "p-3", "g-2", "overflow-auto"], [1, "col-12", "col-md-3"], [1, "text-center"], [1, "text-nowrap", "overflow-hidden"], ["mat-stroked-button", "", "color", "primary", "class", "m-2 w-100", "style", "white-space: pre-wrap !important;", 3, "click", 4, "ngIf"], ["mat-raised-button", "", "color", "primary", "class", "m-2 w-100", "style", "white-space: pre-wrap !important;", 3, "click", 4, "ngIf"], [4, "ngFor", "ngForOf"], [1, "d-flex", "justify-content-end", "mt-3"], ["mat-raised-button", "", "color", "warn", "type", "button", 1, "me-3", "text-white", "py-2", 3, "click"], ["mat-raised-button", "", "color", "primary", "type", "submit", 1, "me-2", "py-2", 3, "click"], ["mat-stroked-button", "", "color", "primary", 1, "m-2", "w-100", 2, "white-space", "pre-wrap !important", 3, "click"], [2, "font-size", ".875rem", "font-weight", "400"], ["mat-raised-button", "", "color", "primary", 1, "m-2", "w-100", 2, "white-space", "pre-wrap !important", 3, "click"], [4, "ngIf"]], template: function SocialMediaOptionsComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "h1", 1);
            i0.ɵɵtext(2, "C\u00E0i \u0110\u1EB7t Metric");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "div", 4)(6, "h5", 5);
            i0.ɵɵtext(7, "Ch\u1ECDn N\u1EC1n T\u1EA3ng");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(8, "div", 4)(9, "div");
            i0.ɵɵtemplate(10, SocialMediaOptionsComponent_button_10_Template, 3, 0, "button", 6);
            i0.ɵɵtemplate(11, SocialMediaOptionsComponent_button_11_Template, 3, 0, "button", 7);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(12, "div");
            i0.ɵɵtemplate(13, SocialMediaOptionsComponent_button_13_Template, 3, 0, "button", 6);
            i0.ɵɵtemplate(14, SocialMediaOptionsComponent_button_14_Template, 3, 0, "button", 7);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(15, "div", 3)(16, "div", 4)(17, "h5", 5);
            i0.ɵɵtext(18, "Ch\u1ECDn K\u00EAnh");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(19, "div", 4);
            i0.ɵɵtemplate(20, SocialMediaOptionsComponent_div_20_Template, 3, 2, "div", 8);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(21, "div", 3)(22, "div", 4)(23, "h5", 5);
            i0.ɵɵtext(24, "Ch\u1ECDn Ch\u1EC9 S\u1ED1");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(25, "div", 4);
            i0.ɵɵtemplate(26, SocialMediaOptionsComponent_div_26_Template, 3, 2, "div", 8);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(27, "div", 3)(28, "div", 4)(29, "h5", 5);
            i0.ɵɵtext(30, "Chi Ti\u1EBFt");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(31, "div", 4);
            i0.ɵɵtemplate(32, SocialMediaOptionsComponent_div_32_Template, 3, 2, "div", 8);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(33, "div", 9)(34, "button", 10);
            i0.ɵɵlistener("click", function SocialMediaOptionsComponent_Template_button_click_34_listener() { return ctx.onNoClick(); });
            i0.ɵɵtext(35, "Cancel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(36, "button", 11);
            i0.ɵɵlistener("click", function SocialMediaOptionsComponent_Template_button_click_36_listener() { return ctx.onSubmit(); });
            i0.ɵɵtext(37, "Submit");
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngIf", ctx.activePlatform !== "facebook");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.activePlatform === "facebook");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.activePlatform !== "instagram");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.activePlatform === "instagram");
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("ngForOf", ctx.channelList);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("ngForOf", ctx.activeConfig == null ? null : ctx.activeConfig.index);
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("ngForOf", ctx.activeDetailMetric);
        } }, dependencies: [i4.NgForOf, i4.NgIf, i5.MatButton, i6.SmsApinamePipe] }); }
}

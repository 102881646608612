import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class ContextMenuItemDirective {
    get disabled() {
        return this.passive ||
            this.divider ||
            !this.evaluateIfFunction(this.enabled, this.currentItem);
    }
    constructor(template, elementRef) {
        this.template = template;
        this.elementRef = elementRef;
        this.divider = false;
        this.enabled = true;
        this.passive = false;
        this.visible = true;
        this.execute = new EventEmitter();
        this.isActive = false;
    }
    evaluateIfFunction(value, item) {
        if (value instanceof Function) {
            return value(item);
        }
        return value;
    }
    setActiveStyles() {
        this.isActive = true;
    }
    setInactiveStyles() {
        this.isActive = false;
    }
    triggerExecute(item, $event) {
        if (!this.evaluateIfFunction(this.enabled, item)) {
            return;
        }
        this.execute.emit({ event: $event, item });
    }
    static { this.ɵfac = function ContextMenuItemDirective_Factory(t) { return new (t || ContextMenuItemDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ElementRef)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ContextMenuItemDirective, selectors: [["", "contextMenuItem", ""]], inputs: { subMenu: "subMenu", divider: "divider", enabled: "enabled", passive: "passive", visible: "visible" }, outputs: { execute: "execute" } }); }
}

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@ngrx/store";
import * as i3 from "../../services/socketio.service";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/material/form-field";
import * as i9 from "@angular/material/input";
import * as i10 from "@angular/material/tooltip";
function GanttProjectInfoDialogComponent_mat_error_14_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Thi\u1EBFu th\u00F4ng tin");
    i0.ɵɵelementEnd();
} }
export class GanttProjectInfoDialogComponent {
    constructor(config, store, socket, dialogRef, data) {
        this.config = config;
        this.store = store;
        this.socket = socket;
        this.dialogRef = dialogRef;
        this.data = data;
        this.form = new FormGroup({
            $cancelled: new FormControl(false),
            $delete: new FormControl(false),
            $new: new FormControl(false),
            collapsed: new FormControl(false),
            id: new FormControl("step1", [Validators.required]),
            label: new FormControl("step1", [Validators.required]),
            type: new FormControl('project', [Validators.required]),
        });
        this.members = [];
        this.isOwner = false;
        console.log(data.task);
        this.members = data.members;
        this.task = data.task;
        this.project_id = data.project_id;
        this.project_name = data.project_name;
        this.isOwner = this.task.isOwner;
        this.form.patchValue({
            $cancelled: this.task.$cancelled ? this.task.$cancelled : false,
            $delete: this.task.$delete ? this.task.$delete : false,
            $new: this.task.$new ? this.task.$new : false,
            label: this.task.label ? this.task.label : 'Step 12',
            type: this.task.type ? this.task.type : 'project',
        });
    }
    onDelete($event) {
        this.task.$delete = true;
        this.dialogRef.close(this.task);
    }
    onCancel($event) {
        this.task.$cancelled = true;
        this.dialogRef.close(this.task);
    }
    onSubmit() {
        const fval = this.form.value;
        const task = {
            ...fval,
        };
        this.dialogRef.close(task);
    }
    static { this.ɵfac = function GanttProjectInfoDialogComponent_Factory(t) { return new (t || GanttProjectInfoDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.SocketioService), i0.ɵɵdirectiveInject(i4.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: GanttProjectInfoDialogComponent, selectors: [["app-gantt-project-info-dialog"]], decls: 24, vars: 4, consts: [["mat-dialog-title", ""], [1, "project-info-dialog", "px-2", 2, "background-color", "#f2f2f2"], ["novalidate", "", 1, "row", "g-0", 3, "formGroup"], [1, "col-12", "px-3"], ["appearance", "outline", "floatLabel", "auto", 1, "w-100", 3, "hideRequiredMarker"], ["matInput", "", "id", "label", "name", "label", "formControlName", "label", "maxlength", "50", "placeholder", "Th\u00F4ng tin task", "autocomplete", "off"], ["label", ""], ["align", "start"], ["align", "end"], [4, "ngIf"], ["mat-raised-button", "", "matTooltip", "Delete task", "color", "warn", 1, "me-2", 3, "click"], [1, "flex-spacer"], ["mat-raised-button", "", 1, "me-2", 3, "click"], ["mat-raised-button", "", "color", "primary", "type", "button", 1, "px-5", 3, "click"]], template: function GanttProjectInfoDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtext(1, "Update");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(2, "mat-dialog-content", 1)(3, "form", 2)(4, "div", 3)(5, "mat-form-field", 4)(6, "mat-label");
            i0.ɵɵtext(7, "Task name");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(8, "input", 5, 6);
            i0.ɵɵelementStart(10, "mat-hint", 7);
            i0.ɵɵtext(11, "Step info");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(12, "mat-hint", 8);
            i0.ɵɵtext(13);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(14, GanttProjectInfoDialogComponent_mat_error_14_Template, 2, 0, "mat-error", 9);
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(15, "mat-dialog-actions", 8)(16, "button", 10);
            i0.ɵɵlistener("click", function GanttProjectInfoDialogComponent_Template_button_click_16_listener($event) { return ctx.onDelete($event); });
            i0.ɵɵtext(17, "Delete");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(18, "div", 11);
            i0.ɵɵelementStart(19, "div")(20, "button", 12);
            i0.ɵɵlistener("click", function GanttProjectInfoDialogComponent_Template_button_click_20_listener($event) { return ctx.onCancel($event); });
            i0.ɵɵtext(21, "Cancel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(22, "button", 13);
            i0.ɵɵlistener("click", function GanttProjectInfoDialogComponent_Template_button_click_22_listener() { return ctx.onSubmit(); });
            i0.ɵɵtext(23, "Done");
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            const _r0 = i0.ɵɵreference(9);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("hideRequiredMarker", true);
            i0.ɵɵadvance(8);
            i0.ɵɵtextInterpolate1("", _r0.value.length, " / 50");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["label"].invalid);
        } }, dependencies: [i5.NgIf, i6.ɵNgNoValidate, i6.DefaultValueAccessor, i6.NgControlStatus, i6.NgControlStatusGroup, i6.MaxLengthValidator, i7.MatButton, i4.MatDialogTitle, i4.MatDialogContent, i4.MatDialogActions, i6.FormGroupDirective, i6.FormControlName, i8.MatFormField, i8.MatLabel, i8.MatHint, i8.MatError, i9.MatInput, i10.MatTooltip], styles: ["[_nghost-%COMP%]   .project-info-dialog[_ngcontent-%COMP%] {\n  padding: 15px;\n  margin: 0;\n}\n[_nghost-%COMP%]   .project-info-dialog[_ngcontent-%COMP%]   .project-name[_ngcontent-%COMP%] {\n  font-size: 1rem;\n  font-weight: 700;\n  margin: 0 10px;\n}\n[_nghost-%COMP%]   .project-info-dialog[_ngcontent-%COMP%]   .project-subtitle[_ngcontent-%COMP%] {\n  margin-left: 25px;\n  font-size: 0.7rem;\n  font-weight: 300;\n}\n[_nghost-%COMP%]   .project-info-dialog[_ngcontent-%COMP%]   .input-label[_ngcontent-%COMP%] {\n  font-size: 0.8rem;\n  font-weight: 700;\n}"] }); }
}

import { createReducer, on } from '@ngrx/store';
import { changeTheme } from './config.actions';
export const configFeatureKey = 'config';
export const initialState = {
    isDarkMode: false,
};
export const _reducer = createReducer(initialState, on(changeTheme, (state, { isDarkMode }) => ({ ...state, isDarkMode })));
export function reducer(state, action) {
    return _reducer(state, action);
}

import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class StoreTemplateService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    find(data) {
        const url = `${this.config.appUrl}/store-template/find`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function StoreTemplateService_Factory(t) { return new (t || StoreTemplateService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: StoreTemplateService, factory: StoreTemplateService.ɵfac, providedIn: 'root' }); }
}

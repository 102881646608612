import { FormControl, FormGroup, Validators } from "@angular/forms";
import { fromAuth, fromSnackbar } from "../../modules/stores/reducer";
import Validation from "../../utils/ConfirmedValidator";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../services/auth.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/material/form-field";
import * as i12 from "@angular/material/input";
function ChangePassDialogComponent_mat_error_18_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error", 20);
    i0.ɵɵtext(1, "Vui l\u00F2ng nh\u1EADp ch\u00EDnh x\u00E1c m\u1EADt kh\u1EA9u c\u0169");
    i0.ɵɵelementEnd();
} }
function ChangePassDialogComponent_mat_error_30_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error", 20);
    i0.ɵɵtext(1, "Vui l\u00F2ng nh\u1EADp ch\u00EDnh x\u00E1c m\u1EADt kh\u1EA9u m\u1EDBi");
    i0.ɵɵelementEnd();
} }
function ChangePassDialogComponent_mat_error_42_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error", 20);
    i0.ɵɵtext(1, "Vui l\u00F2ng nh\u1EADp ch\u00EDnh x\u00E1c m\u1EADt kh\u1EA9u m\u1EDBi");
    i0.ɵɵelementEnd();
} }
function ChangePassDialogComponent_mat_error_43_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error", 20);
    i0.ɵɵtext(1, " M\u00E2t kh\u1EA9u x\u00E1c nh\u1EADn kh\u00F4ng tr\u00F9ng v\u1EDBi M\u1EADt kh\u1EA9u m\u1EDBi t\u1EA1o ");
    i0.ɵɵelementEnd();
} }
export class ChangePassDialogComponent {
    constructor(config, $gaService, store, dialogRef, authService) {
        this.config = config;
        this.$gaService = $gaService;
        this.store = store;
        this.dialogRef = dialogRef;
        this.authService = authService;
        this.form = new FormGroup({
            confirm_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
            new_password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
            password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
        }, {
            validators: [Validation.match('new_password', 'confirm_password')]
        });
        this.hide = true;
        this.hide_new = true;
        this.hide_confirm = true;
    }
    onSubmit() {
        if (this.form.valid) {
            const data = this.form.value;
            const ref_result = this.authService.changePassword(data);
            ref_result.subscribe({
                error: (err) => {
                    console.log(err);
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: "Không thể đổi mật khẩu, vui lòng liên hệ người quản trị." }));
                }, next: () => {
                    if (this.config.ga)
                        this.$gaService.event("change-pass", 'auth', 'change-pass');
                    this.store.dispatch(fromAuth.signout());
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Đổi mật khẩu thành công" }));
                    this.dialogRef.close(true);
                }
            });
        }
    }
    static { this.ɵfac = function ChangePassDialogComponent_Factory(t) { return new (t || ChangePassDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.Store), i0.ɵɵdirectiveInject(i4.MatDialogRef), i0.ɵɵdirectiveInject(i5.AuthService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChangePassDialogComponent, selectors: [["app-change-pass-dialog"]], decls: 50, vars: 16, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 3, "cdkTrapFocusAutoCapture"], [1, "col-12", "profile-content", "p-3"], [1, "position-relative", "d-flex"], ["mat-icon-button", "", 1, "position-absolute", "end-0", 3, "mat-dialog-close"], ["novalidate", "", 1, "mt-5", 3, "formGroup", "ngSubmit"], [1, "row", "mb-2"], [1, "col-12", "col-md-3", "d-flex", "flex-column", "justify-content-center"], [1, "col-12", "col-md-9"], ["appearance", "outline", 1, "w-100"], ["matInput", "", "id", "password", "name", "password", "formControlName", "password", "minlength", "6", "maxlength", "50", "placeholder", "M\u1EADt kh\u1EA9u c\u0169", 3, "type"], ["matSuffix", "", 2, "color", "rgba(0,0,0,.5)", 3, "click"], ["class", "mat-small", 4, "ngIf"], ["appearance", "outline", 1, "w-100", 3, "hideRequiredMarker"], ["matInput", "", "id", "new_password", "name", "new_password", "formControlName", "new_password", "minlength", "6", "maxlength", "50", "placeholder", "M\u1EADt kh\u1EA9u m\u1EDBi", 3, "type"], [1, "row", "mb-5"], ["matInput", "", "id", "confirm_password", "name", "confirm_password", "formControlName", "confirm_password", "minlength", "6", "maxlength", "50", "placeholder", "X\u00E1c nh\u1EADn m\u1EADt kh\u1EA9u", 3, "type"], [1, "d-flex", "flex-row"], [1, "flex-spacer"], ["type", "button", "mat-button", "", 2, "color", "#8817F8", 3, "mat-dialog-close"], ["mat-raised-button", "", "color", "primary", "cdkFocusInitial", ""], [1, "mat-small"]], template: function ChangePassDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "button", 3)(4, "mat-icon");
            i0.ɵɵtext(5, "close");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(6, "form", 4);
            i0.ɵɵlistener("ngSubmit", function ChangePassDialogComponent_Template_form_ngSubmit_6_listener() { return ctx.onSubmit(); });
            i0.ɵɵelementStart(7, "div", 5)(8, "div", 6)(9, "p");
            i0.ɵɵtext(10, "M\u1EADt kh\u1EA9u c\u0169:");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(11, "div", 7)(12, "mat-form-field", 8)(13, "mat-label");
            i0.ɵɵtext(14, "M\u1EADt kh\u1EA9u c\u0169");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(15, "input", 9);
            i0.ɵɵelementStart(16, "mat-icon", 10);
            i0.ɵɵlistener("click", function ChangePassDialogComponent_Template_mat_icon_click_16_listener() { return ctx.hide = !ctx.hide; });
            i0.ɵɵtext(17);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(18, ChangePassDialogComponent_mat_error_18_Template, 2, 0, "mat-error", 11);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(19, "div", 5)(20, "div", 6)(21, "p");
            i0.ɵɵtext(22, "M\u1EADt kh\u1EA9u m\u1EDBi:");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(23, "div", 7)(24, "mat-form-field", 12)(25, "mat-label");
            i0.ɵɵtext(26, "M\u1EADt kh\u1EA9u m\u1EDBi");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(27, "input", 13);
            i0.ɵɵelementStart(28, "mat-icon", 10);
            i0.ɵɵlistener("click", function ChangePassDialogComponent_Template_mat_icon_click_28_listener() { return ctx.hide_new = !ctx.hide_new; });
            i0.ɵɵtext(29);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(30, ChangePassDialogComponent_mat_error_30_Template, 2, 0, "mat-error", 11);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(31, "div", 14)(32, "div", 6)(33, "p");
            i0.ɵɵtext(34, "X\u00E1c nh\u1EADn m\u1EADt kh\u1EA9u:");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(35, "div", 7)(36, "mat-form-field", 12)(37, "mat-label");
            i0.ɵɵtext(38, "X\u00E1c nh\u1EADn m\u1EADt kh\u1EA9u");
            i0.ɵɵelementEnd();
            i0.ɵɵelement(39, "input", 15);
            i0.ɵɵelementStart(40, "mat-icon", 10);
            i0.ɵɵlistener("click", function ChangePassDialogComponent_Template_mat_icon_click_40_listener() { return ctx.hide_confirm = !ctx.hide_confirm; });
            i0.ɵɵtext(41);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(42, ChangePassDialogComponent_mat_error_42_Template, 2, 0, "mat-error", 11);
            i0.ɵɵtemplate(43, ChangePassDialogComponent_mat_error_43_Template, 2, 0, "mat-error", 11);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(44, "div", 16);
            i0.ɵɵelement(45, "div", 17);
            i0.ɵɵelementStart(46, "button", 18);
            i0.ɵɵtext(47, "Cancel");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(48, "button", 19);
            i0.ɵɵtext(49, "X\u00E1c nh\u1EADn");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("mat-dialog-close", true);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(9);
            i0.ɵɵproperty("type", ctx.hide ? "password" : "text");
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate1(" ", ctx.hide ? "visibility" : "visibility_off", " ");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["password"].invalid && (ctx.form.controls["password"].dirty || ctx.form.controls["password"].touched));
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("hideRequiredMarker", true);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("type", ctx.hide_new ? "password" : "text");
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate1(" ", ctx.hide_new ? "visibility" : "visibility_off", " ");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["new_password"].invalid && (ctx.form.controls["new_password"].dirty || ctx.form.controls["new_password"].touched));
            i0.ɵɵadvance(6);
            i0.ɵɵproperty("hideRequiredMarker", true);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("type", ctx.hide_confirm ? "password" : "text");
            i0.ɵɵadvance(2);
            i0.ɵɵtextInterpolate1(" ", ctx.hide_confirm ? "visibility" : "visibility_off", " ");
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["confirm_password"].invalid && (ctx.form.controls["confirm_password"].dirty || ctx.form.controls["confirm_password"].touched));
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.form.controls["confirm_password"].errors == null ? null : ctx.form.controls["confirm_password"].errors["matching"]);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("mat-dialog-close", true);
        } }, dependencies: [i6.NgIf, i7.ɵNgNoValidate, i7.DefaultValueAccessor, i7.NgControlStatus, i7.NgControlStatusGroup, i7.MinLengthValidator, i7.MaxLengthValidator, i8.MatButton, i8.MatIconButton, i9.MatIcon, i4.MatDialogClose, i10.CdkTrapFocus, i7.FormGroupDirective, i7.FormControlName, i11.MatFormField, i11.MatLabel, i11.MatError, i11.MatSuffix, i12.MatInput] }); }
}

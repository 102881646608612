import { FormControl, FormGroup } from "@angular/forms";
import _ from "lodash";
import { lastValueFrom } from "rxjs";
import { CreateProjectDialogComponent } from "../../components/create-project-dialog/create-project-dialog.component";
import { EditProjectDialogComponent } from "../../components/edit-project-dialog/edit-project-dialog.component";
import { authFeatureKey } from "../../modules/stores/auth";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "../../services/projects.service";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/material/form-field";
import * as i11 from "@angular/material/select";
import * as i12 from "@angular/material/core";
import * as i13 from "@angular/material/paginator";
import * as i14 from "../../modules/directives/default-image.directive";
import * as i15 from "@angular/material/input";
import * as i16 from "@angular/material/tooltip";
import * as i17 from "../../utils/pipes/AuthImagePipe";
function ProjectManageComponent_mat_option_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 19);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _type_r3 = ctx.$implicit;
    i0.ɵɵproperty("value", _type_r3);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(_type_r3);
} }
function ProjectManageComponent_button_23_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 20);
    i0.ɵɵlistener("click", function ProjectManageComponent_button_23_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r5); const ctx_r4 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r4.clearFilter()); });
    i0.ɵɵelementStart(1, "mat-icon");
    i0.ɵɵtext(2, "close");
    i0.ɵɵelementEnd()();
} }
function ProjectManageComponent_ng_container_25_p_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 27)(1, "button", 28)(2, "mat-icon", 29);
    i0.ɵɵtext(3, "border_color");
    i0.ɵɵelementEnd()()();
} }
function ProjectManageComponent_ng_container_25_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 21)(2, "div", 22);
    i0.ɵɵlistener("click", function ProjectManageComponent_ng_container_25_Template_div_click_2_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r10); const project_r6 = restoredCtx.$implicit; const ctx_r9 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r9.onProjectClick(project_r6, $event)); });
    i0.ɵɵelement(3, "img", 23);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵpipe(5, "authenticate");
    i0.ɵɵelementStart(6, "div", 24)(7, "p", 25);
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(9, ProjectManageComponent_ng_container_25_p_9_Template, 4, 0, "p", 26);
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const project_r6 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵpropertyInterpolate("src", i0.ɵɵpipeBind1(4, 4, i0.ɵɵpipeBind1(5, 6, project_r6.cover)), i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(4);
    i0.ɵɵpropertyInterpolate("title", project_r6 == null ? null : project_r6.name);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(project_r6.name);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r2.isOwner(project_r6));
} }
export class ProjectManageComponent {
    constructor(router, dialog, overlay, projectsService, store) {
        this.router = router;
        this.dialog = dialog;
        this.overlay = overlay;
        this.projectsService = projectsService;
        this.store = store;
        this.filter = new FormGroup({
            keyword: new FormControl(''),
        });
        this.sort = new FormControl([]);
        this.sort_types = ["_id", "name", "description", "start", "end"];
        this.sort_object = {};
        this.pageSize = 50;
        this.pageIndex = 0;
        this.pageSizeOptions = [25, 50, 100];
        this.showFirstLastButtons = true;
        this.authState = this.store.select(authFeatureKey);
        this.authState.subscribe({
            next: (auth) => {
                if (auth.me)
                    this.me = auth.me;
            }
        });
        this.fn_filter();
    }
    isOwner(project) {
        if (this.me)
            return _.includes(project.owners, this.me._id);
        return false;
    }
    applyFilter() {
        if (this.filter.valid) {
            this.pageIndex = 0;
            this.fn_filter();
        }
    }
    clearFilter() {
        this.filter.patchValue({ keyword: '' });
        this.fn_filter();
    }
    async openCreateDialog() {
        if (this.me) {
            const workspace = this.me.workspace;
            if (!this.dialogRef) {
                const scrollStrategy = this.overlay.scrollStrategies.reposition();
                this.dialogRef = this.dialog.open(CreateProjectDialogComponent, { data: { error: undefined, project: undefined, workspace }, disableClose: true, hasBackdrop: true, scrollStrategy, width: "90%" });
                this.dialogRef.afterClosed().subscribe(async (data) => {
                    this.fn_filter();
                    this.dialogRef = undefined;
                });
            }
        }
    }
    handlePageEvent($event) {
        this.pageSize = $event.pageSize;
        this.pageIndex = $event.pageIndex;
        this.fn_filter();
    }
    fn_filter() {
        if (this.filter.valid) {
            const _filter = _.clone(this.filter.value);
            _filter.keyword = _filter.keyword.trim();
            this.projects = this.projectsService.find({
                "fields": {
                    "_id": 1,
                    "color": 1,
                    "cover": 1,
                    "description": 1,
                    "end": 1,
                    "name": 1,
                    "owners": 1,
                    "start": 1,
                    "type": 1,
                    "workGroup": 1,
                    "workspace": 1
                },
                "filter": _filter,
                "limit": this.pageSize,
                "page": this.pageIndex + 1,
                "sort": _.isEmpty(this.sort_object) ? { "_id": -1 } : this.sort_object
            });
        }
    }
    sortChange() {
        if (this.sort.valid) {
            this.sort_object = {};
            const val = this.sort.value;
            for (const i in val) {
                this.sort_object[val[i]] = 1;
            }
            this.fn_filter();
        }
    }
    async onProjectClick(project, $event) {
        try {
            const editButton = $event.target.closest('.mat-mdc-icon-button');
            if (editButton) {
                if (!this.dialogRef) {
                    const scrollStrategy = this.overlay.scrollStrategies.reposition();
                    this.dialogRef = this.dialog.open(EditProjectDialogComponent, { data: { project }, disableClose: true, hasBackdrop: true, maxWidth: "100%", minWidth: "400px", scrollStrategy });
                    await lastValueFrom(this.dialogRef.afterClosed());
                    this.fn_filter();
                    this.dialogRef = undefined;
                }
            }
            else {
                await this.router.navigate(['/projects/kanban', project._id]);
            }
        }
        catch (err) {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
        }
    }
    static { this.ɵfac = function ProjectManageComponent_Factory(t) { return new (t || ProjectManageComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i2.MatDialog), i0.ɵɵdirectiveInject(i3.Overlay), i0.ɵɵdirectiveInject(i4.ProjectsService), i0.ɵɵdirectiveInject(i5.Store)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ProjectManageComponent, selectors: [["app-project-manage"]], decls: 29, vars: 14, consts: [[1, "p-1", "p-md-4"], [1, "row", "g-0", "m-1", "m-md-3"], [1, "col-12", "d-flex", "flex-column", "flex-md-row", "align-items-md-center"], [1, "d-inline-flex", "flex-row", "align-items-center"], [2, "font-weight", "500", "font-size", "24px"], ["mat-icon-button", "", "matTooltip", "Add new project", 1, "ms-3", 2, "background", "#8817F8", "border-radius", "5px", 3, "click"], [1, "text-white"], [1, "flex-spacer"], [1, "d-flex", "flex-column", "flex-md-row", "mt-2", "mt-md-0", 3, "formGroup"], ["appearance", "outline", 1, "w-100", "me-0", "me-md-2"], [1, "fa-regular", "fa-arrow-down-big-small", "fa-1x"], ["multiple", "", 3, "formControl", "ngModelChange"], [3, "value", 4, "ngFor", "ngForOf"], ["matInput", "", "formControlName", "keyword", "placeholder", "Ex. Mia", 3, "keyup"], ["matIconPrefix", ""], ["matSuffix", "", "mat-icon-button", "", "aria-label", "Clear", 3, "click", 4, "ngIf"], [1, "row", "g-0", "g-md-2", "m-2", "m-md-3"], [4, "ngFor", "ngForOf"], ["aria-label", "Select page", 3, "length", "pageSize", "showFirstLastButtons", "pageSizeOptions", "pageIndex", "page"], [3, "value"], ["matSuffix", "", "mat-icon-button", "", "aria-label", "Clear", 3, "click"], [1, "col-12", "col-md-3", "mb-4"], [1, "card", "project-card", 3, "click"], [1, "project-cover", 3, "src"], [1, "project-info", "d-flex", "flex-row", "justify-content-center"], ["data-bs-toggle", "tooltip", 1, "project-header", 3, "title"], ["class", "d-flex flex-column justify-content-center", 4, "ngIf"], [1, "d-flex", "flex-column", "justify-content-center"], ["mat-icon-button", "", "data-bs-toggle", "tooltip", "title", "S\u1EEDa"], [2, "color", "rgba(0,0,0,.5)", "font-size", ".9rem"]], template: function ProjectManageComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3)(4, "span", 4);
            i0.ɵɵtext(5, "Danh s\u00E1ch d\u1EF1 \u00E1n");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(6, "button", 5);
            i0.ɵɵlistener("click", function ProjectManageComponent_Template_button_click_6_listener() { return ctx.openCreateDialog(); });
            i0.ɵɵelementStart(7, "mat-icon", 6);
            i0.ɵɵtext(8, "add_circle");
            i0.ɵɵelementEnd()()();
            i0.ɵɵelement(9, "div", 7);
            i0.ɵɵelementStart(10, "form", 8)(11, "mat-form-field", 9)(12, "mat-label");
            i0.ɵɵtext(13, "Sort by ");
            i0.ɵɵelement(14, "i", 10);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(15, "mat-select", 11);
            i0.ɵɵlistener("ngModelChange", function ProjectManageComponent_Template_mat_select_ngModelChange_15_listener() { return ctx.sortChange(); });
            i0.ɵɵtemplate(16, ProjectManageComponent_mat_option_16_Template, 2, 2, "mat-option", 12);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(17, "mat-form-field", 9)(18, "mat-label");
            i0.ɵɵtext(19, "Filter");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(20, "input", 13);
            i0.ɵɵlistener("keyup", function ProjectManageComponent_Template_input_keyup_20_listener() { return ctx.applyFilter(); });
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(21, "mat-icon", 14);
            i0.ɵɵtext(22, "search");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(23, ProjectManageComponent_button_23_Template, 3, 0, "button", 15);
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(24, "div", 16);
            i0.ɵɵtemplate(25, ProjectManageComponent_ng_container_25_Template, 10, 8, "ng-container", 17);
            i0.ɵɵpipe(26, "async");
            i0.ɵɵelementStart(27, "mat-paginator", 18);
            i0.ɵɵlistener("page", function ProjectManageComponent_Template_mat_paginator_page_27_listener($event) { return ctx.handlePageEvent($event); });
            i0.ɵɵpipe(28, "async");
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            let tmp_4_0;
            let tmp_5_0;
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("formGroup", ctx.filter);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("formControl", ctx.sort);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx.sort_types);
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("ngIf", ctx.filter.controls["keyword"].value);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngForOf", (tmp_4_0 = i0.ɵɵpipeBind1(26, 10, ctx.projects)) == null ? null : tmp_4_0.data);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("length", (tmp_5_0 = i0.ɵɵpipeBind1(28, 12, ctx.projects)) == null ? null : tmp_5_0.total)("pageSize", ctx.pageSize)("showFirstLastButtons", ctx.showFirstLastButtons)("pageSizeOptions", ctx.pageSizeOptions)("pageIndex", ctx.pageIndex);
        } }, dependencies: [i6.NgForOf, i6.NgIf, i7.ɵNgNoValidate, i7.DefaultValueAccessor, i7.NgControlStatus, i7.NgControlStatusGroup, i8.MatIconButton, i9.MatIcon, i7.FormControlDirective, i7.FormGroupDirective, i7.FormControlName, i10.MatFormField, i10.MatLabel, i10.MatPrefix, i10.MatSuffix, i11.MatSelect, i12.MatOption, i13.MatPaginator, i14.DefaultImageDirective, i15.MatInput, i16.MatTooltip, i6.AsyncPipe, i17.AuthImagePipe], styles: [".project-card[_ngcontent-%COMP%] {\n  background: #EAEAEA;\n  border: 1px solid #E7E1E1;\n  border-radius: 10px;\n  min-height: 150px;\n}\n.project-card[_ngcontent-%COMP%]   .project-cover[_ngcontent-%COMP%] {\n  border-top-left-radius: 10px;\n  border-top-right-radius: 10px;\n  width: 85%;\n  margin: 10% auto 0 auto;\n}\n.project-card[_ngcontent-%COMP%]   .project-info[_ngcontent-%COMP%] {\n  background: white;\n  border-radius: 10px;\n  border-top: 1px solid #D4D3D3;\n  border-left: none;\n  border-right: none;\n  border-bottom: none;\n}\n.project-card[_ngcontent-%COMP%]   .project-header[_ngcontent-%COMP%] {\n  padding: 20px 10px;\n  font-size: 1.25rem;\n  font-weight: 700;\n  text-align: center;\n  display: block;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.project-card[_ngcontent-%COMP%]:hover   .project-cover[_ngcontent-%COMP%] {\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;\n}"] }); }
}

import { CommonModule } from "@angular/common";
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from "@ngrx/store";
import { AuthEffects } from "./auth.effects";
import { authFeatureKey, reducer } from "./auth.reducer";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class AuthModule {
    static { this.ɵfac = function AuthModule_Factory(t) { return new (t || AuthModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AuthModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            StoreModule.forFeature(authFeatureKey, reducer),
            EffectsModule.forFeature([AuthEffects])] }); }
}

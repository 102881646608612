import moment from 'moment';
import { NGX_MOMENT_OPTIONS } from './moment-options';
import * as i0 from "@angular/core";
export class DurationPipe {
    constructor(momentOptions) {
        this.allowedUnits = ['ss', 's', 'm', 'h', 'd', 'M'];
        this._applyOptions(momentOptions);
    }
    transform(value, ...args) {
        if (typeof args === 'undefined' || args.length !== 1) {
            throw new Error('DurationPipe: missing required time unit argument');
        }
        return moment.duration(value, args[0]).humanize();
    }
    _applyOptions(momentOptions) {
        if (!momentOptions) {
            return;
        }
        if (momentOptions.relativeTimeThresholdOptions) {
            const units = Object.keys(momentOptions.relativeTimeThresholdOptions);
            const filteredUnits = units.filter((unit) => this.allowedUnits.indexOf(unit) !== -1);
            filteredUnits.forEach((unit) => {
                moment.relativeTimeThreshold(unit, momentOptions.relativeTimeThresholdOptions[unit]);
            });
        }
    }
    static { this.ɵfac = function DurationPipe_Factory(t) { return new (t || DurationPipe)(i0.ɵɵdirectiveInject(NGX_MOMENT_OPTIONS, 24)); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amDuration", type: DurationPipe, pure: true }); }
}

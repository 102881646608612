import { createReducer, on } from '@ngrx/store';
import { toggle } from './sidenav.actions';
export const sideNavFeatureKey = 'sidenav';
export const initialState = {
    opened: true
};
export const _reducer = createReducer(initialState, on(toggle, (state) => ({ ...state, opened: !state.opened })));
export function reducer(state, action) {
    return _reducer(state, action);
}

export const facebookIndex = [
    {
        'detail': [
            'page_engaged_users',
        ],
        'name': 'page_engagements'
    },
    {
        'detail': [
            'page_impressions',
            'page_impressions_paid',
            'page_impressions_organic',
        ],
        'name': 'page_impressions'
    },
    {
        'detail': [
            'page_actions_post_reactions_like_total',
            'page_actions_post_reactions_love_total',
            'page_actions_post_reactions_wow_total',
            'page_actions_post_reactions_haha_total',
            'page_actions_post_reactions_sorry_total',
            'page_actions_post_reactions_anger_total',
            'page_actions_post_reactions_total'
        ],
        'name': 'page_reactions'
    },
    {
        'detail': [
            'page_fans',
            'page_fans_locale',
            'page_fans_city',
            'page_fans_country',
            'page_fans_gender_age',
            'page_fan_removes'
        ],
        'name': 'page_user_demographics'
    },
    {
        'detail': [
            'page_video_views',
            'page_video_views_paid',
            'page_video_views_organic'
        ],
        'name': 'page_video_views'
    }
];

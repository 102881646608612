export var EActionType;
(function (EActionType) {
    EActionType["notify"] = "NOTIFY";
    EActionType["add_card"] = "ADD_CARD";
    EActionType["update_card"] = "UPDATE_CARD";
    EActionType["delete_card"] = "DELETE_CARD";
    EActionType["dragndrop_card"] = "DRAGNDROP_CARD";
    EActionType["add_column"] = "ADD_COLUMN";
    EActionType["update_column"] = "UPDATE_COLUMN";
    EActionType["delete_column"] = "DELETE_COLUMN";
    EActionType["dragndrop_column"] = "DRAGNDROP_COLUMN";
    EActionType["add_row"] = "ADD_ROW";
    EActionType["update_row"] = "UPDATE_ROW";
    EActionType["delete_row"] = "DELETE_ROW";
    EActionType["dragndrop_row"] = "DRAGNDROP_ROW";
    EActionType["update_workgroup"] = "UPDATE_WORKGROUP";
    EActionType["join_workspace_success"] = "JOIN_WORKSPACE_SUCCESS";
    EActionType["join_workspace_error"] = "JOIN_WORKSPACE_ERROR";
    EActionType["change_workspace_success"] = "CHANGE_WORKSPACE_SUCCESS";
    EActionType["change_workspace_error"] = "CHANGE_WORKSPACE_ERROR";
    EActionType["join_project_success"] = "JOIN_PROJECT_SUCCESS";
    EActionType["join_project_error"] = "JOIN_PROJECT_ERROR";
    EActionType["leave_project_success"] = "LEAVE_PROJECT_SUCCESS";
    EActionType["leave_project_error"] = "LEAVE_PROJECT_ERROR";
    EActionType["disconnecting_success"] = "DISCONNECTING_SUCCESS";
    EActionType["disconnecting_error"] = "DISCONNECTING_ERROR";
    EActionType["error"] = "ERROR";
})(EActionType || (EActionType = {}));

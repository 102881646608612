import { CommonModule } from '@angular/common';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { reducer, SidenavEffects, sideNavFeatureKey } from "../sidenav";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
export class SidenavModule {
    static { this.ɵfac = function SidenavModule_Factory(t) { return new (t || SidenavModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SidenavModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            StoreModule.forFeature(sideNavFeatureKey, reducer),
            EffectsModule.forFeature([SidenavEffects])] }); }
}

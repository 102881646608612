import { CommonModule } from '@angular/common';
import * as i0 from "@angular/core";
export class CustomReuseStrategyModule {
    constructor() {
        this.handlers = {};
    }
    retrieve(route) {
        return this.handlers[route.url.join("/") || route.parent.url.join("/")];
    }
    shouldAttach(route) {
        const url = route.url.join("/") || route.parent.url.join("/");
        return !!this.handlers[url];
    }
    shouldDetach(route) {
        return true;
    }
    shouldReuseRoute(future, curr) {
        return future.routeConfig === curr.routeConfig;
    }
    store(route, handle) {
        while (document.getElementsByClassName('tooltip').length > 0) {
            document.getElementsByClassName('tooltip')[0].remove();
        }
        this.handlers[route.url.join("/") || route.parent.url.join("/")] = handle;
    }
    static { this.ɵfac = function CustomReuseStrategyModule_Factory(t) { return new (t || CustomReuseStrategyModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: CustomReuseStrategyModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] }); }
}

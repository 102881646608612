import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DirectivesModule } from "../directives/directives.module";
import { MomentModule } from "../ngx-moment";
import * as i0 from "@angular/core";
export class KanbanModule {
    static { this.ɵfac = function KanbanModule_Factory(t) { return new (t || KanbanModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: KanbanModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            DragDropModule,
            MatMenuModule,
            MatButtonModule,
            MatFormFieldModule,
            MatIconModule,
            MatTooltipModule,
            FormsModule,
            MatDividerModule,
            MomentModule,
            DirectivesModule, DirectivesModule] }); }
}

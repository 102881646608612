import { HttpHeaders } from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { authFeatureKey } from "../../modules/stores/auth";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@ngrx/store";
export class AuthImagePipe {
    constructor(http, sanitizer, store) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.store = store;
        this.authState = this.store.select(authFeatureKey);
        this.authState.subscribe(auth => {
            this.token = auth.token;
            this.device = auth.device;
        });
    }
    transform(src) {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token, 'device': this.device || '' });
        const imageBlob = this.http.get(src, { headers, responseType: 'blob' });
        return imageBlob.pipe(switchMap((blob) => {
            return new Observable(observer => {
                const reader = new FileReader();
                reader.readAsDataURL(blob); // convert blob to base64
                reader.onloadend = function () {
                    observer.next(reader.result); // emit the base64 string result
                };
            });
        }));
    }
    static { this.ɵfac = function AuthImagePipe_Factory(t) { return new (t || AuthImagePipe)(i0.ɵɵdirectiveInject(i1.HttpClient, 16), i0.ɵɵdirectiveInject(i2.DomSanitizer, 16), i0.ɵɵdirectiveInject(i3.Store, 16)); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "authenticate", type: AuthImagePipe, pure: true }); }
}

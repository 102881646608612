import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt";
import * as i3 from "../types/AppConfig";
export class GuestService {
    constructor(http, jwtHelper, config) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.config = config;
    }
    queryProjectReport(data) {
        const url = `${this.config.appUrl}/guest/get-report`;
        return this.http.post(url, data);
    }
    getListReport() {
        const url = `${this.config.appUrl}/guest/get-list-report`;
        return this.http.get(url);
    }
    getPriorityPerformance(data) {
        const url = `${this.config.appUrl}/guest/get-priority-performance`;
        return this.http.post(url, data);
    }
    getGuestList(data) {
        const url = `${this.config.appUrl}/guest/get-list-guest`;
        return this.http.post(url, data);
    }
    deleteGuest(data) {
        const url = `${this.config.appUrl}/guest/delete-guest`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function GuestService_Factory(t) { return new (t || GuestService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GuestService, factory: GuestService.ɵfac, providedIn: 'root' }); }
}

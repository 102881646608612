import { changeTheme, configFeatureKey } from "../modules/stores/config";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class StyleManagerService {
    constructor(store) {
        this.store = store;
        this.isDarkMode = false;
        this.configState = this.store.select(configFeatureKey);
        this.configState.subscribe({
            next: (config) => {
                this.isDarkMode = config.isDarkMode;
            }
        });
    }
    toggleDarkTheme() {
        if (this.isDarkMode) {
            this.removeStyle('dark-theme');
            document.body.classList.remove('dark-theme');
            this.isDarkMode = false;
        }
        else {
            document.body.classList.add('dark-theme');
            this.isDarkMode = true;
        }
        console.log("this.isDarkMode", this.isDarkMode);
        this.store.dispatch(changeTheme({ isDarkMode: this.isDarkMode }));
    }
    removeStyle(key) {
        const existingLinkElement = getExistingLinkElementByKey(key);
        if (existingLinkElement) {
            document.head.removeChild(existingLinkElement);
        }
    }
    static { this.ɵfac = function StyleManagerService_Factory(t) { return new (t || StyleManagerService)(i0.ɵɵinject(i1.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: StyleManagerService, factory: StyleManagerService.ɵfac, providedIn: 'root' }); }
}
function getLinkElementForKey(key) {
    return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}
function getExistingLinkElementByKey(key) {
    return document.head.querySelector(`link[rel="stylesheet"].${getClassNameForKey(key)}`);
}
function createLinkElementWithKey(key) {
    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(getClassNameForKey(key));
    document.head.appendChild(linkEl);
    return linkEl;
}
function getClassNameForKey(key) {
    return `style-manager-${key}`;
}

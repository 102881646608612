import anime from 'animejs';
import color_utils from './color_utils';
import date_utils from './date_utils';
import { $, animateSVG, createSVG } from './utils';
export default class Bar {
    constructor(gantt, task) {
        this.assignments = [];
        this.connectors = [];
        this.set_defaults(gantt, task);
        this.prepare();
        this.draw();
        this.bind();
    }
    set_defaults(gantt, task) {
        this.action_completed = false;
        this.gantt = gantt;
        this.task = task;
        this.symbol_height = 16;
        this.symbol_width = 16;
        this.assignment_width = 16;
        this.assignment_height = 16;
    }
    prepare() {
        this.prepare_values();
        this.prepare_groups();
        this.prepare_helpers();
    }
    prepare_values() {
        this.invalid = this.task.invalid;
        this.height = this.gantt.options.bar_height;
        this.x = this.compute_x();
        this.y = this.compute_y();
        this.corner_radius = this.gantt.options.bar_corner_radius;
        this.duration = date_utils.diff(this.task._end, this.task._start, 'hour') / this.gantt.options.step;
        this.width = this.gantt.options.column_width * this.duration;
        const child_tasks = this.gantt.getChildren(this.gantt.tasks, this.task.id, false);
        if (child_tasks.length !== 0) {
            const progress = child_tasks.reduce((acc, cur) => acc + cur.progress, 0);
            this.progress_width = this.gantt.options.column_width * this.duration * (progress / 100) || 0;
        }
        else {
            this.progress_width = this.gantt.options.column_width * this.duration * (this.task.progress / 100) || 0;
        }
    }
    prepare_groups() {
        this.group = createSVG('g', {
            class: 'bar-wrapper ' + (this.task.custom_class || ''),
            'data-id': this.task.id,
            'data-type': this.task.type
        });
        this.bar_group = createSVG('g', {
            append_to: this.group,
            class: 'bar-group',
            'data-id': this.task.id
        });
        this.handle_group = createSVG('g', {
            append_to: this.group,
            class: 'handle-group'
        });
        this.assignment_group = createSVG('g', {
            append_to: this.group,
            class: 'assignment_group'
        });
    }
    prepare_helpers() {
        SVGElement.prototype.getX = function () {
            return +this.getAttribute('x');
        };
        SVGElement.prototype.getY = function () {
            return +this.getAttribute('y');
        };
        SVGElement.prototype.getWidth = function () {
            return +this.getAttribute('width');
        };
        SVGElement.prototype.getHeight = function () {
            return +this.getAttribute('height');
        };
        SVGElement.prototype.getEndX = function () {
            return this.getX() + this.getWidth();
        };
    }
    draw() {
        this.draw_bar();
        this.draw_progress_bar();
        this.draw_label();
        this.draw_symbol();
        this.draw_assignments();
        this.draw_handles();
    }
    get_folder_path(x, y, width, height) {
        const curve = 2;
        const curve_end = 5;
        return `
                M ${x + curve} ${y}
                h ${width - curve * 2}
                a ${curve} ${curve} 0 0 1 ${curve} ${curve}
                v ${height - curve * 2}
                l -${curve_end} -${curve_end}
                h -${width - curve_end * 2}
                l -${curve_end} ${curve_end}
                v -${height - curve * 2}
                a ${curve} ${curve} 0 0 1 ${curve} -${curve}
         `;
    }
    draw_bar() {
        this.$bar = createSVG('rect', {
            append_to: this.bar_group,
            class: 'bar',
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            width: this.width,
            x: this.x,
            y: this.y
        });
        if (this.task.color) {
            $.attr(this.$bar, {
                fill: this.task.color
            });
        }
        else {
            $.attr(this.$bar, {
                fill: '#b8c2cc'
            });
        }
        animateSVG(this.$bar, 'width', 0, this.width);
        if (this.invalid) {
            this.$bar.classList.add('bar-invalid');
        }
    }
    update_bar() {
        this.prepare_values();
        this.update_bar_position({ width: this.width, x: this.x });
        this.update_progressbar_position();
        this.update_label();
        this.update_label_position();
        this.update_symbol_position();
        this.update_assignments_position();
        this.update_handle_position();
        this.compute_parent_progress();
        this.update_parent_progressbar_position();
        this.update_parent_label_position();
        this.update_parent_symbol_position();
        this.update_parent_handle_position();
    }
    draw_progress_bar() {
        if (this.invalid)
            return;
        this.$bar_progress = createSVG('rect', {
            append_to: this.bar_group,
            class: 'bar-progress',
            height: this.height,
            rx: this.corner_radius,
            ry: this.corner_radius,
            width: this.progress_width,
            x: this.x,
            y: this.y
        });
        if (this.task.color) {
            $.attr(this.$bar_progress, {
                fill: color_utils.shadeColor(this.task.color, 1.5)
            });
        }
        else {
            $.attr(this.$bar_progress, {
                fill: '#8D99A6'
            });
        }
        animateSVG(this.$bar_progress, 'width', 0, this.progress_width);
    }
    draw_label() {
        this.label = createSVG('text', {
            append_to: this.bar_group,
            class: 'bar-label',
            innerHTML: this.task.label,
            x: this.x + this.width / 2,
            y: this.y + this.height / 2
        });
        // labels get BBox in the next tick
        requestAnimationFrame(() => this.update_label_position());
    }
    update_label() {
        $.attr(this.label, {
            innerHTML: this.task.label
        });
    }
    draw_symbol() {
        if (this.task.type === 'project') {
            createSVG('use', {
                append_to: this.bar_group,
                class: 'bar-symbol',
                height: this.symbol_height,
                href: '#folder',
                width: this.symbol_width,
                x: this.x + this.symbol_width * 2,
                y: this.y + this.height / 2 - this.symbol_height / 2
            });
        }
        else {
            createSVG('use', {
                append_to: this.bar_group,
                class: 'bar-symbol',
                height: this.symbol_height,
                href: '#square-list',
                width: this.symbol_width,
                x: this.x + this.symbol_width * 2,
                y: this.y + this.height / 2 - this.symbol_height / 2
            });
        }
        // symbols get BBox in the next tick
        requestAnimationFrame(() => this.update_symbol_position());
    }
    draw_assignments() {
        this.assignments = this.assignments || [];
        if (this.task.members && this.task.members.length > 0) {
            for (let i = 0; i < this.task.members.length; i++) {
                const member = this.task.members[i];
                const assignment = {};
                assignment.rect = createSVG('rect', {
                    append_to: this.assignment_group,
                    class: 'avatar_rect',
                    height: this.assignment_height,
                    rx: this.assignment_height / 2,
                    ry: this.assignment_height / 2,
                    stroke: '#b0b0b0',
                    'stroke-width': '2',
                    width: this.assignment_width,
                    x: this.x + this.width + (i + 1) * 21,
                    y: this.y + this.height / 2 - this.assignment_height / 2
                });
                assignment.image = createSVG('image', {
                    append_to: this.assignment_group,
                    'clip-path': 'inset(0% round 16px)',
                    'data-control': 'tooltip',
                    'data-tooltip': member.label,
                    height: this.assignment_height,
                    href: member.avatar,
                    width: this.assignment_width,
                    x: this.x + this.width + (i + 1) * 21,
                    y: this.y + this.height / 2 - this.assignment_height / 2
                });
                this.assignments.push(assignment);
            }
        }
    }
    draw_handles() {
        if (this.invalid)
            return;
        const bar = this.$bar;
        const handle_width = 8;
        const connector_radius = 5;
        createSVG('rect', {
            append_to: this.handle_group,
            class: 'handle right',
            height: this.height - 2,
            rx: this.corner_radius,
            ry: this.corner_radius,
            width: handle_width,
            x: bar.getX() + bar.getWidth() - 9,
            y: bar.getY() + 1
        });
        createSVG('rect', {
            append_to: this.handle_group,
            class: 'handle left',
            height: this.height - 2,
            rx: this.corner_radius,
            ry: this.corner_radius,
            width: handle_width,
            x: bar.getX() + 1,
            y: bar.getY() + 1
        });
        const childs = this.gantt.getChildren(this.gantt.tasks, this.task.id, false);
        let disabled_class = '';
        if (childs.length > 0) {
            disabled_class = ' disabled';
        }
        if (this.task.progress >= 0 && this.task.progress <= 100) {
            this.$handle_progress = createSVG('polygon', {
                append_to: this.handle_group,
                class: 'handle progress' + disabled_class,
                points: this.get_progress_polygon_points().join(',')
            });
        }
        this.$connector_left = createSVG('circle', {
            append_to: this.handle_group,
            class: 'connector left',
            cx: bar.getX() - 1,
            cy: bar.getY() + this.height / 2,
            'data-task': this.task.id,
            'data-type': this.task.type,
            r: connector_radius
        });
        this.$connector_right = createSVG('circle', {
            append_to: this.handle_group,
            class: 'connector right',
            cx: bar.getX() + bar.getWidth() - 1,
            cy: bar.getY() + this.height / 2,
            'data-task': this.task.id,
            'data-type': this.task.type,
            r: connector_radius
        });
    }
    get_progress_polygon_points() {
        const bar_progress = this.$bar_progress;
        return [
            bar_progress.getEndX() - 5,
            bar_progress.getY() + bar_progress.getHeight(),
            bar_progress.getEndX() + 5,
            bar_progress.getY() + bar_progress.getHeight(),
            bar_progress.getEndX(),
            bar_progress.getY() + bar_progress.getHeight() - 8.66
        ];
    }
    bind() {
        if (this.invalid)
            return;
        this.setup_click_event();
        this.setup_hover_event();
    }
    setup_click_event() {
        $.on(this.group, 'focus ' + this.gantt.options.popup_trigger, () => {
            if (this.action_completed) {
                // just finished a move action, wait for a few seconds
                return;
            }
            this.gantt.unselect_all();
            this.group.classList.add('active');
        });
        $.on(this.group, 'dblclick', () => {
            if (this.action_completed) {
                // just finished a move action, wait for a few seconds
                return;
            }
            this.show_popup();
            this.gantt.click_task_event = new CustomEvent('click-task', { detail: { card: this.task } });
            this.gantt.element.dispatchEvent(this.gantt.click_task_event);
            // this.gantt.trigger_event('click', [this.task]);
        });
    }
    setup_hover_event() {
        $.on(this.group, 'mouseover', () => {
            if (this.action_completed) {
                return;
            }
            if (this.task.color) {
                $.attr(this.$bar, {
                    fill: color_utils.shadeColor(this.task.color, 1.5)
                });
                if (this.$bar_progress) {
                    $.attr(this.$bar_progress, {
                        fill: color_utils.shadeColor(this.task.color, 1.9)
                    });
                }
            }
        });
        $.on(this.group, 'mouseout', () => {
            if (this.action_completed) {
                return;
            }
            if (this.task.color) {
                $.attr(this.$bar, {
                    fill: this.task.color
                });
                if (this.$bar_progress) {
                    $.attr(this.$bar_progress, {
                        fill: color_utils.shadeColor(this.task.color, 1.5)
                    });
                }
            }
        });
    }
    show_popup() {
        if (this.gantt.bar_being_dragged)
            return;
        const start_date = date_utils.format(this.task._start, 'MMM D', this.gantt.options.language);
        const end_date = date_utils.format(date_utils.add(this.task._end, -1, 'second'), 'MMM D', this.gantt.options.language);
        const subtitle = start_date + ' - ' + end_date;
        this.gantt.show_popup({
            subtitle,
            target_element: this.$bar,
            task: this.task,
            title: this.task.label
        });
    }
    update_bar_position({ x = null, y = null, width = null }) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const bar = this.$bar;
        if (x) {
            // get all x values of parent task
            const xs = this.task.links.map((dep) => {
                return this.gantt.get_bar(dep.source).$bar.getX();
            });
            // child task must not go before parent
            const valid_x = xs.reduce((prev, curr) => {
                return x >= curr;
            }, x);
            if (!valid_x) {
                width = null;
                return;
            }
            this.update_attr(bar, 'x', x);
        }
        if (y) {
            anime({
                complete() {
                    that.update_label_position();
                    that.update_symbol_position();
                    that.update_handle_position();
                    that.update_progressbar_position();
                    that.update_connector_position();
                },
                duration: 500,
                easing: 'cubicBezier(.5, .05, .1, .3)',
                targets: bar,
                update() {
                    that.update_label_position();
                    that.update_symbol_position();
                    that.update_handle_position();
                    that.update_progressbar_position();
                    that.update_connector_position();
                },
                y
            });
        }
        if (width && width >= this.gantt.options.column_width) {
            this.update_attr(bar, 'width', width);
        }
        this.update_label_position();
        this.update_symbol_position();
        this.update_handle_position();
        this.update_progressbar_position();
        this.update_connector_position();
    }
    update_parent_position({ x = null }) {
        if (this.task.parent) {
            const folder = this.gantt.get_bar(this.task.parent);
            const bar = folder.$bar;
            if (x) {
                // If child bar move to left
                if (x < bar.getX()) {
                    const newwidth = bar.getWidth() + Math.abs(bar.getX() - x);
                    $.attr(bar, { width: newwidth, x });
                }
                // If child bar move to right
                if (x + this.$bar.getWidth() > bar.getX() + bar.getWidth()) {
                    const newwidth = Math.abs(x + this.$bar.getWidth() - bar.getX());
                    $.attr(bar, { width: newwidth });
                }
            }
            this.update_parent_progressbar_position();
            this.update_parent_label_position();
            this.update_parent_symbol_position();
            this.update_parent_handle_position();
        }
    }
    date_changed() {
        let changed = false;
        const { new_start_date, new_end_date } = this.compute_start_end_date();
        if (Number(this.task._start) !== Number(new_start_date)) {
            changed = true;
            this.task._start = new_start_date;
        }
        if (Number(this.task._end) !== Number(new_end_date)) {
            changed = true;
            this.task._end = new_end_date;
        }
        if (!changed)
            return;
        this.gantt.date_change_event = new CustomEvent('date-change', {
            detail: {
                card: this.task,
                end: new_end_date,
                start: new_start_date
            }
        });
        this.gantt.element.dispatchEvent(this.gantt.date_change_event);
        // this.gantt.trigger_event('date_change', [
        //   this.task,
        //   new_start_date,
        //   date_utils.add(new_end_date, -1, 'second')
        // ]);
    }
    progress_changed() {
        const new_progress = this.compute_progress();
        this.task.progress = new_progress;
        this.gantt.progress_change_event = new CustomEvent('progress-change', {
            detail: {
                card: this.task,
                progress: new_progress
            }
        });
        this.gantt.element.dispatchEvent(this.gantt.progress_change_event);
        // this.gantt.trigger_event('progress_change', [this.task, new_progress]);
        requestAnimationFrame(() => {
            this.compute_parent_progress();
            this.update_parent_progressbar_position();
        });
    }
    set_action_completed() {
        this.action_completed = true;
        setTimeout(() => (this.action_completed = false), 1000);
    }
    compute_start_end_date() {
        const bar = this.$bar;
        const x_in_units = bar.getX() / this.gantt.options.column_width;
        const new_start_date = date_utils.add(this.gantt.gantt_start, x_in_units * this.gantt.options.step, 'hour');
        const width_in_units = bar.getWidth() / this.gantt.options.column_width;
        const new_end_date = date_utils.add(new_start_date, width_in_units * this.gantt.options.step, 'hour');
        return { new_end_date, new_start_date };
    }
    compute_progress() {
        return (this.$bar_progress.getWidth() / this.$bar.getWidth()) * 100;
    }
    compute_parent_progress() {
        const parent_tasks = this.gantt.getParent(this.gantt.tasks, this.task.id, true);
        for (const parent_task of parent_tasks) {
            const child_tasks = this.gantt.getChildren(this.gantt.tasks, parent_task.id, false);
            const child_tasks_weighted_mean_value = child_tasks.map((_task) => {
                return [_task.progress, _task.priority];
            });
            const progress = this.gantt.weightedMean(child_tasks_weighted_mean_value);
            parent_task.progress = progress;
            const parent_bar = this.gantt.get_bar(parent_task.id);
            parent_bar.task.progress = progress;
            requestAnimationFrame(() => {
                parent_bar.update_parent_progressbar_position();
            });
        }
    }
    compute_x() {
        const { step, column_width } = this.gantt.options;
        const task_start = this.task._start;
        const gantt_start = this.gantt.gantt_start;
        const diff = date_utils.diff(task_start, gantt_start, 'hour');
        let x = (diff / step) * column_width;
        if (this.gantt.view_is('Month')) {
            const diff = date_utils.diff(task_start, gantt_start, 'day');
            x = (diff * column_width) / 30;
        }
        return x;
    }
    compute_y() {
        return (this.gantt.options.padding / 2 +
            this.task._index * (this.height + this.gantt.options.padding));
    }
    get_snap_position(dx) {
        const odx = dx;
        let rem;
        let position;
        if (this.gantt.view_is('Week')) {
            rem = dx % (this.gantt.options.column_width / 7);
            position =
                odx -
                    rem +
                    (rem < this.gantt.options.column_width / 14
                        ? 0
                        : this.gantt.options.column_width / 7);
        }
        else if (this.gantt.view_is('Month')) {
            rem = dx % (this.gantt.options.column_width / 30);
            position =
                odx -
                    rem +
                    (rem < this.gantt.options.column_width / 60
                        ? 0
                        : this.gantt.options.column_width / 30);
        }
        else {
            rem = dx % this.gantt.options.column_width;
            position =
                odx -
                    rem +
                    (rem < this.gantt.options.column_width / 2
                        ? 0
                        : this.gantt.options.column_width);
        }
        return position;
    }
    update_attr(element, attr, value) {
        value = +value;
        if (!isNaN(value)) {
            element.setAttribute(attr, value);
        }
        return element;
    }
    update_progressbar_position() {
        if (this.$bar_progress) {
            this.$bar_progress.setAttribute('x', this.$bar.getX());
            if (this.task.progress) {
                this.$bar_progress.setAttribute('width', this.$bar.getWidth() * (this.task.progress / 100));
            }
        }
        this.$bar_progress.setAttribute('y', this.$bar.getY());
    }
    update_label_position() {
        const bar = this.$bar;
        const label = this.group.querySelector('.bar-label');
        if (label.getBBox().width > bar.getWidth()) {
            label.classList.add('big');
            label.setAttribute('x', bar.getX() + bar.getWidth() + 5);
        }
        else {
            label.classList.remove('big');
            label.setAttribute('x', bar.getX() + bar.getWidth() / 2);
        }
        label.setAttribute('y', bar.getY() + bar.getHeight() / 2);
    }
    update_symbol_position() {
        const bar = this.$bar;
        const symbol = this.group.querySelector('.bar-symbol');
        if (symbol.getBBox().width > bar.getWidth()) {
            symbol.classList.add('big');
            symbol.setAttribute('x', bar.getX() + bar.getWidth() + 5);
        }
        else {
            symbol.classList.remove('big');
            symbol.setAttribute('x', bar.getX() + this.symbol_height * 2);
        }
        symbol.setAttribute('y', bar.getY() + bar.getHeight() / 2 - this.symbol_height / 2);
    }
    update_assignments_position() {
        const bar = this.$bar;
        const bar_x = bar.getX();
        const bar_width = bar.getWidth();
        for (let i = 0; i < this.assignments.length; i++) {
            const assignment = this.assignments[i];
            const rect = assignment.rect;
            const image = assignment.image;
            $.attr(rect, { x: bar_x + bar_width + (i + 1) * 21 });
            $.attr(image, { x: bar_x + bar_width + (i + 1) * 21 });
        }
    }
    update_handle_position() {
        const bar = this.$bar;
        if (this.task.type !== 'project') {
            this.handle_group
                .querySelector('.handle.left')
                .setAttribute('x', bar.getX() + 1);
            this.handle_group
                .querySelector('.handle.right')
                .setAttribute('x', bar.getEndX() - 9);
            this.handle_group
                .querySelector('.handle.left')
                .setAttribute('y', bar.getY());
            this.handle_group
                .querySelector('.handle.right')
                .setAttribute('y', bar.getY());
        }
        this.handle_group
            .querySelector('.connector.left')
            .setAttribute('cx', bar.getX() - 1);
        this.handle_group
            .querySelector('.connector.right')
            .setAttribute('cx', bar.getEndX() - 1);
        this.handle_group
            .querySelector('.connector.left')
            .setAttribute('cy', bar.getY() + this.height / 2);
        this.handle_group
            .querySelector('.connector.right')
            .setAttribute('cy', bar.getY() + this.height / 2);
        const handle = this.group.querySelector('.handle.progress');
        handle &&
            handle.setAttribute('points', this.get_progress_polygon_points());
    }
    update_parent_progressbar_position() {
        if (this.task.parent) {
            const folder = this.gantt.get_bar(this.task.parent);
            const bar = folder.$bar;
            if (folder.$bar_progress) {
                folder.$bar_progress.setAttribute('x', bar.getX());
                if (folder.task.progress) {
                    folder.$bar_progress.setAttribute('width', bar.getWidth() * (folder.task.progress / 100));
                }
            }
            folder.$bar_progress.setAttribute('y', bar.getY());
        }
    }
    update_parent_label_position() {
        const folder = this.gantt.get_bar(this.task.parent);
        const bar = folder.$bar;
        const label = folder.group.querySelector('.bar-label');
        if (label.getBBox().width > bar.getWidth()) {
            label.classList.add('big');
            label.setAttribute('x', bar.getX() + bar.getWidth() + 5);
        }
        else {
            label.classList.remove('big');
            label.setAttribute('x', bar.getX() + bar.getWidth() / 2);
        }
        label.setAttribute('y', bar.getY() + bar.getHeight() / 2);
    }
    update_parent_symbol_position() {
        const folder = this.gantt.get_bar(this.task.parent);
        const bar = folder.$bar;
        const symbol = folder.group.querySelector('.bar-symbol');
        if (symbol.getBBox().width > bar.getWidth()) {
            symbol.classList.add('big');
            symbol.setAttribute('x', bar.getX() + bar.getWidth() + 5);
        }
        else {
            symbol.classList.remove('big');
            symbol.setAttribute('x', bar.getX() + folder.symbol_height * 2);
        }
        symbol.setAttribute('y', bar.getY() + bar.getHeight() / 2 - folder.symbol_height / 2);
    }
    update_parent_handle_position() {
        if (this.task.parent) {
            const folder = this.gantt.get_bar(this.task.parent);
            const bar = folder.$bar;
            if (folder.task.type !== 'project') {
                folder.handle_group
                    .querySelector('.handle.left')
                    .setAttribute('x', bar.getX() + 1);
                folder.handle_group
                    .querySelector('.handle.right')
                    .setAttribute('x', bar.getEndX() - 9);
                folder.handle_group
                    .querySelector('.handle.left')
                    .setAttribute('y', bar.getY());
                folder.handle_group
                    .querySelector('.handle.right')
                    .setAttribute('y', bar.getY());
            }
            folder.handle_group
                .querySelector('.connector.left')
                .setAttribute('cx', bar.getX() - 1);
            folder.handle_group
                .querySelector('.connector.right')
                .setAttribute('cx', bar.getEndX() - 1);
            folder.handle_group
                .querySelector('.connector.left')
                .setAttribute('cy', bar.getY() + folder.height / 2);
            folder.handle_group
                .querySelector('.connector.right')
                .setAttribute('cy', bar.getY() + folder.height / 2);
            const handle = folder.group.querySelector('.handle.progress');
            handle &&
                handle.setAttribute('points', folder.get_progress_polygon_points());
        }
    }
    update_connector_position() {
        this.connectors = this.connectors || [];
        for (const connector of this.connectors) {
            connector.update();
        }
    }
}

/* ngx-moment (c) 2015, 2016 Uri Shaked / MIT Licence */
import { EventEmitter } from '@angular/core';
import moment from 'moment';
import * as i0 from "@angular/core";
export class CalendarPipe {
    /**
     * Internal reference counter, so we can clean up when no instances are in use
     */
    static { this.refs = 0; }
    static { this.timer = null; }
    static { this.midnight = null; }
    constructor(cdRef, ngZone) {
        this.cdRef = cdRef;
        this.ngZone = ngZone;
        // using a single static timer for all instances of this pipe for performance reasons
        CalendarPipe.initTimer(ngZone);
        CalendarPipe.refs++;
        // values such as Today will need to be replaced with Yesterday after midnight,
        // so make sure we subscribe to an EventEmitter that we set up to emit at midnight
        this.midnightSub = CalendarPipe.midnight?.subscribe(() => {
            this.ngZone.run(() => this.cdRef.markForCheck());
        });
    }
    transform(value, ...args) {
        let formats = null;
        let referenceTime = null;
        for (let i = 0, len = args.length; i < len; i++) {
            if (args[i] !== null) {
                if (typeof args[i] === 'object' && !moment.isMoment(args[i])) {
                    formats = args[i];
                }
                else {
                    referenceTime = moment(args[i]);
                }
            }
        }
        return moment(value).calendar(referenceTime, formats);
    }
    ngOnDestroy() {
        if (CalendarPipe.refs > 0) {
            CalendarPipe.refs--;
        }
        if (CalendarPipe.refs === 0) {
            CalendarPipe.removeTimer();
        }
        this.midnightSub?.unsubscribe();
    }
    static initTimer(ngZone) {
        // initialize the timer
        if (!CalendarPipe.midnight) {
            CalendarPipe.midnight = new EventEmitter();
            if (typeof window !== 'undefined') {
                const timeToUpdate = CalendarPipe._getMillisecondsUntilUpdate();
                CalendarPipe.timer = ngZone.runOutsideAngular(() => {
                    return window.setTimeout(() => {
                        // emit the current date
                        CalendarPipe.midnight?.emit(new Date());
                        // refresh the timer
                        CalendarPipe.removeTimer();
                        CalendarPipe.initTimer(ngZone);
                    }, timeToUpdate);
                });
            }
        }
    }
    static removeTimer() {
        if (CalendarPipe.timer) {
            window.clearTimeout(CalendarPipe.timer);
            CalendarPipe.timer = null;
            CalendarPipe.midnight = null;
        }
    }
    static _getMillisecondsUntilUpdate() {
        const now = moment();
        const tomorrow = moment().startOf('day').add(1, 'days');
        const timeToMidnight = tomorrow.valueOf() - now.valueOf();
        return timeToMidnight + 1000; // 1 second after midnight
    }
    static { this.ɵfac = function CalendarPipe_Factory(t) { return new (t || CalendarPipe)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef, 16), i0.ɵɵdirectiveInject(i0.NgZone, 16)); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "amCalendar", type: CalendarPipe, pure: false }); }
}

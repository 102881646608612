import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class MemberReportService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    getMemberReport() {
        const url = `${this.config.appUrl}/report/member`;
        return this.http.get(url);
    }
    static { this.ɵfac = function MemberReportService_Factory(t) { return new (t || MemberReportService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MemberReportService, factory: MemberReportService.ɵfac, providedIn: 'root' }); }
}

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import { fromSnackbar } from "../../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "ngx-google-analytics";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/workspace.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/material/form-field";
import * as i11 from "ngx-dropzone";
import * as i12 from "../../modules/directives/default-image.directive";
import * as i13 from "@angular/material/input";
function EditWorkspaceDialogComponent_mat_error_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1, "Vui l\u00F2ng nh\u1EADp \u0111\u1EA7y \u0111\u1EE7 t\u00EAn club");
    i0.ɵɵelementEnd();
} }
function EditWorkspaceDialogComponent_ngx_dropzone_image_preview_25_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ngx-dropzone-image-preview", 19);
    i0.ɵɵlistener("removed", function EditWorkspaceDialogComponent_ngx_dropzone_image_preview_25_Template_ngx_dropzone_image_preview_removed_0_listener() { const restoredCtx = i0.ɵɵrestoreView(_r5); const f_r3 = restoredCtx.$implicit; const ctx_r4 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r4.onRemove(f_r3)); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const f_r3 = ctx.$implicit;
    i0.ɵɵproperty("file", f_r3)("removable", true);
} }
function EditWorkspaceDialogComponent_mat_error_26_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error", 20);
    i0.ɵɵtext(1, "Vui l\u00F2ng ch\u1ECDn \u1EA3nh cover");
    i0.ɵɵelementEnd();
} }
export class EditWorkspaceDialogComponent {
    constructor(config, $gaService, dialogRef, data, store, workspaceService) {
        this.config = config;
        this.$gaService = $gaService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.store = store;
        this.workspaceService = workspaceService;
        this.submited = false;
        this.loading = false;
        this.files = [];
        this.floatLabelControl = new FormControl('auto');
        this.form = new FormGroup({
            _id: new FormControl('', [Validators.required]),
            file: new FormControl(''),
            name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
        });
        const workspace = data.workspace;
        this.form.patchValue({
            _id: workspace._id,
            file: workspace.cover,
            name: workspace.name,
        });
    }
    onNoClick() {
        this.dialogRef.close(false);
    }
    async onSubmit() {
        this.submited = true;
        this.loading = true;
        if (this.form.valid) {
            try {
                const data = this.toFormData(this.form.value);
                const workspace = await lastValueFrom(this.workspaceService.updateWorkspace(data));
                if (this.config.ga)
                    this.$gaService.event("edit-workspace", 'workspace', 'edit-workspace');
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Cập nhật workspace thành công." }));
                this.dialogRef.close(workspace);
            }
            catch (err) {
                const errorMsg = err.error.message || err.error.error || "Connection error";
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
            }
            finally {
                this.loading = false;
            }
        }
    }
    onSelect($event) {
        this.files.push(...$event.addedFiles);
        if (this.files.length > 1) {
            this.replaceFile();
        }
        this.form.patchValue({
            file: this.files[0]
        });
    }
    onRemove(f) {
        this.files.splice(this.files.indexOf(f), 1);
        this.form.patchValue({
            file: null
        });
    }
    getFloatLabelValue() {
        return this.floatLabelControl.value || 'auto';
    }
    replaceFile() {
        this.files.splice(0, 1); // index =0 , remove_count = 1
    }
    toFormData(formValue) {
        const formData = new FormData();
        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }
        return formData;
    }
    static { this.ɵfac = function EditWorkspaceDialogComponent_Factory(t) { return new (t || EditWorkspaceDialogComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.GoogleAnalyticsService), i0.ɵɵdirectiveInject(i3.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA), i0.ɵɵdirectiveInject(i4.Store), i0.ɵɵdirectiveInject(i5.WorkspaceService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: EditWorkspaceDialogComponent, selectors: [["app-edit-workspace-dialog"]], decls: 33, vars: 14, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 3, "cdkTrapFocusAutoCapture"], [1, "col-12"], [1, "d-flex", "flex-column", "align-items-center", "my-2"], ["src", "assets/img/logo.png", 2, "height", "50px"], ["novalidate", "", 3, "formGroup", "ngSubmit"], [1, "row", "g-0", "px-4", "py-2", 2, "background", "#F2F2F2"], [1, "mt-2", "text-center", 2, "font-size", "1.5rem", "font-weight", "700"], [1, "mb-5", "text-center", 2, "font-size", ".8rem", "font-weight", "100", "font-style", "italic"], ["appearance", "outline", 1, "mb-3", "w-100", 3, "floatLabel", "hideRequiredMarker"], ["matInput", "", "autocomplete", "off", "id", "name", "name", "name", "formControlName", "name", "maxlength", "50", "placeholder", "Ex. C\u00F5i thi\u00EAn thai club"], [4, "ngIf"], [1, "mb-3"], ["accept", "image/jpeg,image/jpg,image/png,image/gif,image/webp", 3, "multiple", "maxFileSize", "change"], [1, "fa-thin", "fa-arrow-up-from-bracket", "fa-3x", "mb-2"], ["ngProjectAs", "ngx-dropzone-preview", "class", "ngx-dropzone-preview", 5, ["ngx-dropzone-preview"], 3, "file", "removable", "removed", 4, "ngFor", "ngForOf"], ["class", "mt-2", 4, "ngIf"], [1, "d-flex", "flex-row-reverse", "p-4"], ["mat-button", "", "cdkFocusInitial", "", 1, "ms-3", 2, "background", "#8817F8", "border-radius", "5px", "padding", "5px 50px", "color", "white", "font-size", "15px", "font-weight", "700", 3, "disabled"], ["mat-button", "", 3, "disabled", "click"], ["ngProjectAs", "ngx-dropzone-preview", 5, ["ngx-dropzone-preview"], 1, "ngx-dropzone-preview", 3, "file", "removable", "removed"], [1, "mt-2"]], template: function EditWorkspaceDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
            i0.ɵɵelement(3, "img", 3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "form", 4);
            i0.ɵɵlistener("ngSubmit", function EditWorkspaceDialogComponent_Template_form_ngSubmit_4_listener() { return ctx.onSubmit(); });
            i0.ɵɵelementStart(5, "div", 5)(6, "div", 1)(7, "p", 6);
            i0.ɵɵtext(8, "Th\u00F4ng tin \u0111\u1ED9ng l\u1EAFc");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(9, "p", 7);
            i0.ɵɵtext(10, "Qu\u1EA9y banh n\u00F3c c\u00F9ng tuesday");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(11, "p");
            i0.ɵɵtext(12, "T\u00EAn \u0111\u1ED9ng l\u1EAFc");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(13, "div")(14, "mat-form-field", 8);
            i0.ɵɵelement(15, "input", 9);
            i0.ɵɵtemplate(16, EditWorkspaceDialogComponent_mat_error_16_Template, 2, 0, "mat-error", 10);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(17, "div", 11)(18, "p");
            i0.ɵɵtext(19, "Cover");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(20, "ngx-dropzone", 12);
            i0.ɵɵlistener("change", function EditWorkspaceDialogComponent_Template_ngx_dropzone_change_20_listener($event) { return ctx.onSelect($event); });
            i0.ɵɵelementStart(21, "ngx-dropzone-label");
            i0.ɵɵelement(22, "i", 13);
            i0.ɵɵelementStart(23, "p");
            i0.ɵɵtext(24, "T\u1EA3i \u1EA3nh cover t\u1EEB thi\u1EBFt b\u1ECB c\u1EE7a b\u1EA1n");
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(25, EditWorkspaceDialogComponent_ngx_dropzone_image_preview_25_Template, 1, 2, "ngx-dropzone-image-preview", 14);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(26, EditWorkspaceDialogComponent_mat_error_26_Template, 2, 0, "mat-error", 15);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(27, "div", 16)(28, "button", 17);
            i0.ɵɵelement(29, "i");
            i0.ɵɵtext(30, " Update ");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(31, "button", 18);
            i0.ɵɵlistener("click", function EditWorkspaceDialogComponent_Template_button_click_31_listener() { return ctx.onNoClick(); });
            i0.ɵɵtext(32, "Cancel");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("formGroup", ctx.form);
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("floatLabel", ctx.getFloatLabelValue())("hideRequiredMarker", true);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("ngIf", ctx.form.controls["name"].invalid);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("multiple", false)("maxFileSize", 1000000);
            i0.ɵɵadvance(5);
            i0.ɵɵproperty("ngForOf", ctx.files);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngIf", ctx.submited && ctx.form.controls["file"].invalid);
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMapInterpolate1("fa fa-spinner fa-spin me-3 text-white ", ctx.loading ? "d-inline-flex" : "d-none", "");
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("disabled", ctx.loading);
        } }, dependencies: [i6.NgForOf, i6.NgIf, i7.ɵNgNoValidate, i7.DefaultValueAccessor, i7.NgControlStatus, i7.NgControlStatusGroup, i7.MaxLengthValidator, i8.MatButton, i9.CdkTrapFocus, i7.FormGroupDirective, i7.FormControlName, i10.MatFormField, i10.MatError, i11.NgxDropzoneComponent, i11.NgxDropzoneLabelDirective, i11.NgxDropzoneImagePreviewComponent, i12.DefaultImageDirective, i13.MatInput] }); }
}

import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../modules/directives/default-image.directive";
export class ConfirmDialogComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.title = data.title;
        this.message = data.message;
    }
    onDismiss() {
        this.dialogRef.close(false);
    }
    onConfirm() {
        this.dialogRef.close(true);
    }
    static { this.ɵfac = function ConfirmDialogComponent_Factory(t) { return new (t || ConfirmDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ConfirmDialogComponent, selectors: [["app-confirm-dialog"]], decls: 19, vars: 3, consts: [["cdkTrapFocus", "", 1, "row", "g-0", 2, "min-width", "400px", 3, "cdkTrapFocusAutoCapture"], [1, "col-12"], [1, "d-flex", "flex-column", "align-items-center", "my-2"], ["src", "assets/img/logo.png", 2, "height", "50px"], [1, "row", "g-0", "px-4", "py-2", 2, "background", "#F2F2F2"], [1, "mt-2", "mb-4"], [1, "row", "mb-2"], [1, "d-flex", "flex-row", "justify-content-between", "m-3"], [1, "d-flex", "justify-content-start"], [1, "d-flex", "justify-content-end"], ["mat-raised-button", "", "type", "button", "cdkFocusInitial", "", 1, "me-3", "py-2", 3, "click"], ["mat-raised-button", "", "color", "warn", "type", "button", 1, "py-2", 3, "click"]], template: function ConfirmDialogComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2);
            i0.ɵɵelement(3, "img", 3);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "div", 4)(5, "div", 1)(6, "h3", 5);
            i0.ɵɵtext(7);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(8, "div", 6)(9, "div", 1)(10, "p");
            i0.ɵɵtext(11);
            i0.ɵɵelementEnd()()()()();
            i0.ɵɵelementStart(12, "div", 7);
            i0.ɵɵelement(13, "div", 8);
            i0.ɵɵelementStart(14, "div", 9)(15, "button", 10);
            i0.ɵɵlistener("click", function ConfirmDialogComponent_Template_button_click_15_listener() { return ctx.onDismiss(); });
            i0.ɵɵtext(16, "No");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(17, "button", 11);
            i0.ɵɵlistener("click", function ConfirmDialogComponent_Template_button_click_17_listener() { return ctx.onConfirm(); });
            i0.ɵɵtext(18, "Yes");
            i0.ɵɵelementEnd()()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("cdkTrapFocusAutoCapture", true);
            i0.ɵɵadvance(7);
            i0.ɵɵtextInterpolate(ctx.title);
            i0.ɵɵadvance(4);
            i0.ɵɵtextInterpolate(ctx.message);
        } }, dependencies: [i2.MatButton, i3.CdkTrapFocus, i4.DefaultImageDirective] }); }
}

import { createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from "uuid";
import { fromSnackbar } from "../reducer";
import { AuthActionTypes, getMeFailure, getMeSuccess, guestSigninFailure, guestSigninSuccess, guestSignOutFailure, guestSignOutSuccess, init, setWorkspaceFailure, setWorkspaceSuccess, signinFailure, signinSuccess, signoutFailure, signoutSuccess, signupFailure, signupSuccess, } from "./auth.actions";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../../../services/auth.service";
import * as i3 from "../../../services/guest-auth.service";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../types/AppConfig";
import * as i7 from "ngx-google-analytics";
import * as i8 from "../../../services/socketio.service";
import * as i9 from "ngx-cookie-service";
export class AuthEffects {
    constructor(actions$, authService, guestAuthService, router, store, config, $gaService, socket, cookieService) {
        this.actions$ = actions$;
        this.authService = authService;
        this.guestAuthService = guestAuthService;
        this.router = router;
        this.store = store;
        this.config = config;
        this.$gaService = $gaService;
        this.socket = socket;
        this.cookieService = cookieService;
        this.initEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.INIT), map(() => {
            let device = localStorage.getItem('device');
            if (!device) {
                device = uuidv4();
                localStorage.setItem('device', device);
            }
            if (!this.cookieService.get('c_id')) {
                this.cookieService.set('c_id', device, 10 * 365, '/', 'tuesday.baby', true, 'Lax');
            }
            return { type: '[UserEffects]: Init' };
        })));
        this.signinEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNIN), exhaustMap(({ email, password }) => this.authService.signIn(email, password).pipe(map((response) => {
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 20000, panelClass: "success-dialog" }, message: 'Singin successfully' }));
            return signinSuccess({ device: response.device, token: response.token });
        }), catchError(err => {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
            return of(signinFailure({ error: err }));
        })))));
        this.signinSuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNIN_SUCCESS), tap((response) => {
            if (this.config.ga)
                this.$gaService.event("signin", 'auth', 'sinin');
            localStorage.setItem('x-access-token', response.token);
            localStorage.setItem('device', response.device);
            this.router.navigate(['/']);
        })), {
            dispatch: false,
        });
        this.signinOAuth2SuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.OAUTH2_SIGNIN_SUCCESS), tap((response) => {
            if (this.config.ga)
                this.$gaService.event("OAuth2 signin", 'auth', 'sinin');
            localStorage.setItem('OAuthToken', response.OAuthToken);
        })), {
            dispatch: false,
        });
        this.signupEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNUP), exhaustMap(({ email, password, name, user_type }) => this.authService.signUp(email, password, name, user_type).pipe(map((response) => {
            if (this.config.ga)
                this.$gaService.event("signup", 'auth', 'signup');
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: 'User created successfully' }));
            return signupSuccess({ token: response.token });
        }), tap(() => {
            this.router.navigate(['/auth/signin']);
        }), catchError((err) => {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
            return of(signupFailure({ error: err.message }));
        })))));
        this.getMeEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GET_ME), exhaustMap(() => this.authService.getUser().pipe(map((response) => {
            return getMeSuccess({ user: response });
        }), catchError(err => of(getMeFailure({ error: err })))))));
        this.signoutEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNOUT), exhaustMap(() => this.authService.signOut().pipe(map(() => {
            console.log('signout success');
            return signoutSuccess();
        }), catchError(err => of(signoutFailure()))))));
        this.signoutFailureEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNOUT_FAILURE), tap(() => {
            localStorage.removeItem('x-access-token');
            localStorage.removeItem('workspace');
            this.router.navigate(['/auth/signin']);
        })), {
            dispatch: false,
        });
        this.signoutSuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SIGNOUT_SUCCESS), tap(() => {
            if (this.config.ga)
                this.$gaService.event("signout", 'auth', 'signout');
            localStorage.removeItem('x-access-token');
            localStorage.removeItem('workspace');
            this.router.navigate(['/auth/signin']);
        })), {
            dispatch: false,
        });
        this.setWorkSpaceEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SET_WORKSPACE), exhaustMap(({ workspace: workspace }) => {
            return this.authService.setWorkspace({ workspace }).pipe(map((response) => {
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: 'Set workspace successfully' }));
                return setWorkspaceSuccess({ token: response.token, workspace });
            }), catchError(err => {
                const errorMsg = err.error.error || err.statusText || "Connection error";
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
                return of(setWorkspaceFailure({ error: err }));
            }));
        })));
        this.setWorkSpaceSuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.SET_WORKSPACE_SUCCESS), tap(async (response) => {
            if (this.config.ga)
                this.$gaService.event("change-workspace", 'auth', 'change-workspace');
            localStorage.setItem('workspace', response.workspace);
            localStorage.setItem('x-access-token', response.token);
            // this.socket.removeAllListeners();
            if (this.socket.ioSocket && !this.socket.ioSocket.connected) {
                this.socket.connect();
            }
            this.socket.emit("change-workspace", { token: response.token, workspace: response.workspace });
            this.router.navigate(['/']);
        })), {
            dispatch: false,
        });
        this.guestSigninEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GUEST_SIGNIN), exhaustMap(({ email, password }) => this.guestAuthService.signIn(email, password).pipe(map((response) => {
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: 'Singin successfully' }));
            return guestSigninSuccess({ device: response.device, token: response.token });
        }), catchError(err => {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
            return of(guestSigninFailure({ error: err }));
        })))));
        this.guestSigninSuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GUEST_SIGNIN_SUCCESS), tap((response) => {
            localStorage.setItem('x-access-token', response.token);
            this.router.navigate([`/guest-view/guest-report-view/`], { queryParams: { scode: response.secret_code } });
        })), {
            dispatch: false,
        });
        this.guestSignoutEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GUEST_SIGNOUT), exhaustMap(() => this.authService.guestSignOut().pipe(map(() => {
            console.log('signout success');
            return guestSignOutSuccess();
        }), catchError(err => of(guestSignOutFailure()))))));
        this.guestSignoutFailureEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GUEST_SIGNOUT_FAILURE), tap(() => {
            localStorage.removeItem('x-access-token');
            this.router.navigate(['/guest/login']);
        })), {
            dispatch: false,
        });
        this.guestSignoutSuccessEffect = createEffect(() => this.actions$.pipe(ofType(AuthActionTypes.GUEST_SIGNOUT_SUCCESS), tap(() => {
            if (this.config.ga)
                this.$gaService.event("signout", 'auth', 'signout');
            localStorage.removeItem('x-access-token');
            this.router.navigate(['/guest/login']);
        })), {
            dispatch: false,
        });
    }
    ngrxOnInitEffects() {
        return init();
    }
    static { this.ɵfac = function AuthEffects_Factory(t) { return new (t || AuthEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.GuestAuthService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.Store), i0.ɵɵinject(i6.AppConfig, 8), i0.ɵɵinject(i7.GoogleAnalyticsService), i0.ɵɵinject(i8.SocketioService), i0.ɵɵinject(i9.CookieService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthEffects, factory: AuthEffects.ɵfac }); }
}

import { facebookIndex } from "../types/socialMediaReportConfig";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class SocialMediaService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    smsNameTransform(value) {
        let stringArray = [];
        let name = value;
        if (value.indexOf('_')) {
            stringArray = value.split('_');
            for (const word in stringArray) {
                stringArray[word] = this.capitalizeFirstLetter(stringArray[word]);
            }
            name = stringArray.join(' ');
        }
        return name;
    }
    objectToBarChartData(dataobj) {
        const yData = [];
        const seriesData = [];
        for (const i in dataobj) {
            yData.push(i);
            seriesData.push(dataobj[i]);
        }
        return ([yData, seriesData]);
    }
    objectToMultiBarChartData(dataObj) {
        const _series = [];
        const nameList = [];
        for (const i in dataObj) {
            nameList.push(i);
            const toArrKey = i.split('-')[0];
            const keyName = i.split('-');
            keyName.splice(0, 1);
            const _keyName = keyName.join('-');
            if (_series.length === 0) {
                _series.push({ 'name': toArrKey, 'preData': [{ '_data': dataObj[i], '_name': _keyName }] });
            }
            else if (_series.length > 0 && _series.filter(x => x.name === toArrKey).length > 0) {
                _series.forEach(x => {
                    if (x.name === toArrKey) {
                        x.preData.push({ '_data': dataObj[i], '_name': _keyName });
                    }
                });
            }
            else if (_series.length > 0 && _series.filter(x => x.name === toArrKey).length === 0) {
                _series.push({ 'name': toArrKey, 'preData': [{ '_data': dataObj[i], '_name': _keyName }] });
            }
        }
        const yData = _series.reduce((x, y) => x.preData.length > y.preData.length ? x : y).preData.map((a) => a._name);
        for (const i in _series) {
            const pureData = [];
            for (const j in _series[i].preData) {
                for (const k in yData) {
                    if (_series[i].preData[j]._name === yData[k]) {
                        pureData[parseInt(k)] = _series[i].preData[j]._data;
                    }
                }
            }
            _series[i].data = pureData;
            delete _series[i].preData;
        }
        return ([yData, _series]);
    }
    multiBarChartConfigProcessing(yData, _series, title) {
        // _series = [{name, type, data}]
        _series.forEach(x => x.type = 'bar');
        const option = {
            grid: {
                bottom: '3%',
                containLabel: true,
                left: '3%',
                right: '4%'
            },
            legend: {
                left: 'right',
                orient: 'vertical',
                top: '20px'
            },
            series: _series,
            title: {
                left: 'center',
                text: title
            },
            tooltip: {
                axisPointer: {
                    type: 'shadow'
                },
                trigger: 'axis'
            },
            xAxis: {
                boundaryGap: [0, 0.01],
                data: yData,
                type: 'category'
            },
            yAxis: {
                type: 'value',
            }
        };
        return option;
    }
    barChartConfigProcessing(seriesData, yData, title) {
        const option = {
            grid: {
                bottom: '3%',
                containLabel: true,
                left: '3%',
                right: '4%'
            },
            series: [
                {
                    barWidth: '60%',
                    data: seriesData,
                    name: 'Direct',
                    type: 'bar'
                }
            ],
            title: {
                // subtext: 'Fake Data',
                left: 'center',
                text: title
            },
            tooltip: {
                axisPointer: {
                    type: 'shadow'
                },
                trigger: 'axis'
            },
            xAxis: [
                {
                    type: 'value'
                }
            ],
            yAxis: [
                {
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: yData,
                    type: 'category'
                }
            ]
        };
        return option;
    }
    verticalBarChartConfigProcessing(seriesData, yData, title) {
        const option = {
            grid: {
                bottom: '3%',
                containLabel: true,
                left: '3%',
                right: '4%'
            },
            series: [
                {
                    barWidth: '60%',
                    data: seriesData,
                    name: 'Direct',
                    type: 'bar'
                }
            ],
            title: {
                // subtext: 'Fake Data',
                left: 'center',
                text: title
            },
            tooltip: {
                axisPointer: {
                    type: 'shadow'
                },
                trigger: 'axis'
            },
            xAxis: [
                {
                    data: yData,
                    type: 'category',
                }
            ],
            yAxis: [
                {
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: yData,
                    type: 'value'
                }
            ]
        };
        return option;
    }
    pieChartConfigProcessing(dataObj, title) {
        return {
            legend: {
                left: 'right',
                orient: 'vertical',
                top: '10%'
            },
            series: [
                {
                    data: dataObj,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            shadowOffsetX: 0
                        }
                    },
                    name: 'Access From',
                    radius: '80%',
                    top: '10%',
                    type: 'pie'
                }
            ],
            title: {
                // subtext: 'Fake Data',
                left: 'center',
                text: title
            },
            tooltip: {
                trigger: 'item'
            }
        };
    }
    lineChartConfigProcessing(dataObj) {
        dataObj.data.sort((a, b) => (a.end_time > b.end_time) ? 1 : ((b.end_time > a.end_time) ? -1 : 0));
        const timeSeries = [];
        const dataSeries = [];
        let config;
        if (typeof dataObj.data[0].value !== 'object') {
            for (const item of dataObj.data) {
                timeSeries.push(item.end_time);
                dataSeries.push(item.value);
            }
            config = {
                legend: {
                    // Try 'horizontal'
                    orient: 'vertical',
                    right: 5,
                    top: 'center'
                },
                series: [
                    {
                        data: dataSeries,
                        name: 'value',
                        type: 'line',
                    }
                ],
                title: {
                    left: 'center',
                    text: this.smsNameTransform(dataObj._id.name)
                },
                tooltip: {
                    axisPointer: {
                        type: 'shadow'
                    },
                    trigger: 'axis'
                },
                xAxis: {
                    data: timeSeries,
                    type: 'category',
                },
                yAxis: {
                    type: 'value',
                },
            };
        }
        else if (typeof dataObj.data[0].value === 'object') {
            for (const item of dataObj.data) {
                timeSeries.push(item.end_time);
            }
            for (const name in dataObj.data[0].value) {
                const lineDataSeries = { 'data': [0], name, 'type': 'line' };
                const lineSubData = [];
                for (const _item of dataObj.data) {
                    lineSubData.push(_item.value[name]);
                }
                lineDataSeries.data = lineSubData;
                dataSeries.push(lineDataSeries);
            }
            config = {
                legend: {
                    // Try 'horizontal'
                    orient: 'vertical',
                    right: 5,
                    top: 'center'
                },
                series: dataSeries,
                title: {
                    left: 'center',
                    text: this.smsNameTransform(dataObj._id.name)
                },
                xAxis: {
                    data: timeSeries,
                    type: 'category',
                },
                yAxis: {
                    type: 'value',
                }
            };
        }
        return config;
    }
    fbCategoryMetrics(data) {
        let processedData = [];
        const viewConfigHalf = 'halfView';
        const viewConfigFull = 'fullView';
        for (const i in facebookIndex) {
            processedData.push({ '_data': [], 'name': facebookIndex[i].name, 'option': [] });
            for (const j in data) {
                if (facebookIndex[i].detail.includes(data[j]._id.name)) {
                    processedData[i]._data.push(data[j]);
                }
            }
        }
        for (const ii in processedData) {
            if (processedData[ii].name === 'page_engagements' || processedData[ii].name === 'page_impressions') {
                for (const jj in processedData[ii]._data) {
                    const config = this.lineChartConfigProcessing(processedData[ii]._data[jj]);
                    processedData[ii].option.push({ config, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf });
                }
            }
            else if (processedData[ii].name === 'page_reactions') {
                const dataConfigArr = [];
                for (const jj in processedData[ii]._data) {
                    if (processedData[ii]._data[jj]._id.name !== 'page_actions_post_reactions_total') {
                        const total = processedData[ii]._data[jj].data.reduce((x, y) => x + y.value, 0);
                        processedData[ii]._data[jj]._total = total;
                        dataConfigArr.push({ 'name': this.smsNameTransform(processedData[ii]._data[jj]._id.name), 'value': total });
                    }
                }
                const config = this.pieChartConfigProcessing(dataConfigArr, this.smsNameTransform(processedData[ii].name));
                processedData[ii].option.push({ config, 'name': processedData[ii].name, 'viewConfig': viewConfigFull });
            }
            else if (processedData[ii].name === 'page_video_views') {
                const dataConfigArr = [];
                const filterData = processedData[ii]._data.filter((x) => x._id.name !== 'page_video_views');
                filterData.forEach((elem) => {
                    const total = elem.data.reduce((x, y) => x + y.value, 0);
                    dataConfigArr.push({ 'name': this.smsNameTransform(elem._id.name), 'value': total });
                });
                const config = this.pieChartConfigProcessing(dataConfigArr, this.smsNameTransform(processedData[ii].name));
                processedData[ii].option.push({ config, 'name': processedData[ii].name, 'viewConfig': viewConfigFull });
            }
            else if (processedData[ii].name === 'page_user_demographics') {
                for (const jj in processedData[ii]._data) {
                    const minDateData = processedData[ii]._data[jj].data.filter((x) => new Date(x.end_time).getTime() === Math.min(...processedData[ii]._data[jj].data.map((o) => new Date(o.end_time).getTime())));
                    const maxDateData = processedData[ii]._data[jj].data.filter((x) => new Date(x.end_time).getTime() === Math.max(...processedData[ii]._data[jj].data.map((o) => new Date(o.end_time).getTime())));
                    if (processedData[ii]._data[jj]._id.name === 'page_fans_locale' || processedData[ii]._data[jj]._id.name === 'page_fans_country') {
                        const config_start = this.verticalBarChartConfigProcessing(this.objectToBarChartData(minDateData[0].value)[1], this.objectToBarChartData(minDateData[0].value)[0], this.smsNameTransform(minDateData[0].name) + ' ' + minDateData[0].end_time);
                        const config_end = this.verticalBarChartConfigProcessing(this.objectToBarChartData(maxDateData[0].value)[1], this.objectToBarChartData(maxDateData[0].value)[0], this.smsNameTransform(maxDateData[0].name) + ' ' + maxDateData[0].end_time);
                        processedData[ii].option.push(...[{ 'config': config_start, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }, { 'config': config_end, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }]);
                    }
                    else if (processedData[ii]._data[jj]._id.name === 'page_fans_city') {
                        const config_start = this.barChartConfigProcessing(this.objectToBarChartData(minDateData[0].value)[1], this.objectToBarChartData(minDateData[0].value)[0], this.smsNameTransform(minDateData[0].name) + ' ' + minDateData[0].end_time);
                        const config_end = this.barChartConfigProcessing(this.objectToBarChartData(maxDateData[0].value)[1], this.objectToBarChartData(maxDateData[0].value)[0], this.smsNameTransform(maxDateData[0].name) + ' ' + maxDateData[0].end_time);
                        processedData[ii].option.push(...[{ 'config': config_start, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }, { 'config': config_end, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }]);
                    }
                    else if (processedData[ii]._data[jj]._id.name === 'page_fans_gender_age') {
                        const config_start = this.multiBarChartConfigProcessing(this.objectToMultiBarChartData(minDateData[0].value)[0], this.objectToMultiBarChartData(minDateData[0].value)[1], this.smsNameTransform(minDateData[0].name) + ' ' + minDateData[0].end_time);
                        const config_end = this.multiBarChartConfigProcessing(this.objectToMultiBarChartData(maxDateData[0].value)[0], this.objectToMultiBarChartData(maxDateData[0].value)[1], this.smsNameTransform(maxDateData[0].name) + ' ' + maxDateData[0].end_time);
                        processedData[ii].option.push(...[{ 'config': config_start, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }, { 'config': config_end, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigHalf }]);
                    }
                    else {
                        const config = this.lineChartConfigProcessing(processedData[ii]._data[jj]);
                        processedData[ii].option.push({ config, 'name': processedData[ii]._data[jj]._id.name, 'viewConfig': viewConfigFull });
                    }
                }
            }
        }
        processedData = processedData.filter(x => x._data.length > 0);
        return processedData;
    }
    socialMediaAnalyze(data) {
        const url = `${this.config.appUrl}/sms-report/social-media-analyze`;
        return this.http.post(url, data);
    }
    socialMediaReportConfigUpdate(data) {
        const url = `${this.config.appUrl}/sms-report-metrics/updateChannelMetrics`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function SocialMediaService_Factory(t) { return new (t || SocialMediaService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SocialMediaService, factory: SocialMediaService.ɵfac, providedIn: 'root' }); }
}

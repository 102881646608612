/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/tooltip";
function NotificationActionComponent_div_1_option_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const actionType_r5 = ctx.$implicit;
    i0.ɵɵproperty("value", actionType_r5.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(actionType_r5.label);
} }
function NotificationActionComponent_div_1_button_8_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function NotificationActionComponent_div_1_button_8_Template_button_click_0_listener() { i0.ɵɵrestoreView(_r9); const i_r2 = i0.ɵɵnextContext().index; const ctx_r7 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r7.removeAction(i_r2)); });
    i0.ɵɵelement(1, "i", 13);
    i0.ɵɵelementEnd();
} }
function NotificationActionComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4)(2, "select", 5);
    i0.ɵɵlistener("ngModelChange", function NotificationActionComponent_div_1_Template_select_ngModelChange_2_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r11); const action_r1 = restoredCtx.$implicit; return i0.ɵɵresetView(action_r1.action = $event); });
    i0.ɵɵelementStart(3, "option", 6);
    i0.ɵɵtext(4, "Select one action");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(5, NotificationActionComponent_div_1_option_5_Template, 2, 2, "option", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "input", 8);
    i0.ɵɵlistener("ngModelChange", function NotificationActionComponent_div_1_Template_input_ngModelChange_6_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r11); const action_r1 = restoredCtx.$implicit; return i0.ɵɵresetView(action_r1.title = $event); });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(7, "input", 9);
    i0.ɵɵlistener("ngModelChange", function NotificationActionComponent_div_1_Template_input_ngModelChange_7_listener($event) { const restoredCtx = i0.ɵɵrestoreView(_r11); const action_r1 = restoredCtx.$implicit; return i0.ɵɵresetView(action_r1.url = $event); });
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, NotificationActionComponent_div_1_button_8_Template, 2, 0, "button", 10);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const action_r1 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngModel", action_r1.action)("disabled", action_r1.title === "default");
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", ctx_r0._actionTypes);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", action_r1.title)("disabled", action_r1.title === "default");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngModel", action_r1.url);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", action_r1.title !== "default");
} }
export class NotificationActionComponent {
    constructor() {
        this._actions = [];
        this._actionTypes = [{ label: "Open URL", value: "open_url" }, { label: "Open App", value: "open_app" }];
        this.propagateChange = (_) => {
        };
        this.propagateTouched = (_) => {
        };
    }
    get actions() {
        return this._actions;
    }
    set actions(value) {
        this._actions = value;
        this.propagateChange(this._actions);
    }
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    registerOnTouched(fn) {
        this.propagateTouched = fn;
    }
    writeValue(value) {
        if (value !== undefined) {
            this._actions = value;
        }
    }
    addAction() {
        const action = { action: "open_url", title: "Action new", url: "https://google.com" };
        this._actions.push(action);
    }
    removeAction(i) {
        this._actions.splice(i, 1);
    }
    static { this.ɵfac = function NotificationActionComponent_Factory(t) { return new (t || NotificationActionComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotificationActionComponent, selectors: [["app-notification-action"]], features: [i0.ɵɵProvidersFeature([
                {
                    multi: true,
                    provide: NG_VALUE_ACCESSOR,
                    useExisting: forwardRef(() => NotificationActionComponent)
                }
            ])], decls: 4, vars: 1, consts: [[1, "d-flex", "flex-column"], ["class", "card p-2 d-flex flex-column mb-2 position-relative", 4, "ngFor", "ngForOf"], ["matTooltip", "Add new action", "matTooltipShowDelay", "1000", 1, "btn", "btn-outline-secondary", "w-100", 3, "click"], [1, "card", "p-2", "d-flex", "flex-column", "mb-2", "position-relative"], [1, "d-flex", "flex-row", "mb-2"], ["aria-label", "Default select example", 1, "form-select", "me-2", 3, "ngModel", "disabled", "ngModelChange"], ["selected", ""], [3, "value", 4, "ngFor", "ngForOf"], ["type", "text", "placeholder", "Text of action button", "aria-label", "Text of action button", 1, "form-control", 3, "ngModel", "disabled", "ngModelChange"], ["type", "text", "placeholder", "Url or App", "aria-label", "Url or app to open", 1, "form-control", 3, "ngModel", "ngModelChange"], ["class", "btn-remove", "matTooltip", "Remove this action", 3, "click", 4, "ngIf"], [3, "value"], ["matTooltip", "Remove this action", 1, "btn-remove", 3, "click"], [1, "fa-solid", "fa-trash"]], template: function NotificationActionComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtemplate(1, NotificationActionComponent_div_1_Template, 9, 7, "div", 1);
            i0.ɵɵelementStart(2, "button", 2);
            i0.ɵɵlistener("click", function NotificationActionComponent_Template_button_click_2_listener() { return ctx.addAction(); });
            i0.ɵɵtext(3, "Add action");
            i0.ɵɵelementEnd()();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngForOf", ctx._actions);
        } }, dependencies: [i1.NgForOf, i1.NgIf, i2.NgSelectOption, i2.ɵNgSelectMultipleOption, i2.DefaultValueAccessor, i2.SelectControlValueAccessor, i2.NgControlStatus, i2.NgModel, i3.MatTooltip], styles: ["[_nghost-%COMP%]  .card {\n  transition: all 0.3s ease-in-out;\n}\n[_nghost-%COMP%]  .card:hover {\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;\n}\n[_nghost-%COMP%]  .card:hover .btn-remove {\n  opacity: 1;\n}\n[_nghost-%COMP%]  .card .btn-remove {\n  position: absolute;\n  top: -8px;\n  right: -8px;\n  box-sizing: content-box;\n  width: 16px;\n  height: 16px;\n  padding: 0.25em 0.25em;\n  color: #FFFFFF;\n  background: #ff8585;\n  border: 0;\n  border-radius: 50%;\n  line-height: 16px;\n  opacity: 0;\n}\n[_nghost-%COMP%]  .card .btn-remove i {\n  font-size: 12px !important;\n  line-height: 12px !important;\n}\n[_nghost-%COMP%]  .card .btn-remove:hover {\n  background: #d52e2e;\n}"] }); }
}

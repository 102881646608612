import { ConnectionPositionPair } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { EventEmitter } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
export class PopoverDirective {
    constructor(elementRef, overlay, vcr, render) {
        this.elementRef = elementRef;
        this.overlay = overlay;
        this.vcr = vcr;
        this.render = render;
        this.createPopover = new EventEmitter();
        this.unsubscribe = new Subject();
    }
    ngOnInit() {
        this.createOverlay();
    }
    createOverlay() {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        const positionStrategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([
            new ConnectionPositionPair({ originX: "start", originY: "center" }, { overlayX: "start", overlayY: "center" }),
            new ConnectionPositionPair({ originX: "start", originY: "center" }, { overlayX: "start", overlayY: "center" })
        ]);
        this.overlayRef = this.overlay.create({
            backdropClass: "",
            hasBackdrop: true,
            positionStrategy,
            scrollStrategy
        });
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
            this.detachOverlay();
        });
        this.createPopover.emit(this.overlayRef);
        // this.render.listen(this.overlayRef.hostElement, 'click', this.onClick);
    }
    ngOnDestroy() {
        this.detachOverlay();
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }
    attachOverlay() {
        if (!this.overlayRef.hasAttached()) {
            const periodSelectorPortal = new TemplatePortal(this.popoverTrigger, this.vcr);
            this.overlayRef.attach(periodSelectorPortal);
        }
    }
    detachOverlay() {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
    clickOut() {
        this.attachOverlay();
    }
    static { this.ɵfac = function PopoverDirective_Factory(t) { return new (t || PopoverDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Overlay), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Renderer2)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: PopoverDirective, selectors: [["", "popoverTrigger", ""]], hostBindings: function PopoverDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function PopoverDirective_click_HostBindingHandler() { return ctx.clickOut(); });
        } }, inputs: { popoverTrigger: "popoverTrigger" }, outputs: { createPopover: "createPopover" } }); }
}

import { createReducer, on } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { getMe, getMeFailure, getMeSuccess, guestSignin, guestSigninFailure, guestSigninSuccess, guestSignOut, guestSignOutFailure, guestSignOutSuccess, oAuth2SigninSuccess, setWorkspace, setWorkspaceFailure, setWorkspaceSuccess, signin, signinFailure, signinSuccess, signout, signoutFailure, signoutSuccess, signup, signupFailure, signupSuccess } from "./auth.actions";
export const authFeatureKey = 'auth';
const getOAuthTokenFromLocalStorage = () => {
    return localStorage.getItem('OAuthToken')?.toString();
};
const getTokenFromLocalStorage = () => {
    return localStorage.getItem('x-access-token')?.toString();
};
const getDeviceFromLocalStorage = () => {
    let device = localStorage.getItem('device');
    if (!device) {
        device = uuidv4();
        localStorage.setItem('device', device);
    }
    return device;
};
const getWorkspaceFromLocalStorage = () => {
    return localStorage.getItem('workspace')?.toString();
};
export const initialState = {
    OAuthToken: getOAuthTokenFromLocalStorage(),
    device: getDeviceFromLocalStorage(),
    error: undefined,
    loading: false,
    me: undefined,
    token: getTokenFromLocalStorage(),
    workspace: getWorkspaceFromLocalStorage()
};
const _authReducer = createReducer(initialState, 
// signin
on(signin, state => ({ ...state, loading: true })), on(signinSuccess, (state, { token, device }) => ({ ...state, device, loading: false, token })), on(signinFailure, (state, { error }) => ({ ...state, error, loading: false })), on(oAuth2SigninSuccess, (state, { OAuthToken }) => ({ ...state, OAuthToken })), 
// signup
on(signup, (state) => ({ ...state, loading: true })), on(signupSuccess, (state, { token }) => ({ ...state, loading: false, token })), on(signupFailure, (state, { error }) => ({ ...state, error, loading: false })), 
// signOut
on(signout, state => ({ ...state, loading: true })), on(signoutSuccess, state => ({ ...state, loading: false, me: undefined, token: undefined, workspace: undefined })), on(signoutFailure, state => ({ ...state, loading: false })), 
// getMe
on(getMe, state => ({ ...state, loading: true })), on(getMeSuccess, (state, { user }) => ({ ...state, loading: false, me: user, workspace: user.workspace })), on(getMeFailure, (state, { error }) => ({ ...state, error, loading: false })), on(setWorkspace, state => ({ ...state, loading: true })), on(setWorkspaceSuccess, (state, { token, workspace }) => ({ ...state, loading: false, token, workspace })), on(setWorkspaceFailure, (state, { error }) => ({ ...state, error, loading: false })), on(guestSignin, state => ({ ...state, loading: true })), on(guestSigninSuccess, (state, { token }) => ({ ...state, loading: false, token })), on(guestSigninFailure, (state, { error }) => ({ ...state, error, loading: false })), on(guestSignOut, state => ({ ...state, loading: true })), on(guestSignOutSuccess, state => ({ ...state, loading: false, token: undefined })), on(guestSignOutFailure, state => ({ ...state, loading: false })));
export function reducer(state, action) {
    return _authReducer(state, action);
}

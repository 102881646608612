import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@auth0/angular-jwt";
import * as i3 from "../types/AppConfig";
import * as i4 from "@ngrx/store";
export class PublishingService {
    constructor(http, jwtHelper, config, store) {
        this.http = http;
        this.jwtHelper = jwtHelper;
        this.config = config;
        this.store = store;
    }
    scheduledFbFeed(data) {
        const url = `${this.config.appUrl}/publishing/post-feed-fb`;
        return this.http.post(url, data);
    }
    getFinishedPost(data) {
        const url = `${this.config.appUrl}/publishing/filter-finished-post`;
        return this.http.post(url, data);
    }
    deleteFinishedPost(id) {
        const url = `${this.config.appUrl}/publishing/post-feed-fb/${id}`;
        return this.http.delete(url);
    }
    static { this.ɵfac = function PublishingService_Factory(t) { return new (t || PublishingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JwtHelperService), i0.ɵɵinject(i3.AppConfig, 8), i0.ɵɵinject(i4.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PublishingService, factory: PublishingService.ɵfac, providedIn: 'root' }); }
}

import { Observable } from "rxjs";
import { fromSnackbar } from "../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
import * as i3 from "@angular/service-worker";
import * as i4 from "@ngrx/store";
export class PushNotificationService {
    constructor(http, config, swPush, store) {
        this.http = http;
        this.config = config;
        this.swPush = swPush;
        this.store = store;
        this.permission = this.isSupported() ? "default" : "denied";
    }
    isSupported() {
        return "Notification" in window;
    }
    requestPermission() {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                console.log("Permission has been granted");
                this.permission = "granted";
            }
            else if (Notification.permission !== "denied") {
                console.log("Requesting permission...");
                this.swPush.requestSubscription({
                    serverPublicKey: this.config.gcm?.vapid?.publicKey ? this.config.gcm?.vapid?.publicKey : '', // VAPID public key
                }).then(sub => {
                    console.log("Subscribed to notifications", sub);
                    this.subscription(sub).subscribe();
                })
                    .catch(err => {
                    console.error("Could not subscribe to notifications", err);
                    this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: 'Could not subscribe to notifications' }));
                });
            }
        }
        else {
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: 'This browser does not support desktop notification' }));
        }
    }
    subscription(subscription) {
        const url = `${this.config.appUrl}/notification/subscribe`;
        return this.http.post(url, subscription);
    }
    preview(data) {
        const url = `${this.config.appUrl}/notification/preview`;
        return this.http.post(url, data);
    }
    create(title, options) {
        const self = this;
        return new Observable((obs) => {
            if (!("Notification" in window)) {
                console.log("Notifications are not available in this environment");
                obs.complete();
            }
            if (self.permission !== "granted") {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            const _notify = new Notification(title, options);
            _notify.onshow = function (e) {
                // auto close after 7.5 seconds
                // setTimeout(() => {
                //   _notify.close();
                // }, 7500);
                return obs.next({
                    event: e,
                    notification: _notify
                });
            };
            _notify.onclick = function (e) {
                _notify.close();
                return obs.next({
                    event: e,
                    notification: _notify
                });
            };
            _notify.onerror = function (e) {
                return obs.error({
                    event: e,
                    notification: _notify
                });
            };
            _notify.onclose = function () {
                return obs.complete();
            };
        });
    }
    generateNotification(source) {
        if (this.permission === "granted") {
            source.forEach((item, i) => {
                const options = {
                    badge: item.badge,
                    body: item.body,
                    icon: item.icon,
                    image: item.image,
                    tag: item.tag,
                };
                setTimeout(() => {
                    const ref = this.create(item.title, options).subscribe((res) => {
                        console.log("res", res);
                    });
                    console.log("ref", ref);
                }, i * 3000);
            });
        }
    }
    static { this.ɵfac = function PushNotificationService_Factory(t) { return new (t || PushNotificationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8), i0.ɵɵinject(i3.SwPush), i0.ɵɵinject(i4.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PushNotificationService, factory: PushNotificationService.ɵfac, providedIn: 'root' }); }
}

import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { authFeatureKey } from "../modules/stores/auth";
import { fromAuth } from "../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class TokenInterceptorService {
    constructor(store) {
        this.store = store;
        this.authState = this.store.select(authFeatureKey);
        this.authState.subscribe(auth => {
            this.token = auth.token;
            this.device = auth.device;
        });
    }
    intercept(request, next) {
        request = request.clone({
            withCredentials: true
        });
        if (this.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
        }
        if (this.device) {
            request = request.clone({
                setHeaders: {
                    device: this.device || ''
                },
            });
        }
        return next.handle(request).pipe(catchError((err) => {
            if (err.status === 401) {
                console.log("401 error");
                this.store.dispatch(fromAuth.signout());
            }
            const error = err || (err.error && err.error.message) || err.statusText;
            return throwError(error);
        }));
    }
    static { this.ɵfac = function TokenInterceptorService_Factory(t) { return new (t || TokenInterceptorService)(i0.ɵɵinject(i1.Store)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TokenInterceptorService, factory: TokenInterceptorService.ɵfac, providedIn: 'root' }); }
}

import { filter, map } from "rxjs/operators";
import { fromSnackbar } from "../modules/stores/reducer";
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ngrx/store";
import * as i3 from "../types/AppConfig";
function promptUser(event) {
    return true;
}
export class PromptUpdateService {
    constructor(swUpdate, store, config) {
        this.swUpdate = swUpdate;
        this.store = store;
        this.config = config;
        if (config.production) {
            swUpdate.versionUpdates
                .pipe(filter((evt) => evt.type === 'VERSION_READY'))
                .subscribe(evt => {
                if (promptUser(evt)) {
                    // Reload the page to update to the latest version.
                    store.dispatch(fromSnackbar.showSnackbar({ action: 'Ok', config: { duration: 5000, panelClass: "success-dialog" }, message: "Reloading to update to the latest version." }));
                    console.log('Reloading to update to the latest version.');
                    document.location.reload();
                }
            });
            const updatesAvailable = swUpdate.versionUpdates.pipe(filter((evt) => evt.type === 'VERSION_READY'), map(evt => ({
                available: evt.latestVersion,
                current: evt.currentVersion,
                type: 'UPDATE_AVAILABLE',
            })));
        }
    }
    static { this.ɵfac = function PromptUpdateService_Factory(t) { return new (t || PromptUpdateService)(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.AppConfig)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PromptUpdateService, factory: PromptUpdateService.ɵfac, providedIn: 'root' }); }
}

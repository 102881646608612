import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class MemberReportWSService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    // ws-member-report
    getMemberReportWS(start_date) {
        const url = `${this.config.appUrl}/report/ws-member-report?start_date=${start_date}`;
        return this.http.get(url);
    }
    static { this.ɵfac = function MemberReportWSService_Factory(t) { return new (t || MemberReportWSService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: MemberReportWSService, factory: MemberReportWSService.ɵfac, providedIn: 'root' }); }
}

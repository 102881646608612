import { lastValueFrom } from "rxjs";
import { authFeatureKey } from "../../modules/stores/auth";
import { fromSnackbar } from "../../modules/stores/reducer";
import { snackbarFeatureKey } from "../../modules/stores/snackbar";
import * as i0 from "@angular/core";
import * as i1 from "../../types/AppConfig";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "../../services/auth.service";
import * as i5 from "@angular/forms";
import * as i6 from "../../modules/dotlottie-player/dotlottie-player.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/material/form-field";
import * as i10 from "../../modules/directives/default-image.directive";
import * as i11 from "@angular/material/input";
export class ForgotPassComponent {
    constructor(config, store, snackBar, authService) {
        this.config = config;
        this.store = store;
        this.snackBar = snackBar;
        this.authService = authService;
        this.loading = false;
        this.authState = this.store.select(authFeatureKey);
        this.snackBarState = this.store.select(snackbarFeatureKey);
    }
    async onSubmit(f) {
        try {
            if (f.valid) {
                this.loading = true;
                const forgot = await lastValueFrom(this.authService.forgot(f.value));
                this.store.dispatch(fromSnackbar.showSnackbar({ action: 'OK', config: { duration: 5000, panelClass: "success-dialog" }, message: "Gửi email lấy lại mật khẩu thành công" }));
            }
        }
        catch (err) {
            const errorMsg = err.error.message || err.error.error || "Connection error";
            this.store.dispatch(fromSnackbar.showSnackbar({ action: 'Retry', config: { duration: 5000, panelClass: "danger-dialog" }, message: errorMsg }));
        }
        finally {
            this.loading = false;
        }
    }
    googleAuth($event) {
        const callback = encodeURI(`${this.config.url}/auth/oauth-success`);
        window.location.href = `${this.config.appBaseUrl}/api/auth/google?callback=${callback}`;
    }
    facebookAuth($event) {
        const callback = encodeURI(`${this.config.url}/auth/oauth-success`);
        window.location.href = `${this.config.appBaseUrl}/api/auth/facebook?callback=${callback}`;
    }
    static { this.ɵfac = function ForgotPassComponent_Factory(t) { return new (t || ForgotPassComponent)(i0.ɵɵdirectiveInject(i1.AppConfig, 8), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.MatSnackBar), i0.ɵɵdirectiveInject(i4.AuthService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ForgotPassComponent, selectors: [["app-forgot-pass"]], decls: 41, vars: 6, consts: function () { let i18n_0; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_welcome$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_1 = goog.getMsg("Tri\u1EC7u h\u1ED3i n\u00E3o!");
            i18n_0 = MSG_EXTERNAL_forgot_welcome$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_1;
        }
        else {
            i18n_0 = $localize `:@@forgot.welcome:Triệu hồi não!`;
        } let i18n_2; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_email_label$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_3 = goog.getMsg("Enter your email");
            i18n_2 = MSG_EXTERNAL_forgot_email_label$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_3;
        }
        else {
            i18n_2 = $localize `:@@forgot.email.label:Enter your email`;
        } let i18n_4; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_email_placeholder$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_5 = goog.getMsg("pat@example.com");
            i18n_4 = MSG_EXTERNAL_forgot_email_placeholder$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_5;
        }
        else {
            i18n_4 = $localize `:@@forgot.email.placeholder:pat@example.com`;
        } let i18n_6; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_forgot_button$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_7 = goog.getMsg("Sent email");
            i18n_6 = MSG_EXTERNAL_forgot_forgot_button$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_7;
        }
        else {
            i18n_6 = $localize `:@@forgot.forgot-button:Sent email`;
        } let i18n_8; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_if_have_a_password$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_9 = goog.getMsg("If you have a password.");
            i18n_8 = MSG_EXTERNAL_forgot_if_have_a_password$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_9;
        }
        else {
            i18n_8 = $localize `:@@forgot.if-have-a-password:If you have a password.`;
        } let i18n_10; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_signin_button$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_11 = goog.getMsg("Sign In");
            i18n_10 = MSG_EXTERNAL_forgot_signin_button$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_11;
        }
        else {
            i18n_10 = $localize `:@@forgot.signin-button:Sign In`;
        } let i18n_12; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_login_with_google$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_13 = goog.getMsg("Continue with Google ");
            i18n_12 = MSG_EXTERNAL_forgot_login_with_google$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_13;
        }
        else {
            i18n_12 = $localize `:@@forgot.login-with-google:Continue with Google `;
        } let i18n_14; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_forgot_login_with_facebook$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_15 = goog.getMsg("Continue with Facebook ");
            i18n_14 = MSG_EXTERNAL_forgot_login_with_facebook$$SRC_APP_PAGES_FORGOT_PASS_FORGOT_PASS_COMPONENT_TS_15;
        }
        else {
            i18n_14 = $localize `:@@forgot.login-with-facebook:Continue with Facebook `;
        } return [[1, "card", "card-white"], [1, "row", "g-0", "h-100"], [1, "col-12", "col-md-6", "d-none", "d-md-flex", "flex-column", "text-center"], [1, "my-auto"], ["autoplay", "", "loop", "", "mode", "normal", "src", "assets/animations/20221118-145915lf30_editor_ovlsossv.lottie", 2, "width", "100%"], [1, "mt-auto", "mb-2"], ["src", "assets/img/logo.png", 1, "img-fluid", 2, "height", "50px"], [1, "col-12", "col-md-6", "bg-purple", "d-flex", "flex-column", "justify-content-center"], [1, "mb-5", 2, "text-align", "center", "font-size", "3rem", "font-weight", "700", "letter-spacing", "0.035em", "line-height", "3.2rem", "color", "#fff"], i18n_0, ["novalidate", "", 3, "ngSubmit"], ["f", "ngForm"], ["appearance", "fill", 1, "mb-2", "w-100"], i18n_2, ["matInput", "", "type", "email", "placeholder", i18n_4, "id", "email", "name", "email", "ngModel", "", "email", "", "required", ""], ["email", "ngModel"], [1, "form-group", "d-flex", "flex-column", "flex-md-row", "align-items-center"], ["mat-button", "", "type", "submit", 1, "btn", "btn-yellow", "btn-block", "py-2", 3, "disabled"], i18n_6, [1, "ms-0", "ms-md-3", "mt-3", "mt-md-0", 2, "font-size", ".75rem", "font-weight", "500", "letter-spacing", "0.035em", "color", "#fff"], i18n_8, ["mat-button", "", "color", "accent", "routerLink", "/auth/signin", 2, "font-weight", "700", "padding", "5px"], i18n_10, [1, "row", "mt-3"], [1, "col-12", "col-lg-6", "mt-2"], ["mat-button", "", "type", "button", 1, "btn", "btn-white", "w-100", "py-2", "social-auth-btn", 3, "disabled", "click"], [1, "d-flex", "flex-row"], [2, "flex", "0 0 27px"], ["src", "assets/icons/google.svg"], [2, "flex", "1 1 auto", "align-self", "center"], i18n_12, ["src", "assets/icons/Facebook.svg", 2, "width", "25px"], i18n_14]; }, template: function ForgotPassComponent_Template(rf, ctx) { if (rf & 1) {
            const _r2 = i0.ɵɵgetCurrentView();
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "div", 3);
            i0.ɵɵelement(4, "dotlottie-player", 4);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "div", 5);
            i0.ɵɵelement(6, "img", 6);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(7, "div", 7)(8, "p", 8);
            i0.ɵɵi18n(9, 9);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "form", 10, 11);
            i0.ɵɵlistener("ngSubmit", function ForgotPassComponent_Template_form_ngSubmit_10_listener() { i0.ɵɵrestoreView(_r2); const _r0 = i0.ɵɵreference(11); return i0.ɵɵresetView(ctx.onSubmit(_r0)); });
            i0.ɵɵelementStart(12, "mat-form-field", 12)(13, "mat-label");
            i0.ɵɵi18n(14, 13);
            i0.ɵɵelementEnd();
            i0.ɵɵelement(15, "input", 14, 15);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(17, "div", 16)(18, "button", 17);
            i0.ɵɵelement(19, "i");
            i0.ɵɵelementStart(20, "span");
            i0.ɵɵi18n(21, 18);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(22, "span", 19);
            i0.ɵɵi18n(23, 20);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(24, "button", 21);
            i0.ɵɵi18n(25, 22);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(26, "div", 23)(27, "div", 24)(28, "button", 25);
            i0.ɵɵlistener("click", function ForgotPassComponent_Template_button_click_28_listener($event) { return ctx.googleAuth($event); });
            i0.ɵɵelementStart(29, "div", 26)(30, "div", 27);
            i0.ɵɵelement(31, "img", 28);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(32, "div", 29);
            i0.ɵɵi18n(33, 30);
            i0.ɵɵelementEnd()()()();
            i0.ɵɵelementStart(34, "div", 24)(35, "button", 25);
            i0.ɵɵlistener("click", function ForgotPassComponent_Template_button_click_35_listener($event) { return ctx.facebookAuth($event); });
            i0.ɵɵelementStart(36, "div", 26)(37, "div", 27);
            i0.ɵɵelement(38, "img", 31);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(39, "div", 29);
            i0.ɵɵi18n(40, 32);
            i0.ɵɵelementEnd()()()()()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(18);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(1);
            i0.ɵɵclassMapInterpolate1("fa fa-spinner fa-spin me-3 text-white ", ctx.loading ? "d-inline-flex" : "d-none", "");
            i0.ɵɵadvance(9);
            i0.ɵɵproperty("disabled", ctx.loading);
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("disabled", ctx.loading);
        } }, dependencies: [i5.ɵNgNoValidate, i5.DefaultValueAccessor, i5.NgControlStatus, i5.NgControlStatusGroup, i5.RequiredValidator, i5.EmailValidator, i5.NgModel, i5.NgForm, i6.DotlottiePlayerComponent, i7.RouterLink, i8.MatButton, i9.MatFormField, i9.MatLabel, i10.DefaultImageDirective, i11.MatInput], styles: [".card-white[_ngcontent-%COMP%] {\n  background-color: white;\n  box-shadow: 0px 6px 52px 26px rgba(0, 0, 0, 0.1);\n  -webkit-box-shadow: 0px 6px 52px 26px rgba(0, 0, 0, 0.1);\n  -moz-box-shadow: 0px 6px 52px 26px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  border: none;\n}\n\n.bg-purple[_ngcontent-%COMP%] {\n  background-color: #8817F8;\n  border-radius: 5px;\n  border: none;\n  padding: 40px 40px;\n}\n\n[_nghost-%COMP%]  .mat-form-field .mat-form-field-flex {\n  background-color: #fff;\n  border-radius: 20px;\n}\n[_nghost-%COMP%]  .mat-form-field-underline {\n  width: 0 !important;\n}\n\n.btn-yellow[_ngcontent-%COMP%] {\n  background: #F2994A;\n  border-radius: 20px;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;\n  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;\n  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;\n  font-size: 1rem !important;\n  font-weight: 700;\n  padding: 18px 30px;\n  color: #fff;\n}\n\n.social-auth-btn[_ngcontent-%COMP%] {\n  border-radius: 20px;\n}\n.social-auth-btn[_ngcontent-%COMP%]   *[_ngcontent-%COMP%] {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 17px;\n  letter-spacing: 0.035em;\n  color: black;\n}"] }); }
}

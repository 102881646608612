import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../types/AppConfig";
export class FileService {
    constructor(http, config) {
        this.http = http;
        this.config = config;
    }
    getDirectories() {
        const url = `${this.config.appUrl}/file/manage/list/directories`;
        return this.http.get(url);
    }
    getFiles($url) {
        const url = `${this.config.appUrl}/file/manage/list${$url}`;
        return this.http.get(url);
    }
    downloadFile(url) {
        return this.http.get(url, { responseType: 'blob' });
    }
    deleteFile($url) {
        const url = `${this.config.appUrl}/file/${$url}`;
        return this.http.delete(url);
    }
    uploadPrivateFile(project, task, data) {
        const url = `${this.config.appUrl}/file/upload/${project}/${task}`;
        return this.http.post(url, data);
    }
    createShareFile(data) {
        const url = `${this.config.appUrl}/file/share`;
        return this.http.post(url, data);
    }
    static { this.ɵfac = function FileService_Factory(t) { return new (t || FileService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfig, 8)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FileService, factory: FileService.ɵfac, providedIn: 'root' }); }
}

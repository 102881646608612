import { createEffect, ofType } from '@ngrx/effects';
import { delay, map, tap } from "rxjs/operators";
import { hideSnackbar, SnackbarActionTypes } from "./snackbar.actions";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "@angular/material/snack-bar";
export class SnackbarEffects {
    constructor(actions$, snackBar) {
        this.actions$ = actions$;
        this.snackBar = snackBar;
        this.hideSnackbarEffect = createEffect(() => this.actions$.pipe(ofType(SnackbarActionTypes.HIDE), tap(() => {
            this.snackBar.dismiss();
        })), {
            dispatch: false,
        });
        this.showSnackbarEffect = createEffect(() => this.actions$.pipe(ofType(SnackbarActionTypes.SHOW), tap((result) => {
            this.snackBar.open(result.message, result.action, result.config);
        }), delay(5000), map(() => hideSnackbar())));
    }
    static { this.ɵfac = function SnackbarEffects_Factory(t) { return new (t || SnackbarEffects)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.MatSnackBar)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SnackbarEffects, factory: SnackbarEffects.ɵfac }); }
}
